import axios from 'axios';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'constants/session-storage-constants';

// const BASE_URL = 'http://localhost:8080/';

export type RefreshAccessResponse = {
    access_token: string;
    refresh_token: string;
};

const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN);

    const response = await authApi.post<RefreshAccessResponse>(
        '/api/v1/auth/refresh',
        null,
        { headers: { Authorization: `Bearer ${refreshToken}` } }
    );
    localStorage.setItem(AUTH_TOKEN, response.data.access_token);
    localStorage.setItem(REFRESH_TOKEN, response.data.refresh_token);
    return response.data;
};

export const authApi = axios.create({});

// Request interceptor for API calls
authApi.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem(AUTH_TOKEN);
        if (config.url !== '/api/v1/auth/refresh') {
            config.headers.Authorization = `Bearer ${token}`;
        }
        config.headers.Accept = 'application/json';
        config.baseURL = process.env.REACT_APP_BASE_URL;
        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

authApi.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        const errStatus = error?.response?.status as number;

        // Prevent infinite loop
        if (
            errStatus === 403 &&
            originalRequest.url === '/api/v1/auth/refresh'
        ) {
            localStorage.removeItem(AUTH_TOKEN);
            localStorage.removeItem(REFRESH_TOKEN);
            window.location.href = '/login';
            return Promise.reject(error);
        }

        //TODO what will be returned by back end in case of token needs to be refreshed?
        if (errStatus === 403 && !originalRequest._retry) {
            originalRequest._retry = true;
            const response = await refreshAccessToken();
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + response.access_token;
            return authApi(originalRequest);
        }
        return Promise.reject(error);
    }
);
