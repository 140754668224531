import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './index.css';

import { ThemeProvider } from '@mui/system';
import theme from './theme';
import { BrowserRouter } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from 'react-query';
import { AuthMiddleware } from './middleware';
import { Provider } from 'react-redux';
import { store } from 'store/store';

const container = document.getElementById('root')!;
const root = createRoot(container);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            retry: 1,
            staleTime: 0,
            // staleTime: Infinity,
            // cacheTime: 10000,
            cacheTime: 1,
        },
    },
});

root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <Provider store={store}>
                        <AuthMiddleware>
                            <App />
                        </AuthMiddleware>
                    </Provider>
                </BrowserRouter>
            </QueryClientProvider>
        </ThemeProvider>
    </React.StrictMode>
);
