import { useQuery } from 'react-query';
import { SDGAssessmentListResponse } from './sdgAssessmentTypes';
import { getSDGAssessmentList } from './sdgAssessmentService';

type Props<M> = {
    assessmentId: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: SDGAssessmentListResponse) => void;
    mapper?: (
        data?: SDGAssessmentListResponse
    ) => M | SDGAssessmentListResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: SDGAssessmentListResponse) => data;

export const useGetSDGAssessmentList = <M = SDGAssessmentListResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    assessmentId,
}: Props<M>) => {
    const query = useQuery<SDGAssessmentListResponse, Error>(
        ['sdgAssessments', assessmentId],
        () => getSDGAssessmentList(assessmentId),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
