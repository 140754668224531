import { TableCell, TableHead, TableRow } from '@mui/material';

export const CustomTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Identifier</TableCell>
                <TableCell align='right'>Unique ID</TableCell>
                <TableCell align='right'>Category</TableCell>
                <TableCell align='right'>1st Sub-Category</TableCell>
                <TableCell align='right'>2nd Sub-Category</TableCell>
                <TableCell align='right'>Question</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    );
};
