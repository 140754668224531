import {
    CompanyPerformanceQuestionListResponse,
    CompanyPerformanceQuestionResponse,
    CreateUpdateCompanyPerformanceQuestion,
} from 'api/hooks/company-performance-question/companyPerformanceQuestionsTypes';
import { authApi } from 'lib';

export const getCompanyPerformanceQuestionList = async () => {
    const response = await authApi.get<CompanyPerformanceQuestionListResponse>(
        '/api/v1/company_performance_questions'
    );
    return response.data;
};

export const getCompanyPerformanceQuestionById = async (id: string) => {
    const response = await authApi.get<CompanyPerformanceQuestionResponse>(
        `/api/v1/company_performance_questions/${id}`
    );
    return response.data;
};

export const createCompanyPerformanceQuestion = async (
    request: CreateUpdateCompanyPerformanceQuestion
) => {
    const response = await authApi.post<CompanyPerformanceQuestionResponse>(
        `/api/v1/company_performance_questions`,
        request
    );
    return response.data;
};

export const updateCompanyPerformanceQuestion = async (
    id: string,
    request: CreateUpdateCompanyPerformanceQuestion
) => {
    const response = await authApi.put<CompanyPerformanceQuestionResponse>(
        `/api/v1/company_performance_questions/${id}`,
        request
    );
    return response.data;
};

export const deleteCompanyPerformanceQuestion = async (id: string) => {
    return await authApi.delete(`/api/v1/company_performance_questions/${id}`);
};
