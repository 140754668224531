import { TableCell, TableHead, TableRow } from '@mui/material';

export const CustomTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align='right'>Code</TableCell>
                <TableCell align='right' width={'50%'}>
                    Sub-Industries
                </TableCell>
                <TableCell align='right' width={'50%'}>
                    Participates in reports
                </TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    );
};
