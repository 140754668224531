import { QaStatus } from '../commonTypes';

export type MaterialityAssessmentScoreByAnswer = {
    answer?: string;
    score?: number;
};

export type MaterialityAssessmentAssurerCommentUser = {
    _id: string;
    name?: string;
    last_name?: string;
};

export type MaterialityAssessmentAssurerComment = {
    date_time?: string;
    user?: Partial<MaterialityAssessmentAssurerCommentUser>;
    comment?: string;
};

export enum MaterialityAssessmentAnswerValidation {
    'Yes or ND' = 'Yes or ND',
    'Yes or ND or n/a' = 'Yes or ND or n/a',
}

export enum MaterialityAssessmentYesOrND {
    'Yes' = 'Yes',
    'ND' = 'ND',
}

export enum MaterialityAssessmentYesOrNDOrNA {
    'Yes' = 'Yes',
    'ND' = 'ND',
    'n/a' = 'n/a',
}

export type MaterialityAssessment = {
    _id: string;
    question?: string;
    max_score_possible?: number;
    scores_by_answer?: MaterialityAssessmentScoreByAnswer[];
    answer_validation: MaterialityAssessmentAnswerValidation;
    answer?: string;
    reference_for_answer?: string;
    suggested_answer?: string;
    score?: number;
    weighted_score?: number;
    weighted_max_score_possible?: number;
    assurer_comment?: MaterialityAssessmentAssurerComment[];
    imported?: boolean;
    date_imported?: string;
    assessment?: string;
    question_number?: number;
    for_qa?: boolean;
    qa_status?: QaStatus;
    scoring_guidance?: string;
    has_comments: boolean;
};

export type CreateMaterialityAssessment = {
    answer?: string;
    reference_for_answer?: string;
    qa_status?: QaStatus;
};

export type MaterialityAssessmentListResponse = MaterialityAssessment[];
