import { QaStatus } from '../commonTypes';

export type CompanyPerformanceAssessmentFilters = {
    answer_for_current_fiscal_year?: string;
    answer_for_previous_fiscal_year?: string;
    answer_for_year_before_previous_fiscal_year?: string;
    qa_status?: string[];
    has_comments?: string;
};

export enum CompanyPerformanceAssessmentAnswerValidation {
    'Date before company fiscal year end' = 'Date before company fiscal year end',
    'From a list of currency codes or n/a' = 'From a list of currency codes or n/a',
    'Float equal or greater than zero or ND' = 'Float equal or greater than zero or ND',
    'Float equal or greater than zero or ND or n/a' = 'Float equal or greater than zero or ND or n/a',
    'Integer equal or greater than zero or ND' = 'Integer equal or greater than zero or ND',
    'Disabled' = 'Disabled',
    'From options: No indication of less than full coverage, Less than full coverage, Continuing operations only, ND' = 'From options: No indication of less than full coverage, Less than full coverage, Continuing operations only, ND',
    '(Percentage equal or greater than zero and less than or equal to 100%) or ND' = '(Percentage equal or greater than zero and less than or equal to 100%) or ND',
    'From options: No indication of less than full coverage, Less than full coverage, Continuing operations only, ND, n/a' = 'From options: No indication of less than full coverage, Less than full coverage, Continuing operations only, ND, n/a',
    'Integer equal or greater than zero or ND or n/a' = 'Integer equal or greater than zero or ND or n/a',
    'From a list of currency codes or n/a or ND' = 'From a list of currency codes or n/a or ND',
    '(Percentage equal or greater than zero and less than or equal to 100%) or ND or n/a' = '(Percentage equal or greater than zero and less than or equal to 100%) or ND or n/a',
    '(Percentage equal or greater than zero) or ND or n/a' = '(Percentage equal or greater than zero) or ND or n/a',
    'From options: Yes, No' = 'From options: Yes, No',
    'Integer greater than zero or ND or n/a' = 'Integer greater than zero or ND or n/a',
    'Date in the past' = 'Date in the past',
    // 'From options: All or A, B, C, D, E (or any combination of letters A-E separated by a comma) or ND or n/a' = 'From options: All or A, B, C, D, E (or any combination of letters A-E separated by a comma) or ND or n/a',
    'From options: Yes, No, Not determined based on public disclosure' = 'From options: Yes, No, Not determined based on public disclosure',
    'From options: Yes, No, Not determined based on public disclosure, n/a' = 'From options: Yes, No, Not determined based on public disclosure, n/a',
    'From options: Mining Only, Manufacturing only, Both, Not determined based on public disclosure, n/a' = 'From options: Mining Only, Manufacturing only, Both, Not determined based on public disclosure, n/a',
    'From options: Yes, No, No indication based on public disclosure' = 'From options: Yes, No, No indication based on public disclosure',
    'From options: Yes, No, ND' = 'From options: Yes, No, ND',
    'From options: Yes, ND' = 'From options: Yes, ND',
    'n/a or ND or Date in the past' = 'n/a or ND or Date in the past',
}

export enum CompanyPerformanceAssessmentNoIndicationFullCoverageOperations {
    'No indication of less than full coverage' = 'No indication of less than full coverage',
    'Less than full coverage' = 'Less than full coverage',
    'Continuing operations only' = 'Continuing operations only',
    'ND' = 'ND',
}

export enum CompanyPerformanceAssessmentNoIndicationFullCoverageOperationsNDNA {
    'No indication of less than full coverage' = 'No indication of less than full coverage',
    'Less than full coverage' = 'Less than full coverage',
    'Continuing operations only' = 'Continuing operations only',
    'ND' = 'ND',
    'n/a' = 'n/a',
}

export enum CompanyPerformanceAssessmentMiningManufacturingNotDetermined {
    'Mining Only' = 'Mining Only',
    'Manufacturing only' = 'Manufacturing only',
    'Not determined based on public disclosure' = 'Not determined based on public disclosure',
    'Both' = 'Both',
    'n/a' = 'n/a',
}

export enum CompanyPerformanceAssessmentYesNo {
    'Yes' = 'Yes',
    'No' = 'No',
}

export enum CompanyPerformanceAssessmentYesND {
    'Yes' = 'Yes',
    'ND' = 'ND',
}

export enum CompanyPerformanceAssessmentYesNoND {
    'Yes' = 'Yes',
    'No' = 'No',
    'ND' = 'ND',
}

export enum CompanyPerformanceAssessmentYesNoNoIndication {
    'Yes' = 'Yes',
    'No' = 'No',
    'No indication based on public disclosure' = 'No indication based on public disclosure',
}

export enum CompanyPerformanceAssessmentYesNoNotDeterminedNA {
    'Yes' = 'Yes',
    'No' = 'No',
    'Not determined based on public disclosure' = 'Not determined based on public disclosure',
    'n/a' = 'n/a',
}

export enum CompanyPerformanceAssessmentYesNoNotDetermined {
    'Yes' = 'Yes',
    'No' = 'No',
    'Not determined based on public disclosure' = 'Not determined based on public disclosure',
}

// TODO INPUT, DATE, CURRENCIES, Options

export type CompanyPerformanceAssessment = {
    _id: string;
    question_number?: number;
    unique_id?: string;
    section?: string;
    topic?: string;
    link_to_at?: string;
    question_id?: string;
    question?: string;
    guidance?: string;
    assessment?: string;
    answer_for_previous_fiscal_year?: string;
    answer_for_current_fiscal_year?: string;
    answer_for_year_before_previous_fiscal_year?: string;
    suggested_answer?: string;
    answer_validation?: CompanyPerformanceAssessmentAnswerValidation;
    previous_fiscal_year_answer_validation?: CompanyPerformanceAssessmentAnswerValidation;
    year_before_previous_fiscal_year_answer_validation?: CompanyPerformanceAssessmentAnswerValidation;
    for_qa?: boolean;
    qa_status?: QaStatus;
    has_comments: boolean;
    formula_for_previous_fiscal_year?: string;
    formula_for_current_fiscal_year?: string;
    formula_for_year_before_previous_fiscal_year?: string;
};

export type CreateCompanyPerformanceAssessment = {
    formula_for_previous_fiscal_year?: string;
    formula_for_current_fiscal_year?: string;
    formula_for_year_before_previous_fiscal_year?: string;
    answer_for_previous_fiscal_year: string;
    answer_for_current_fiscal_year: string;
    answer_for_year_before_previous_fiscal_year: string;
    qa_status?: QaStatus;
};

export type CompanyPerformanceAssessmentListResponse =
    CompanyPerformanceAssessment[];
