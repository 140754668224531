import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@mui/material';
import { FC, useState } from 'react';

type Props = {
    open: boolean;
    value: string;
    onClose: () => void;
    onSave: (value: string) => void;
    title?: string;
    contentText?: string;
    isReadOnly: boolean;
};

export const AdditionalInfoDialog: FC<Props> = ({
    open,
    onClose,
    onSave,
    title,
    contentText,
    value: initialValue,
    isReadOnly,
}) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleSave = () => {
        onSave(value);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>
                {contentText && (
                    <DialogContentText>{contentText}</DialogContentText>
                )}

                <TextField
                    autoFocus
                    fullWidth
                    variant='standard'
                    value={value}
                    onChange={handleChange}
                    multiline
                    rows={4}
                    disabled={isReadOnly}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleSave} disabled={isReadOnly}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
