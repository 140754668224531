import { Footer } from 'components/footer';
import { Outlet } from 'react-router-dom';
import { Header } from '../../header';
import { BodyContainer } from './layout.styles';
import { Box } from '@mui/material';

export const Layout = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100vh',
            }}
        >
            <Box>
                <Header />
                <BodyContainer>
                    <Outlet />
                </BodyContainer>
            </Box>
            <Footer />
        </Box>
    );
};
