import { authApi } from 'lib';
import {
    CreateIndustry,
    Industry,
    IndustryListResponse,
    UpdateIndustry,
} from './industryTypes';

export const getIndustryList = async () => {
    const response = await authApi.get<IndustryListResponse>(
        '/api/v1/industries'
    );
    return response.data;
};

export const createIndustry = async (request: CreateIndustry) => {
    const response = await authApi.post<Industry>('api/v1/industries', request);
    return response.data;
};

export const updateIndustry = async (id: string, request: UpdateIndustry) => {
    const response = await authApi.put<Industry>(
        `api/v1/industries/${id}`,
        request
    );
    return response.data;
};
