import { FC, useState } from 'react';
import {
    CompanyPerformanceAssessmentAnswerValidation,
    CompanyPerformanceAssessmentMiningManufacturingNotDetermined,
    CompanyPerformanceAssessmentNoIndicationFullCoverageOperations,
    CompanyPerformanceAssessmentNoIndicationFullCoverageOperationsNDNA,
    CompanyPerformanceAssessmentYesND,
    CompanyPerformanceAssessmentYesNo,
    CompanyPerformanceAssessmentYesNoND,
    CompanyPerformanceAssessmentYesNoNoIndication,
    CompanyPerformanceAssessmentYesNoNotDetermined,
    CompanyPerformanceAssessmentYesNoNotDeterminedNA,
} from 'api/hooks/company-performance-assessment/companyPerformanceAssessmentTypes';
import {
    Autocomplete,
    Box,
    IconButton,
    TextField,
    Tooltip,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { EnumToDropdownOptions } from 'utils';
import InfoIcon from '@mui/icons-material/Info';
import { CurrencyCode } from 'utils/enums/currency-code.enum';
import { formatNumberWithThousandSeparations } from 'utils/common';

type Props = {
    answerValidation?: CompanyPerformanceAssessmentAnswerValidation;
    value: string;
    formula: string;
    name: string;
    formulaName: string;
    onChange: (
        name: string,
        formulaName: string,
        value: string,
        formulaValue: string
    ) => void;
    fiscalYearEnd?: string;
    isReadOnly: boolean;
};

export const AnswerValidationCombination: FC<Props> = ({
    answerValidation,
    value,
    formula,
    name,
    formulaName,
    onChange,
    fiscalYearEnd,
    isReadOnly,
}) => {
    const [inputValue, setInputValue] = useState(value);
    const [formulaValue, setFormulaValue] = useState(formula);
    const [isInputError, setIsInputError] = useState(false);

    const validationRules: { [key: string]: (input: string) => boolean } = {
        [CompanyPerformanceAssessmentAnswerValidation[
            '(Percentage equal or greater than zero and less than or equal to 100%) or ND'
        ]]: (input: string) => input === 'ND' || (+input >= 0 && +input <= 100),

        [CompanyPerformanceAssessmentAnswerValidation[
            '(Percentage equal or greater than zero and less than or equal to 100%) or ND or n/a'
        ]]: (input: string) =>
            input === 'ND' || input === 'n/a' || (+input >= 0 && +input <= 100),

        [CompanyPerformanceAssessmentAnswerValidation[
            '(Percentage equal or greater than zero) or ND or n/a'
        ]]: (input: string) => input === 'ND' || input === 'n/a' || +input >= 0,

        [CompanyPerformanceAssessmentAnswerValidation[
            'Float equal or greater than zero or ND'
        ]]: (input: string) => input === 'ND' || +input >= 0,

        [CompanyPerformanceAssessmentAnswerValidation[
            'Float equal or greater than zero or ND or n/a'
        ]]: (input: string) => input === 'ND' || input === 'n/a' || +input >= 0,

        // [CompanyPerformanceAssessmentAnswerValidation[
        //     'From options: All or A, B, C, D, E (or any combination of letters A-E separated by a comma) or ND or n/a'
        // ]]: (input: string) =>
        //     input === 'ND' ||
        //     input === 'n/a' ||
        //     input === 'All' ||
        //     isLetterCombinationValid(input),

        [CompanyPerformanceAssessmentAnswerValidation[
            'Integer equal or greater than zero or ND'
        ]]: (input: string) =>
            input === 'ND' || (Number.isInteger(+input) && +input >= 0),

        [CompanyPerformanceAssessmentAnswerValidation[
            'Integer equal or greater than zero or ND or n/a'
        ]]: (input: string) =>
            input === 'ND' ||
            input === 'n/a' ||
            (Number.isInteger(+input) && +input >= 0),

        [CompanyPerformanceAssessmentAnswerValidation[
            'Integer greater than zero or ND or n/a'
        ]]: (input: string) =>
            input === 'ND' ||
            input === 'n/a' ||
            (Number.isInteger(+input) && +input > 0),

        [CompanyPerformanceAssessmentAnswerValidation[
            'n/a or ND or Date in the past'
        ]]: (input: string) =>
            input === 'ND' || input === 'n/a' || checkIfDateInPast(input),
    };

    const isInputComponent = (): boolean => {
        return validationRules.hasOwnProperty(answerValidation ?? '');
    };

    const isInputDateComponent = (): boolean => {
        return (
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'Date before company fiscal year end'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation['Date in the past']
        );
    };

    const isOptionsComponent = (): boolean => {
        return (
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From options: Yes, No, ND'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From options: Yes, No, No indication based on public disclosure'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From options: Yes, No, Not determined based on public disclosure, n/a'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From a list of currency codes or n/a'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From a list of currency codes or n/a or ND'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From options: Mining Only, Manufacturing only, Both, Not determined based on public disclosure, n/a'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From options: No indication of less than full coverage, Less than full coverage, Continuing operations only, ND'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From options: Yes, ND'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From options: Yes, No'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From options: No indication of less than full coverage, Less than full coverage, Continuing operations only, ND, n/a'
                ] ||
            answerValidation ===
                CompanyPerformanceAssessmentAnswerValidation[
                    'From options: Yes, No, Not determined based on public disclosure'
                ]
        );
    };

    const isInputValid = (input: string) => {
        if (answerValidation) {
            const validationFunction = validationRules[answerValidation ?? ''];
            if (validationFunction) {
                return validationFunction(input);
            }
            return false;
        }
        return true;
    };

    // const isLetterCombinationValid = (input: string) => {
    //     const validOptions = ['A', 'B', 'C', 'D', 'E'];
    //     const inputArray = input.split(',').map((item) => item.trim());

    //     for (const item of inputArray) {
    //         if (!validOptions.includes(item)) {
    //             return false;
    //         }
    //     }

    //     return true;
    // };

    const checkIfDateInPast = (input: string) => {
        const inputDate = new Date(input);
        const currentDate = new Date();

        return inputDate < currentDate;
    };

    const isInputDisabled = () => {
        return (
            answerValidation ===
            CompanyPerformanceAssessmentAnswerValidation.Disabled
        );
    };

    const handleFormulaInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = e.target;
        setFormulaValue(value);
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = e.target;
        setInputValue(value);
    };

    const handleFormulaInputBlur = () => {
        if (formulaValue !== value) {
            onChange(name, formulaName, inputValue, formulaValue);
            return;
        }
    };

    const handleInputBlur = () => {
        if (inputValue !== value) {
            if (isInputValid(inputValue)) {
                setIsInputError(false);
                onChange(name, formulaName, inputValue, formulaValue);
                return;
            }

            setIsInputError(true);
        }
    };

    const handleArticleDateChange = (newValue: Date | null) => {
        if (!newValue) return;

        const modifiedArticleDate = moment(newValue).format('YYYY-MM-DD');

        onChange(name, formulaName, modifiedArticleDate, formulaValue);
    };

    const maxDate =
        fiscalYearEnd &&
        answerValidation ===
            CompanyPerformanceAssessmentAnswerValidation[
                'Date before company fiscal year end'
            ]
            ? new Date(fiscalYearEnd)
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation['Date in the past']
            ? new Date()
            : null;

    const CompanyPerformanceAssessmentYesNoNDOptions = EnumToDropdownOptions(
        CompanyPerformanceAssessmentYesNoND
    );
    const CompanyPerformanceAssessmentYesNoNoIndicationOptions =
        EnumToDropdownOptions(CompanyPerformanceAssessmentYesNoNoIndication);
    const CompanyPerformanceAssessmentYesNoNotDeterminedOptions =
        EnumToDropdownOptions(CompanyPerformanceAssessmentYesNoNotDeterminedNA);
    const CurrencyOptions = EnumToDropdownOptions(CurrencyCode);
    const CompanyPerformanceAssessmentNoIndicationFullCoverageOperationsOptions =
        EnumToDropdownOptions(
            CompanyPerformanceAssessmentNoIndicationFullCoverageOperations
        );
    const CompanyPerformanceAssessmentMiningManufacturingNotDeterminedOptions =
        EnumToDropdownOptions(
            CompanyPerformanceAssessmentMiningManufacturingNotDetermined
        );
    const CompanyPerformanceAssessmentYesNoOptions = EnumToDropdownOptions(
        CompanyPerformanceAssessmentYesNo
    );
    const CompanyPerformanceAssessmentYesNDOptions = EnumToDropdownOptions(
        CompanyPerformanceAssessmentYesND
    );

    const CompanyPerformanceAssessmentYesNDNotDeterminedOptions =
        EnumToDropdownOptions(CompanyPerformanceAssessmentYesNoNotDetermined);

    const CompanyPerformanceAssessmentNoIndicationFullCoverageOperationsNDNAOptions =
        EnumToDropdownOptions(
            CompanyPerformanceAssessmentNoIndicationFullCoverageOperationsNDNA
        );

    const answerValidationTypeOptions =
        answerValidation ===
        CompanyPerformanceAssessmentAnswerValidation[
            'From options: Yes, No, ND'
        ]
            ? CompanyPerformanceAssessmentYesNoNDOptions
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'From options: Yes, No, No indication based on public disclosure'
              ]
            ? CompanyPerformanceAssessmentYesNoNoIndicationOptions
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'From options: Mining Only, Manufacturing only, Both, Not determined based on public disclosure, n/a'
              ]
            ? CompanyPerformanceAssessmentMiningManufacturingNotDeterminedOptions
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'From options: No indication of less than full coverage, Less than full coverage, Continuing operations only, ND'
              ]
            ? CompanyPerformanceAssessmentNoIndicationFullCoverageOperationsOptions
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'From options: Yes, ND'
              ]
            ? CompanyPerformanceAssessmentYesNDOptions
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'From options: Yes, No'
              ]
            ? CompanyPerformanceAssessmentYesNoOptions
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'From options: Yes, No, Not determined based on public disclosure, n/a'
              ]
            ? CompanyPerformanceAssessmentYesNoNotDeterminedOptions
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'Float equal or greater than zero or ND'
              ]
            ? [...CurrencyOptions, { value: 'ND', label: 'ND' }]
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'From a list of currency codes or n/a or ND'
              ]
            ? [
                  ...CurrencyOptions,
                  { value: 'ND', label: 'ND' },
                  { value: 'n/a', label: 'n/a' },
              ]
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'From a list of currency codes or n/a'
              ]
            ? [...CurrencyOptions, { value: 'n/a', label: 'n/a' }]
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'From options: No indication of less than full coverage, Less than full coverage, Continuing operations only, ND, n/a'
              ]
            ? CompanyPerformanceAssessmentNoIndicationFullCoverageOperationsNDNAOptions
            : answerValidation ===
              CompanyPerformanceAssessmentAnswerValidation[
                  'From options: Yes, No, Not determined based on public disclosure'
              ]
            ? CompanyPerformanceAssessmentYesNDNotDeterminedOptions
            : null;

    // const handleSelect = (event: unknown | SelectChangeEvent) => {
    //     const { name, value } = (event as SelectChangeEvent).target;
    //     console.log({ name, value });

    //     onChange(name, value);
    // };

    const handleSelect = (newValue: string) => {
        // const { name, value } = (event as SelectChangeEvent).target;
        // console.log({ name, value });

        onChange(name, formulaName, newValue, formulaValue);
    };

    return answerValidation ? (
        <>
            {isInputDisabled() ? (
                <TextField
                    sx={{ width: 200 }}
                    value={inputValue}
                    name={name}
                    disabled
                    style={{ backgroundColor: 'lightgray' }}
                />
            ) : isInputComponent() ? (
                <Box display={'flex'} flexDirection={'column'}>
                    <TextField
                        sx={{ width: 200 }}
                        value={formulaValue}
                        name={name}
                        onChange={handleFormulaInputChange}
                        onBlur={handleFormulaInputBlur}
                        // error={isInputError}
                        disabled={isReadOnly}
                    />
                    Score:{' '}
                    {formatNumberWithThousandSeparations(parseInt(inputValue))}
                </Box>
            ) : isInputDateComponent() ? (
                <DesktopDatePicker
                    inputFormat='DD/MM/YYYY'
                    value={new Date(value)}
                    onChange={(newValue) => {
                        handleArticleDateChange(newValue);
                    }}
                    maxDate={maxDate}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant='outlined'
                            sx={{ width: 200 }}
                            error={isInputError}
                        />
                    )}
                    disabled={isReadOnly}
                />
            ) : isOptionsComponent() ? (
                <Autocomplete
                    disabled={isReadOnly}
                    sx={{ width: 200 }}
                    options={
                        answerValidationTypeOptions?.map(
                            (option) => option.value
                        ) ?? []
                    }
                    renderInput={(params) => (
                        <TextField {...params} name={name} />
                    )}
                    isOptionEqualToValue={(option, value) => {
                        return option === value;
                    }}
                    onChange={(event: any, newValue: string | null) => {
                        // console.log({ event: event.target.value });
                        handleSelect(newValue ?? '');
                    }}
                    value={value}
                />
            ) : null}
            <Tooltip title={answerValidation} placement='right'>
                <IconButton>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
        </>
    ) : (
        <>
            <TextField
                disabled={isReadOnly}
                sx={{ width: 200 }}
                value={inputValue}
                name={name}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
            />
            <Tooltip title={'No Validation'} placement='right'>
                <IconButton>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

// const isInputComponent = () => {
//     const isInput =
//         answerValidation ===
//             CompanyPerformanceAssessmentAnswerValidation[
//                 '(Percentage equal or greater than zero and less than or equal to 100%) or ND'
//             ] ||
//         answerValidation ===
//             CompanyPerformanceAssessmentAnswerValidation[
//                 '(Percentage equal or greater than zero and less than or equal to 100%) or ND or n/a'
//             ] ||
//         answerValidation ===
//             CompanyPerformanceAssessmentAnswerValidation[
//                 '(Percentage equal or greater than zero) or ND or n/a'
//             ] ||
//         answerValidation ===
//             CompanyPerformanceAssessmentAnswerValidation[
//                 'Float equal or greater than zero or ND'
//             ] ||
//         answerValidation ===
//             CompanyPerformanceAssessmentAnswerValidation[
//                 'Float equal or greater than zero or ND or n/a'
//             ] ||
//         answerValidation ===
//             CompanyPerformanceAssessmentAnswerValidation[
//                 'From options: All or A, B, C, D, E (or any combination of letters A-E separated by a comma) or ND or n/a'
//             ] ||
//         answerValidation ===
//             CompanyPerformanceAssessmentAnswerValidation[
//                 'Integer equal or greater than zero or ND'
//             ] ||
//         answerValidation ===
//             CompanyPerformanceAssessmentAnswerValidation[
//                 'Integer equal or greater than zero or ND or n/a'
//             ] ||
//         answerValidation ===
//             CompanyPerformanceAssessmentAnswerValidation[
//                 'Integer greater than zero or ND or n/a'
//             ] ||
//         answerValidation ===
//             CompanyPerformanceAssessmentAnswerValidation[
//                 'n/a or ND or Date in the past'
//             ];

//     return isInput;
// };

// const isInputValid = (input: string): boolean => {
//     if (
//         answerValidation ===
//         CompanyPerformanceAssessmentAnswerValidation[
//             '(Percentage equal or greater than zero and less than or equal to 100%) or ND'
//         ]
//     ) {
//         return input === 'ND' || (+input >= 0 && +input <= 100);
//     } else if (
//         answerValidation ===
//         CompanyPerformanceAssessmentAnswerValidation[
//             '(Percentage equal or greater than zero and less than or equal to 100%) or ND or n/a'
//         ]
//     ) {
//         return (
//             input === 'ND' ||
//             input === 'n/a' ||
//             (+input >= 0 && +input <= 100)
//         );
//     } else if (
//         answerValidation ===
//         CompanyPerformanceAssessmentAnswerValidation[
//             '(Percentage equal or greater than zero) or ND or n/a'
//         ]
//     ) {
//         return input === 'ND' || input === 'n/a' || +input >= 0;
//     } else if (
//         answerValidation ===
//         CompanyPerformanceAssessmentAnswerValidation[
//             'Float equal or greater than zero or ND'
//         ]
//     ) {
//         return input === 'ND' || +input >= 0;
//     } else if (
//         answerValidation ===
//         CompanyPerformanceAssessmentAnswerValidation[
//             'Float equal or greater than zero or ND or n/a'
//         ]
//     ) {
//         return input === 'ND' || input === 'n/a' || +input >= 0;
//     } else if (
//         answerValidation ===
//         CompanyPerformanceAssessmentAnswerValidation[
//             'From options: All or A, B, C, D, E (or any combination of letters A-E separated by a comma) or ND or n/a'
//         ]
//     ) {
//         return (
//             input === 'ND' ||
//             input === 'n/a' ||
//             input === 'All' ||
//             isLetterCombinationValid(input)
//         );
//     } else if (
//         answerValidation ===
//         CompanyPerformanceAssessmentAnswerValidation[
//             'Integer equal or greater than zero or ND'
//         ]
//     ) {
//         return input === 'ND' || (Number.isInteger(+input) && +input >= 0);
//     } else if (
//         answerValidation ===
//         CompanyPerformanceAssessmentAnswerValidation[
//             'Integer equal or greater than zero or ND or n/a'
//         ]
//     ) {
//         return (
//             input === 'ND' ||
//             input === 'n/a' ||
//             (Number.isInteger(+input) && +input >= 0)
//         );
//     } else if (
//         answerValidation ===
//         CompanyPerformanceAssessmentAnswerValidation[
//             'Integer greater than zero or ND or n/a'
//         ]
//     ) {
//         return (
//             input === 'ND' ||
//             input === 'n/a' ||
//             (Number.isInteger(+input) && +input > 0)
//         );
//     } else if (
//         answerValidation ===
//         CompanyPerformanceAssessmentAnswerValidation[
//             'n/a or ND or Date in the past'
//         ]
//     ) {
//         return (
//             input === 'ND' || input === 'n/a' || checkIfDateInPast(input)
//         );
//     }

//     return false;
// };
