import {
    Button,
    Checkbox,
    Container,
    FormControlLabel,
    Stack,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import { generateRoutePath } from 'utils';
import { WithSpinner } from 'components/with-spinner';
import { useNavigate, useParams } from 'react-router';
import { RoutePaths } from 'routes';
import { AdminRoutePaths } from 'constants/enums/routes-constants';
import { PageTitle } from 'components/shared/page-title';
import { useGetCategory } from 'api/hooks/category/useGetCategory';
import { useCreateCategory } from 'api/hooks/category/useCreateCategory';
import { useUpdateCategory } from 'api/hooks/category/useUpdateCategory';
import { CreateUpdateCategory } from 'api/hooks/category/categoryTypes';

type Props = {};

export const AdminCategoryForm: FC<Props> = () => {
    const navigate = useNavigate();
    const { categoryId } = useParams();
    const isNew = categoryId === '0';

    const { data: category, isLoading: categoryLoading } = useGetCategory({
        id: categoryId ?? '',
        enabled: !isNew,
        onError: () => {
            navigateToCategoryList();
        },
        onSuccess: (data) => {
            formik.setValues({
                category_name: data?.category_name,
                first_sub_category_name: data?.first_sub_category_name,
                version: data?.version,
                use_for_benchmarking: data?.use_for_benchmarking,
                category_code: data?.category_code,
            });
        },
    });

    const { mutate: createCategory } = useCreateCategory({
        onSuccess: () => navigateToCategoryList(),
    });

    const { mutate: updateCategory } = useUpdateCategory({
        onSuccess: () => navigateToCategoryList(),
    });

    const formName = AdminCategoryForm.name;
    const validationSchema = Yup.object({
        category_name: Yup.string().required('Required'),
        first_sub_category_name: Yup.string().required('Required'),
        version: Yup.string().required('Required'),
        use_for_benchmarking: Yup.bool().required('Required'),
    });
    const formik = useFormik({
        initialValues: {
            category_name: '',
            first_sub_category_name: '',
            version: '',
            use_for_benchmarking: false,
            category_code: '',
        },
        validationSchema,
        onSubmit: (data) => {
            const request: CreateUpdateCategory = {
                category_name: data.category_name,
                first_sub_category_name: data.first_sub_category_name,
                use_for_benchmarking: data.use_for_benchmarking,
                version: data.version,
            };

            createUpdateCategory(request);
        },
    });
    type FormikTypes = typeof formik.initialValues;

    const navigateToCategoryList = () => {
        navigate(
            generateRoutePath([
                RoutePaths.ADMIN,
                AdminRoutePaths.ADMIN_CATEGORY,
            ])
        );
    };

    const handleCancel = () => {
        navigateToCategoryList();
    };

    const createUpdateCategory = (request: CreateUpdateCategory) => {
        if (isNew) {
            createCategory(request);
        } else {
            updateCategory({
                id: category._id,
                request,
            });
        }
    };

    const isLoading = categoryLoading;

    return (
        <WithSpinner isLoading={isLoading}>
            <Container>
                <PageTitle
                    title={
                        isNew
                            ? 'Create New Company Performance'
                            : `Update Question ${category?.category_code}`
                    }
                />
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'category_name',
                                    'Category Name',
                                    formName,
                                    formik
                                )}
                            />
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'first_sub_category_name',
                                    'First Sub-Category Name',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'version',
                                    'Version',
                                    formName,
                                    formik
                                )}
                            />
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'category_code',
                                    'Category Code',
                                    formName,
                                    formik
                                )}
                                disabled
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <div style={{ width: '100%' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={
                                                formik.values
                                                    .use_for_benchmarking
                                            }
                                            onChange={(e) => {
                                                formik.setFieldValue(
                                                    'use_for_benchmarking',
                                                    !formik.values
                                                        .use_for_benchmarking
                                                );
                                            }}
                                        />
                                    }
                                    label='Use For Benchmarking'
                                />
                            </div>
                        </Stack>
                        <Stack
                            direction={'row'}
                            spacing={2}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                color='primary'
                                data-testid={`${formName}-cancel`}
                                variant='contained'
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                color='primary'
                                data-testid={`${formName}-submit`}
                                role='submit'
                                type='submit'
                                variant='contained'
                            >
                                Save
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Container>
        </WithSpinner>
    );
};
