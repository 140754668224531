import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import userReducer from './slices/userSlice';
import controversiesReducer from './slices/controversiesAssessmentSlice';
import companyPerformanceReducer from './slices/companyPerformanceAssessmentSlice';
import mainQuestionsReducer from './slices/mainQuestionAssessmentSlice';
import materialityReducer from './slices/materialityAssessmentSlice';
import sdgReducer from './slices/sdgAssessmentSlice';
import autoSaveReducer from './slices/autoSaveSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        controversiesAssessment: controversiesReducer,
        companyPerformanceAssessment: companyPerformanceReducer,
        mainQuestionAssessment: mainQuestionsReducer,
        materialityAssessment: materialityReducer,
        sdgAssessment: sdgReducer,
        autoSave: autoSaveReducer,
    },
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
