import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { PrivacyNotice } from 'components/privacy-notice/';

export const Footer = () => {
    const getCurrentYear = () => new Date().getFullYear();
    return (
        <Box
            sx={{
                // flexGrow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                // height: 50,
                padding: 5,
            }}
        >
            <PrivacyNotice />
            <Typography component='div' sx={{ fontSize: '0.75rem' }}>
                © {getCurrentYear()} CEN Group Holdings Limited | company
                number: 15126793 | registered address: Jmh House, 481 Green
                Lanes, Palmers Green, London, N13 4BS, United Kingdom
            </Typography>
        </Box>
    );
};
