import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import { CustomTableHeader } from './table-header';
import { CustomTableBody } from './table-body';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { CustomPagination } from 'components/shared/custom-pagination';
import { ControversyAssessment } from 'api/hooks/controversy-assessment/controversyAssessmentTypes';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
    addNewControversy,
    softDeleteControversy,
    selectControversiesAssessmentTableDataActive,
    updateControversy,
    setInitialControversies,
    selectControversiesAssessmentPageData,
    updateRowsPerPage,
    updatePage,
    toggleSelected,
    updateQaStatus,
} from 'store/slices/controversiesAssessmentSlice';
import PopChat from 'components/popchat/popchat';
import { AssessmentCommentTarget } from 'api/hooks/assessment-comments/assessmentCommentsTypes';
import { QaStatus } from 'api/hooks/commonTypes';
import { useGetControversyAssessmentList } from 'api/hooks/controversy-assessment/useGetControversyAssessmentList';
import { useParams } from 'react-router';
import { WithSpinner } from 'components/with-spinner';
import { useControversyAssessmentnSave } from './use-controversy-assessment-save';
import { QaStatusComponent } from '../qa-status';

type Props = {
    isReadonly: boolean;
};

export const ControversyAssessmentListTable: FC<Props> = ({ isReadonly }) => {
    const dispatch = useAppDispatch();
    const { assessmentId } = useParams();
    const [chatQuestionId, setChatQuestionId] = useState<string | null>(null);
    const [openChat, setOpenChat] = useState(false);
    const { page, rowsPerPage } = useAppSelector(
        selectControversiesAssessmentPageData
    );
    const {
        isLoading,
        query: { isRefetching },
    } = useGetControversyAssessmentList({
        assessmentId: assessmentId ?? '',
        enabled: !!assessmentId && assessmentId !== '0',
        onSuccess: (data) => {
            dispatch(setInitialControversies(data));
        },
    });

    const { save } = useControversyAssessmentnSave();

    const handleSetRowsPerPage = async (rowsPerPage: number) => {
        await save();
        dispatch(updateRowsPerPage(rowsPerPage));
    };

    const handleSetPage = async (page: number) => {
        await save();
        dispatch(updatePage(page));
    };

    const handleOpenChat = (id: string) => {
        setChatQuestionId(id);
        setOpenChat(true);
    };

    const handleCloseChat = () => {
        setChatQuestionId(null);
        setOpenChat(false);
    };
    const controversiesTableData = useAppSelector(
        selectControversiesAssessmentTableDataActive
    );

    const data = controversiesTableData;

    const handleChange = (controversyAssessment: ControversyAssessment) => {
        dispatch(updateControversy({ data: controversyAssessment }));
    };

    const handleAddNewControversy = () => {
        dispatch(addNewControversy());
    };

    const handleDeleteControversy = (id: string) => {
        dispatch(softDeleteControversy(id));
    };

    const handleStatusChange = (
        qaStatus: QaStatus,
        row: ControversyAssessment
    ) => {
        dispatch(updateControversy({ data: { ...row, qa_status: qaStatus } }));
    };

    const handleCheck = (id: string) => {
        dispatch(toggleSelected({ _id: id }));
    };

    const handleBulkStatusChange = (qaStatus: QaStatus) => {
        const selectedData = controversiesTableData.filter(
            (el) => el.isSelected
        );
        for (const data of selectedData) {
            dispatch(
                updateQaStatus({
                    _id: data.data._id,
                    qaStatus,
                })
            );
        }
    };

    const isBulkUpdateVisible = controversiesTableData.some(
        (el) => el.isSelected
    );

    const pagedData = data.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    return (
        <WithSpinner isLoading={isLoading || isRefetching}>
            <Box>
                {isBulkUpdateVisible && (
                    <Box sx={{ mt: 2 }}>
                        <QaStatusComponent
                            label='Bulk Qa Status Update'
                            forceShow
                            handleClick={handleBulkStatusChange}
                        />
                    </Box>
                )}

                <Box sx={{ mt: 5 }}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <IconButton
                            onClick={handleAddNewControversy}
                            disabled={isReadonly}
                        >
                            <AddCircleIcon fontSize='large' color='primary' />
                        </IconButton>
                        <TableContainer>
                            <Table stickyHeader>
                                <CustomTableHeader />
                                <CustomTableBody
                                    data={pagedData}
                                    onChange={handleChange}
                                    onDelete={handleDeleteControversy}
                                    handleOpenChat={handleOpenChat}
                                    handleStatusChange={handleStatusChange}
                                    isReadonly={isReadonly}
                                    onCheck={handleCheck}
                                />
                            </Table>
                        </TableContainer>
                        <CustomPagination
                            dataLength={data?.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setPage={handleSetPage}
                            setRowsPerPage={handleSetRowsPerPage}
                        />
                    </Paper>
                    {openChat && (
                        <PopChat
                            isReadOnly={isReadonly}
                            questionId={chatQuestionId ?? ''}
                            closeChat={handleCloseChat}
                            target={AssessmentCommentTarget.CONTROVERSIES}
                        />
                    )}
                </Box>
            </Box>
        </WithSpinner>
    );
};
