import {
    SDGAssessment,
    SDGAssessmentMapped,
} from 'api/hooks/sdg-assessment/sdgAssessmentTypes';
import { getQuestionAnswerMappedByRow } from 'utils';

export const mapSDGAssessmentListResponse = (
    assessmentData?: {
        data: SDGAssessment;
        isSelected: boolean;
    }[]
): SDGAssessmentMapped[] => {
    if (!assessmentData) return [];

    return assessmentData.map(({ data: sdgAssessment, isSelected }) => {
        const questionAnswers = getQuestionAnswerMappedByRow(sdgAssessment);
        return {
            _id: sdgAssessment._id,
            sdg_goal: sdgAssessment.sdg_goal,
            question_answer: questionAnswers,
            for_qa: sdgAssessment.for_qa,
            qa_status: sdgAssessment.qa_status,
            has_comments: sdgAssessment.has_comments,
            isSelected,
        } as SDGAssessmentMapped;
    });
};
