import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography,
} from '@mui/material';
import { FC, useState } from 'react';

export const PrivacyNotice: FC = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <Button onClick={handleOpen}>Privacy Notice</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
            >
                <DialogTitle id='scroll-dialog-title'>
                    Privacy Notice
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id='scroll-dialog-description'
                        // ref={descriptionElementRef}
                        // tabIndex={-1}
                    >
                        <Typography>Improving Your Experience</Typography>
                        <br />
                        <Typography>
                            At CEN-ESG, we understand that privacy is important
                            to you. We are committed to treating your personal
                            data and information with care and integrity. This
                            Privacy Notice specifically addresses personal data
                            that we collect through the website under the domain
                            name{' '}
                            <a
                                href='https://www.cen-esg.com'
                                target='_blank'
                                rel='noreferrer'
                            >
                                www.cen-esg.com
                            </a>{' '}
                            (the 'website') and is supplementary to the CEN-ESG
                            Privacy Policy.
                        </Typography>
                        <br />
                        <Typography>
                            When we say “CEN-ESG”, “we”, “us” or “the Company”,
                            we are referring to CEN-ESG Limited.
                        </Typography>
                        <br />
                        <Typography>1. Scope</Typography>
                        <br />
                        <Typography>
                            This Notice applies in the following circumstances:
                        </Typography>
                        <br />
                        <Typography>
                            (i) When you visit the website; <br />
                            (ii) When you contact us by email or telephone using
                            the contact details we make available on the
                            website; <br /> (iii) When you submit information to
                            us using an online form contained on the website.
                            <br />
                        </Typography>
                        <br />
                        <Typography>
                            2.The type of personal data we collect about you and
                            how we get it
                        </Typography>
                        <br />
                        <Typography>
                            “Personal data” refers to any information that
                            relates to you and that identifies you either
                            directly from that information (e.g. your name) or
                            indirectly, by reference to other information that
                            we have access to (e.g. a unique ID number).{' '}
                        </Typography>
                        <br />
                        <Typography>
                            Most of the personal information we collect about
                            you via our website, will be information that you
                            provide to us voluntarily. For example, when you
                            complete an online form on our website, we ask you
                            to provide your contact information such as name,
                            email address and telephone number.
                        </Typography>
                        <br />
                        <Typography>
                            We also collect some technical data automatically
                            through essential cookies, solely for the purposes
                            of the website functioning and identifying any
                            problems that you faced while visiting us. For
                            example, ssr-caching cookies are used for the cache
                            function by the website to optimise the response
                            time between the visitor and the website.{' '}
                        </Typography>
                        <br />
                        <Typography>3. How we use it </Typography>
                        <br />
                        <Typography>
                            We will only use your personal data fairly and where
                            we have lawful reason to do so.
                        </Typography>
                        <br />
                        <Typography>
                            We are allowed to use your personal data if we have
                            your consent or another legally permitted reason
                            applies. This includes to fulfil a contract with you
                            when we have a legal duty to comply with or when it
                            is in our legitimate business interest to use your
                            personal data. We can only rely on our legitimate
                            business interest if it is fair and reasonable to do
                            so.
                        </Typography>
                        <br />
                        <Typography>
                            Accordingly, we may use your personal data to:{' '}
                        </Typography>
                        <br />
                        <Typography>
                            <ul>
                                <li>
                                    improve and personalise your experience when
                                    you use the website;
                                </li>
                                <li>
                                    improve our services and products to you, if
                                    you or the organisation you work for are a
                                    client or prospective client;
                                </li>
                                <li>
                                    maintain and develop our business
                                    relationship with you;
                                </li>
                                <li>
                                    identify services you may be interested in;
                                </li>
                                <li>
                                    process and respond to any requests,
                                    enquiries or complaints received from you;
                                </li>
                                <li>monitor and analyse our business;</li>
                                <li>
                                    send you marketing materials and invite you
                                    to events;
                                </li>
                            </ul>
                        </Typography>
                        <br />
                        <Typography>
                            We will only keep your personal data for as long as
                            is necessary to fulfil the purpose for which we
                            collected it or to comply with any legal, regulatory
                            or reporting obligations or to assert or defend
                            legal claims.
                        </Typography>
                        <br />
                        <Typography>
                            4. Sharing and transferring personal data
                        </Typography>
                        <br />
                        <Typography>
                            We treat your personal data with respect and do not
                            share it with third parties except as set out below.
                        </Typography>
                        <br />
                        <Typography>
                            We may disclose certain data to third parties that
                            perform services on our behalf and have a need to
                            access the data in connection with those services.
                            For example, third parties involved in providing
                            data analytics to us may have access to your
                            personal information. Contractual agreements between
                            us and such third parties provide that your
                            information will be kept confidential and secure. We
                            do not sell or rent your personal data to third
                            parties.
                        </Typography>
                        <br />
                        <Typography>
                            While it is unlikely, we may be required to disclose
                            your information to comply with legal or regulatory
                            requirements or for the purpose of or in connection
                            with legal proceedings or in order to exercise or
                            defend legal rights. We will use reasonable
                            endeavours to notify you before we do this unless we
                            are legally restricted from doing so.
                        </Typography>
                        <br />
                        <Typography>
                            5. Your rights in relation to the personal data we
                            hold about you{' '}
                        </Typography>
                        <br />
                        <Typography>
                            You have the following data protection rights in
                            relation to the personal information we hold about
                            you.
                        </Typography>
                        <br />
                        <Typography variant='h6'>
                            Your right of access{' '}
                        </Typography>
                        <br />
                        <Typography>
                            If you ask us, we will confirm whether we are
                            processing your personal information and, subject to
                            any applicable exemptions, provide you with a copy
                            of that personal information (along with certain
                            other details) within the timescales or extended
                            timescales provided for by the UK GDPR for complex
                            requests, or where applicable, provide you with an
                            explanation as to why we will not be complying with
                            your request.{' '}
                        </Typography>
                        <br />
                        <Typography variant='h6'>
                            Your right to rectification{' '}
                        </Typography>
                        <br />
                        <Typography>
                            If the personal information we hold about you is
                            inaccurate or incomplete, you are entitled to have
                            it rectified. If you are entitled to rectification
                            and if we have shared your personal information with
                            others, we will let them know about the
                            rectification where possible and where this would
                            not involve disproportionate effort. If you ask us,
                            where possible and lawful to do so, we will also
                            tell you who we have shared your personal
                            information with so that you can contact them
                            directly.
                        </Typography>
                        <br />
                        <Typography variant='h6'>
                            Your right to erasure
                        </Typography>
                        <br />
                        <Typography>
                            You can ask us to delete or remove your personal
                            information in some circumstances such as where we
                            no longer need it or if you withdraw your consent
                            (where applicable because that was the legal basis
                            on which we were processing your personal
                            information). If you are entitled to erasure and if
                            we have shared your personal information with
                            others, we will take reasonable steps to inform
                            those others where possible and where this would not
                            involve disproportionate effort. If you ask us,
                            where it is possible and lawful for us to do so, we
                            will also tell you who we have shared your personal
                            information with so that you can contact them
                            directly.
                        </Typography>
                        <br />
                        <Typography variant='h6'>
                            Your right to restrict processing
                        </Typography>
                        <br />
                        <Typography>
                            You can ask us to 'block' or suppress the processing
                            of your personal information in certain
                            circumstances such as where you contest the accuracy
                            of that personal information or you object to us. If
                            you are entitled to restriction and if we have
                            shared your personal information with others, we
                            will let them know about the restriction where it is
                            possible for us to do so. If you ask us, where it is
                            possible and lawful for us to do so, we will also
                            tell you who we have shared your personal
                            information with so that you can contact them
                            directly.
                        </Typography>
                        <br />
                        <Typography variant='h6'>
                            Your right to data portability
                        </Typography>
                        <br />
                        <Typography>
                            With effect from 2018, you have the right, in
                            certain circumstances, to obtain personal
                            information you have provided us with (in a
                            structured, commonly used and machine readable
                            format) and to reuse it elsewhere or to ask us to
                            transfer this to a third party of your choice.
                        </Typography>
                        <br />
                        <Typography variant='h6'>
                            Your right to object to processing
                        </Typography>
                        <br />
                        <Typography>
                            You can ask us to stop processing your personal
                            information, and we will do so, if we are:
                        </Typography>
                        <br />
                        <Typography>
                            1.relying on our own or someone else's legitimate
                            interests to process your personal information,
                            except if we can demonstrate compelling legal
                            grounds for the processing; or{' '}
                        </Typography>
                        <Typography>
                            2. processing your personal information for direct
                            marketing.
                        </Typography>
                        <br />
                        <Typography variant='h6'>
                            Your right to withdraw consent
                        </Typography>
                        <br />
                        <Typography>
                            If we rely on your consent (or explicit consent) as
                            our legal basis for processing your personal
                            information, you have the right to withdraw that
                            consent at any time.{' '}
                        </Typography>
                        <br />
                        <Typography>6.Contact ​ </Typography>
                        <br />
                        <Typography>
                            If you have any queries or concerns relating to this
                            Privacy Notice or how we use personal information
                            collected from the website, please contact us by
                            email at{' '}
                            <a href='mailto:privacy@cen-esg.com'>
                                privacy@cen-esg.com
                            </a>
                        </Typography>
                        <br />
                        <Typography>
                            We hope that we are able to resolve any query or
                            concern you raise about our use of your personal
                            information. However, if you feel that we have not
                            handled your query or concern to your satisfaction
                            you can contact the Information Commissioner's
                            Office (ICO), the UK supervisory authority for data
                            protection issues at{' '}
                            <a href=' https://ico.org.uk/make-a-complaint/'>
                                Make a complaint
                            </a>{' '}
                            or by calling their office on [0303 123 1113].
                        </Typography>
                        <br />
                        <Typography>
                            7.Changes to this Privacy Notice
                        </Typography>
                        <br />
                        <Typography>
                            This Notice will be regularly reviewed to ensure its
                            suitability, adequacy and effectiveness, and to take
                            into account changes to applicable laws,
                            regulations, or to the business activities of the
                            Company.
                        </Typography>
                        <br />
                        <Typography>Last updated: January 2022</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
