import { TableCell, TableHead, TableRow } from '@mui/material';

export const CustomTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell align='right'>Section</TableCell>
                <TableCell align='right'>Topic</TableCell>
                {/* <TableCell align='right'>Link To AT</TableCell> */}
                <TableCell align='right'>Unique ID</TableCell>
                <TableCell align='right'>Question</TableCell>
                <TableCell align='right'>Assessment Fiscal Year - 2</TableCell>
                <TableCell align='right'>Assessment Fiscal Year - 1</TableCell>
                <TableCell align='right'>Assessment Fiscal Year</TableCell>
                <TableCell>Qa Status</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
            </TableRow>
        </TableHead>
    );
};
