import { authApi } from 'lib';
import {
    CompanyPerformanceAssessment,
    CompanyPerformanceAssessmentFilters,
    CompanyPerformanceAssessmentListResponse,
    CreateCompanyPerformanceAssessment,
} from './companyPerformanceAssessmentTypes';

export const getCompanyPerformanceAssessmentList = async (
    id: string,
    filters: CompanyPerformanceAssessmentFilters
) => {
    const params = new URLSearchParams();

    if (filters.answer_for_current_fiscal_year) {
        params.append(
            'answer_for_current_fiscal_year',
            String(filters.answer_for_current_fiscal_year)
        );
    }

    if (filters.answer_for_previous_fiscal_year) {
        params.append(
            'answer_for_previous_fiscal_year',
            String(filters.answer_for_previous_fiscal_year)
        );
    }

    if (filters.answer_for_year_before_previous_fiscal_year) {
        params.append(
            'answer_for_year_before_previous_fiscal_year',
            String(filters.answer_for_year_before_previous_fiscal_year)
        );
    }

    if (filters.qa_status) {
        filters.qa_status.forEach((data) => {
            params.append('qa_status', data);
        });
    }

    if (filters.has_comments) {
        params.append('has_comments', String(filters.has_comments));
    }

    const response =
        await authApi.get<CompanyPerformanceAssessmentListResponse>(
            `/api/v1/company_performance_assessments?${params.toString()}`,
            { params: { assessment_id: id } }
        );
    return response.data;
};

export const updateCompanyPerformanceAssessment = async (
    id: string,
    request: CreateCompanyPerformanceAssessment
) => {
    const response = await authApi.put<CompanyPerformanceAssessment>(
        `/api/v1/company_performance_assessments/${id}`,
        request
    );
    return response.data;
};
