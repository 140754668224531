export type SDGQuestionGuide = {
    title: string;
    order: number;
    text: string;
};

export type SDGQuestionScoreByAnswer = {
    answer: string;
    score?: number;
};

export enum SDGQuestionAnswerValidation {
    YES_OR_NO = 'Yes or No',
    FULLY_OR_PARTIALLY_OR_NO = 'Fully or Partially or No',
}

export type SDGQuestion = {
    _id: string;
    question: string;
    max_score_possible?: number;
    scores_by_answer?: SDGQuestionScoreByAnswer[];
    question_number?: number;
    answer_validation?: SDGQuestionAnswerValidation;
    guides?: SDGQuestionGuide[];
};

export type CreateUpdateSDGQuestion = {
    question: string;
    max_score_possible?: number;
    scores_by_answer?: SDGQuestionScoreByAnswer[];
    question_number?: number;
    answer_validation?: SDGQuestionAnswerValidation;
    guides?: SDGQuestionGuide[];
};

export type SDGQuestionListResponse = SDGQuestion[];

export type SDGQuestionResponse = SDGQuestion;
