import { useQuery } from 'react-query';
import { SDGQuestionListResponse } from './sdgQuestionsTypes';
import { getSDGQuestionList } from './sdgQuestionService';

type Props<M> = {
    onError?: (error: Error) => void;
    onSuccess?: (data: SDGQuestionListResponse) => void;
    mapper?: (
        data?: SDGQuestionListResponse
    ) => M | SDGQuestionListResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: SDGQuestionListResponse) => data;

export const useGetSDGQuestionList = <M = SDGQuestionListResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
}: Props<M>) => {
    const query = useQuery<SDGQuestionListResponse, Error>(
        ['sdgQuestions'],
        getSDGQuestionList,
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
