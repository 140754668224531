import { authApi } from 'lib';
import {
    CreateUpdateMainQuestion,
    MainQuestionListResponse,
    MainQuestionResponse,
} from './mainQuestionsTypes';

export const getMainQuestionList = async () => {
    const response = await authApi.get<MainQuestionListResponse>(
        '/api/v1/main_questions'
    );
    return response.data;
};

export const getMainQuestionById = async (id: string) => {
    const response = await authApi.get<MainQuestionResponse>(
        `/api/v1/main_questions/${id}`
    );
    return response.data;
};

export const createMainQuestion = async (request: CreateUpdateMainQuestion) => {
    const response = await authApi.post<MainQuestionResponse>(
        `/api/v1/main_questions`,
        request
    );
    return response.data;
};

export const updateMainQuestion = async (
    id: string,
    request: CreateUpdateMainQuestion
) => {
    const response = await authApi.put<MainQuestionResponse>(
        `/api/v1/main_questions/${id}`,
        request
    );
    return response.data;
};

export const deleteMainQuestion = async (id: string) => {
    return await authApi.delete(`/api/v1/main_questions/${id}`);
};
