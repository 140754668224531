import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { CompanyPerformanceAssessmentFilters } from 'api/hooks/company-performance-assessment/companyPerformanceAssessmentTypes';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { EnumToDropdownOptions, multipleSelectOptionsToString } from 'utils';
import { useCompanyPerformanceSave } from './use-company-performance-save';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
    selectCompanyPerformanceAssessmentFilters,
    setInitialCompanyPerformanceAssessment,
    updateFilters,
} from 'store/slices/companyPerformanceAssessmentSlice';
import { useGetCompanyPerformanceAssessmentList } from 'api/hooks/company-performance-assessment/useGetCompanyPerformanceAssessmentList';
import { QaStatus } from 'api/hooks/commonTypes';
import { CustomMultipleSelectField } from 'components/shared/form/custom-multiple-select-field';

type Props = {};

export const CompanyPerformanceAssessmentFilterSection: FC<Props> = () => {
    const { assessmentId } = useParams();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const dispatch = useAppDispatch();
    const { save } = useCompanyPerformanceSave();

    const filters = useAppSelector(selectCompanyPerformanceAssessmentFilters);

    const { query } = useGetCompanyPerformanceAssessmentList({
        assessmentId: assessmentId ?? '',
        enabled: false,
        onSuccess: (data) => {
            dispatch(setInitialCompanyPerformanceAssessment(data));
        },
        filters: filters ?? {},
    });

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    const handleInputChange = (
        propertyName: keyof CompanyPerformanceAssessmentFilters,
        value: string | string[]
    ) => {
        const updatedFilters = { ...filters, [propertyName]: value };
        dispatch(updateFilters(updatedFilters));
    };

    const handleSearch = async () => {
        const isSuccess = await save();
        if (isSuccess) query.refetch();
    };

    const handleClear = () => {
        dispatch(updateFilters({}));
    };

    const handleMultipleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        const newValue = Array.isArray(value)
            ? value.length
                ? multipleSelectOptionsToString(value)
                : null
            : value;

        // formik.setFieldValue(name, newValue);
        const updatedFilters = { ...filters, [name]: newValue?.split(';') };
        dispatch(updateFilters(updatedFilters));
    };

    const handleMultipleDelete = (
        value: string,
        name: keyof CompanyPerformanceAssessmentFilters
    ) => {
        // const newArray = stringToMultipleSelectOptions(
        //     filters![name] as string
        // )?.filter((current) => {
        //     return current !== value;
        // });
        // const newValues = multipleSelectOptionsToString(newArray);

        // // formik.setFieldValue(name, newValues === '' ? null : newValues);
        // const updatedFilters = { ...filters, [name]: newValues.split(';') };
        // dispatch(updateFilters(updatedFilters));

        const newArray = filters?.qa_status?.filter((current) => {
            return current !== value;
        });
        const updatedFilters = { ...filters, [name]: newArray };
        dispatch(updateFilters(updatedFilters));
    };

    const qaStatusAnswerOptions = EnumToDropdownOptions(QaStatus);

    return (
        <Box mt={2}>
            <Button
                variant='contained'
                color='primary'
                onClick={toggleFormVisibility}
            >
                {isFormVisible ? 'Hide Filters' : 'Show Filters'}
            </Button>
            {isFormVisible && (
                <Grid container spacing={2} mt={2}>
                    <Grid item xs={3}>
                        <Box display={'flex'}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                handleInputChange(
                                                    'answer_for_current_fiscal_year',
                                                    'null'
                                                );
                                            } else {
                                                handleInputChange(
                                                    'answer_for_current_fiscal_year',
                                                    ''
                                                );
                                            }
                                        }}
                                    />
                                }
                                label='Check Null'
                            />
                            <TextField
                                label='Answer For Current Fiscal Year'
                                fullWidth
                                value={
                                    filters?.answer_for_current_fiscal_year ||
                                    ''
                                }
                                onChange={(e) =>
                                    handleInputChange(
                                        'answer_for_current_fiscal_year',
                                        e.target.value
                                    )
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box display={'flex'}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                handleInputChange(
                                                    'answer_for_previous_fiscal_year',
                                                    'null'
                                                );
                                            } else {
                                                handleInputChange(
                                                    'answer_for_previous_fiscal_year',
                                                    ''
                                                );
                                            }
                                        }}
                                    />
                                }
                                label='Check Null'
                            />
                            <TextField
                                label='Answer For Previous Fiscal Year'
                                fullWidth
                                value={
                                    filters?.answer_for_previous_fiscal_year ||
                                    ''
                                }
                                onChange={(e) =>
                                    handleInputChange(
                                        'answer_for_previous_fiscal_year',
                                        e.target.value
                                    )
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box display={'flex'}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                handleInputChange(
                                                    'answer_for_year_before_previous_fiscal_year',
                                                    'null'
                                                );
                                            } else {
                                                handleInputChange(
                                                    'answer_for_year_before_previous_fiscal_year',
                                                    ''
                                                );
                                            }
                                        }}
                                    />
                                }
                                label='Check Null'
                            />
                            <TextField
                                label='Answer For Year Before Previous Fiscal Year'
                                fullWidth
                                value={
                                    filters?.answer_for_year_before_previous_fiscal_year ||
                                    ''
                                }
                                onChange={(e) =>
                                    handleInputChange(
                                        'answer_for_year_before_previous_fiscal_year',
                                        e.target.value
                                    )
                                }
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            {/* <InputLabel id='status'>Qa Status</InputLabel> */}
                            <CustomMultipleSelectField
                                options={qaStatusAnswerOptions}
                                onChange={handleMultipleSelect}
                                // {...generateMultipleSelectFieldProps<FormikTypes>(
                                //     'dataFormat',
                                //     'Data Format',
                                //     formName,
                                //     formik
                                // )}
                                value={filters?.qa_status?.join(';')}
                                name='qa_status'
                                formName='MainQuestsionsForm'
                                label='QA Status'
                                errorText={''}
                                handleDelete={(value) =>
                                    handleMultipleDelete(value, 'qa_status')
                                }
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id='status'>Has Comments</InputLabel>
                            <Select
                                id='status'
                                label='Status'
                                value={filters?.has_comments || ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        'has_comments',
                                        e.target.value
                                    )
                                }
                            >
                                <MenuItem value=''>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='true'>
                                    <em>True</em>
                                </MenuItem>
                                <MenuItem value='false'>
                                    <em>False</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid item xs={1.5}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={handleSearch}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Button
                            variant='outlined'
                            color='secondary'
                            fullWidth
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
