import { useEffect, useState } from 'react';
import { AssessmentStatus } from '../assessment/assessmentTypes';
import { useGetAssessmentStatus } from '../assessment/useGetAssessmentStatus';

type Props = {
    assessmentId: string;
    currentStatuses: AssessmentStatus[];
};

export const useCheckAssessmentStatus = ({
    currentStatuses,
    assessmentId,
}: Props) => {
    const [isReadOnly, setIsReadOnly] = useState(true);

    const { data, isLoading } = useGetAssessmentStatus({
        assessmentId: assessmentId ?? '',
        enabled: assessmentId !== '0' && !!assessmentId,
    });

    useEffect(() => {
        const readonlyStatuses = [
            AssessmentStatus.COMPLETED,
            AssessmentStatus.READY_FOR_AMENDMENT,
            // AssessmentStatus.READY_FOR_ANALYSIS,
            AssessmentStatus.READY_FOR_ASSURANCE,
            // AssessmentStatus.READY_FOR_FINAL_REVIEW,
            // AssessmentStatus.READY_FOR_REASSURANCE,
        ];

        if (data) {
            setIsReadOnly(
                readonlyStatuses.includes(data?.status) ||
                    !currentStatuses.some((val) => val === data?.status)
            );
        } else {
            setIsReadOnly(false);
        }
    }, [data, currentStatuses]);

    return { isReadOnly, currentStatus: data?.status, isLoading };
};
