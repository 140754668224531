import { Routes, Route } from 'react-router-dom';
import { Layout } from 'components/shared/layout';
import { RequireUser } from 'components/shared/require-user';
import { CompanyDetails } from 'features/company-details';
import { CompanyList } from 'features/company-list';
import { Login } from 'features/login';
import { RecoverPassword } from 'features/recover-password/recover-password';
import { RouteParams, RoutePaths } from '../constants/enums/routes-constants';
import { AssessmentList } from 'components/assessment/assessment-list';
import { generateRoutePath } from 'utils';
import { AssessmentDetails } from 'features/assessment-details';
import { Admin } from 'features/admin';
import { QueryBuilder } from 'features/query-builder';
import { NotAllowed } from 'features/not-allowed';
import { Dashboard } from 'features/dashboard';
import { ResetPassword } from 'features/reset-password';
import { ChangePassword } from 'features/change-password';
import { RoleType, UserType } from 'api/hooks/user/userTypes';
import { UserErrorRateList } from 'features/user-error-rate-list';
import MFA from 'features/mfa/mfa';

export const AppRoutes = () => {
    return (
        <Routes>
            <Route path='/' element={<Layout />}>
                <Route
                    element={
                        <RequireUser
                            allowedTypes={[UserType.EMPLOYEE, UserType.CLIENT]}
                        />
                    }
                >
                    <Route
                        path={generateRoutePath([RoutePaths.DASHBOARD])}
                        element={<Dashboard />}
                    />
                    <Route
                        path={generateRoutePath([RoutePaths.CHANGE_PASSWORD])}
                        element={<ChangePassword />}
                    />
                </Route>
                <Route
                    element={
                        <RequireUser
                            allowedTypes={[UserType.CLIENT, UserType.EMPLOYEE]}
                            allowedRoles={[RoleType.EXPORTER, RoleType.ADMIN]}
                        />
                    }
                >
                    <Route
                        path={generateRoutePath([RoutePaths.QUERY_BUILDER])}
                        element={<QueryBuilder />}
                    />
                </Route>
                {/* <Route
                    element={
                        <RequireUser
                            allowedTypes={[UserType.EMPLOYEE]}
                            allowedRoles={[RoleType.EXPORTER, RoleType.ADMIN]}
                        />
                    }
                >
                    <Route
                        path={generateRoutePath([RoutePaths.QUERY_BUILDER])}
                        element={<QueryBuilder />}
                    />
                </Route> */}
                <Route
                    element={<RequireUser allowedTypes={[UserType.EMPLOYEE]} />}
                >
                    <Route
                        path={generateRoutePath([RoutePaths.ASSESSMENT_LIST])}
                        element={<AssessmentList />}
                    />
                    <Route
                        path={generateRoutePath([
                            RoutePaths.ASSESSMENT_LIST,
                            RouteParams.ASSESSMENT_ID,
                        ])}
                        element={<AssessmentDetails />}
                    />
                </Route>
                <Route
                    element={
                        <RequireUser
                            allowedTypes={[UserType.EMPLOYEE]}
                            allowedRoles={[RoleType.ADMIN, RoleType.PLANNER]}
                        />
                    }
                >
                    <Route
                        path={generateRoutePath([RoutePaths.COMPANY_LIST])}
                        element={<CompanyList />}
                    />
                    <Route
                        path={generateRoutePath([RoutePaths.USER_ERROR_LIST])}
                        element={<UserErrorRateList />}
                    />
                    <Route
                        path={generateRoutePath([
                            RoutePaths.COMPANY_LIST,
                            RouteParams.COMPANY_ID,
                        ])}
                        element={<CompanyDetails />}
                    />
                </Route>
                <Route
                    element={
                        <RequireUser
                            allowedTypes={[UserType.EMPLOYEE]}
                            allowedRoles={[RoleType.ADMIN]}
                        />
                    }
                >
                    <Route
                        path={generateRoutePath([RoutePaths.ADMIN, '/*'])}
                        element={<Admin />}
                    />
                </Route>
                <Route
                    path={generateRoutePath([RoutePaths.NOT_ALLOWED])}
                    element={<NotAllowed />}
                />
            </Route>
            <Route
                path={generateRoutePath([RoutePaths.LOGIN])}
                element={<Login />}
            />
            <Route
                path={generateRoutePath([RoutePaths.MFA])}
                element={<MFA />}
            />
            <Route
                path={generateRoutePath([RoutePaths.RECOVER_PASSWORD])}
                element={<RecoverPassword />}
            />
            <Route
                path={generateRoutePath([RoutePaths.RESET_PASSWORD])}
                element={<ResetPassword />}
            />
        </Routes>
    );
};
