import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';

export const QueryBuilderGuide: FC = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button onClick={handleOpen}>Query Builder Guide</Button>
            <Dialog
                open={open}
                onClose={handleClose}
                scroll='paper'
                aria-labelledby='scroll-dialog-title'
                aria-describedby='scroll-dialog-description'
            >
                <DialogTitle id='scroll-dialog-title'>
                    Query Builder Guide
                </DialogTitle>
                <DialogContent dividers={true}>
                    <DialogContentText
                        id='scroll-dialog-description'
                        component={'div'}
                        // ref={descriptionElementRef}
                        // tabIndex={-1}
                    >
                        <h3>Step 1: Query builder data selection</h3>
                        <ul>
                            <li>
                                Company - list of companies available to you
                            </li>
                            <li>
                                ESG Industry - list of available ESG Industries
                            </li>
                            <li>
                                ESG Sub-Industry - list of available ESG
                                Sub-Industries
                            </li>
                            <li>
                                Published Since - This can be used to download
                                all new data and/or reports published since any
                                given date (e.g. your last download)
                            </li>
                            <li>
                                Fiscal Year - fiscal/financial year for which
                                the company has been assessed
                            </li>
                            <li>
                                Data Format - ESG data formats options (.XLSX or
                                .CSV). The default is Microsoft excel .XLSX
                            </li>
                        </ul>

                        <h3>Step 1 validations in order to generate export:</h3>
                        <ol>
                            <li>Company or ESG Industry should be chosen.</li>
                            <li>
                                If Company selection is chosen, you cannot use
                                the industry and sub-industry inputs.
                            </li>
                            <li>
                                You must select an ESG Industry in order to
                                choose ESG Sub-Industry. The selection of ESG
                                Sub-Industries will depend on the ESG Industry
                                chosen.
                            </li>
                            <li>
                                If you select an ESG Industry and/or ESG
                                Sub-Industry, the list of companies in the
                                Company selection will then be those within the
                                selected ESG Industry and/or ESG Sub-Industry.
                            </li>
                            <li>
                                Published Since field is optional. When not
                                selected, all available data and/or reports will
                                be provided.
                            </li>
                            <li>
                                Fiscal year input is optional. When not
                                selected, all available fiscal years ESG data
                                and/or reports will be provided.
                            </li>
                        </ol>

                        <h3>Step 2: Checkboxes</h3>
                        <ul>
                            <li>
                                Data - if you want to receive an ESG .XLSX or
                                .CSV data file, this should be checked
                            </li>
                            <li>
                                Report - if you want to receive a PDF research
                                report, this should be checked
                            </li>
                        </ul>

                        <h3>Step 2 validations in order to generate export:</h3>
                        <ol>
                            <li>
                                One of the "Data" or "Report" checkboxes should
                                be checked.
                            </li>
                        </ol>

                        <h3>Step 3: Buttons</h3>
                        <ul>
                            <li>
                                Clear - by pressing this, all inputs will be
                                deleted
                            </li>
                            <li>Generate Export - prepare download</li>
                        </ul>

                        <h3>Step 4: Download data</h3>
                        <span>
                            Once you have made selections through steps 1-3 and
                            then clicked the "Generate Export" button,
                            generation will start. Once this has been prepared
                            (length of time will depend on the size of data), it
                            will be ready for you to download the file.At the
                            bottom of the page, ten history of generated exports
                            are shown.
                        </span>
                        <ul>
                            <li>
                                "Down arrow" - shows detail of Generated Export
                            </li>
                            <li>Date - generation request date and time</li>
                            <li>
                                Number of Companies - how many companies are
                                included in the generation
                            </li>
                            <li>
                                Status - status of preparation for download,
                                which can be: in progress, failed, completed
                            </li>
                        </ul>
                        <span>
                            After the status changes to Completed (green tick),
                            you will see a download button (down arrow).Pressing
                            on this button, your ESG data and/or report download
                            will start onto your local machine.
                        </span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
