// export enum AnswerValidation {
//     DATE_IN_THE_PAST = 'Date in the past',
//     DATE_BEFORE_COMPANY_FISCAL_YEAR_END = 'Date before company fiscal year end',
//     INTEGER_EQUAL_OR_GREATER_THAN_ZERO = 'Integer equal or greater than zero',
//     FLOAT_EQUAL_OR_GREATER_THAN_ZERO_OR_ND = 'Float equal or greater than zero or ND',
//     PERCENTAGE_EQUAL_OR_GREATER_THAN_ZERO = 'Percentage equal or greater than zero',
//     PERCENTAGE_EQUAL_OR_GREATER_THAN_ZERO_OR_ND_OR_NA = 'Percentage equal or greater than zero or ND or n/a',
// }
export enum AnswerValidation {
    '(Percentage equal or greater than zero and less than or equal to 100%) or ND' = '(Percentage equal or greater than zero and less than or equal to 100%) or ND',
    '(Percentage equal or greater than zero and less than or equal to 100%) or ND or n/a' = '(Percentage equal or greater than zero and less than or equal to 100%) or ND or n/a',
    '(Percentage equal or greater than zero) or ND or n/a' = '(Percentage equal or greater than zero) or ND or n/a',
    'Date before company fiscal year end' = 'Date before company fiscal year end',
    'Date in the past' = 'Date in the past',
    'Float equal or greater than zero or ND or n/a' = 'Float equal or greater than zero or ND or n/a',
    'Float equal or greater than zero or ND' = 'Float equal or greater than zero or ND',
    'From a list of currency codes or n/a' = 'From a list of currency codes or n/a',
    'From a list of currency codes or n/a or ND' = 'From a list of currency codes or n/a or ND',
    'From options: All or A, B, C, D, E (or any combination of letters A-E separated by a comma) or ND or n/a' = 'From options: All or A, B, C, D, E (or any combination of letters A-E separated by a comma) or ND or n/a',
    'From options: Yes, No, ND' = 'From options: Yes, No, ND',
    'From options: Yes, No, No indication based on public disclosure' = 'From options: Yes, No, No indication based on public disclosure',
    'From options: Yes, No, Not determined based on public disclosure' = 'From options: Yes, No, Not determined based on public disclosure',
    'From options: No indication of less than full coverage, Less than full coverage, Continuing operations only' = 'From options: No indication of less than full coverage, Less than full coverage, Continuing operations only',
    'From options: Mining Only, Manufacturing only, Not determined based on public disclosure' = 'From options: Mining Only, Manufacturing only, Not determined based on public disclosure',
    'From options: Yes, No' = 'From options: Yes, No',
    'From options: Yes, ND' = 'From options: Yes, ND',
    'Integer equal or greater than zero or ND' = 'Integer equal or greater than zero or ND',
    'Integer equal or greater than zero or ND or n/a' = 'Integer equal or greater than zero or ND or n/a',
    'Integer greater than zero or ND or n/a' = 'Integer greater than zero or ND or n/a',
    'n/a or ND or Date in the past' = 'n/a or ND or Date in the past',
    'Disabled' = 'Disabled',
}

export type CompanyPerformanceQuestionGuide = {
    title: string;
    order: number;
    text: string;
};

export type CompanyPerformanceQuestion = {
    _id: string;
    question_number?: number;
    unique_id: string;
    section: string;
    topic: string;
    link_to_at?: string;
    question_id: string;
    question: string;
    guidance: string;
    answer_validation?: AnswerValidation;
    previous_fiscal_year_answer_validation?: AnswerValidation;
    year_before_previous_fiscal_year_answer_validation?: AnswerValidation;
};

export type CreateUpdateCompanyPerformanceQuestion = {
    question_number?: number;
    unique_id: string;
    section: string;
    topic: string;
    link_to_at?: string;
    question_id: string;
    question: string;
    guidance: string;
    answer_validation?: AnswerValidation;
    previous_fiscal_year_answer_validation?: AnswerValidation;
    year_before_previous_fiscal_year_answer_validation?: AnswerValidation;
};

export type CompanyPerformanceQuestionListResponse =
    CompanyPerformanceQuestion[];

export type CompanyPerformanceQuestionResponse = CompanyPerformanceQuestion;
