import { useQuery } from 'react-query';
import { CompanyResponse } from './companyTypes';
import { getCompanyById } from './companyService';

type Props<M> = {
    companyId: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: CompanyResponse) => void;
    mapper?: (data?: CompanyResponse) => M | CompanyResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: CompanyResponse) => data;

export const useGetCompany = <M = CompanyResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    companyId,
}: Props<M>) => {
    const query = useQuery<CompanyResponse, Error>(
        ['company', companyId],
        () => getCompanyById(companyId),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
