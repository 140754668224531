import { useQuery } from 'react-query';
import { Report, ReportListResponse } from './reportTypes';
import { getReportList } from './reportService';

type Props<M> = {
    onError?: (error: Error) => void;
    onSuccess?: (data: ReportListResponse) => void;
    mapper?: (data?: ReportListResponse) => M | Report[] | undefined;
    enabled?: boolean;
    refetchInterval?: number | false;
};

const defaultMapper = (data?: ReportListResponse) => data;

export const useGetReports = <M = ReportListResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    refetchInterval = false,
}: Props<M>) => {
    const query = useQuery<ReportListResponse, Error>(
        ['reports'],
        getReportList,
        {
            onSuccess,
            onError,
            enabled: enabled,
            refetchInterval: refetchInterval,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
