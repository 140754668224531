import {
    QuestionAnswerMapped,
    QuestionMapped,
    SDGAssessment,
    SDGAssessmentQuestion,
} from 'api/hooks/sdg-assessment/sdgAssessmentTypes';
import { sdgQuestions } from 'constants/sdg-questions';

// export const findAnswerByQuestion = (
//     sdgAssessment: SDGAssessment,
//     questionToFind: string
// ) => {
//     const question = sdgAssessment.questions?.find(
//         (question) => question.question === questionToFind
//     );

//     return question?.answer;
// };

// export const getAnswersByRow = (row: SDGAssessment) => {
//     const answer1 = findAnswerByQuestion(row, sdgHeaders.QUESTION_1.question);
//     const answer2 = findAnswerByQuestion(row, sdgHeaders.QUESTION_2.question);
//     const answer3 = findAnswerByQuestion(row, sdgHeaders.QUESTION_3.question);
//     const answer4 = findAnswerByQuestion(row, sdgHeaders.QUESTION_4.question);
//     const answer5 = findAnswerByQuestion(row, sdgHeaders.QUESTION_5.question);

//     return { answer1, answer2, answer3, answer4, answer5 };
// };

export const getOptionsByAnswer = (dropdownOptions: string[]) => {
    return dropdownOptions.map((option) => ({
        value: option,
        label: option,
    }));
};

export const getAnswerOptions = () => {
    const options1 = getOptionsByAnswer(sdgQuestions.Q1.dropdownOptions);
    const options2 = getOptionsByAnswer(sdgQuestions.Q2.dropdownOptions);
    const options3 = getOptionsByAnswer(sdgQuestions.Q3.dropdownOptions);
    const options4 = getOptionsByAnswer(sdgQuestions.Q4.dropdownOptions);
    const options5 = getOptionsByAnswer(sdgQuestions.Q5.dropdownOptions);

    return { options1, options2, options3, options4, options5 };
};

export const getQuestionMappedByQuestion = (
    questionToFind: string,
    questions?: SDGAssessmentQuestion[]
): QuestionMapped => {
    const foundQuestion = questions?.find(
        (question) => question.question === questionToFind
    );
    return {
        answer: foundQuestion?.answer ?? '',
        question: foundQuestion?.question ?? '',
        reference_for_answer: foundQuestion?.reference_for_answer ?? '',
        answer_validation: foundQuestion?.answer_validation ?? null,
        score: foundQuestion?.score,
        weighted_score: foundQuestion?.weighted_score,
    };
};

export const getQuestionAnswerMappedByRow = (
    row: SDGAssessment
): QuestionAnswerMapped => {
    const Q1 = getQuestionMappedByQuestion(
        sdgQuestions.Q1.question,
        row.questions
    );
    const Q2 = getQuestionMappedByQuestion(
        sdgQuestions.Q2.question,
        row.questions
    );
    const Q3 = getQuestionMappedByQuestion(
        sdgQuestions.Q3.question,
        row.questions
    );
    const Q4 = getQuestionMappedByQuestion(
        sdgQuestions.Q4.question,
        row.questions
    );
    const Q5 = getQuestionMappedByQuestion(
        sdgQuestions.Q5.question,
        row.questions
    );
    return { Q1, Q2, Q3, Q4, Q5 };
};
