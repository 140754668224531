import { FC, Fragment, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useNavigate } from 'react-router';
import { useAppDispatch } from 'store/store';
import { logoutUser } from 'store/slices/userSlice';
import { Button } from '@mui/material';
import { RoutePaths } from 'routes';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'constants/session-storage-constants';
import { ReactComponent as Logo } from 'assets/logo-wide.svg';
import { useGetMe } from 'api/hooks';
import { Link } from 'react-router-dom';
import { GenerateApiKeyDialog } from 'components/generate-api-key-dialog';
import { RoleType, UserType } from 'api/hooks/user/userTypes';
import { useCreateMeApiKey } from 'api/hooks/user/useCreateMeApiKey';

type Props = {
    onlyView?: boolean;
};

export const Header: FC<Props> = ({ onlyView = false }) => {
    const [apiKeyDialogoOpen, setApiKeyDialogoOpen] = useState(false);
    const [apiKey, setApiKey] = useState<null | string>(null);

    const { data: user, isLoading } = useGetMe({
        enabled: !onlyView,
    });

    const isSuperUser = user?.is_superuser;
    const isAdmin = user?.roles?.includes(RoleType.ADMIN) ?? false;
    const isPlanner = user?.roles?.includes(RoleType.PLANNER) ?? false;

    const { mutate: createApiKey } = useCreateMeApiKey({
        onSuccess: (data) => {
            setApiKeyDialogoOpen(true);
            setApiKey(data.key);
        },
    });

    const handleGenerateApiKey = () => {
        createApiKey();
        handleClose();
    };
    const handleApiKeyDialogClose = () => {
        setApiKeyDialogoOpen(false);
    };

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onLogoutHandler = async () => {
        dispatch(logoutUser());
        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(REFRESH_TOKEN);
        navigate(RoutePaths.LOGIN);
    };

    const onChangePasswordHandler = async () => {
        navigate(RoutePaths.CHANGE_PASSWORD);
        handleClose();
    };

    const navigationButton = (label: string, onClick: () => void) => {
        return (
            <Button
                onClick={onClick}
                sx={{
                    color: 'black',
                    display: 'block',
                }}
            >
                {label}
            </Button>
        );
    };

    return !isLoading ? (
        <Fragment>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position='fixed' sx={{ backgroundColor: 'white' }}>
                    <Toolbar>
                        <Typography
                            variant='h6'
                            component='div'
                            sx={{
                                flexGrow: 1,
                                display: 'flex',
                                alignItems: 'center',
                                height: 80,
                            }}
                        >
                            <Link
                                to={'/'}
                                style={{ marginRight: '10px', height: 50 }}
                            >
                                <Logo style={{ width: 100, height: 50 }} />
                            </Link>
                        </Typography>

                        {!onlyView && (
                            <>
                                <Box sx={{ display: 'flex' }}>
                                    {user.type === UserType.EMPLOYEE && (
                                        <>
                                            {navigationButton(
                                                'Assessments',
                                                () => {
                                                    navigate(
                                                        RoutePaths.ASSESSMENT_LIST
                                                    );
                                                }
                                            )}
                                            {(isAdmin || isPlanner) &&
                                                navigationButton(
                                                    'Companies',
                                                    () => {
                                                        navigate(
                                                            RoutePaths.COMPANY_LIST
                                                        );
                                                    }
                                                )}
                                            {(isAdmin || isPlanner) &&
                                                navigationButton(
                                                    'User Error Rates',
                                                    () => {
                                                        navigate(
                                                            RoutePaths.USER_ERROR_LIST
                                                        );
                                                    }
                                                )}
                                            {isAdmin &&
                                                navigationButton(
                                                    'Admin',
                                                    () => {
                                                        navigate(
                                                            RoutePaths.ADMIN
                                                        );
                                                    }
                                                )}
                                        </>
                                    )}
                                    {user.type === UserType.CLIENT && (
                                        <>
                                            {navigationButton(
                                                'Query Builder',
                                                () => {
                                                    navigate(
                                                        RoutePaths.QUERY_BUILDER
                                                    );
                                                }
                                            )}
                                        </>
                                    )}
                                    {user.type === UserType.EMPLOYEE &&
                                        (isSuperUser ||
                                            user.roles?.includes(
                                                RoleType.EXPORTER
                                            ) ||
                                            user.roles?.includes(
                                                RoleType.ADMIN
                                            )) && (
                                            <>
                                                {navigationButton(
                                                    'Query Builder',
                                                    () => {
                                                        navigate(
                                                            RoutePaths.QUERY_BUILDER
                                                        );
                                                    }
                                                )}
                                            </>
                                        )}
                                </Box>
                                <Box>
                                    <IconButton
                                        size='large'
                                        onClick={handleMenu}
                                        color='primary'
                                    >
                                        <AccountCircle />
                                    </IconButton>
                                    <Menu
                                        anchorEl={anchorEl}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        keepMounted
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem
                                            onClick={onChangePasswordHandler}
                                        >
                                            Change password
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() =>
                                                handleGenerateApiKey()
                                            }
                                        >
                                            Generate API Key
                                        </MenuItem>
                                        <MenuItem onClick={onLogoutHandler}>
                                            Sign out
                                        </MenuItem>
                                    </Menu>
                                </Box>
                            </>
                        )}
                    </Toolbar>
                </AppBar>
            </Box>
            <GenerateApiKeyDialog
                apiKey={apiKey ?? ''}
                apiKeyDialogoOpen={apiKeyDialogoOpen}
                handleApiKeyDialogClose={handleApiKeyDialogClose}
            />
        </Fragment>
    ) : null;
};
