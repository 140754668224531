import { Box } from '@mui/material';

interface TabPanelProps<T> {
    children?: React.ReactNode;
    currentTab: T;
    value: T;
}

export const TabPanel = <T extends unknown>(props: TabPanelProps<T>) => {
    const { children, value, currentTab, ...other } = props;

    return (
        <div
            role='tabpanel'
            hidden={value !== currentTab}
            id={`simple-tabpanel-${currentTab}`}
            aria-labelledby={`simple-tab-${currentTab}`}
            {...other}
        >
            {value === currentTab && <Box>{children}</Box>}
        </div>
    );
};
