import { TableCell, TableHead, TableRow } from '@mui/material';

export const CustomTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell>N</TableCell>
                <TableCell width={200}>Details</TableCell>
                <TableCell>Media Article Incident</TableCell>
                <TableCell>Detailed description of incident</TableCell>
                <TableCell>Link</TableCell>
                <TableCell>Date article was written</TableCell>
                <TableCell>Score reduction (/100)</TableCell>
                <TableCell>Qa Status</TableCell>
                <TableCell />
                <TableCell />
                <TableCell />
            </TableRow>
        </TableHead>
    );
};
