import { Stack, TextField } from '@mui/material';
import { useGetStatistics } from 'api/hooks/assessment/useGetStatistics';
import { WithSpinner } from 'components/with-spinner';
import { FC } from 'react';
import { useParams } from 'react-router';

type Props = {};

export const AssessmentDetailsOverallInfo: FC<Props> = () => {
    const { assessmentId } = useParams();
    const { data, isLoading } = useGetStatistics({
        assessmentId: assessmentId ?? '',
        enabled: assessmentId !== '0' && !!assessmentId,
    });

    return (
        <WithSpinner isLoading={isLoading}>
            {data && (
                <form>
                    <Stack
                        direction={'row'}
                        spacing={2}
                        justifyContent='center'
                        marginBottom={4}
                    >
                        <TextField
                            variant='outlined'
                            value={data.status ?? ''}
                            disabled
                            label='Status'
                            sx={{ width: 250 }}
                        />
                        <TextField
                            variant='outlined'
                            value={data.fiscal_year ?? ''}
                            disabled
                            label='Fiscal Year'
                            sx={{ width: 250 }}
                        />
                        <TextField
                            variant='outlined'
                            value={data.company?.name ?? ''}
                            disabled
                            label='Company Name'
                            sx={{ width: 250 }}
                        />
                        {/* <TextField
                            variant='outlined'
                            value={data.percentage_of_completion ?? ''}
                            disabled
                            label='Percentage Of Completion'
                        />
                        <TextField
                            variant='outlined'
                            value={data.analyst_spent_hours ?? ''}
                            disabled
                            label='Analyst Spent Hours'
                        />
                        <TextField
                            variant='outlined'
                            value={data.assurer_spent_hours ?? ''}
                            disabled
                            label='QA Spent Hours'
                        /> */}
                    </Stack>
                </form>
            )}
        </WithSpinner>
    );
};
