import { getCompanyPerformanceQuestionById } from 'api/hooks/company-performance-question/companyPerformanceQuestionsService';
import { CompanyPerformanceQuestionResponse } from 'api/hooks/company-performance-question/companyPerformanceQuestionsTypes';
import { useQuery } from 'react-query';

type Props<M> = {
    id: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: CompanyPerformanceQuestionResponse) => void;
    mapper?: (
        data?: CompanyPerformanceQuestionResponse
    ) => M | CompanyPerformanceQuestionResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: CompanyPerformanceQuestionResponse) => data;

export const useGetCompanyPerformanceQuestion = <
    M = CompanyPerformanceQuestionResponse
>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    id,
}: Props<M>) => {
    const query = useQuery<CompanyPerformanceQuestionResponse, Error>(
        ['companyPerformanceQuestion', id],
        () => getCompanyPerformanceQuestionById(id),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
