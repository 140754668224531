import { useQuery } from 'react-query';
import { PeerGroupListResponse } from './peerGroupTypes';
import { getPeerGroupList } from './peerGroupService';

type Props<M> = {
    onError?: (error: Error) => void;
    onSuccess?: (data: PeerGroupListResponse) => void;
    mapper?: (
        data?: PeerGroupListResponse
    ) => M | PeerGroupListResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: PeerGroupListResponse) => data;

export const useGetPeerGroupList = <M = PeerGroupListResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
}: Props<M>) => {
    const query = useQuery<PeerGroupListResponse, Error>(
        ['peerGroups'],
        getPeerGroupList,
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
