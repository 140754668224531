import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
} from '@mui/material';
import { FC, ReactElement } from 'react';

type Props = {
    open: boolean;
    onClose: () => void;
    title?: string;
    contentText?: string;
    children?: ReactElement;
};

export const DialogComponent: FC<Props> = ({
    open,
    onClose,
    title,
    contentText,
    children,
}) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            {title && <DialogTitle>{title}</DialogTitle>}
            {title && <Divider />}
            <DialogContent>
                {contentText && (
                    <DialogContentText>{contentText}</DialogContentText>
                )}
                {children}
            </DialogContent>
        </Dialog>
    );
};
