import {
    Button,
    Checkbox,
    FormControlLabel,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {
    CustomSelectField,
    generateSelectFieldProps,
} from 'components/shared/form/custom-select-field';
import {
    CustomMultipleSelectField,
    generateMultipleSelectFieldProps,
} from 'components/shared/form/custom-multiple-select-field';
import {
    multipleSelectOptionsToString,
    stringToMultipleSelectOptions,
} from 'utils';
import { CustomAutoCompleteField } from 'components/shared/form/custom-autocomplete-field';
import {
    autocompleteFormikValueToOptions,
    optionsToAutoCompleteFormikValue,
} from 'utils/multiple-select-utils';
import { Industry } from 'api/hooks/industry/industryTypes';
import { Company, CreateUpdateCompany } from 'api/hooks/company/companyTypes';
import { Country } from 'api/hooks/country/countryTypes';
import { PeerGroup } from 'api/hooks/peer-group/peerGroupTypes';
import { dateToAPIDate } from 'utils/common';

type Props = {
    onCancelClick: () => void;
    onSaveClick: (
        createUpdateCompanyRequest: CreateUpdateCompany,
        id?: string
    ) => void;
    company?: Company;
    industries: Industry[];
    countries: Country[];
    peerGroups: PeerGroup[];
};

export const CompanyDetailsForm: FC<Props> = ({
    company,
    industries,
    countries,
    peerGroups,
    onCancelClick,
    onSaveClick,
}) => {
    const formName = 'CompanyDetailsForm';
    const formik = useFormik({
        initialValues: {
            name: company?.name ?? '',
            arPublicationDate: company?.ar_publication_date?.toString() ?? '',
            client: company?.is_client ?? false,
            countryListed: multipleSelectOptionsToString(
                company?.country_listed
            ),
            fiscalYearEnd: company?.fiscal_year_end?.toString() ?? '',
            industry: company?.industry?.code ?? '',
            peerGroup: optionsToAutoCompleteFormikValue(
                company?.peer_groups?.map((peerGroup) => ({
                    value: peerGroup.isin,
                    label: peerGroup.name,
                })) ?? []
            ),
            subIndustry: multipleSelectOptionsToString(
                company?.sub_industries?.map(
                    (subIndustry) => subIndustry.code ?? ''
                ) ?? []
            ),
            uniqueIdentifier: company?.isin ?? '',
            website: company?.website ?? '',
        },
        onSubmit: (values) => {
            // const fiscalYearEnd = new Date(values.fiscalYearEnd)
            //     .toISOString()
            //     .split('T')[0];
            // const arPublicationDate = new Date(values.arPublicationDate)
            //     .toISOString()
            //     .split('T')[0];

            onSaveClick(
                {
                    isin: values.uniqueIdentifier,
                    name: values.name,
                    is_client: values.client,
                    ar_publication_date: dateToAPIDate(
                        new Date(values.arPublicationDate)
                    ),
                    fiscal_year_end: dateToAPIDate(
                        new Date(values.fiscalYearEnd)
                    ),
                    country_listed: stringToMultipleSelectOptions(
                        values.countryListed
                    ),
                    industry: values.industry,
                    peer_groups: autocompleteFormikValueToOptions(
                        values.peerGroup
                    ).map((option) => option.value),
                    sub_industries: stringToMultipleSelectOptions(
                        values.subIndustry
                    ),
                    website: values.website,
                },
                company?._id
            );
        },
    });
    type FormikTypes = typeof formik.initialValues;

    const handleSelectIndustry = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
        formik.setFieldValue('subIndustry', null);
    };

    const handleMultipleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        const newValue = Array.isArray(value)
            ? value.length
                ? multipleSelectOptionsToString(value)
                : null
            : value;

        formik.setFieldValue(name, newValue);
    };

    const handleSubIndustryDeleteClick = (value: string) => {
        const newArray = stringToMultipleSelectOptions(
            formik.values.subIndustry
        )?.filter((subIndustry) => {
            return subIndustry !== value;
        });

        const newValues = multipleSelectOptionsToString(newArray);
        formik.setFieldValue(
            'subIndustry',
            newValues === '' ? null : newValues
        );
    };

    const handleCountryDeleteClick = (value: string) => {
        const newArray = stringToMultipleSelectOptions(
            formik.values.countryListed
        )?.filter((country) => {
            return country !== value;
        });
        const newValues = multipleSelectOptionsToString(newArray);

        formik.setFieldValue(
            'countryListed',
            newValues === '' ? null : newValues
        );
    };

    const handleAutoCompleteSelect = (name: string, newValue: string) => {
        formik.setFieldValue(name, newValue);
    };

    const industriesOptions = industries.map((industry) => ({
        value: industry.code,
        label: industry.name,
    }));

    const subIndustriesOptions = industries
        .find((industry) => industry.code === formik.values.industry)
        ?.sub_industries.map((subIndustry) => ({
            value: subIndustry.code,
            label: subIndustry.name,
        }));

    const countriesOptions = countries.map((country) => ({
        value: country.code,
        label: country.name,
    }));

    const peerGroupOptions = peerGroups.map((peerGroup) => ({
        value: peerGroup.isin,
        label: peerGroup.name,
    }));

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={4}>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'name',
                            'Name',
                            formName,
                            formik
                        )}
                    />
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'uniqueIdentifier',
                            'Unique Identifier',
                            formName,
                            formik
                        )}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomSelectField
                        options={industriesOptions}
                        onChange={handleSelectIndustry}
                        {...generateSelectFieldProps<FormikTypes>(
                            'industry',
                            'Industry',
                            formName,
                            formik
                        )}
                    />
                    <CustomMultipleSelectField
                        options={subIndustriesOptions}
                        onChange={handleMultipleSelect}
                        {...generateMultipleSelectFieldProps<FormikTypes>(
                            'subIndustry',
                            'Sub Industry',
                            formName,
                            formik
                        )}
                        handleDelete={handleSubIndustryDeleteClick}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomAutoCompleteField
                        options={peerGroupOptions}
                        onChange={(value) => {
                            handleAutoCompleteSelect('peerGroup', value);
                        }}
                        value={formik.values.peerGroup}
                        label={'Peer Group'}
                    />
                    <DesktopDatePicker
                        label='AR Publication Date'
                        inputFormat='DD/MM/YYYY'
                        disableFuture
                        value={formik.values.arPublicationDate ?? null}
                        onChange={(newValue) => {
                            formik.setFieldValue('arPublicationDate', newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant='outlined'
                            />
                        )}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomMultipleSelectField
                        options={countriesOptions}
                        onChange={handleMultipleSelect}
                        {...generateMultipleSelectFieldProps<FormikTypes>(
                            'countryListed',
                            'Country Listed',
                            formName,
                            formik
                        )}
                        handleDelete={handleCountryDeleteClick}
                    />
                    <DesktopDatePicker
                        label='Fiscal Year End'
                        inputFormat='DD/MM/YYYY'
                        disableFuture
                        value={formik.values.fiscalYearEnd ?? null}
                        onChange={(newValue) => {
                            formik.setFieldValue('fiscalYearEnd', newValue);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant='outlined'
                            />
                        )}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'website',
                            'Website',
                            formName,
                            formik
                        )}
                    />
                    <div style={{ width: '100%' }} />
                </Stack>
                <Stack spacing={2} width={'50%'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.client}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'client',
                                        !formik.values.client
                                    );
                                }}
                            />
                        }
                        label='Client'
                    />
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={'flex-end'}
                >
                    <Button variant='outlined' onClick={onCancelClick}>
                        Cancel
                    </Button>
                    <Button variant='contained' role='submit' type='submit'>
                        Save
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};
