import { CreateCompanyPerformanceAssessment } from 'api/hooks/company-performance-assessment/companyPerformanceAssessmentTypes';
import { useGetCompanyPerformanceAssessmentList } from 'api/hooks/company-performance-assessment/useGetCompanyPerformanceAssessmentList';
import { useUpdateCompanyPerformanceAssessment } from 'api/hooks/company-performance-assessment/useUpdateCompanyPerformanceAssessment';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { DataState } from 'store/enums';
import {
    selectCompanyPerformanceAssessment,
    selectCompanyPerformanceAssessmentFilters,
    setInitialCompanyPerformanceAssessment,
} from 'store/slices/companyPerformanceAssessmentSlice';
import { useAppDispatch, useAppSelector } from 'store/store';

export const useCompanyPerformanceSave = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { assessmentId } = useParams();
    const dispatch = useAppDispatch();

    const data = useAppSelector(selectCompanyPerformanceAssessment);
    const filters = useAppSelector(selectCompanyPerformanceAssessmentFilters);

    const { mutateAsync } = useUpdateCompanyPerformanceAssessment({});

    const { query: queryCompanyPerformanceAssessment } =
        useGetCompanyPerformanceAssessmentList({
            assessmentId: assessmentId ?? '',
            enabled: false,
            filters: filters ?? {},
        });

    const save = async (): Promise<boolean> => {
        const updateData = data.table.filter(
            (element) => element.state === DataState.UPDATED
        );
        if (updateData.length) {
            setIsLoading(true);
            let success = true;
            for (const element of updateData) {
                try {
                    await mutateAsync({
                        id: element.data._id,
                        request:
                            element.data as CreateCompanyPerformanceAssessment,
                    });
                } catch (error) {
                    success = false;
                }
            }

            if (success) {
                toast.success('Company Performance Saved!');
                const data = await queryCompanyPerformanceAssessment.refetch();
                dispatch(
                    setInitialCompanyPerformanceAssessment(data.data ?? [])
                );
            } else {
                toast.error('Company Performance Save Failed!');
            }

            return success;
        }

        return true;
    };

    return { save, isLoading };
};
