import { useGetSDGAssessmentList } from 'api/hooks';
import { CreateSDGAssessment } from 'api/hooks/sdg-assessment/sdgAssessmentTypes';
import { useUpdateSDGAssessment } from 'api/hooks/sdg-assessment/useUpdateSDGAssessment';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { DataState } from 'store/enums';

import {
    selectSDGAssessment,
    setInitialSDG,
    updateSDG,
} from 'store/slices/sdgAssessmentSlice';
import { useAppDispatch, useAppSelector } from 'store/store';

export const useSDGAssessmentSave = () => {
    const { assessmentId } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();

    const data = useAppSelector(selectSDGAssessment);

    const { query: querySDGAssessment } = useGetSDGAssessmentList({
        assessmentId: assessmentId ?? '',
        enabled: false,
    });

    const { mutateAsync } = useUpdateSDGAssessment({});

    const save = async (): Promise<boolean> => {
        const updateData = data.table.filter(
            (element) => element.state === DataState.UPDATED
        );
        if (updateData.length) {
            setIsLoading(true);
            let success = true;
            for (const element of updateData) {
                try {
                    await mutateAsync({
                        id: element.data._id,
                        request: element.data as CreateSDGAssessment,
                    });
                    dispatch(
                        updateSDG({
                            data: element.data,
                            state: DataState.INITIAL,
                        })
                    );
                } catch (error) {
                    dispatch(
                        updateSDG({
                            data: element.data,
                            state: DataState.ERROR,
                        })
                    );
                    success = false;
                }
            }

            if (success) {
                toast.success('SDG Saved!');
                const sdgData = await querySDGAssessment.refetch();
                dispatch(setInitialSDG(sdgData.data ?? []));
            } else {
                toast.error('SDG Save Failed!');
            }

            return success;
        }

        return true;
    };

    return { save, isLoading };
};
