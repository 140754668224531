import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { DataState } from 'store/enums';
import {
    CompanyPerformanceAssessment,
    CompanyPerformanceAssessmentFilters,
    CompanyPerformanceAssessmentListResponse,
} from 'api/hooks/company-performance-assessment/companyPerformanceAssessmentTypes';
import { QaStatus } from 'api/hooks/commonTypes';

export interface CompanyPerformanceAssessmentState {
    table: {
        selected: boolean;
        data: CompanyPerformanceAssessment;
        state: DataState;
    }[];
    page: number;
    rowsPerPage: number;
    filters?: CompanyPerformanceAssessmentFilters;
}

const initialState: CompanyPerformanceAssessmentState = {
    table: [],
    page: 0,
    rowsPerPage: 100,
    filters: {},
};

export const CompanyPerformanceAssessmentSlice = createSlice({
    name: 'companyPerformanceAssessment',
    initialState,
    reducers: {
        updatePage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        updateRowsPerPage: (state, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload;
        },
        updateFilters: (
            state,
            action: PayloadAction<CompanyPerformanceAssessmentFilters>
        ) => {
            state.filters = action.payload;
        },
        setInitialCompanyPerformanceAssessment: (
            state,
            action: PayloadAction<CompanyPerformanceAssessmentListResponse>
        ) => {
            const newCompanyPerfomances = action.payload.map((el) => ({
                data: el,
                state: DataState.INITIAL,
                selected: false,
            }));
            state.table = newCompanyPerfomances;
        },
        updateCompanyPerformanceAssessment: (
            state,
            action: PayloadAction<{
                data: CompanyPerformanceAssessment;
                state?: DataState;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload.data._id) {
                    return {
                        ...el,
                        data: action.payload.data,
                        state:
                            action.payload.state === undefined
                                ? el.state === DataState.NEW
                                    ? DataState.NEW
                                    : DataState.UPDATED
                                : action.payload.state,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
        updateQaStatus: (
            state,
            action: PayloadAction<{
                _id: string;
                qaStatus: QaStatus;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        data: {
                            ...el.data,
                            qa_status: action.payload.qaStatus,
                        },
                        state: DataState.UPDATED,
                        selected: false,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
        toggleCompanyPerformanceAssessment: (
            state,
            action: PayloadAction<{
                _id: string;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        selected: !el.selected,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
    },
});

export const {
    setInitialCompanyPerformanceAssessment,
    updateCompanyPerformanceAssessment,
    toggleCompanyPerformanceAssessment,
    updateQaStatus,
    updatePage,
    updateRowsPerPage,
    updateFilters,
} = CompanyPerformanceAssessmentSlice.actions;

export const selectCompanyPerformanceAssessmentTable = (state: RootState) =>
    state.companyPerformanceAssessment.table;

export const selectCompanyPerformanceAssessment = (state: RootState) =>
    state.companyPerformanceAssessment;

export const selectCompanyPerformanceAssessmentTableData = (state: RootState) =>
    state.companyPerformanceAssessment.table.map((el) => ({
        data: el.data,
        isSelected: el.selected,
    }));

export const selectCompanyPerformanceAssessmentPageData = (
    state: RootState
) => ({
    page: state.companyPerformanceAssessment.page,
    rowsPerPage: state.companyPerformanceAssessment.rowsPerPage,
});

export const selectCompanyPerformanceAssessmentFilters = (state: RootState) =>
    state.companyPerformanceAssessment.filters;

export default CompanyPerformanceAssessmentSlice.reducer;
