import { authApi } from 'lib';
import {
    CategoryListResponse,
    CategoryResponse,
    CreateUpdateCategory,
} from './categoryTypes';

export const getCategoryList = async () => {
    const response = await authApi.get<CategoryListResponse>(
        '/api/v1/categories'
    );
    return response.data;
};

export const getCategoryById = async (id: string) => {
    const response = await authApi.get<CategoryResponse>(
        `/api/v1/categories/${id}`
    );
    return response.data;
};

export const createCategory = async (request: CreateUpdateCategory) => {
    const response = await authApi.post<CategoryResponse>(
        `/api/v1/categories`,
        request
    );
    return response.data;
};

export const updateCategory = async (
    id: string,
    request: CreateUpdateCategory
) => {
    const response = await authApi.put<CategoryResponse>(
        `/api/v1/categories/${id}`,
        request
    );
    return response.data;
};

export const deleteCategory = async (id: string) => {
    return await authApi.delete(`/api/v1/categories/${id}`);
};
