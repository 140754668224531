import { Box, Button, Pagination, TextField, Typography } from '@mui/material';
import { FC, useState } from 'react';

type Props = {
    dataLength: number;
    page: number;
    rowsPerPage: number;
    setPage: (page: number) => void;
    setRowsPerPage: (rowsPerPage: number) => void;
};

export const CustomPagination: FC<Props> = ({
    dataLength,
    page,
    rowsPerPage,
    setPage,
    setRowsPerPage,
}) => {
    const [newPage, setNewPage] = useState<null | number>(null);

    const pageCount = Math.ceil(dataLength / rowsPerPage);
    const currentPage = page + 1;
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage - 1);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                my: 2,
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                <Typography marginRight={1}>Rows Per Page</Typography>
                <TextField
                    value={rowsPerPage}
                    onChange={(event) => {
                        const newValue = event.target.value
                            ? Math.min(
                                  Math.max(parseInt(event.target.value, 10), 1),
                                  200
                              )
                            : 10;
                        setRowsPerPage(newValue);
                        setPage(0);
                    }}
                    sx={{ width: 60, height: 50, p: 0 }}
                />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                <Typography marginRight={1}>Jump To Page</Typography>
                <TextField
                    value={newPage ?? ''}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setNewPage(null);
                        } else {
                            const newValue = event.target.value
                                ? Math.min(
                                      Math.max(
                                          parseInt(event.target.value, 10),
                                          1
                                      ),
                                      pageCount
                                  )
                                : 1;

                            setNewPage(newValue);
                        }
                    }}
                    sx={{ width: 60, height: 50, p: 0 }}
                />
                <Button
                    onClick={(e) => {
                        e.preventDefault();
                        if (newPage) {
                            setPage(newPage - 1);
                        }
                    }}
                >
                    JUMP
                </Button>
            </Box>
            <Pagination
                page={currentPage}
                showFirstButton
                showLastButton
                count={pageCount}
                onChange={handleChangePage}
            />
        </Box>
    );
};
