import { useQuery } from 'react-query';
import { MaterialityQuestionListResponse } from './materialityQuestionsTypes';
import { getMaterialityQuestionList } from './materialityQuestionService';

type Props<M> = {
    onError?: (error: Error) => void;
    onSuccess?: (data: MaterialityQuestionListResponse) => void;
    mapper?: (
        data?: MaterialityQuestionListResponse
    ) => M | MaterialityQuestionListResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: MaterialityQuestionListResponse) => data;

export const useGetMaterialityQuestionList = <
    M = MaterialityQuestionListResponse
>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
}: Props<M>) => {
    const query = useQuery<MaterialityQuestionListResponse, Error>(
        ['materialityQuestions'],
        getMaterialityQuestionList,
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
