import { useQuery } from 'react-query';
import { CompanyInformation } from './assessmentTypes';
import { getCompanyInformation } from './assessmentService';

type Props<M> = {
    assessmentId: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: CompanyInformation) => void;
    mapper?: (data?: CompanyInformation) => M | CompanyInformation | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: CompanyInformation) => data;

export const useGetCompanyInformation = <M = CompanyInformation>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    assessmentId,
}: Props<M>) => {
    const query = useQuery<CompanyInformation, Error>(
        ['companyInformation', assessmentId],
        () => getCompanyInformation(assessmentId),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
