import { Button, Container, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import { generateRoutePath } from 'utils';
import { WithSpinner } from 'components/with-spinner';
import { useNavigate, useParams } from 'react-router';
import { RoutePaths } from 'routes';
import { AdminRoutePaths } from 'constants/enums/routes-constants';
import { useGetMainQuestion } from 'api/hooks/main-question/useGetMainQuestion';
import { PageTitle } from 'components/shared/page-title';
import { VariationForm } from './variation/variation-form';
import { useCreateMainQuestion } from 'api/hooks/main-question/useCreateMainQuestion';
import { useUpdateMainQuestion } from 'api/hooks/main-question/useUpdateMainQuestion';
import { VariationListTable } from './variation/variations-list-table';
import { toast } from 'react-toastify';
import {
    CreateUpdateMainQuestion,
    CreateUpdateMainQuestionVariation,
    MainQuestionVariationGuide,
} from 'api/hooks/main-question/mainQuestionsTypes';

type Props = {};

export const AdminMainQuestionForm: FC<Props> = () => {
    const navigate = useNavigate();
    const { mainQuestionId } = useParams();
    const isNew = mainQuestionId === '0';

    const [showNewVariationForm, setShowNewVariationForm] = useState(false);
    const [variationToEdit, setVariationToEdit] = useState<{
        data: CreateUpdateMainQuestionVariation;
        index: number;
    }>();
    const [variations, setVariations] = useState<
        CreateUpdateMainQuestionVariation[]
    >([]);

    const { data: mainQuestion, isLoading: mainQuestionLoading } =
        useGetMainQuestion({
            id: mainQuestionId ?? '',
            enabled: !isNew,
            onError: () => {
                navigateToMainQuestions();
            },
            onSuccess: (data) => {
                setVariations(data.variations ?? []);

                formik.setValues({
                    identifier: data?.identifier ?? '',
                    unique_id: data?.unique_id ?? '',
                    category: data?.category ?? '',
                    sub_category_first: data?.sub_category_first ?? '',
                    sub_category_second: data?.sub_category_second ?? '',
                    question: data?.question ?? '',
                });

                formik.validateForm();
            },
        });

    const { mutate: createMainQuestion } = useCreateMainQuestion({
        onSuccess: () => navigateToMainQuestions(),
    });
    const { mutate: updateMainQuestion } = useUpdateMainQuestion({
        onSuccess: () => navigateToMainQuestions(),
    });

    const formName = AdminMainQuestionForm.name;
    const validationSchema = Yup.object({
        identifier: Yup.string().required('Required'),
        unique_id: Yup.string().required('Required'),
        category: Yup.string().required('Required'),
        sub_category_first: Yup.string().required('Required'),
        sub_category_second: Yup.string().required('Required'),
        question: Yup.string().required('Required'),
    });
    const formik = useFormik({
        initialValues: {
            identifier: '',
            unique_id: '',
            category: '',
            sub_category_first: '',
            sub_category_second: '',
            question: '',
        },
        validationSchema,
        onSubmit: (data) => {
            const createUpdateVariations: CreateUpdateMainQuestionVariation[] =
                variations.map((item) => ({
                    industry: item.industry,
                    sub_industry: item.sub_industry,
                    scoring_guidance: item.scoring_guidance,
                    max_score_possible: item.max_score_possible,
                    question_number: item.question_number,
                    guides: item.guides,
                }));
            const mainQuestion: CreateUpdateMainQuestion = {
                category: data.category,
                identifier: data.identifier,
                question: data.question,
                sub_category_first: data.sub_category_first,
                sub_category_second: data.sub_category_second,
                unique_id: data.unique_id,
                variations: createUpdateVariations,
            };
            if (isNew) {
                createMainQuestion(mainQuestion);
            } else {
                if (mainQuestionId) {
                    updateMainQuestion({
                        id: mainQuestionId,
                        request: mainQuestion,
                    });
                }
            }
        },
    });
    type FormikTypes = typeof formik.initialValues;

    const navigateToMainQuestions = () => {
        navigate(
            generateRoutePath([
                RoutePaths.ADMIN,
                AdminRoutePaths.ADMIN_MAIN_QUESTIONS,
            ])
        );
    };

    const handleDeleteVariation = (index: number) => {
        const newVariations = variations.filter((_, i) => i !== index);
        setVariations(newVariations);
    };

    const handleEditVariation = (index: number) => {
        if (index > variations.length) return;
        const variationToEdit = variations[index];
        setVariationToEdit({ data: variationToEdit, index });
        setShowNewVariationForm(true);
    };

    const handleUpdateVariation = (
        variationIndex: number,
        newVariation: CreateUpdateMainQuestionVariation
    ) => {
        const newVariations = variations.map((variation, index) => {
            if (index === variationIndex) {
                return {
                    ...variation,
                    ...newVariation,
                    guides: variation.guides,
                };
            }
            return variation;
        });
        setVariationToEdit(undefined);
        setShowNewVariationForm(false);
        setVariations(newVariations);
    };

    const handleAddVariation = (
        variation: CreateUpdateMainQuestionVariation
    ) => {
        const newVariations = [...variations, variation];

        setVariations(newVariations);
        setShowNewVariationForm(false);
    };

    const handleAddGuide = (
        variationIndex: number,
        variationGuide: MainQuestionVariationGuide
    ) => {
        const foundVariation = variations.find(
            (_, index) => index === variationIndex
        );
        const foundGuide =
            foundVariation?.guides?.some((item) => {
                return item.order === variationGuide.order;
            }) ?? false;

        if (foundGuide) {
            toast.error('Guide with that order number already added');
            return false;
        }

        const newVariations = variations.map((item, index) => {
            if (index === variationIndex) {
                const oldGuides = item?.guides ?? [];
                item.guides = [...oldGuides, variationGuide];
            }
            return item;
        });
        setVariations(newVariations);
        return true;
    };

    const handleDeleteGuide = (variationIndex: number, orderNumber: number) => {
        const newVariations = variations.map((item, index) => {
            if (index === variationIndex) {
                const newGuides =
                    item.guides?.filter(
                        (guide) => guide.order !== orderNumber
                    ) ?? [];
                item.guides = [...newGuides];
            }

            return item;
        });

        setVariations(newVariations);
    };

    const isLoading = mainQuestionLoading;

    return (
        <WithSpinner isLoading={isLoading}>
            <Container>
                <PageTitle
                    title={
                        isNew
                            ? 'Create New Question'
                            : `Update Question ${mainQuestion?.identifier}`
                    }
                />
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'identifier',
                                    'Identifier',
                                    formName,
                                    formik
                                )}
                            />
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'category',
                                    'Category',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'unique_id',
                                    'Unique Id',
                                    formName,
                                    formik
                                )}
                            />
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'sub_category_first',
                                    'First Sub Category',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <div style={{ width: '100%' }} />
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'sub_category_second',
                                    'Second Sub Category',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'question',
                                    'Question',
                                    formName,
                                    formik
                                )}
                                multiline
                                rows={4}
                            />
                        </Stack>
                    </Stack>

                    <Stack spacing={2} marginTop={4}>
                        {!showNewVariationForm ? (
                            <Button
                                color='primary'
                                role='submit'
                                type='submit'
                                variant='contained'
                                onClick={() => setShowNewVariationForm(true)}
                            >
                                Add New Variation
                            </Button>
                        ) : (
                            <VariationForm
                                onCancel={() => setShowNewVariationForm(false)}
                                onAdd={handleAddVariation}
                                onEdit={handleUpdateVariation}
                                variation={variationToEdit}
                            />
                        )}
                        <VariationListTable
                            onDelete={handleDeleteVariation}
                            onEdit={handleEditVariation}
                            onAddGuide={handleAddGuide}
                            onDeleteGuide={handleDeleteGuide}
                            variations={variations}
                        />

                        <Stack
                            direction={'row'}
                            spacing={2}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                color='primary'
                                data-testid={`${formName}-cancel`}
                                variant='contained'
                                onClick={() => navigateToMainQuestions()}
                            >
                                Cancel
                            </Button>
                            <Button
                                color='primary'
                                data-testid={`${formName}-submit`}
                                role='submit'
                                type='submit'
                                variant='contained'
                            >
                                Save
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Container>
        </WithSpinner>
    );
};
