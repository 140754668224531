import { authApi } from 'lib';
import {
    CreateSDGAssessment,
    SDGAssessmentListResponse,
} from './sdgAssessmentTypes';

export const getSDGAssessmentList = async (id: string) => {
    const response = await authApi.get<SDGAssessmentListResponse>(
        '/api/v1/sdg_assessments',
        { params: { assessment_id: id } }
    );
    return response.data;
};

export const updateSDGAssessmentList = async (
    id: string,
    request: CreateSDGAssessment
) => {
    const response = await authApi.put(
        `/api/v1/sdg_assessments/${id}`,
        request
    );
    return response.data;
};
