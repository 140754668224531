import { FC } from 'react';
import { ScreenLoader } from 'components/shared/screen-loader';
import { useGetMe } from 'api/hooks/';
import { AUTH_TOKEN } from 'constants/session-storage-constants';

type Props = {
    children: React.ReactElement;
};

export const AuthMiddleware: FC<Props> = ({ children }) => {
    // const dispatch = useAppDispatch();
    const token = localStorage.getItem(AUTH_TOKEN);

    const { isLoading } = useGetMe({
        enabled: !!token,
    });

    if (isLoading && !!token) {
        //TODO Spinner here
        return <ScreenLoader />;
    }

    return children;
};
