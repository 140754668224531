import { Box, Button, Container, Menu, MenuItem } from '@mui/material';
import { TabPanel } from 'components/shared/tab-panel';
import { AssessmentTabs } from 'components/assessment/assessment-analyst/assessment-tabs-enum';
import { FC, Fragment, SyntheticEvent, useState } from 'react';
import { AssessmentDetailsTabsHeaders } from './assessment-details-tabs-header';
import { MainQuestionAssessment } from './main-question-assessment/main-question-assessment';
import { MaterialityAssessment } from './materiality-assessment/materiality-assessment';
import { SDGAssessment } from './sdg-assessment/sdg-assessment';
import { ControversyAssessment } from './controversy-assessment/controversy-assessment';
import { CompanyPerformanceAssessment } from './company-performance-assessment/company-performance-assessment';
import { AutoSave, AutoSaveRef } from 'store/autosave';
import QaTypeEdit from './qa-type-edit/qa-type-edit';
import { AnalystAndAssurerEdit } from './analyst-and-assurer-edit/analyst-and-assurer-edit';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import { CompanyInformationEdit } from './company-information-edit/company-information-edit';
import { ConfirmationDialog } from 'components/confirmation-dialog';
import { useNavigate, useParams } from 'react-router';
import ConfirmationDialogWithoutButton from 'components/confirmation-dialog/confirmation-dialog-without-button';
import { useDeleteAssessment } from 'api/hooks/assessment/useDeleteAssessment';
import { toast } from 'react-toastify';

type Props = {
    autoSaveRef: React.MutableRefObject<AutoSaveRef | null>;
};

export const AssessmentAnalyst: FC<Props> = ({ autoSaveRef }) => {
    const [value, setValue] = useState(AssessmentTabs.QUESTIONNAIRE);

    const handleChange = (event: SyntheticEvent, newValue: AssessmentTabs) => {
        setValue(newValue);
    };

    return (
        <Container>
            <Box>
                <Box
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                    padding={0}
                >
                    <AssessmentDetailsTabsHeaders
                        value={value}
                        onChange={handleChange}
                    />
                </Box>
                <Box padding={0}>
                    <TabPanel
                        value={value}
                        currentTab={AssessmentTabs.QUESTIONNAIRE}
                    >
                        <MainQuestionAssessment />
                    </TabPanel>
                    <TabPanel
                        value={value}
                        currentTab={AssessmentTabs.MATERIALITY}
                    >
                        <MaterialityAssessment />
                    </TabPanel>
                    <TabPanel
                        value={value}
                        currentTab={
                            AssessmentTabs.SUSTAINABLE_DEVELOPMENT_GOALS
                        }
                    >
                        <SDGAssessment />
                    </TabPanel>
                    <TabPanel
                        value={value}
                        currentTab={AssessmentTabs.CONTROVERSIES_SEARCH}
                    >
                        <ControversyAssessment />
                    </TabPanel>
                    <TabPanel
                        value={value}
                        currentTab={AssessmentTabs.COMPANY_PERFORMANCE_DATA}
                    >
                        <CompanyPerformanceAssessment />
                    </TabPanel>
                </Box>
            </Box>
            <Box
                // display={'flex'}
                sx={{
                    '& > :not(style)': {
                        // m: 1,
                        // margin: 0,
                        // top: 'auto',
                        // left: 20,
                        right: 'auto',
                        bottom: 20,
                        position: 'fixed',
                    },
                }}
            >
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                    <SplitActionButtons />
                    {/* <AnalystAndAssurerEdit /> */}
                    {/* <QaTypeEdit /> */}
                    <AutoSave ref={autoSaveRef} />
                </Box>
            </Box>
        </Container>
    );
};

const SplitActionButtons = () => {
    const { assessmentId } = useParams();
    const navigate = useNavigate();
    const [openAnalystAndAssurer, setOpenAnalystAndAssurer] = useState(false);
    const [openDeleteAssesmentDialog, setOpenDeleteAssessmentDialog] =
        useState(false);
    const [openQaTypeEdit, setOpenQaTypeEdit] = useState(false);
    const [openCompanyInformationEdit, setOpenCompanyInformationEdit] =
        useState(false);

    const { mutate } = useDeleteAssessment({
        onSuccess: () => {
            navigate('/');
            toast.success('Assessment Deleted');
        },
    });

    const handleAssessmentDeletion = (id: string) => {
        mutate(id);
    };

    return (
        <>
            <PopupState variant='popover' popupId='demo-popup-menu'>
                {(popupState) => (
                    <Fragment>
                        <Button
                            variant='contained'
                            {...bindTrigger(popupState)}
                        >
                            Actions
                        </Button>
                        <Menu {...bindMenu(popupState)}>
                            <MenuItem
                                onClick={() => {
                                    setOpenAnalystAndAssurer(true);
                                    popupState.close();
                                }}
                            >
                                Analyst/Assurer Edit
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setOpenQaTypeEdit(true);
                                    popupState.close();
                                }}
                            >
                                Qa Type Edit
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setOpenCompanyInformationEdit(true);
                                    popupState.close();
                                }}
                            >
                                Company Information Edit
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    // setOpenCompanyInformationEdit(true);
                                    popupState.close();
                                    setOpenDeleteAssessmentDialog(true);
                                }}
                            >
                                Delete Assessment
                            </MenuItem>
                        </Menu>
                    </Fragment>
                )}
            </PopupState>
            {openAnalystAndAssurer && (
                <AnalystAndAssurerEdit
                    open={openAnalystAndAssurer}
                    setOpen={setOpenAnalystAndAssurer}
                />
            )}
            {openQaTypeEdit && (
                <QaTypeEdit open={openQaTypeEdit} setOpen={setOpenQaTypeEdit} />
            )}
            {openCompanyInformationEdit && (
                <CompanyInformationEdit
                    open={openCompanyInformationEdit}
                    setOpen={setOpenCompanyInformationEdit}
                />
            )}
            <ConfirmationDialogWithoutButton
                open={openDeleteAssesmentDialog}
                setOpen={setOpenDeleteAssessmentDialog}
                id={assessmentId || ''}
                onDelete={handleAssessmentDeletion}
                desc='Are you sure you want to delete this assessment?'
            />
        </>
    );
};
