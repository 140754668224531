const delimeter = ';';

export const multipleSelectOptionsToString = (options?: string[]) => {
    if (!options) return '';
    return options.join(delimeter);
};

export const stringToMultipleSelectOptions = (str?: string) => {
    if (!str) return [];
    return str.split(delimeter);
};

export const autocompleteFormikValueToOptions = (str?: string) => {
    if (!str) return [];
    return stringToMultipleSelectOptions(str).map(
        (val) => JSON.parse(val) as { value: string; label: string }
    );
};

export const optionsToAutoCompleteFormikValue = (
    options: {
        value: string;
        label: string;
    }[]
) => {
    if (!options) return '';
    const arr = options.map((option) => JSON.stringify(option));
    return multipleSelectOptionsToString(arr);
};
