import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button } from '@mui/material';
import { FC, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone';
import { CustomPagination } from 'components/shared/custom-pagination';
import { Company } from 'api/hooks/company/companyTypes';

type Props = {
    data: Company[];
    onOpenClick: (id: string) => void;
};

export const CompanyListTable: FC<Props> = ({ data, onOpenClick }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    return (
        <Box sx={{ mt: 5 }}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align='right'>
                                    Unique Identifier
                                </TableCell>
                                <TableCell align='right'>Industry</TableCell>
                                <TableCell align='right'>
                                    Sub-Industry
                                </TableCell>
                                <TableCell align='right'>
                                    AR Publication Date
                                </TableCell>
                                <TableCell align='right'>
                                    Fiscal Year End
                                </TableCell>
                                <TableCell align='right'>Client</TableCell>
                                <TableCell align='right'>Peer Group</TableCell>
                                <TableCell align='right'>
                                    Country Listed
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component='th' scope='row'>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.isin}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.industry?.name}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.sub_industries
                                                ?.map(
                                                    (subIndustry) =>
                                                        subIndustry.name
                                                )
                                                .join(', ')}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.ar_publication_date?.toString()}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.fiscal_year_end?.toString()}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.is_client && (
                                                <CheckCircleIcon color='primary' />
                                            )}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.peer_groups
                                                ?.map(
                                                    (peerGroup) =>
                                                        `${peerGroup.isin}-${peerGroup.name}`
                                                )
                                                .join(', ')}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.country_listed?.join(', ')}
                                        </TableCell>
                                        <TableCell align='right'>
                                            <Button
                                                onClick={() =>
                                                    onOpenClick(row?._id)
                                                }
                                            >
                                                <ArrowRightTwoToneIcon fontSize='large' />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <CustomPagination
                    dataLength={data?.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
        </Box>
    );
};
