import { authApi } from 'lib';
import {
    CreateUpdateMaterialityQuestion,
    MaterialityQuestionListResponse,
    MaterialityQuestionResponse,
} from './materialityQuestionsTypes';

export const getMaterialityQuestionList = async () => {
    const response = await authApi.get<MaterialityQuestionListResponse>(
        '/api/v1/materiality_questions'
    );
    return response.data;
};

export const getMaterialityQuestionById = async (id: string) => {
    const response = await authApi.get<MaterialityQuestionResponse>(
        `/api/v1/materiality_questions/${id}`
    );
    return response.data;
};

export const createMaterialityQuestion = async (
    request: CreateUpdateMaterialityQuestion
) => {
    const response = await authApi.post<MaterialityQuestionResponse>(
        `/api/v1/materiality_questions`,
        request
    );
    return response.data;
};

export const updateMaterialityQuestion = async (
    id: string,
    request: CreateUpdateMaterialityQuestion
) => {
    const response = await authApi.put<MaterialityQuestionResponse>(
        `/api/v1/materiality_questions/${id}`,
        request
    );
    return response.data;
};

export const deleteMaterialityQuestion = async (id: string) => {
    return await authApi.delete(`/api/v1/materiality_questions/${id}`);
};
