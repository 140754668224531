import { useNavigate, useParams } from 'react-router';
import { AssessmentDirection } from 'api/hooks/assessment/assessmentTypes';
import { useFlow } from 'api/hooks/assessment/useFlow';
import { FC, Fragment, MutableRefObject } from 'react';
import { AutoSaveRef } from 'store/autosave';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';

type Props = {
    open: boolean;
    setOpen: (open: boolean) => void;
    direction: AssessmentDirection | null;
    autoSaveRef: MutableRefObject<AutoSaveRef | null>;
};

export const AssessmentStepperConfirmation: FC<Props> = ({
    direction,
    autoSaveRef,
    setOpen,
    open,
}) => {
    const { assessmentId } = useParams();
    const navigate = useNavigate();

    const { mutateAsync: flow, isLoading: flowLoading } = useFlow({
        onError: () => {
            handleClose();
        },
    });

    const handleAction = async () => {
        if (autoSaveRef.current) {
            await autoSaveRef.current.save();
        }
        if (assessmentId && direction) {
            try {
                await flow({
                    id: assessmentId,
                    request: { direction },
                });

                await handleUpdate();
            } catch (error) {
                handleClose();
            }
        }
    };

    const handleUpdate = async () => {
        navigate(0);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const isLoading = flowLoading;

    return (
        <Fragment>
            {!isLoading && (
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'
                >
                    <DialogTitle id='alert-dialog-title'>
                        {'Assessment status change'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                            Are you sure?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>No</Button>
                        <Button onClick={handleAction} autoFocus>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Fragment>
    );
};
