import { Button, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import {
    Country,
    CreateCountry,
    UpdateCountry,
} from 'api/hooks/country/countryTypes';

type Props = {
    record: Country | null;
    isCreate: boolean;
    onClose: () => void;
    onSave: (data: CreateCountry | UpdateCountry) => void;
};

export const PeerGroupForm: FC<Props> = ({
    record,
    onClose,
    onSave,
    isCreate,
}) => {
    const formName = 'PeerGroupForm';

    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        code: Yup.string().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            name: record?.name ?? '',
            code: record?.code ?? '',
        },
        validationSchema,
        onSubmit: (values) => {
            if (isCreate) {
                const dataToCreate: CreateCountry = {
                    code: values.code,
                    name: values.name,
                };
                onSave(dataToCreate);
            } else {
                const dataToUpdate: UpdateCountry = {
                    code: values.code,
                    name: values.name,
                };
                onSave(dataToUpdate);
            }
        },
    });

    type FormikTypes = typeof formik.initialValues;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'name',
                            'Name',
                            formName,
                            formik
                        )}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'code',
                            'Code',
                            formName,
                            formik
                        )}
                    />
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={'flex-end'}
                >
                    <Button
                        color='primary'
                        data-testid={`${formName}-cancel`}
                        variant='contained'
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        data-testid={`${formName}-submit`}
                        role='submit'
                        type='submit'
                        variant='contained'
                    >
                        Save
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};
