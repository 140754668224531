import { Button, Container, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import { MaterialityQuestionGuide } from 'api/hooks/materiality-question/materialityQuestionsTypes';

type Props = {
    onAdd: (data: MaterialityQuestionGuide) => void;
    onCancel: () => void;
};

export const GuideForm: FC<Props> = ({ onCancel, onAdd }) => {
    const formName = GuideForm.name;
    const validationSchema = Yup.object({
        order: Yup.string().required('Required'),
        title: Yup.string().required('Required'),
        text: Yup.string().required('Required'),
    });
    const formik = useFormik({
        initialValues: {
            order: '',
            title: '',
            text: '',
        },
        validationSchema,
        onSubmit: (data) => {
            onAdd({
                order: parseInt(data.order),
                text: data.text,
                title: data.title,
            });
        },
    });
    type FormikTypes = typeof formik.initialValues;

    return (
        <Container>
            <form onSubmit={formik.handleSubmit}>
                <Stack spacing={2}>
                    <Stack direction={'row'} spacing={2}>
                        <CustomTextField
                            {...generateTextFieldProps<FormikTypes>(
                                'order',
                                'Order',
                                formName,
                                formik
                            )}
                        />
                        <CustomTextField
                            {...generateTextFieldProps<FormikTypes>(
                                'title',
                                'Title',
                                formName,
                                formik
                            )}
                        />
                        <CustomTextField
                            {...generateTextFieldProps<FormikTypes>(
                                'text',
                                'Text',
                                formName,
                                formik
                            )}
                        />
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                        <Button variant='outlined' role='submit' type='submit'>
                            Add
                        </Button>
                        <Button variant='outlined' onClick={onCancel}>
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </form>
        </Container>
    );
};
