import { FC, useState } from 'react';
import { useParams } from 'react-router';
import { useGetAssessmentStatus } from 'api/hooks/assessment/useGetAssessmentStatus';
import { AssessmentStatus } from 'api/hooks/assessment/assessmentTypes';
import { QaStatus } from 'api/hooks/commonTypes';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { useGetMe } from 'api/hooks';
import { RoleType } from 'api/hooks/user/userTypes';

type Props = {
    for_qa?: boolean;
    qa_status?: QaStatus;
    forceShow?: boolean;
    handleClick: (qaStatus: QaStatus) => void;
    label?: string;
};

export const QaStatusComponent: FC<Props> = ({
    for_qa,
    qa_status,
    handleClick,
    forceShow,
    label = 'QA STATUS',
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const { assessmentId } = useParams();

    const { data, isLoading: assessmentStatusLoading } = useGetAssessmentStatus(
        {
            assessmentId: assessmentId ?? '',
            enabled: assessmentId !== '0' && !!assessmentId,
        }
    );

    const { data: user, isLoading: userLoading } = useGetMe({});

    const isAssurer = user?.roles?.includes(RoleType.ASSURER) ?? false;

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChooseMenu = (qaStatus: QaStatus) => {
        handleClick(qaStatus);
        handleCloseMenu();
    };

    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    if (assessmentStatusLoading || userLoading) {
        return null;
    }

    if (!for_qa && !forceShow) {
        return null;
    }

    if (data.status === AssessmentStatus.ANALYSIS_IN_PROGRESS) {
        if (
            forceShow ||
            qa_status === QaStatus.INCORRECT ||
            qa_status === QaStatus.AMENDED
        ) {
            return (
                <Box>
                    <Button
                        id='basic-button'
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleOpenMenu}
                    >
                        {label}
                    </Button>
                    <Menu
                        id='basic-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleCloseMenu}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem
                            onClick={() => handleChooseMenu(QaStatus.AMENDED)}
                        >
                            Amend
                        </MenuItem>
                        <MenuItem
                            onClick={() => handleChooseMenu(QaStatus.INCORRECT)}
                        >
                            Undo Amend
                        </MenuItem>
                    </Menu>
                </Box>
            );
        }
    }

    if (data.status === AssessmentStatus.ASSURANCE_IN_PROGRESS && isAssurer) {
        return (
            <Box>
                <Button
                    id='basic-button'
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup='true'
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleOpenMenu}
                >
                    {label}
                </Button>
                <Menu
                    id='basic-menu'
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem
                        onClick={() =>
                            handleChooseMenu(QaStatus.WARNING_RESOLVED)
                        }
                    >
                        Warning Resolved
                    </MenuItem>
                    <MenuItem
                        onClick={() =>
                            handleChooseMenu(QaStatus.ERROR_RESOLVED)
                        }
                    >
                        Error Resolved
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleChooseMenu(QaStatus.INCORRECT)}
                    >
                        Incorrect
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleChooseMenu(QaStatus.CORRECT)}
                    >
                        Correct
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleChooseMenu(QaStatus.AMENDED)}
                    >
                        Amended
                    </MenuItem>
                    <MenuItem
                        onClick={() => handleChooseMenu(QaStatus.PENDING)}
                    >
                        Pending
                    </MenuItem>
                </Menu>
            </Box>
        );
    }

    return null;
};
