import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { FC, Fragment, useState } from 'react';
import { VariationsTableHeader } from './variations-table-header';
import { VariationsTableBodyRow } from './variations-table-body-row';
import { VariationGuideListTableRow } from './variations-guide-list-table-row';
import {
    CreateUpdateMainQuestionVariation,
    MainQuestionVariationGuide,
} from 'api/hooks/main-question/mainQuestionsTypes';

type RowProps = {
    onDelete: (index: number) => void;
    onEdit: (index: number) => void;
    onAddGuide: (
        variationIndex: number,
        variationGuide: MainQuestionVariationGuide
    ) => boolean;
    onDeleteGuide: (variationIndex: number, orderNumber: number) => void;
    row: CreateUpdateMainQuestionVariation;
    index: number;
};

const VariationListTableRow: FC<RowProps> = ({
    onDelete,
    onEdit,
    onAddGuide,
    onDeleteGuide,
    row,
    index,
}) => {
    const [open, setOpen] = useState(false);

    const handleDelete = () => {
        onDelete(index);
    };

    const handleEdit = () => {
        onEdit(index);
    };

    return (
        <Fragment key={`${row.question_number}`}>
            <VariationsTableBodyRow
                onDelete={handleDelete}
                onEdit={handleEdit}
                open={open}
                row={row}
                setOpen={setOpen}
            />
            <VariationGuideListTableRow
                open={open}
                row={row}
                onAddGuide={(variationGuide) =>
                    onAddGuide(index, variationGuide)
                }
                onDeleteGuide={(orderNumber) =>
                    onDeleteGuide(index, orderNumber)
                }
            />
        </Fragment>
    );
};

type Props = {
    onDelete: (index: number) => void;
    onEdit: (index: number) => void;
    onAddGuide: (
        variationIndex: number,
        variationGuide: MainQuestionVariationGuide
    ) => boolean;
    onDeleteGuide: (variationIndex: number, orderNumber: number) => void;
    variations: CreateUpdateMainQuestionVariation[];
};

export const VariationListTable: FC<Props> = ({
    onDelete,
    variations,
    onEdit,
    onAddGuide,
    onDeleteGuide,
}) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <VariationsTableHeader />
                <TableBody>
                    {variations?.map((row, index) => (
                        <VariationListTableRow
                            key={`variation-list-table-${index}`}
                            onAddGuide={onAddGuide}
                            onDelete={onDelete}
                            onDeleteGuide={onDeleteGuide}
                            onEdit={onEdit}
                            row={row}
                            index={index}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
