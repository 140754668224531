import { QaStatus } from '../commonTypes';

export type MainQuestionAssessmentFilters = {
    answer?: MainQuestionAssessmentAnswer;
    question?: string;
    unique_id?: string[];
    identifier?: string[];
    category?: string[];
    sub_category_first?: string[];
    sub_category_second?: string[];
    scoring_guidance?: string;
    order_by?: string;
    qa_status?: QaStatus[];
    has_comments?: string;
    scoring_judgment?: string;
    reference_for_answer?: string;
};

export type MainQuestionAssessmentGuide = {
    additionalProp1: string;
    additionalProp2: string;
    additionalProp3: string;
};

export type MainQuestionAssessmentScoreByAnswer = {
    answer?: string;
    score?: number;
};

export type MainQuestionAssessmentAssurerCommentUser = {
    _id: string;
    name?: string;
    last_name?: string;
};

export type MainQuestionAssessmentAssurerComment = {
    date_time?: string;
    user?: MainQuestionAssessmentAssurerCommentUser;
    comment?: string;
};

export enum MainQuestionAssessmentAnswer {
    Yes = 'Yes',
    Partial = 'Partial',
    ND = 'ND',
    No = 'No',
    'n/a' = 'n/a',
}

export type MainQuestionAssessment = {
    answer?: MainQuestionAssessmentAnswer;
    reference_for_answer?: string;
    suggested_answer?: string;
    score?: string;
    formula?: string;
    weighted_score?: number;
    weighted_max_score_possible?: number;
    assurer_comment?: MainQuestionAssessmentAssurerComment[];
    imported?: boolean;
    date_imported?: string;
    _id: string;
    question?: string;
    max_score_possible?: number;
    scores_by_answer?: MainQuestionAssessmentScoreByAnswer[];
    question_number?: number;
    industry?: string;
    sub_industry?: string;
    unique_id?: string;
    identifier?: string;
    category?: string;
    category_code?: string;
    sub_category_first?: string;
    sub_category_first_code: string;
    sub_category_second?: string;
    scoring_guidance: string;
    guide?: MainQuestionAssessmentGuide;
    assessment?: string;
    scoring_judgment?: string;
    initial_weighted_max_score_possible: number;
    for_qa?: boolean;
    qa_status?: QaStatus;
    has_comments: boolean;
};

export type CreateMainQuestionAssessment = {
    formula?: string;
    answer?: string;
    reference_for_answer?: string;
    scoring_judgment?: string;
    score?: number;
    qa_status?: QaStatus;
};

export type MainQuestionAssessmentListResponse = MainQuestionAssessment[];
