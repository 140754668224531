import {
    Box,
    Button,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LoopIcon from '@mui/icons-material/Loop';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { Report, ReportStatus } from 'api/hooks/report/reportTypes';

type Props = {
    data: Report;
    regenerateReport: (report: Report) => void;
};

export const CustomTableRow: FC<Props> = ({ data, regenerateReport }) => {
    const [open, setOpen] = useState(false);

    const date = new Date(data.start_datetime);

    const getIconByStatus = () => {
        switch (data.status) {
            case ReportStatus.REPORT_IN_PROGRESS: {
                return (
                    <Tooltip title='In Progress'>
                        <LoopIcon color='warning' />
                    </Tooltip>
                );
            }
            case ReportStatus.REPORT_COMPLETED: {
                return (
                    <Tooltip title='Completed'>
                        <CheckCircleIcon color='success' />
                    </Tooltip>
                );
            }
            case ReportStatus.REPORT_FAILED: {
                return (
                    <Tooltip title='Failed'>
                        <ErrorIcon color='error' />
                    </Tooltip>
                );
            }
            case ReportStatus.REPORT_NO_MATCH: {
                return (
                    <Tooltip title='No Match'>
                        <CheckBoxOutlineBlankIcon color='warning' />
                    </Tooltip>
                );
            }
            default: {
                return 'Unknown Status';
            }
        }
    };

    const countUniqueAssessments = () => {
        const uniqueAssessments = new Set<string>();
        data.assessments.forEach((assessment) =>
            uniqueAssessments.add(assessment.isin)
        );
        return uniqueAssessments.size;
    };

    return (
        <>
            <TableRow
                key={data._id}
                sx={{
                    '&:last-child td, &:last-child th': {
                        border: 0,
                    },
                }}
            >
                <TableCell>
                    {data.assessments && (
                        <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell>{date.toLocaleString()}</TableCell>
                <TableCell align='right'>{countUniqueAssessments()}</TableCell>
                <TableCell align='right'>{getIconByStatus()}</TableCell>

                <TableCell align='right'>
                    {data.results?.url ? (
                        <a
                            href={data.results.url}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <IconButton onClick={() => {}}>
                                <DownloadForOfflineIcon
                                    fontSize='large'
                                    color='primary'
                                />
                            </IconButton>
                        </a>
                    ) : (
                        <Button onClick={() => regenerateReport(data)}>
                            Re-Generate
                        </Button>
                    )}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    {data.assessments && (
                        <Collapse in={open} timeout='auto' unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography
                                    variant='h6'
                                    gutterBottom
                                    component='div'
                                >
                                    Assessments
                                </Typography>
                                <Table size='small' aria-label='purchases'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ISIN</TableCell>
                                            <TableCell>
                                                Fiscal Year End
                                            </TableCell>
                                            <TableCell>Name</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.assessments?.map((assessment) => (
                                            <TableRow
                                                key={`${data._id}-${assessment._id}`}
                                            >
                                                <TableCell
                                                    component='th'
                                                    scope='row'
                                                >
                                                    {assessment.isin}
                                                </TableCell>
                                                <TableCell>
                                                    {assessment.fiscal_year_end}
                                                </TableCell>
                                                <TableCell>
                                                    {assessment.name}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    )}
                </TableCell>
            </TableRow>
        </>
    );
};
