import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { EnumToDropdownOptions } from 'utils';
import { AssuranceType } from 'api/hooks/assessment/assessmentTypes';
import { useChangeQaType } from 'api/hooks/assessment/useChangeQaType';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router';
import { useGetAssessment } from 'api/hooks';

type Props = {
    open: boolean;
    setOpen: (isOpen: boolean) => void;
};

const QaTypeEdit: FC<Props> = ({ open, setOpen }) => {
    const { assessmentId } = useParams();
    const [newValue, setNewValue] = useState<null | AssuranceType>(null);
    const queryClient = useQueryClient();

    const { isLoading, data } = useGetAssessment({
        assessmentId: assessmentId ?? '',
        enabled: !!assessmentId && assessmentId !== '0',
    });

    useEffect(() => {
        setNewValue(data?.assurance_type ?? null);
    }, [data?.assurance_type]);

    const { mutateAsync } = useChangeQaType({
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['assessment', assessmentId],
            });
        },
    });

    const handleSave = () => {
        if (newValue && assessmentId) {
            mutateAsync({
                id: assessmentId,
                qaType: newValue,
            });
            setOpen(false);
        }
    };

    const handleInputChange = (value: string) => {
        setNewValue(value as AssuranceType);
    };

    const qaTypeOptions = EnumToDropdownOptions(AssuranceType);

    return (
        <>
            {!isLoading && (
                <>
                    <Dialog
                        open={open}
                        onClose={() => setOpen(false)}
                        fullWidth
                        maxWidth='sm'
                    >
                        <DialogTitle>Qa Type Edit</DialogTitle>
                        <DialogContent>
                            <Box display='flex'>
                                <Select
                                    fullWidth
                                    id='qaStatus'
                                    // label='Qa Status'
                                    name='Qa Status'
                                    value={newValue || ''}
                                    onChange={(e) =>
                                        handleInputChange(e.target.value)
                                    }
                                >
                                    {qaTypeOptions &&
                                        qaTypeOptions.map((option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label || option.value}
                                            </MenuItem>
                                        ))}
                                </Select>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                            </Box>
                        </DialogContent>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default QaTypeEdit;
