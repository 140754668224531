import { useMutation } from 'react-query';
import { updateCompanyPerformanceAssessment } from './companyPerformanceAssessmentService';
import { CreateCompanyPerformanceAssessment } from './companyPerformanceAssessmentTypes';
import { modifyError } from 'utils/error-modifer-utils';
import { toast } from 'react-toastify';

type Props = {
    onError?: (error: Error) => void;
    onSuccess?: () => void;
};

export const useUpdateCompanyPerformanceAssessment = ({
    onSuccess = () => {},
    onError = () => {},
}: Props) => {
    const onErrorModified = (error: any) => {
        const errors = modifyError(error?.response?.data);
        if (errors) {
            for (const err of errors) {
                toast.error(err);
            }
        } else {
            toast.error('Internal error, please contact administrator');
        }

        if (onError) {
            onError(error);
        }
    };
    const mutation = useMutation(
        async (input: {
            id: string;
            request: CreateCompanyPerformanceAssessment;
        }) => await updateCompanyPerformanceAssessment(input.id, input.request),
        {
            onSuccess,
            onError: onErrorModified,
        }
    );

    return {
        mutate: mutation.mutate,
        mutateAsync: mutation.mutateAsync,
        isLoading: mutation.isLoading,
        error: mutation.error,
    };
};
