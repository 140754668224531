import { Container, Typography } from '@mui/material';
import { FC } from 'react';

type Props = {
    title: string;
    subTitle?: string;
};

export const PageTitle: FC<Props> = ({ title, subTitle }) => {
    return (
        <Container>
            <Typography
                variant='h4'
                component='div'
                textAlign={'center'}
                marginBottom={1}
            >
                {title}
            </Typography>
            <Typography
                variant='h6'
                component='div'
                textAlign={'center'}
                marginBottom={1}
            >
                {subTitle}
            </Typography>
        </Container>
    );
};
