import { useGetMaterialityAssessmentList } from 'api/hooks/materiality-assessment/useGetMaterialityAssessmentList';
import { useUpdateMaterialityAssessment } from 'api/hooks/materiality-assessment/useUpdateMaterialityAssessment';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { DataState } from 'store/enums';
import {
    selectMaterialityAssessment,
    setInitialMateriality,
    updateMateriality,
} from 'store/slices/materialityAssessmentSlice';
import { useAppDispatch, useAppSelector } from 'store/store';

export const useMaterialityAssessmentSave = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { assessmentId } = useParams();
    const dispatch = useAppDispatch();

    const data = useAppSelector(selectMaterialityAssessment);

    const { query: queryMaterialityAssessment } =
        useGetMaterialityAssessmentList({
            assessmentId: assessmentId ?? '',
            enabled: false,
        });

    const { mutateAsync } = useUpdateMaterialityAssessment({});

    const save = async (): Promise<boolean> => {
        const updateData = data.table.filter(
            (element) => element.state === DataState.UPDATED
        );
        if (updateData.length) {
            setIsLoading(true);
            let success = true;
            for (const element of updateData) {
                try {
                    await mutateAsync({
                        id: element.data._id,
                        request: element.data,
                    });
                    dispatch(
                        updateMateriality({
                            data: element.data,
                            state: DataState.INITIAL,
                        })
                    );
                } catch (error) {
                    dispatch(
                        updateMateriality({
                            data: element.data,
                            state: DataState.ERROR,
                        })
                    );
                    success = false;
                }
            }

            if (success) {
                toast.success('Materiality Saved!');
                const materialityData =
                    await queryMaterialityAssessment.refetch();
                dispatch(setInitialMateriality(materialityData.data ?? []));
            } else {
                toast.error('Materiality Save Failed!');
            }

            return success;
        }

        return true;
    };

    return { save, isLoading };
};
