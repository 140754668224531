import { ErrorResponse } from '../commonTypes';

export enum UserType {
    CLIENT = 'CLIENT',
    EMPLOYEE = 'EMPLOYEE',
}

export enum RoleType {
    ADMIN = 'ADMIN',
    PLANNER = 'PLANNER',
    ASSURER = 'ASSURER',
    ANALYST = 'ANALYST',
    EXPORTER = 'EXPORTER',
    REPORTER = 'REPORTER',
    IMPORTER = 'IMPORTER',
}

export type UserFilters = {
    roles: RoleType[];
};

export type UserCompany = {
    _id: string;
    isin: string;
    name: string;
};

export type UserPeerGroup = {
    _id: string;
    name?: string;
    isin?: string;
};

export type User = {
    _id: string;
    name?: string;
    last_name?: string;
    personal_id?: string;
    phone?: string;
    email?: string;
    type: UserType;
    roles?: RoleType[];
    is_active: boolean;
    is_superuser: boolean;
    companies?: UserCompany[];
    peer_group?: UserPeerGroup;
    initial_password_changed: boolean;
};

export type CreateUser = {
    name: string;
    last_name: string;
    personal_id: string;
    phone: string;
    type: string;
    email: string;
    companies?: UserCompany[];
    peer_group?: UserPeerGroup;
    is_active: boolean;
    is_superuser: boolean;
    roles?: string[] | null;
};

export type UpdateUser = {
    name: string;
    last_name: string;
    personal_id: string;
    phone: string;
    companies?: UserCompany[];
    peer_group?: UserPeerGroup;
    is_active: boolean;
    is_superuser: boolean;
    roles?: string[] | null;
};

export type UsersForSelectsResponse = {
    _id: string;
    name?: string;
    last_name?: string;
}[];

export type UsersResponse = User[];

export type MeResponse = User;

export type RecoverPasswordResponse = {
    status: string;
};

export type LoginRequest = {
    username: string;
    password: string;
    grant_type?: string;
    scope?: string;
    client_id?: string;
    client_secret?: string;
};

export type LoginResponse = {
    access_token: string;
    refresh_token: string;
} & ErrorResponse;

export type ResetPasswordRequest = {
    token: string;
    new_password: string;
};

export type ResetPasswordResponse = {
    msg: string;
};

export type ChangePasswordRequest = {
    old_password: string;
    new_password_1: string;
    new_password_2: string;
};

export type ChangePasswordResponse = {
    detail: any;
};

export type UserErrorRate = {
    calculation_date: Date;
    main_questionnaire_error_rate: number;
    company_performance_error_rate: number;
    normalized_error_rate: number;
    number_of_questions: number;
    number_of_yes: number;
    isin: string;
    fiscal_year_end: Date;
    name: string;
    last_name: string;
    company_name: string;
    qa_type: string;
};

export type TUserErrorRatesFilters = {
    companyName: string;
    companyIsin: string;
    analystName: string;
    analystLastName: string;
};
