import { Button, Container, SelectChangeEvent, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import { EnumToDropdownOptions, generateRoutePath } from 'utils';
import { WithSpinner } from 'components/with-spinner';
import { useNavigate, useParams } from 'react-router';
import { RoutePaths } from 'routes';
import { AdminRoutePaths } from 'constants/enums/routes-constants';
import { PageTitle } from 'components/shared/page-title';
import { useGetSDGQuestion } from 'api/hooks/sgd-question/useGetSDGQuestion';
import { useCreateSDGQuestion } from 'api/hooks/sgd-question/useCreateSDGQuestion';
import { useUpdateSDGQuestion } from 'api/hooks/sgd-question/useUpdateSDGQuestion';
import {
    CreateUpdateSDGQuestion,
    SDGQuestionAnswerValidation,
    SDGQuestionGuide,
} from 'api/hooks/sgd-question/sdgQuestionsTypes';
import { GuideForm } from './guide/guide-form';
import { GuideListTable } from './guide/guide-list-table';
import {
    CustomSelectField,
    generateSelectFieldProps,
} from 'components/shared/form/custom-select-field';
import { getKeyByValue } from 'utils/enumHelper';

type Props = {};

export const AdminSDGQuestionForm: FC<Props> = () => {
    const navigate = useNavigate();
    const { sdgQuestionId } = useParams();
    const isNew = sdgQuestionId === '0';

    // const [scoresByAnswer, setScoresByAnswer] =
    //     useState<SDGQuestionScoreByAnswer[]>();
    const [guides, setGuides] = useState<SDGQuestionGuide[]>();
    const [showGuideForm, setShowGuideForm] = useState(false);

    const { data: sdgQuestion, isLoading: sdgQuestionLoading } =
        useGetSDGQuestion({
            id: sdgQuestionId ?? '',
            enabled: !isNew,
            onError: () => {
                navigateToSDGQuestionList();
            },
            onSuccess: (data) => {
                const answerValidation = getKeyByValue(
                    SDGQuestionAnswerValidation,
                    data?.answer_validation ?? ''
                );
                formik.setValues({
                    question_number: data?.question_number?.toString() ?? '',
                    question: data?.question,
                    max_score_possible:
                        data?.max_score_possible?.toString() ?? '',
                    answer_validation: answerValidation ?? '',
                });
                // setScoresByAnswer(data.scores_by_answer);
                setGuides(data.guides);
            },
        });

    const { mutate: createSDGQuestion } = useCreateSDGQuestion({
        onSuccess: () => navigateToSDGQuestionList(),
    });

    const { mutate: updateSDGQuestion } = useUpdateSDGQuestion({
        onSuccess: () => navigateToSDGQuestionList(),
    });

    const formName = AdminSDGQuestionForm.name;
    const validationSchema = Yup.object({
        question_number: Yup.string().required('Required'),
        question: Yup.string().required('Required'),
        max_score_possible: Yup.string().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            question_number: '',
            question: '',
            max_score_possible: '',
            answer_validation: '',
        },
        validationSchema,
        onSubmit: (data) => {
            const answerValidation =
                data.answer_validation as keyof typeof SDGQuestionAnswerValidation;

            const request: CreateUpdateSDGQuestion = {
                question: data.question,
                max_score_possible: parseFloat(data.max_score_possible),
                question_number: parseInt(data.question_number),
                answer_validation:
                    SDGQuestionAnswerValidation[answerValidation],
                guides: guides,
            };
            createUpdateSDGQuestion(request);
        },
    });
    type FormikTypes = typeof formik.initialValues;

    const navigateToSDGQuestionList = () => {
        navigate(
            generateRoutePath([
                RoutePaths.ADMIN,
                AdminRoutePaths.ADMIN_SDG_QUESTIONS,
            ])
        );
    };

    const handleDeleteGuide = (index: number) => {
        const newGuides = guides?.filter((value, i) => i !== index);

        setGuides(newGuides);
    };

    const handleAddGuide = (data: SDGQuestionGuide) => {
        const oldGuides = guides ?? [];
        const newGuides = [...oldGuides, data];

        setGuides(newGuides);
        setShowGuideForm(false);
    };

    const handleCancel = () => {
        navigateToSDGQuestionList();
    };

    const handleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
    };

    const createUpdateSDGQuestion = (request: CreateUpdateSDGQuestion) => {
        if (isNew) {
            createSDGQuestion(request);
        } else {
            updateSDGQuestion({
                id: sdgQuestion._id,
                request,
            });
        }
    };

    const answerValidationOptions = EnumToDropdownOptions(
        SDGQuestionAnswerValidation
    );

    const isLoading = sdgQuestionLoading;

    return (
        <WithSpinner isLoading={isLoading}>
            <Container>
                <PageTitle
                    title={
                        isNew
                            ? 'Create New SDG'
                            : `Update SDG ${sdgQuestion?.question_number}`
                    }
                />
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'question_number',
                                    'Question Number',
                                    formName,
                                    formik
                                )}
                            />
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'max_score_possible',
                                    'Max Score Possible',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'question',
                                    'Question',
                                    formName,
                                    formik
                                )}
                            />
                            <CustomSelectField
                                options={answerValidationOptions}
                                onChange={handleSelect}
                                {...generateSelectFieldProps<FormikTypes>(
                                    'answer_validation',
                                    'Answer Validation',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            {!showGuideForm ? (
                                <Button
                                    color='primary'
                                    role='submit'
                                    type='submit'
                                    variant='contained'
                                    onClick={() => setShowGuideForm(true)}
                                >
                                    Add New Guide
                                </Button>
                            ) : (
                                <GuideForm
                                    onAdd={handleAddGuide}
                                    onCancel={() => setShowGuideForm(false)}
                                />
                            )}
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <GuideListTable
                                data={guides}
                                onDelete={handleDeleteGuide}
                            />
                        </Stack>

                        <Stack
                            direction={'row'}
                            spacing={2}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                color='primary'
                                data-testid={`${formName}-cancel`}
                                variant='contained'
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                color='primary'
                                data-testid={`${formName}-submit`}
                                role='submit'
                                type='submit'
                                variant='contained'
                            >
                                Save
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Container>
        </WithSpinner>
    );
};
