import { useQuery } from 'react-query';
import { AssessmentResponse } from './assessmentTypes';
import { getAssessmentById } from './assessmentService';

type Props<M> = {
    assessmentId: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: AssessmentResponse) => void;
    mapper?: (data?: AssessmentResponse) => M | AssessmentResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: AssessmentResponse) => data;

export const useGetAssessment = <M = AssessmentResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    assessmentId,
}: Props<M>) => {
    const query = useQuery<AssessmentResponse, Error>(
        ['assessment', assessmentId],
        () => getAssessmentById(assessmentId),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
