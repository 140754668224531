import { Container, Paper } from '@mui/material';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useGetCompany } from 'api/hooks';
import { RoutePaths } from 'routes';
import { CompanyDetailsForm } from 'components/company-details-form';
import { useGetIndustries } from 'api/hooks/industry/useGetIndustries';
import { useGetCountryList } from 'api/hooks/country/useGetCountry';
import { PageTitle } from 'components/shared/page-title';
import { WithSpinner } from 'components/with-spinner';
import { useGetPeerGroupList } from 'api/hooks/peer-group/useGetPeerGroupList';
import { useCreateCompany } from 'api/hooks/company/useCreateCompany';
import { useUpdateCompany } from 'api/hooks/company/useUpdateCompany';
import { CreateUpdateCompany } from 'api/hooks/company/companyTypes';

export const CompanyDetails: FC = () => {
    const { companyId } = useParams();
    const navigate = useNavigate();

    const { data: company, isLoading: companyLoading } = useGetCompany({
        enabled: !!companyId && companyId !== '0',
        companyId: companyId ?? '',
    });

    const { data: industries, isLoading: industriesLoading } = useGetIndustries(
        {}
    );

    const { data: countries, isLoading: countriesLoading } = useGetCountryList(
        {}
    );

    const { data: peerGroups, isLoading: peerGroupsLoading } =
        useGetPeerGroupList({});

    const { mutate: createCompany } = useCreateCompany({
        onSuccess: () => {
            navigate(RoutePaths.COMPANY_LIST);
        },
    });
    const { mutate: updateCompany } = useUpdateCompany({
        onSuccess: () => {
            navigate(RoutePaths.COMPANY_LIST);
        },
    });

    const isLoading =
        companyLoading ||
        industriesLoading ||
        countriesLoading ||
        peerGroupsLoading;

    const handleCancelClick = () => {
        navigate(RoutePaths.COMPANY_LIST);
    };

    const handleSaveClick = (
        createUpdateCompanyRequest: CreateUpdateCompany,
        id?: string
    ) => {
        if (id) {
            updateCompany({ id: id, request: createUpdateCompanyRequest });
        } else {
            createCompany(createUpdateCompanyRequest);
        }
    };

    return (
        <WithSpinner isLoading={isLoading}>
            <Container sx={{ paddingY: 4 }} component={Paper}>
                <PageTitle title='Company Details' />
                <CompanyDetailsForm
                    company={company}
                    industries={industries}
                    countries={countries}
                    peerGroups={peerGroups}
                    onCancelClick={handleCancelClick}
                    onSaveClick={handleSaveClick}
                />
            </Container>
        </WithSpinner>
    );
};
