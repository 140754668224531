import { Container } from '@mui/material';
import { useGetCompanyInformation } from 'api/hooks/assessment/useGetCompanyInformation';
import { WithSpinner } from 'components/with-spinner';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { RoutePaths } from 'routes';
import { AssessmentCompanyInformationForm } from './assessment-company-information-form';
import { useGetCountryList } from 'api/hooks/country/useGetCountry';
import { useGetIndustries } from 'api/hooks/industry/useGetIndustries';
import {
    AssessmentDirection,
    AssessmentStatus,
    CreateUpdateCompanyInformation,
} from 'api/hooks/assessment/assessmentTypes';
import { useUpdateCompanyInformation } from 'api/hooks/assessment/useUpdateCompanyInformation';
import { useFlow } from 'api/hooks/assessment/useFlow';
import { useCheckAssessmentStatus } from 'api/hooks/common/useCheckAssessmentStatus';
import { useGetCompaniesForSelects } from 'api/hooks/company/useGetCompaniesForSelects';

export const AssessmentCompanyInformation: FC = () => {
    const navigate = useNavigate();

    const { assessmentId } = useParams();

    const assessmentStatus = useCheckAssessmentStatus({
        assessmentId: assessmentId ?? '',
        currentStatuses: [AssessmentStatus.READY_FOR_ANALYSIS],
    });

    const { mutateAsync: update } = useUpdateCompanyInformation({});
    const { mutateAsync: flow } = useFlow({});

    const { data: companyInformation, isLoading: companyInformationLoading } =
        useGetCompanyInformation({
            assessmentId: assessmentId ?? '',
            enabled: !!assessmentId && assessmentId !== '0',
        });

    const { data: companies, isLoading: companiesLoading } =
        useGetCompaniesForSelects({});

    const { data: countries, isLoading: countriesLoading } = useGetCountryList(
        {}
    );

    const { data: industries, isLoading: industriesLoading } = useGetIndustries(
        {}
    );

    const handleCancel = () => {
        navigate(RoutePaths.ASSESSMENT_LIST);
    };

    const handleSave = async (
        request: CreateUpdateCompanyInformation,
        needToContinueFlow: boolean
    ) => {
        if (assessmentId) {
            await update({ id: assessmentId, request: request });
            if (needToContinueFlow) {
                await flow({
                    id: assessmentId,
                    request: { direction: AssessmentDirection.PROCESS },
                });
            }

            navigate(0);
            // navigate(
            //     generateRoutePath([
            //         RoutePaths.ASSESSMENT_LIST,
            //         `/${assessmentId}`,
            //     ])
            // );
        }
    };

    const handleGoBack = async () => {
        if (assessmentId) {
            await flow({
                id: assessmentId,
                request: { direction: AssessmentDirection.ROLLBACK },
            });
            navigate(RoutePaths.ASSESSMENT_LIST);
        }
    };

    const isLoading =
        companyInformationLoading ||
        companiesLoading ||
        countriesLoading ||
        industriesLoading;

    return (
        <WithSpinner isLoading={isLoading}>
            <Container>
                <AssessmentCompanyInformationForm
                    companyInformation={companyInformation}
                    companiesData={companies}
                    countries={countries}
                    industriesData={industries}
                    onCancel={handleCancel}
                    onSave={handleSave}
                    isReadOnly={assessmentStatus.isReadOnly}
                    onGoBack={handleGoBack}
                />
            </Container>
        </WithSpinner>
    );
};
