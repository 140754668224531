export type AssessmentComment = {
    comment: string;
    user: { _id: number; name: string; last_name: string };
    created_at?: string;
};

export enum AssessmentCommentTarget {
    MAIN_QUESTIONNAIRE = 'MAIN_QUESTIONNAIRE',
    COMPANY_PERFORMANCE = 'COMPANY_PERFORMANCE',
    MATERIALITY = 'MATERIALITY',
    CONTROVERSIES = 'CONTROVERSIES',
    SDG = 'SDG',
}

export type CreateAssessmentComment = {
    comment: string;
    target: AssessmentCommentTarget;
};

export type AssessmentCommentResponse = AssessmentComment[];
