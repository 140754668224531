import { TableCell, TableHead, TableRow } from '@mui/material';

export const VariationsTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell>Industry</TableCell>
                <TableCell align='right'>Sub-Industry</TableCell>
                <TableCell align='right'>Question Order Number</TableCell>
                <TableCell align='right'>Scoring Guide</TableCell>
                <TableCell align='right'>General Guides</TableCell>
                <TableCell align='right'>Max Score Possible</TableCell>
                <TableCell align='right' />
                <TableCell align='right' />
            </TableRow>
        </TableHead>
    );
};
