import { Container } from '@mui/material';
import { FC, useEffect } from 'react';
import { PageTitle } from 'components/shared/page-title';
import { WithSpinner } from 'components/with-spinner';
import { useGetUserErrorRates } from 'api/hooks/user/useGetUserErrorRates';
import UserErrorRateListTable from './user-error-rate-list-table';
import UserErrorRatesFilters from './user-error-rate-filters';
import { useFormik } from 'formik';
import { TUserErrorRatesFilters } from 'api/hooks/user/userTypes';

export const UserErrorRateList: FC = () => {
    const { handleChange, values, handleSubmit, resetForm } =
        useFormik<TUserErrorRatesFilters>({
            initialValues: {
                companyIsin: '',
                companyName: '',
                analystName: '',
                analystLastName: '',
            },
            onSubmit: () => {
                refetch();
            },
        });

    const {
        data: userErrorRateList,
        isLoading,
        refetch,
    } = useGetUserErrorRates({ values });

    useEffect(() => {
        refetch();
    }, [refetch]);

    return (
        <Container sx={{ display: 'flex', flexDirection: 'column' }}>
            <PageTitle title='User Error Rates' />
            <UserErrorRatesFilters
                values={values}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                resetForm={resetForm}
            />
            <WithSpinner isLoading={isLoading}>
                <UserErrorRateListTable data={userErrorRateList} />
            </WithSpinner>
        </Container>
    );
};
