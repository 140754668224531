export type MaterialityQuestionGuide = {
    title: string;
    order: number;
    text: string;
};

export type MaterialityQuestionScoreByAnswer = {
    answer: string;
    score?: number;
};

export enum MaterialityQuestionAnswerValidation {
    YES_OR_ND = 'Yes or ND',
    YES_OR_ND_OR_NA = 'Yes or ND or n/a',
}

export type MaterialityQuestion = {
    _id: string;
    question: string;
    max_score_possible?: number;
    scores_by_answer?: MaterialityQuestionScoreByAnswer[];
    question_number?: number;
    answer_validation?: MaterialityQuestionAnswerValidation;
    scoring_guidance: string;
    guides?: MaterialityQuestionGuide[];
};

export type CreateUpdateMaterialityQuestion = {
    question: string;
    max_score_possible?: number;
    scores_by_answer?: MaterialityQuestionScoreByAnswer[];
    question_number?: number;
    answer_validation?: MaterialityQuestionAnswerValidation;
    scoring_guidance: string;
    guides?: MaterialityQuestionGuide[];
};

export type MaterialityQuestionListResponse = MaterialityQuestion[];

export type MaterialityQuestionResponse = MaterialityQuestion;
