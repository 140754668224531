import { Button, TableBody, TableCell, TableRow } from '@mui/material';
import { MaterialityQuestion } from 'api/hooks/materiality-question/materialityQuestionsTypes';
import { ConfirmationDialog } from 'components/confirmation-dialog';

import { FC } from 'react';

type Props = {
    data: MaterialityQuestion[];
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
};

export const CustomTableBody: FC<Props> = ({ data, onEdit, onDelete }) => {
    return (
        <>
            <TableBody>
                {data.map((row) => {
                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell>{row.question_number}</TableCell>
                            <TableCell align='right'>{row.question}</TableCell>
                            <TableCell align='right'>
                                {row.max_score_possible}
                            </TableCell>
                            <TableCell align='right'>
                                <Button onClick={() => onEdit(row._id)}>
                                    Edit
                                </Button>
                                <ConfirmationDialog
                                    id={row._id}
                                    onDelete={onDelete}
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </>
    );
};
