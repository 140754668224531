import { ErrorResponse } from '../commonTypes';

export enum AssessmentStatus {
    DRAFT = 'DRAFT',
    READY_FOR_ANALYSIS = 'READY_FOR_ANALYSIS',
    ANALYSIS_IN_PROGRESS = 'ANALYSIS_IN_PROGRESS',
    READY_FOR_ASSURANCE = 'READY_FOR_ASSURANCE',
    ASSURANCE_IN_PROGRESS = 'ASSURANCE_IN_PROGRESS',
    READY_FOR_AMENDMENT = 'READY_FOR_AMENDMENT',
    // READY_FOR_FINAL_REVIEW = 'READY_FOR_FINAL_REVIEW',
    // FINAL_REVIEW_IN_PROGRESS = 'FINAL_REVIEW_IN_PROGRESS',
    // READY_FOR_REASSURANCE = 'READY_FOR_REASSURANCE',
    COMPLETED = 'COMPLETED',
}

export enum AssessmentDirection {
    PROCESS = 'PROCESS',
    AMEND = 'AMEND',
    ROLLBACK = 'ROLLBACK',
}

export enum AssuranceType {
    LBL = 'LBL',
    SAMPLE = 'SAMPLE',
    'PROPORTIONAL LBL' = 'PROPORTIONAL LBL',
}

export type AssessmentFilters = {
    company_is_client?: boolean | null;
    company_isin?: string[];
    company_name?: string[];
    analyst_id?: string[];
    assurer_id?: string[];
    order_by?: string;
    fiscal_year?: number[];
    assessment_deadline_from?: Date;
    assessment_deadline_to?: Date;
    status?: AssessmentStatus[];
    assurance_deadline_from?: Date;
    assurance_deadline_to?: Date;
};

export type AssessmentCompanyIndustry = {
    _id: string;
    name?: string;
    code?: string;
};

export type AssessmentCompanySubIndustry = {
    name?: string;
    code?: string;
};

export type AssessmentCompanyPeerGroup = {
    _id: string;
    name?: string;
    isin?: string;
};

export type AssessmentCompany = {
    _id: string;
    isin?: string;
    name?: string;
    country_listed?: string[];
    industry?: AssessmentCompanyIndustry;
    sub_industries?: AssessmentCompanySubIndustry[];
    website?: string;
    peer_group?: AssessmentCompanyPeerGroup;
    fiscal_year_end?: string;
    ar_publication_date?: string;
    is_client?: boolean;
};

export type AssessmentUser = {
    _id: string;
    name?: string;
    last_name?: string;
};

export type PdfReport = {
    file?: string;
    description?: string;
    expires_in?: number;
    url?: string;
};

export type Assessment = {
    _id: string;
    company?: AssessmentCompany;
    fiscal_year?: number;
    analyst?: AssessmentUser;
    assessment_tool_version?: string;
    assessment_deadline?: string;
    assessment_date_completed?: string;
    status?: AssessmentStatus;
    assurer?: AssessmentUser;
    assurance_type?: AssuranceType;
    assurance_deadline?: string;
    assurance_date_completed?: string;
    pdf_report?: PdfReport;
    scores?: string;
    reporter?: AssessmentUser;
    report_deadline?: string;
    report_assurer?: AssessmentUser;
    imported?: boolean;
    date_imported?: string;
    qa_info?: {
        extended: boolean;
        iteration: number;
        initial_yes_answers: number;
    };
    internal: boolean;
};

export type GeneralInformation = {
    analyst: string;
    assurer: string;
    fiscal_year: number;
    assessment_deadline: string;
    assurance_deadline: string;
    status?: string;
    assurance_type: string;
    company: string;
    _id: string;
};

export enum CompanyInformationEmployeeNumber {
    'No employees' = 'No employees',
    'Less than 100 employees' = 'Less than 100 employees',
    '100 or more employees' = '100 or more employees',
}

export type CompanyInformation = {
    country_listed: string[];
    website: string;
    fiscal_year_end: string;
    ar_publication_date: string;
    industry: string;
    sub_industries: string[];
    employee_number: CompanyInformationEmployeeNumber;
    sub_industry_rationale: string;
    isin: string;
    name: string;
};

export type CompanyInformationBasic = {
    country_listed: string[];
    website: string;
    fiscal_year_end: string;
    ar_publication_date: string;
    employee_number: CompanyInformationEmployeeNumber;
    sub_industry_rationale: string;
};

export type Statistics = {
    fiscal_year?: number;
    company?: { name: string };
    percentage_of_completion?: number;
    analyst_spent_hours?: number;
    assurer_spent_hours?: number;
    status?: string;
};

export type CreateUpdateGeneralInformation = Omit<GeneralInformation, '_id'>;
export type CreateUpdateCompanyInformation = Omit<
    CompanyInformation,
    'isin' | 'name'
>;

export type InternalExternalRequest = {
    assessment_ids: string[];
    internal: boolean;
};

export type AnalystAndAssurerResponse = {
    analyst: string;
    assurer: string;
    assessment_deadline: string;
    assurance_deadline: string;
    _id: string;
};

export type UpdateAnalystAndAssurer = {
    analyst: string;
    assurer: string;
    assessment_deadline: string;
    assurance_deadline: string;
};

export type AssessmentListResponse = Assessment[];

export type AssessmentResponse = Assessment;

export type StatisticsResponse = Statistics;

export type CreateAssessmentRequest = Omit<Assessment, '_id'>;

export type CreateAssessmentResponse = Assessment & ErrorResponse;

export type FlowRequest = { direction: AssessmentDirection };

export type AssessmentStatusResponse = { status: AssessmentStatus };
