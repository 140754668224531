import { Container } from '@mui/material';
import { FC } from 'react';
import { PageTitle } from 'components/shared/page-title';

export const NotAllowed: FC = () => {
    return (
        <Container>
            <PageTitle title='Not Allowed' />
        </Container>
    );
};
