import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, debounce } from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { CustomTableHeader } from './table-header';
import { CustomTableBody } from './table-body';
import { CustomPagination } from 'components/shared/custom-pagination';
import { CompanyPerformanceAssessment } from 'api/hooks/company-performance-assessment/companyPerformanceAssessmentTypes';
import {
    selectCompanyPerformanceAssessmentPageData,
    selectCompanyPerformanceAssessmentTableData,
    setInitialCompanyPerformanceAssessment,
    toggleCompanyPerformanceAssessment,
    updateCompanyPerformanceAssessment,
    updatePage,
    updateQaStatus,
    updateRowsPerPage,
} from 'store/slices/companyPerformanceAssessmentSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import PopChat from 'components/popchat/popchat';
import { AssessmentCommentTarget } from 'api/hooks/assessment-comments/assessmentCommentsTypes';
import { QaStatus } from 'api/hooks/commonTypes';
import { useGetCompanyPerformanceAssessmentList } from 'api/hooks/company-performance-assessment/useGetCompanyPerformanceAssessmentList';
import { useParams } from 'react-router';
import { WithSpinner } from 'components/with-spinner';
import { useCompanyPerformanceSave } from './use-company-performance-save';
import { QaStatusComponent } from '../qa-status';

type Props = {
    isReadOnly: boolean;
};

export const CompanyPerformanceAssessmentListTable: FC<Props> = ({
    isReadOnly,
}) => {
    const dispatch = useAppDispatch();
    const { assessmentId } = useParams();
    const [chatQuestionId, setChatQuestionId] = useState<string | null>(null);
    const [openChat, setOpenChat] = useState(false);
    const [scrollPosition, setScrollPosition] = useState(0);
    const tableRef = useRef<HTMLTableElement>(null);
    const { page, rowsPerPage } = useAppSelector(
        selectCompanyPerformanceAssessmentPageData
    );
    const {
        isLoading,
        query: { isRefetching },
    } = useGetCompanyPerformanceAssessmentList({
        assessmentId: assessmentId ?? '',
        enabled: !!assessmentId && assessmentId !== '0',
        onSuccess: (data) => {
            dispatch(setInitialCompanyPerformanceAssessment(data));
        },
        filters: {},
    });

    const { save } = useCompanyPerformanceSave();

    const handleSetRowsPerPage = async (rowsPerPage: number) => {
        await save();
        dispatch(updateRowsPerPage(rowsPerPage));
    };

    const handleSetPage = async (page: number) => {
        await save();
        dispatch(updatePage(page));
    };

    const handleOpenChat = (id: string) => {
        setChatQuestionId(id);
        setOpenChat(true);
    };

    const handleCloseChat = () => {
        setChatQuestionId(null);
        setOpenChat(false);
    };

    const companyPerformanceAssessmentForm = useAppSelector(
        selectCompanyPerformanceAssessmentTableData
    );

    const handleChange = (data: CompanyPerformanceAssessment) => {
        dispatch(updateCompanyPerformanceAssessment({ data }));
    };

    const handleStatusChange = (
        qaStatus: QaStatus,
        row: CompanyPerformanceAssessment
    ) => {
        dispatch(
            updateCompanyPerformanceAssessment({
                data: { ...row, qa_status: qaStatus },
            })
        );
    };

    const handleCheck = (id: string) => {
        dispatch(toggleCompanyPerformanceAssessment({ _id: id }));
    };

    const handleBulkStatusChange = (qaStatus: QaStatus) => {
        const selectedData = companyPerformanceAssessmentForm.filter(
            (el) => el.isSelected
        );
        for (const data of selectedData) {
            dispatch(
                updateQaStatus({
                    _id: data.data._id,
                    qaStatus,
                })
            );
        }
    };

    const handleScroll = () => {
        if (tableRef.current) {
            setScrollPosition(tableRef.current.scrollTop);
        }
    };

    useEffect(() => {
        if (tableRef.current) {
            tableRef.current.scrollTop = scrollPosition;
        }
    }, [scrollPosition, companyPerformanceAssessmentForm]);

    const isBulkUpdateVisible = companyPerformanceAssessmentForm.some(
        (el) => el.isSelected
    );

    const pagedData = companyPerformanceAssessmentForm.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    return (
        <WithSpinner isLoading={isLoading || isRefetching}>
            <Box>
                {isBulkUpdateVisible && (
                    <Box sx={{ mt: 2 }}>
                        <QaStatusComponent
                            label='Bulk Qa Status Update'
                            forceShow
                            handleClick={handleBulkStatusChange}
                        />
                    </Box>
                )}
                <Box sx={{ mt: 5 }}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer
                            ref={tableRef}
                            onScroll={debounce(handleScroll, 700)}
                            style={{ height: '60vh' }}
                        >
                            <Table stickyHeader>
                                <CustomTableHeader />
                                <CustomTableBody
                                    data={pagedData}
                                    onChange={handleChange}
                                    handleOpenChat={handleOpenChat}
                                    isReadOnly={isReadOnly}
                                    handleStatusChange={handleStatusChange}
                                    onCheck={handleCheck}
                                />
                            </Table>
                        </TableContainer>
                        <CustomPagination
                            dataLength={
                                companyPerformanceAssessmentForm?.length
                            }
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setPage={handleSetPage}
                            setRowsPerPage={handleSetRowsPerPage}
                        />
                    </Paper>
                    {openChat && (
                        <PopChat
                            questionId={chatQuestionId ?? ''}
                            closeChat={handleCloseChat}
                            target={AssessmentCommentTarget.COMPANY_PERFORMANCE}
                            isReadOnly={isReadOnly}
                        />
                    )}
                </Box>
            </Box>
        </WithSpinner>
    );
};
