export enum ReportQueryDataFormat {
    XLSX = 'XLSX',
    CSV = 'CSV',
}

export enum ReportStatus {
    REPORT_IN_PROGRESS = 'REPORT_IN_PROGRESS',
    REPORT_FAILED = 'REPORT_FAILED',
    REPORT_COMPLETED = 'REPORT_COMPLETED',
    REPORT_NO_MATCH = 'REPORT_NO_MATCH',
}

export enum ReportQueryType {
    INTERNAL_RATING_REPORT = 'INTERNAL_RATING_REPORT',
    INTERNAL_COMPANY_DATA = 'INTERNAL_COMPANY_DATA',
    EXTERNAL_CLIENT_DATA = 'EXTERNAL_CLIENT_DATA',
}

export enum ReportQueryAccessLevel {
    PRIVATE = 'PRIVATE',
    COMPANY_WIDE = 'COMPANY_WIDE',
    PEER_GROUP_WIDE = 'PEER_GROUP_WIDE',
}

export type ReportQueryCompany = {
    _id: string;
    isin: string;
    name: string;
};

export type ReportQueryIndustry = {
    _id: string;
    name: string;
    code: string;
    sub_industries: ReportQueryIndustrySubIndustry[];
};

export type ReportQueryIndustrySubIndustry = {
    name: string;
    code: string;
};

export type ReportQuery = {
    type: ReportQueryType;
    companies: ReportQueryCompany[];
    fiscal_years: number[];
    industries: ReportQueryIndustry[];
    data_report: boolean;
    pdf_report: boolean;
    formats: ReportQueryDataFormat[];
    access_level: ReportQueryAccessLevel;
    publication_date: string;
};

export type ReportResults = {
    file: string;
    description: string;
    expires_in: string;
    url: string;
};

export type ReportAssessment = {
    _id: string;
    isin: string;
    name: string;
    fiscal_year_end: string;
};

export type ReportUser = {
    _id: string;
    name?: string;
    last_name?: string;
};

export type Report = {
    _id: string;
    user: ReportUser;
    start_datetime: string;
    end_datetime: string;
    status: ReportStatus;
    assessments: ReportAssessment[];
    query: ReportQuery;
    results: ReportResults;
    task_id: 'string';
};

export type CreateReport = {
    type: ReportQueryType;
    fiscal_years: number[];
    data_report: boolean;
    pdf_report: boolean;
    formats: ReportQueryDataFormat[];
    publication_date?: string;
    access_level: ReportQueryAccessLevel;
    companies: string[];
    industries: string[];
    sub_industries: string[];
};

export type ReportListResponse = Report[];
