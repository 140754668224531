import { ErrorResponse } from 'api/hooks/commonTypes';

export const modifyError = (error: ErrorResponse) => {
    if (error?.detail) {
        if (typeof error.detail === 'string') {
            return [error.detail];
        } else {
            return error.detail.map((el) => `${el.loc.join(';')}: ${el.msg}`);
        }
    }
};
