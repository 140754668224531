import { FC, useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

type Props = {
    desc?: string;
    title?: string;
    id: string;
    onDelete: (id: string) => void;
};

export const ConfirmationDialog: FC<Props> = ({
    id,
    onDelete,
    desc = 'Are you sure you want to delete this record?',
    title = 'Confirm Deletion',
}) => {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        onDelete(id);
        setOpen(false);
    };

    return (
        <>
            <Button onClick={() => setOpen(true)}>Delete</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>{desc}</DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleDelete} color='primary'>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ConfirmationDialog;
