// const StringIsNumber = (value: string) => isNaN(Number(value)) === false;

export const EnumToDropdownOptions = (enumme: any) => {
    return (
        Object.keys(enumme)
            // .filter(StringIsNumber)
            .map((key) => ({ value: key, label: enumme[key] }))
    );
};

export const EnumToMultipleDropdownOptions = (enumme: any) => {
    return Object.keys(enumme).map((key) => ({
        value: key,
        label: enumme[key],
    }));
};

export const getKeyByValue = <T extends string>(
    enumObject: Record<string, T>,
    value: T
): keyof typeof enumObject | undefined => {
    return Object.keys(enumObject).find((key) => enumObject[key] === value);
};

export const getValueByKey = (
    enumObject: Record<string, string>,
    key: string
): string | undefined => {
    return enumObject[key as keyof typeof enumObject];
};
