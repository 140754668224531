import { QaStatus } from '../commonTypes';

export type SDGAssessmentQuestionAssurerCommentUser = {
    _id: string;
    name?: string;
    last_name?: string;
};

export type SDGAssessmentQuestionAssurerComment = {
    date_time?: string;
    user?: SDGAssessmentQuestionAssurerCommentUser;
    comment?: string;
};

export type SDGAssessmentQuestionScoreByAnswer = {
    answer?: string;
    score?: number;
};

export enum SDGAssessmentQuestionAnswerValidation {
    'Yes or No' = 'Yes or No',
    'Fully or Partially or No' = 'Fully or Partially or No',
}

export enum SDGAssessmentQuestionYesOrNo {
    'Yes' = 'Yes',
    'No' = 'No',
}

export enum SDGAssessmentQuestionFullyOrPartiallyOrNo {
    'Fully' = 'Fully',
    'Partially' = 'Partially',
    'No' = 'No',
}

export type SDGAssessmentQuestion = {
    answer?: string;
    reference_for_answer?: string;
    suggested_answer?: string;
    score?: number;
    weighted_score?: number;
    weighted_max_score_possible?: number;
    assurer_comment?: SDGAssessmentQuestionAssurerComment[];
    imported?: boolean;
    date_imported?: string;
    question?: string;
    max_score_possible?: number;
    scores_by_answer?: SDGAssessmentQuestionScoreByAnswer[];
    answer_validation: SDGAssessmentQuestionAnswerValidation;
};

export type SDGAssessment = {
    _id: string;
    assessment?: string;
    sdg_goal?: number;
    assurer_comment?: string;
    questions?: SDGAssessmentQuestion[];
    for_qa?: boolean;
    qa_status?: QaStatus;
    has_comments: boolean;
};

export type QuestionMapped = {
    question: string;
    answer: string;
    reference_for_answer: string;
    answer_validation: SDGAssessmentQuestionAnswerValidation | null;
    score?: number;
    weighted_score?: number;
};

export type QuestionAnswerMapped = {
    Q1: QuestionMapped;
    Q2: QuestionMapped;
    Q3: QuestionMapped;
    Q4: QuestionMapped;
    Q5: QuestionMapped;
};

export type SDGAssessmentMapped = {
    _id: string;
    sdg_goal?: number;
    question_answer: QuestionAnswerMapped;
    for_qa: boolean;
    qa_status: QaStatus;
    has_comments: boolean;
    isSelected: boolean;
};

export type CreateSDGAssessment = {
    questions: {
        question: string;
        answer: string;
        reference_for_answer: string;
    }[];
    qa_status?: QaStatus;
    assessment: string;
    sdg_goal: number;
};

export type SDGAssessmentListResponse = SDGAssessment[];
