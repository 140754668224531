import { Button, TableBody, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { FC } from 'react';
import { User } from 'api/hooks/user/userTypes';
import { booleanToText } from 'utils/common';

type Props = {
    data: User[];
    openEditFormDialog: (id: string) => void;
    generateApiKey: (userId: string) => void;
};

export const CustomTableBody: FC<Props> = ({
    data,
    openEditFormDialog,
    generateApiKey,
}) => {
    return (
        <>
            <TableBody>
                {data.map((row) => {
                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell>{row.name}</TableCell>
                            <TableCell align='right'>{row.last_name}</TableCell>
                            <TableCell align='right'>{row.email}</TableCell>
                            <TableCell align='right'>{row.type}</TableCell>
                            <TableCell align='right'>
                                {booleanToText(row.is_active)}
                            </TableCell>
                            <TableCell align='right'>
                                {booleanToText(row.is_superuser)}
                            </TableCell>
                            <TableCell align='right'>
                                <Button
                                    onClick={() => openEditFormDialog(row._id)}
                                >
                                    <EditIcon fontSize='large' />
                                </Button>
                                <Button onClick={() => generateApiKey(row._id)}>
                                    Generate API KEY
                                </Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </>
    );
};
