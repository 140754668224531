import { Button } from '@mui/material';
import { FC } from 'react';
import { ForgotPasswordContainer } from './styles';

type Props = {
    onClick: () => void;
};

export const ForgotPasswordButton: FC<Props> = ({ onClick }) => {
    return (
        <ForgotPasswordContainer>
            <Button onClick={onClick} variant='text'>
                Forgot password?
            </Button>
        </ForgotPasswordContainer>
    );
};
