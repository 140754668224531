import { useQuery } from 'react-query';
import { MaterialityQuestionResponse } from './materialityQuestionsTypes';
import { getMaterialityQuestionById } from './materialityQuestionService';

type Props<M> = {
    id: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: MaterialityQuestionResponse) => void;
    mapper?: (
        data?: MaterialityQuestionResponse
    ) => M | MaterialityQuestionResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: MaterialityQuestionResponse) => data;

export const useGetMaterialityQuestion = <M = MaterialityQuestionResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    id,
}: Props<M>) => {
    const query = useQuery<MaterialityQuestionResponse, Error>(
        ['materialityQuestion', id],
        () => getMaterialityQuestionById(id),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
