import { Button, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import { toast } from 'react-toastify';

type Props = {
    onSubmit: (password: string) => void;
};

export const ResetPasswordForm: FC<Props> = ({ onSubmit }) => {
    const formName = 'ResetPasswordForm';

    const validationSchema = Yup.object({
        password: Yup.string().required('Required').min(12).label('Password'),
        repeatPassword: Yup.string()
            .required('Required')
            .min(12)
            .label('Password'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            repeatPassword: '',
        },
        validationSchema,
        onSubmit: (values) => {
            if (values.password !== values.repeatPassword) {
                toast.error('Password does not match');
            } else {
                onSubmit(values.password);
            }
        },
    });

    type FormikTypes = typeof formik.initialValues;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <CustomTextField
                    {...generateTextFieldProps<FormikTypes>(
                        'password',
                        'New Password',
                        formName,
                        formik
                    )}
                    type='password'
                />
                <CustomTextField
                    {...generateTextFieldProps<FormikTypes>(
                        'repeatPassword',
                        'Repeat Password',
                        formName,
                        formik
                    )}
                    type='password'
                />
                <Button
                    color='primary'
                    data-testid={`${formName}-submit`}
                    role='submit'
                    type='submit'
                    variant='contained'
                >
                    Save
                </Button>
            </Stack>
        </form>
    );
};
