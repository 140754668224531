import { Container } from '@mui/material';
import { useGetCompanyList } from 'api/hooks';
import { useNavigate } from 'react-router';
import { RoutePaths } from 'routes';
import { generateRoutePath } from 'utils';
import { CompanyListTableActions } from 'components/company-list-table-actions';
import { CompanyListTable } from 'components/company-list-table';
import { FC } from 'react';
import { PageTitle } from 'components/shared/page-title';
import { WithSpinner } from 'components/with-spinner';

export const CompanyList: FC = () => {
    const { data: companies, isLoading } = useGetCompanyList({});
    const navigate = useNavigate();

    const handleCreateNewCompanyClick = () => {
        navigate(generateRoutePath([RoutePaths.COMPANY_LIST, '/0']));
    };

    const handleOpenClick = (id: string) => {
        navigate(generateRoutePath([RoutePaths.COMPANY_LIST, `/${id}`]));
    };

    return (
        <WithSpinner isLoading={isLoading}>
            <Container sx={{ display: 'flex', flexDirection: 'column' }}>
                <PageTitle title='Companies' />
                <CompanyListTableActions
                    onCreateNewCompanyClick={handleCreateNewCompanyClick}
                />
                <CompanyListTable
                    data={companies}
                    onOpenClick={handleOpenClick}
                />
            </Container>
        </WithSpinner>
    );
};
