import { authApi } from 'lib';
import {
    Country,
    CountryListResponse,
    CreateCountry,
    UpdateCountry,
} from './countryTypes';

export const getCountryList = async () => {
    const response = await authApi.get<CountryListResponse>(
        '/api/v1/countries'
    );
    return response.data;
};

export const createCountry = async (request: CreateCountry) => {
    const response = await authApi.post<Country>('api/v1/countries', request);
    return response.data;
};

export const updateCountry = async (id: string, request: UpdateCountry) => {
    const response = await authApi.put<Country>(
        `api/v1/countries/${id}`,
        request
    );
    return response.data;
};
