import { TableCell, TableHead, TableRow } from '@mui/material';

export const GuideTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Order</TableCell>
                <TableCell align='right'>Title</TableCell>
                <TableCell align='right'>Text</TableCell>
                <TableCell align='right' />
            </TableRow>
        </TableHead>
    );
};
