import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemText,
    TextField,
    Typography,
} from '@mui/material';
import { FC, useEffect, useRef, useState } from 'react';
import { useGetAssessmentCommentList } from 'api/hooks/assessment-comments/useGetAssessmentCommentsList';
import { WithSpinner } from 'components/with-spinner';
import { AssessmentCommentTarget } from 'api/hooks/assessment-comments/assessmentCommentsTypes';
import { useCreateAssessmentComment } from 'api/hooks/assessment-comments/useCreateAssessmentComments';

type Props = {
    questionId: string;
    target: AssessmentCommentTarget;
    closeChat: () => void;
    isReadOnly: boolean;
};

const PopChat: FC<Props> = ({ questionId, target, closeChat, isReadOnly }) => {
    const [newMessage, setNewMessage] = useState('');
    // const { assessmentId } = useParams();
    // const queryClient = useQueryClient();

    const { data, isLoading } = useGetAssessmentCommentList({
        questionId: questionId,
    });

    const { mutateAsync } = useCreateAssessmentComment({
        // onSuccess: () => {
        //     queryClient.invalidateQueries({
        //         queryKey: ['mainQuestionAssessments', assessmentId],
        //     });
        // },
    });

    const messagesEndRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const handleSave = () => {
        mutateAsync({
            questionId,
            request: {
                comment: newMessage,
                target,
            },
        });
        closeChat();
    };

    useEffect(() => {
        if (data) {
            scrollToBottom();
        }
    }, [data]);

    return (
        <div>
            <WithSpinner isLoading={isLoading}>
                <Dialog open={true} onClose={closeChat} fullWidth maxWidth='sm'>
                    <DialogTitle>Chat</DialogTitle>
                    <DialogContent>
                        <div
                            style={{
                                maxHeight: '300px',
                                overflowY: 'auto',
                                marginBottom: '16px',
                            }}
                        >
                            <List>
                                {data?.map((message, index) => (
                                    <ListItem key={index}>
                                        <ListItemText>
                                            <Typography variant='body1'>
                                                {message.comment}
                                            </Typography>
                                            <Typography variant='caption'>
                                                {`${message.user.name} ${message.user.last_name}`}
                                            </Typography>
                                            <Typography
                                                variant='caption'
                                                ml={1}
                                            >
                                                {new Date(
                                                    message.created_at ?? ''
                                                ).toLocaleString()}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                ))}
                                <div ref={messagesEndRef} />
                            </List>
                        </div>
                        <Box display='flex'>
                            <TextField
                                value={newMessage}
                                onChange={(e) => {
                                    setNewMessage(e.target.value);
                                }}
                                fullWidth
                                variant='outlined'
                                placeholder='Type your message'
                                disabled={isReadOnly}
                            />
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={handleSave}
                                disabled={isReadOnly}
                            >
                                Send
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </WithSpinner>
        </div>
    );
};

export default PopChat;
