import { Box, Container } from '@mui/material';
import { FC } from 'react';

import { AdminNavigation } from './admin-navigation';
import { Outlet, Route, Routes } from 'react-router';
import { AdminRoutePaths, RouteParams } from 'constants/enums/routes-constants';
import { generateRoutePath } from 'utils';
import { AdminCountryListTable } from 'components/admin/admin-country-list-table';
import { AdminIndustryListTable } from 'components/admin/admin-industry-list-table';
import { AdminPeerGroupsListTable } from 'components/admin/admin-peer-groups-table';
import { AdminUserListTable } from 'components/admin/admin-user-list-table';
import {
    AdminMainQuestionForm,
    AdminMainQuestionListTable,
} from 'components/admin/admin-main-question';
import {
    AdminCompanyPerformanceForm,
    AdminCompanyPerformanceListTable,
} from 'components/admin/admin-company-performance';
import { AdminImportListTable } from 'components/admin/admin-import-list-table';
import {
    AdminCategoryForm,
    AdminCategoryListTable,
} from 'components/admin/admin-category';
import { ImportType } from 'api/hooks/import/importType';
import {
    AdminSDGQuestionForm,
    AdminSDGQuestionListTable,
} from 'components/admin/admin-sdg-question';
import { AdminMaterialityQuestionForm } from 'components/admin/admin-materiality-question/admin-materiality-question-form';
import { AdminMaterialityQuestionListTable } from 'components/admin/admin-materiality-question/admin-materiality-question-list-table';

const Layout = () => {
    return (
        <>
            {/* <PageTitle title='Admin' /> */}
            <Container sx={{ display: 'flex' }}>
                <AdminNavigation />
                <Box width='100%' px={1}>
                    <Outlet />
                </Box>
            </Container>
        </>
    );
};

export const Admin: FC = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Layout />}>
                <Route index element={<AdminCountryListTable />} />
                <Route
                    path={AdminRoutePaths.ADMIN_COUNTRIES}
                    element={<AdminCountryListTable />}
                />
                <Route
                    path={AdminRoutePaths.ADMIN_INDUSTRIES}
                    element={<AdminIndustryListTable />}
                />
                <Route
                    path={AdminRoutePaths.ADMIN_PEERGROUPS}
                    element={<AdminPeerGroupsListTable />}
                />
                <Route
                    path={AdminRoutePaths.ADMIN_USERS}
                    element={<AdminUserListTable />}
                />
                <Route
                    path={AdminRoutePaths.ADMIN_MAIN_QUESTIONS}
                    element={<AdminMainQuestionListTable />}
                />
                <Route
                    path={AdminRoutePaths.ADMIN_COMPANY_PERFORMANCE}
                    element={<AdminCompanyPerformanceListTable />}
                />
                <Route
                    path={AdminRoutePaths.ADMIN_CATEGORY}
                    element={<AdminCategoryListTable />}
                />
                <Route
                    path={AdminRoutePaths.ADMIN_SDG_QUESTIONS}
                    element={<AdminSDGQuestionListTable />}
                />
                <Route
                    path={AdminRoutePaths.ADMIN_MATERIALITY_QUESTIONS}
                    element={<AdminMaterialityQuestionListTable />}
                />
                <Route
                    path={generateRoutePath([
                        AdminRoutePaths.ADMIN_MAIN_QUESTIONS,
                        RouteParams.MAIN_QUESTION_ID,
                    ])}
                    element={<AdminMainQuestionForm />}
                />
                <Route
                    path={generateRoutePath([
                        AdminRoutePaths.ADMIN_COMPANY_PERFORMANCE,
                        RouteParams.COMPANY_PERFORMANCE_ID,
                    ])}
                    element={<AdminCompanyPerformanceForm />}
                />
                <Route
                    path={generateRoutePath([
                        AdminRoutePaths.ADMIN_CATEGORY,
                        RouteParams.CATEGORY_ID,
                    ])}
                    element={<AdminCategoryForm />}
                />
                <Route
                    path={generateRoutePath([
                        AdminRoutePaths.ADMIN_SDG_QUESTIONS,
                        RouteParams.SDG_QUESTION_ID,
                    ])}
                    element={<AdminSDGQuestionForm />}
                />
                <Route
                    path={generateRoutePath([
                        AdminRoutePaths.ADMIN_MATERIALITY_QUESTIONS,
                        RouteParams.MATERIALITY_QUESTION_ID,
                    ])}
                    element={<AdminMaterialityQuestionForm />}
                />
                <Route path={AdminRoutePaths.ADMIN_IMPORT}>
                    <Route
                        path={generateRoutePath([
                            AdminRoutePaths.ADMIN_IMPORT,
                            AdminRoutePaths.ADMIN_IMPORT_ASSESSMENTS,
                        ])}
                        element={
                            <AdminImportListTable
                                importType={ImportType.ASSESSMENT}
                            />
                        }
                    />
                    <Route
                        path={generateRoutePath([
                            AdminRoutePaths.ADMIN_IMPORT,
                            AdminRoutePaths.ADMIN_IMPORT_USERS,
                        ])}
                        element={
                            <AdminImportListTable
                                importType={ImportType.USER}
                            />
                        }
                    />
                    <Route
                        path={generateRoutePath([
                            AdminRoutePaths.ADMIN_IMPORT,
                            AdminRoutePaths.ADMIN_IMPORT_PERFORMANCE_DATA,
                        ])}
                        element={
                            <AdminImportListTable
                                importType={ImportType.PERFORMANCE_DATA}
                            />
                        }
                    />
                    <Route
                        path={generateRoutePath([
                            AdminRoutePaths.ADMIN_IMPORT,
                            AdminRoutePaths.ADMIN_IMPORT_REPORT_DATA,
                        ])}
                        element={
                            <AdminImportListTable
                                importType={ImportType.REPORT_DATA}
                            />
                        }
                    />
                </Route>
            </Route>
        </Routes>
    );
};
