import { FormikProps } from 'formik';

export const generateMultipleSelectFieldProps = <T>(
    name: keyof T,
    label: string,
    formName: string,
    formik: Partial<FormikProps<T>> &
        Pick<FormikProps<T>, 'values' | 'touched' | 'errors'>,
    helperText?: string
) => {
    return {
        formName: formName,
        label: label,
        name: name,
        value: formik.values && formik.values[name],
        isTouched: formik.touched[name],
        errorText: formik.errors[name],
        helperText: helperText,
    };
};
