export enum ImportType {
    ASSESSMENT = 'ASSESSMENT',
    USER = 'USER',
    PERFORMANCE_DATA = 'PERFORMANCE_DATA',
    REPORT_DATA = 'REPORT_DATA',
}

export enum Status {
    IN_PROGRESS = 'IN_PROGRESS',
    COMPLETED = 'COMPLETED',
    IMPORT_PARTIALLY_COMPLETED = 'IMPORT_PARTIALLY_COMPLETED',
    FAILED = 'FAILED',
}

export enum ResultsStatus {
    SUCCESSFUL = 'SUCCESSFUL',
    NEEDS_APPROVAL = 'NEEDS_APPROVAL',
    FAILED = 'FAILED',
    DUPLICATE = 'DUPLICATE',
}

export type ImportResult = {
    identifier: string[];
    status: ResultsStatus;
    information: {
        assessment: {};
        main_question: {};
        sdg: {};
        company_performance: {};
        materiality: {};
        company: {};
        controversies: {};
    };
};

export type ImportUser = {
    _id: string;
    name?: string;
    last_name?: string;
};

export type Import = {
    _id: string;
    source?: string;
    user?: ImportUser;
    type?: ImportType;
    status?: Status;
    start_datetime?: string;
    end_datetime?: string;
    results: ImportResult[];
};

export type ImportListResponse = Import[];

export type ImportResponse = Import;
