import { Container } from '@mui/system';
import { ResetPasswordContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'routes';
import { useRecoverPassword } from 'api/hooks/';
import { RecoverPasswordForm } from 'components/recover-password-form';
import { FC } from 'react';
import backgroundImage from 'assets/login-background.png';
import { Header } from 'components/header';
import { Box, Paper } from '@mui/material';
import { WithSpinner } from 'components/with-spinner';

export const RecoverPassword: FC = () => {
    const navigate = useNavigate();

    const { mutate, isLoading } = useRecoverPassword({
        onSuccess: () => {
            navigate(RoutePaths.LOGIN);
        },
    });

    const handleSubmit = (email: string) => {
        mutate(email);
    };

    return (
        <WithSpinner isLoading={isLoading}>
            <Box>
                <Header onlyView={true} />
                <ResetPasswordContainer
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                >
                    <Container maxWidth='sm' component={Paper} sx={{ p: 4 }}>
                        <RecoverPasswordForm onSubmit={handleSubmit} />
                    </Container>
                </ResetPasswordContainer>
            </Box>
        </WithSpinner>
    );
};
