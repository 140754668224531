import { useQuery } from 'react-query';
import { UserFilters, UsersForSelectsResponse } from './userTypes';
import { getUsersForSelects } from './userService';

type Props<M> = {
    filters: UserFilters;
    onError?: (error: Error) => void;
    onSuccess?: (data: UsersForSelectsResponse) => void;
    mapper?: (
        data?: UsersForSelectsResponse
    ) => M | UsersForSelectsResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: UsersForSelectsResponse) => data;

export const useGetUsersForSelects = <M = UsersForSelectsResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    filters,
}: Props<M>) => {
    const query = useQuery<UsersForSelectsResponse, Error>(
        ['usersForSelects', filters],
        () => getUsersForSelects(filters),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
