import { TableCell, TableHead, TableRow } from '@mui/material';

export const CustomTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell>Date</TableCell>
                <TableCell align='right'>Number of Companies</TableCell>
                <TableCell align='right'>Status</TableCell>
                <TableCell />
            </TableRow>
        </TableHead>
    );
};
