import { TableCell, TableHead, TableRow } from '@mui/material';

export const CustomTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Category Name</TableCell>
                <TableCell align='right'>Category Code</TableCell>
                <TableCell align='right'>First Sub-Category Name</TableCell>
                <TableCell align='right'>Version</TableCell>
                <TableCell align='right'>Use For Benchmarking</TableCell>
                <TableCell></TableCell>
                {/* <TableCell></TableCell> */}
            </TableRow>
        </TableHead>
    );
};
