import { TableCell, TableHead, TableRow } from '@mui/material';

export const CustomTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Name</TableCell>
                <TableCell align='right'>ISIN</TableCell>
                <TableCell align='right'>Companies</TableCell>
                {/* <TableCell></TableCell> */}
            </TableRow>
        </TableHead>
    );
};
