import { TableCell, TableHead, TableRow } from '@mui/material';
import { sdgQuestions } from 'constants/sdg-questions';

export const SDGTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell width={'10%'}>UN SDG Goal</TableCell>
                <TableCell width={'18%'} align='right'>
                    {sdgQuestions.Q1.question}
                </TableCell>
                <TableCell width={'18%'} align='right'>
                    {sdgQuestions.Q2.question}
                </TableCell>
                <TableCell width={'18%'} align='right'>
                    {sdgQuestions.Q3.question}
                </TableCell>
                <TableCell width={'18%'} align='right'>
                    {sdgQuestions.Q4.question}
                </TableCell>
                <TableCell width={'18%'} align='right'>
                    {sdgQuestions.Q5.question}
                </TableCell>
                <TableCell align='right'>Qa Status</TableCell>
                <TableCell />
                <TableCell />
            </TableRow>
        </TableHead>
    );
};
