import { Button, Container, SelectChangeEvent, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import { EnumToDropdownOptions, generateRoutePath } from 'utils';
import { WithSpinner } from 'components/with-spinner';
import { useNavigate, useParams } from 'react-router';
import { RoutePaths } from 'routes';
import { AdminRoutePaths } from 'constants/enums/routes-constants';
import { PageTitle } from 'components/shared/page-title';
import { useGetCompanyPerformanceQuestion } from 'api/hooks/company-performance-question/useGetCompanyPerformanceQuestion';
import { useCreateCompanyPerformanceQuestion } from 'api/hooks/company-performance-question/useCreateCompanyPerformanceQuestion';
import {
    AnswerValidation,
    CreateUpdateCompanyPerformanceQuestion,
} from 'api/hooks/company-performance-question/companyPerformanceQuestionsTypes';
import {
    CustomSelectField,
    generateSelectFieldProps,
} from 'components/shared/form/custom-select-field';
import { getKeyByValue } from 'utils/enumHelper';
import { useUpdateCompanyPerformanceQuestion } from 'api/hooks/company-performance-question/useUpdateCompanyPerformanceQuestion';

type Props = {};

export const AdminCompanyPerformanceForm: FC<Props> = () => {
    const navigate = useNavigate();
    const { companyPerformanceId } = useParams();
    const isNew = companyPerformanceId === '0';

    const { data: companyPerformance, isLoading: companyPerformanceLoading } =
        useGetCompanyPerformanceQuestion({
            id: companyPerformanceId ?? '',
            enabled: !isNew,
            onError: () => {
                navigateToCompanyPerformanceList();
            },
            onSuccess: (data) => {
                if (!isNew) {
                    const prevFiscalYearAnswerValidation = getKeyByValue(
                        AnswerValidation,
                        data?.previous_fiscal_year_answer_validation ?? ''
                    );
                    const answerValidation = getKeyByValue(
                        AnswerValidation,
                        data?.answer_validation ?? ''
                    );

                    const yearBeforePrevFiscalYearAnswerValidation =
                        getKeyByValue(
                            AnswerValidation,
                            data?.year_before_previous_fiscal_year_answer_validation ??
                                ''
                        );
                    formik.setValues({
                        section: data?.section,
                        link_to_at: data?.link_to_at ?? '',
                        topic: data?.topic,
                        unique_id: data?.unique_id,
                        question_id: data?.question_id,
                        previous_fiscal_year_answer_validation:
                            prevFiscalYearAnswerValidation ?? '',
                        answer_validation: answerValidation ?? '',
                        year_before_previous_fiscal_year_answer_validation:
                            yearBeforePrevFiscalYearAnswerValidation ?? '',
                        question: data?.question,
                        guidance: data?.guidance,
                    });
                }
            },
        });

    const { mutate: createCompanyPerformanceQuestion } =
        useCreateCompanyPerformanceQuestion({
            onSuccess: () => navigateToCompanyPerformanceList(),
        });

    const { mutate: updateCompanyPerformanceQuestion } =
        useUpdateCompanyPerformanceQuestion({
            onSuccess: () => navigateToCompanyPerformanceList(),
        });

    const formName = AdminCompanyPerformanceForm.name;
    const validationSchema = Yup.object({
        section: Yup.string().required('Required'),
        guidance: Yup.string().required('Required'),
        link_to_at: Yup.string().required('Required'),
        topic: Yup.string().required('Required'),
        unique_id: Yup.string().required('Required'),
        question_id: Yup.string().required('Required'),
    });
    const formik = useFormik({
        initialValues: {
            section: '',
            link_to_at: '',
            topic: '',
            unique_id: '',
            question_id: '',
            previous_fiscal_year_answer_validation: '',
            answer_validation: '',
            year_before_previous_fiscal_year_answer_validation: '',
            question: '',
            guidance: '',
        },
        validationSchema,
        onSubmit: (data) => {
            const prevFiscalYearAnswerValidation =
                data.previous_fiscal_year_answer_validation as keyof typeof AnswerValidation;
            const answerValidation =
                data.answer_validation as keyof typeof AnswerValidation;

            const yearBeforePrevFiscalYearAnswerValidation =
                data.year_before_previous_fiscal_year_answer_validation as keyof typeof AnswerValidation;

            const request: CreateUpdateCompanyPerformanceQuestion = {
                section: data.section,
                link_to_at: data.link_to_at,
                topic: data.topic,
                unique_id: data.unique_id,
                question_id: data.question_id,
                previous_fiscal_year_answer_validation:
                    AnswerValidation[prevFiscalYearAnswerValidation],
                answer_validation: AnswerValidation[answerValidation],
                year_before_previous_fiscal_year_answer_validation:
                    AnswerValidation[yearBeforePrevFiscalYearAnswerValidation],
                guidance: data.guidance,
                question: data.question,
            };

            createUpdateCompanyPerformance(request);
        },
    });
    type FormikTypes = typeof formik.initialValues;

    const navigateToCompanyPerformanceList = () => {
        navigate(
            generateRoutePath([
                RoutePaths.ADMIN,
                AdminRoutePaths.ADMIN_COMPANY_PERFORMANCE,
            ])
        );
    };

    const handleCancel = () => {
        navigateToCompanyPerformanceList();
    };

    const handleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
    };

    const createUpdateCompanyPerformance = (
        request: CreateUpdateCompanyPerformanceQuestion
    ) => {
        if (isNew) {
            createCompanyPerformanceQuestion(request);
        } else {
            updateCompanyPerformanceQuestion({
                id: companyPerformance._id,
                request,
            });
        }
    };

    const answerValidationOptions = EnumToDropdownOptions(AnswerValidation);

    const isLoading = companyPerformanceLoading;

    return (
        <WithSpinner isLoading={isLoading}>
            <Container>
                <PageTitle
                    title={
                        isNew
                            ? 'Create New Company Performance'
                            : `Update Question ${companyPerformance?.unique_id}`
                    }
                />
                <form onSubmit={formik.handleSubmit}>
                    <Stack spacing={2}>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'section',
                                    'Section',
                                    formName,
                                    formik
                                )}
                            />
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'link_to_at',
                                    'Link To AT',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'topic',
                                    'Topic',
                                    formName,
                                    formik
                                )}
                            />
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'unique_id',
                                    'Unique ID',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'question_id',
                                    'Question ID',
                                    formName,
                                    formik
                                )}
                            />
                            <CustomSelectField
                                options={answerValidationOptions}
                                onChange={handleSelect}
                                {...generateSelectFieldProps<FormikTypes>(
                                    'answer_validation',
                                    'Assessment Fiscal Year',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'guidance',
                                    'Guidance',
                                    formName,
                                    formik
                                )}
                            />
                            <CustomSelectField
                                options={answerValidationOptions}
                                onChange={handleSelect}
                                {...generateSelectFieldProps<FormikTypes>(
                                    'previous_fiscal_year_answer_validation',
                                    'Assessment Fiscal Year - 1',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <div style={{ width: '100%' }} />
                            <CustomSelectField
                                options={answerValidationOptions}
                                onChange={handleSelect}
                                {...generateSelectFieldProps<FormikTypes>(
                                    'year_before_previous_fiscal_year_answer_validation',
                                    'Assessment Fiscal Year - 2',
                                    formName,
                                    formik
                                )}
                            />
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            <CustomTextField
                                {...generateTextFieldProps<FormikTypes>(
                                    'question',
                                    'Question',
                                    formName,
                                    formik
                                )}
                                multiline
                                rows={4}
                            />
                        </Stack>
                        <Stack
                            direction={'row'}
                            spacing={2}
                            justifyContent={'flex-end'}
                        >
                            <Button
                                color='primary'
                                data-testid={`${formName}-cancel`}
                                variant='contained'
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                color='primary'
                                data-testid={`${formName}-submit`}
                                role='submit'
                                type='submit'
                                variant='contained'
                            >
                                Save
                            </Button>
                        </Stack>
                    </Stack>
                </form>
            </Container>
        </WithSpinner>
    );
};
