import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';

type Props = {
    size?: number;
};

export const CircularLoader: FC<Props> = ({ size = 100 }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 64px)',
                m: 0,
            }}
        >
            <CircularProgress size={size} />
        </Box>
    );
};
