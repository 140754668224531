import { useGetMainQuestionAssessmentList } from 'api/hooks/main-question-assessment/useGetMainQuestionAssessmentList';
import { useUpdateMainQuestionAssessment } from 'api/hooks/main-question-assessment/useUpdateMainQuestionAssessment';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { DataState } from 'store/enums';
import {
    selectMainQuestionAssessment,
    selectMainQuestionAssessmentFilters,
    setInitialMainQuestions,
    updateMainQuestion,
} from 'store/slices/mainQuestionAssessmentSlice';
import { useAppDispatch, useAppSelector } from 'store/store';

export const useMainQuestionSave = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { assessmentId } = useParams();
    const dispatch = useAppDispatch();

    const data = useAppSelector(selectMainQuestionAssessment);
    const filters = useAppSelector(selectMainQuestionAssessmentFilters);

    const { mutateAsync } = useUpdateMainQuestionAssessment({});

    const { query: queryMainQuestionAssessment } =
        useGetMainQuestionAssessmentList({
            assessmentId: assessmentId ?? '',
            enabled: false,
            filters: filters ?? {},
        });

    const save = async (): Promise<boolean> => {
        const updateData = data.table.filter(
            (element) => element.state === DataState.UPDATED
        );
        if (updateData.length) {
            setIsLoading(true);
            let success = true;
            for (const element of updateData) {
                try {
                    await mutateAsync({
                        id: element.data._id,
                        request: {
                            ...element.data,
                            score: parseFloat(element.data.score ?? ''),
                        },
                    });
                    dispatch(
                        updateMainQuestion({
                            data: element.data,
                            state: DataState.INITIAL,
                        })
                    );
                } catch (error) {
                    dispatch(
                        updateMainQuestion({
                            data: element.data,
                            state: DataState.ERROR,
                        })
                    );
                    success = false;
                }
            }

            if (success) {
                toast.success('Main Questions Saved!');
                const data = await queryMainQuestionAssessment.refetch();
                dispatch(setInitialMainQuestions(data.data ?? []));
            } else {
                toast.error('Main Questions Save Failed!');
            }

            return success;
        }

        return true;
    };

    return { save, isLoading };
};
