import { Container } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'routes';
import { useChangePassword, useGetMe } from 'api/hooks/';
import { FC } from 'react';
import { ChangePasswordForm } from 'components/change-password-form';
import { PageTitle } from 'components/shared/page-title';
import { toast } from 'react-toastify';
import { WithSpinner } from 'components/with-spinner';
import { ChangePasswordRequest } from 'api/hooks/user/userTypes';

export const ChangePassword: FC = () => {
    const navigate = useNavigate();

    const { query: getMe } = useGetMe({
        onSuccess: () => {
            // dispatch(
            //     setAuthUser({
            //         initial_password_changed: data.initial_password_changed,
            //         type: data.type,
            //         roles: data.roles,
            //     })
            // );
            navigate(RoutePaths.DASHBOARD);
        },
    });

    const { mutateAsync: changePassword, isLoading } = useChangePassword({
        // onSuccess: () => {
        //     toast.success('Password changed!');
        //     getMe.refetch();
        // },
    });

    const handleSubmit = async (
        oldPassword: string,
        password: string,
        repeatPassword: string
    ) => {
        if (oldPassword && password && repeatPassword) {
            const request: ChangePasswordRequest = {
                old_password: oldPassword,
                new_password_1: password,
                new_password_2: repeatPassword,
            };
            await changePassword(request);
            getMe.refetch();
            toast.success('Password changed!');
        }
    };

    return (
        <WithSpinner isLoading={isLoading}>
            <Container
                maxWidth={'sm'}
                sx={{ display: 'flex', flexDirection: 'column' }}
            >
                <PageTitle title='Change Password' />
                <ChangePasswordForm onSubmit={handleSubmit} />
            </Container>
        </WithSpinner>
    );
};
