import { Dispatch, FC, SetStateAction } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from '@mui/material';

type Props = {
    desc?: string;
    title?: string;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    id: string;
    onDelete: (id: string) => void;
};

export const ConfirmationDialogWithoutButton: FC<Props> = ({
    open,
    setOpen,
    id,
    onDelete,
    desc = 'Are you sure you want to delete this record?',
    title = 'Confirm Deletion',
}) => {
    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        onDelete(id);
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{desc}</DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleDelete} color='primary'>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationDialogWithoutButton;
