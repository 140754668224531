import { Box } from '@mui/material';
import { CircularLoader } from 'components/shared/circular-loader';
import { FC, ReactElement } from 'react';

type Props = {
    isLoading: boolean;
    children: ReactElement;
};
export const WithSpinner: FC<Props> = ({ isLoading = true, children }) => {
    if (isLoading) {
        return (
            <Box sx={{ width: '100%' }}>
                <CircularLoader size={200} />
            </Box>
        );
    }

    return children;
};
