import { Chip, TableBody, TableCell, TableRow } from '@mui/material';

import { FC } from 'react';
import { PeerGroup } from 'api/hooks/peer-group/peerGroupTypes';

type Props = {
    data: PeerGroup[];
    openEditFormDialog: (id: string) => void;
};

export const CustomTableBody: FC<Props> = ({ data, openEditFormDialog }) => {
    return (
        <>
            <TableBody>
                {data.map((row) => {
                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell>{row.name}</TableCell>
                            <TableCell align='right'>{row.isin}</TableCell>
                            <TableCell align='right'>
                                {row.companies?.map((company, index) => {
                                    return (
                                        <Chip
                                            key={index}
                                            label={company}
                                            sx={{ m: 0.5 }}
                                        />
                                    );
                                })}
                            </TableCell>
                            {/* <TableCell align='right'>
                                <Button
                                    onClick={() => openEditFormDialog(row._id)}
                                >
                                    <EditIcon fontSize='large' />
                                </Button>
                            </TableCell> */}
                        </TableRow>
                    );
                })}
            </TableBody>
        </>
    );
};
