import {
    Box,
    Button,
    Checkbox,
    IconButton,
    SelectChangeEvent,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import {
    CustomSelectField,
    OptionValue,
} from 'components/shared/form/custom-select-field';
import { FC, useState } from 'react';
import SourceIcon from '@mui/icons-material/Source';
import { AdditionalInfoDialog } from 'components/additional-info-dialog';
import { EnumToDropdownOptions } from 'utils';
import {
    QuestionMapped,
    SDGAssessmentMapped,
    SDGAssessmentQuestionAnswerValidation,
    SDGAssessmentQuestionFullyOrPartiallyOrNo,
    SDGAssessmentQuestionYesOrNo,
} from 'api/hooks/sdg-assessment/sdgAssessmentTypes';
import { useAppDispatch } from 'store/store';
import {
    updateSDGAnswer,
    updateSDGAnswerToNO,
    updateSDGQaStatus,
    updateSDGReferenceForAnswer,
} from 'store/slices/sdgAssessmentSlice';
import ChatIcon from '@mui/icons-material/Chat';
import { QaStatusComponent } from '../qa-status';
import { QaStatus } from 'api/hooks/commonTypes';

const generateTableCell = (
    question: QuestionMapped,
    onChange: (event: unknown | SelectChangeEvent) => void,
    onSourceClick: () => void,
    disabled: boolean = false
) => {
    const YesOrNoOptions = EnumToDropdownOptions(SDGAssessmentQuestionYesOrNo);

    const FullyOrPartiallyOrNoOptions = EnumToDropdownOptions(
        SDGAssessmentQuestionFullyOrPartiallyOrNo
    );

    const getAnswerValidationByQuestion = (
        answerValidation: SDGAssessmentQuestionAnswerValidation | null
    ): OptionValue[] => {
        const answerValidationTypeOptions =
            answerValidation ===
            SDGAssessmentQuestionAnswerValidation['Yes or No']
                ? YesOrNoOptions
                : answerValidation ===
                  SDGAssessmentQuestionAnswerValidation[
                      'Fully or Partially or No'
                  ]
                ? FullyOrPartiallyOrNoOptions
                : null;

        return answerValidationTypeOptions ?? [];
    };

    const optionsForSelect = getAnswerValidationByQuestion(
        question.answer_validation
    );

    return (
        <TableCell align='right'>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* {Details({ data: question })} */}
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CustomSelectField
                        options={optionsForSelect}
                        onChange={onChange}
                        value={question.answer}
                        name={question.question}
                        disabled={disabled}
                    />
                    {question.answer !== 'No' && (
                        <IconButton
                            size='large'
                            color='inherit'
                            onClick={onSourceClick}
                        >
                            <SourceIcon />
                        </IconButton>
                    )}
                </div>
            </div>
        </TableCell>
    );
};

// const Details: FC<{ data: QuestionMapped }> = ({ data }) => {
//     return (
//         <>
//             <Box>
//                 <Box display={'flex'}>
//                     <Box fontWeight={'bold'}>Score:</Box>
//                     {data.score}
//                 </Box>
//                 <Box display={'flex'}>
//                     <Box fontWeight={'bold'}>Weighted Score:</Box>
//                     {data?.weighted_score}
//                 </Box>
//             </Box>
//         </>
//     );
// };

type Props = {
    data: SDGAssessmentMapped[];
    isReadOnly: boolean;
    handleOpenChat: (id: string) => void;
    onCheck: (id: string) => void;
};

export const SDGTableBody: FC<Props> = ({
    data,
    isReadOnly,
    handleOpenChat,
    onCheck,
}) => {
    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);
    const [dialogData, setDialogData] = useState<{
        id: string;
        question: string;
        reference_for_answer: string;
    } | null>(null);
    const handleSourceClick = (id: string, questionMapped: QuestionMapped) => {
        setDialogData({
            id: id,
            question: questionMapped.question,
            reference_for_answer: questionMapped.reference_for_answer,
        });
        setOpen(true);
    };

    const handleChange = (
        event: unknown | SelectChangeEvent,
        row: SDGAssessmentMapped
    ) => {
        const { name, value } = (event as SelectChangeEvent).target;
        dispatch(
            updateSDGAnswer({
                data: {
                    _id: row._id,
                    question: name,
                    answer: value,
                },
            })
        );
    };
    const handleCloseClick = () => {
        setOpen(false);
        setDialogData(null);
    };

    const handleSaveSourceClick = (value: string) => {
        if (dialogData) {
            dispatch(
                updateSDGReferenceForAnswer({
                    data: {
                        _id: dialogData.id,
                        question: dialogData.question,
                        reference_for_answer: value,
                    },
                })
            );
        }
    };

    const handleStatusChange = (
        qaStatus: QaStatus,
        row: SDGAssessmentMapped
    ) => {
        dispatch(updateSDGQaStatus({ data: { _id: row._id, qaStatus } }));
    };

    const handleNoStatusClick = (row: SDGAssessmentMapped) => {
        dispatch(updateSDGAnswerToNO({ _id: row._id }));
    };

    return (
        <>
            <TableBody>
                {data.map((row) => {
                    const { Q1, Q2, Q3, Q4, Q5 } = row.question_answer;
                    // const { options1, options2, options3, options4, options5 } =
                    //     getAnswerOptions();

                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component='th' scope='row'>
                                <Checkbox
                                    disabled={!row.for_qa}
                                    checked={row.isSelected}
                                    value={row.isSelected}
                                    onChange={() => onCheck(row._id)}
                                />
                            </TableCell>
                            <TableCell component='th' scope='row'>
                                <Box
                                    display={'flex'}
                                    justifyContent={'space-between'}
                                    width={'100%'}
                                >
                                    <Typography>{row?.sdg_goal}</Typography>

                                    <Button
                                        sx={{ p: 0 }}
                                        onClick={() => handleNoStatusClick(row)}
                                    >
                                        Set "No"
                                    </Button>
                                </Box>
                            </TableCell>
                            {generateTableCell(
                                Q1,
                                (event) => handleChange(event, row),
                                () => handleSourceClick(row._id, Q1),
                                isReadOnly
                            )}
                            {generateTableCell(
                                Q2,
                                (event) => handleChange(event, row),
                                () => handleSourceClick(row._id, Q2),
                                isReadOnly
                            )}
                            {generateTableCell(
                                Q3,
                                (event) => handleChange(event, row),
                                () => handleSourceClick(row._id, Q3),
                                isReadOnly
                            )}
                            {generateTableCell(
                                Q4,
                                (event) => handleChange(event, row),
                                () => handleSourceClick(row._id, Q4),
                                isReadOnly
                            )}
                            {generateTableCell(
                                Q5,
                                (event) => handleChange(event, row),
                                () => handleSourceClick(row._id, Q5),
                                isReadOnly
                            )}
                            <TableCell>{row?.qa_status}</TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={() => handleOpenChat(row._id)}
                                >
                                    <ChatIcon
                                        fontSize='large'
                                        color={
                                            row.has_comments
                                                ? 'success'
                                                : 'primary'
                                        }
                                    />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                <QaStatusComponent
                                    for_qa={row.for_qa}
                                    qa_status={row.qa_status}
                                    handleClick={(qaStatus) =>
                                        handleStatusChange(qaStatus, row)
                                    }
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
            {dialogData && (
                <AdditionalInfoDialog
                    onClose={handleCloseClick}
                    onSave={handleSaveSourceClick}
                    open={open}
                    value={dialogData.reference_for_answer ?? ''}
                    title='Source Document/website link and page'
                    isReadOnly={isReadOnly}
                />
            )}
        </>
    );
};
