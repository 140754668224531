import { authApi } from 'lib';
import {
    ChangePasswordRequest,
    ChangePasswordResponse,
    CreateUser,
    LoginRequest,
    LoginResponse,
    MeResponse,
    RecoverPasswordResponse,
    ResetPasswordRequest,
    ResetPasswordResponse,
    TUserErrorRatesFilters,
    UpdateUser,
    User,
    UserErrorRate,
    UserFilters,
    UsersResponse,
} from './userTypes';
import axios from 'axios';

export const getMe = async () => {
    const response = await authApi.get<MeResponse>('api/v1/users/me');
    return response.data;
};

export const getUsers = async () => {
    const response = await authApi.get<UsersResponse>('api/v1/users');
    return response.data;
};

export const createUser = async (request: CreateUser) => {
    const response = await authApi.post<User>('api/v1/users', request);
    return response.data;
};

export const updateUser = async (id: string, request: UpdateUser) => {
    const response = await authApi.put<User>(`api/v1/users/${id}`, request);
    return response.data;
};

export const loginUser = async (request: LoginRequest) => {
    const res = await authApi.post<Pick<LoginResponse, 'access_token'>>(
        '/api/v1/auth/login',
        request,
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }
    );
    return res.data;
};

export const verifyOtp = async (code: string) => {
    const tempToken = localStorage.getItem('tempToken');
    const res = await axios.post<LoginResponse>(
        process.env.REACT_APP_BASE_URL +
            `/api/v1/auth/verify_mfa_code?code=${code}`,
        {},
        {
            headers: {
                Authorization: `Bearer ${tempToken}`,
            },
        }
    );
    return res.data;
};

export const recoverPassword = async (email: string) => {
    const response = await authApi.post<RecoverPasswordResponse>(
        `/api/v1/auth/password-recovery/${email}`
    );

    return response.data;
};

export const resetPassword = async (request: ResetPasswordRequest) => {
    const response = await authApi.post<ResetPasswordResponse>(
        `/api/v1/auth/reset-password`,
        request
    );

    return response.data;
};

export const changePassword = async (request: ChangePasswordRequest) => {
    const response = await authApi.post<ChangePasswordResponse>(
        `/api/v1/users/change_password`,
        request
    );

    return response.data;
};

export const getUsersForSelects = async (filters: UserFilters) => {
    const params = new URLSearchParams();

    if (filters.roles) {
        filters.roles.forEach((data) => {
            params.append('roles', data);
        });
    }
    const response = await authApi.get<UsersResponse>(
        `api/v1/users/for_selects?${params.toString()}`
    );
    return response.data;
};

export const getUserErrorRates = async (values: TUserErrorRatesFilters) => {
    const params = new URLSearchParams();

    const isins = values.companyIsin ? values.companyIsin.split(';') : [];
    const companyNames = values.companyName
        ? values.companyName.split(';')
        : [];
    const analystNames = values.analystName
        ? values.analystName.split(';')
        : [];
    const analystLastNames = values.analystLastName
        ? values.analystLastName.split(';')
        : [];

    if (isins.length) {
        isins.forEach((item) => {
            params.append('error_rates__isin', item);
        });
    }
    if (companyNames.length) {
        companyNames.forEach((item) => {
            params.append('error_rates__company_name', item);
        });
    }
    if (analystNames.length) {
        analystNames.forEach((item) => {
            params.append('name', item);
        });
    }
    if (analystLastNames.length) {
        analystLastNames.forEach((item) => {
            params.append('last_name', item);
        });
    }

    const response = await authApi.get<UserErrorRate[]>(
        'api/v1/users/user_error_rates?' + params.toString()
    );
    return response.data;
};
