import { Box, Button, Container, Paper } from '@mui/material';
import { useCreateReport } from 'api/hooks';
import { FC, useState } from 'react';
import { PageTitle } from 'components/shared/page-title';
import { QueryBuilderForm } from 'components/query-builder-form';
import { useGetIndustries } from 'api/hooks/industry/useGetIndustries';
import { ReportListTable } from 'components/export-list-table';
import { toast } from 'react-toastify';
import { QueryBuilderGuide } from 'components/query-builder-guide';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'store/slices/userSlice';
import { WithSpinner } from 'components/with-spinner';
import { CreateReport, Report } from 'api/hooks/report/reportTypes';
import { UserType } from 'api/hooks/user/userTypes';
import { useGetCompaniesForSelects } from 'api/hooks/company/useGetCompaniesForSelects';

export const QueryBuilder: FC = () => {
    // const { data: user, isLoading: userLoading } = useGetMe({});
    const [isRegenerateReport, setIsRegenerateReport] = useState(false);
    const [regenerateReportData, setRegenerateReportData] =
        useState<null | Report>(null);

    const user = useSelector(selectAuthUser);

    const { data: companiesData, isLoading: companiesLoading } =
        useGetCompaniesForSelects({});

    const { data: industriesData, isLoading: industriesLoading } =
        useGetIndustries({});

    const { mutate: createReport, isLoading: createReportLoading } =
        useCreateReport({
            onSuccess: () => {
                toast.success('Report generation started');
            },
        });

    const handleGenerateReport = (report: CreateReport) => {
        createReport(report);
        setIsRegenerateReport(false);
        setRegenerateReportData(null);
    };

    const regenerateReport = (report: Report) => {
        setIsRegenerateReport(true);
        setRegenerateReportData(report);
    };

    const isLoading =
        industriesLoading || companiesLoading || createReportLoading;

    return (
        <WithSpinner isLoading={isLoading}>
            <Box>
                <PageTitle title='Query Builder' />
                <QueryBuilderGuide />
                <Container
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <a
                        href={
                            'https://prod-cenesg-s3-bucket.s3.eu-west-2.amazonaws.com/media/CENintel+Upgrade+Report.pdf'
                        }
                        target='_blank'
                        rel='noreferrer'
                    >
                        <Button onClick={() => {}}>
                            CENINTEL UPGRADE REPORT
                        </Button>
                    </a>
                    <a
                        href={
                            'https://prod-cenesg-s3-bucket.s3.eu-west-2.amazonaws.com/media/CENintel+Institutional+Methodology+Manual.pdf'
                        }
                        target='_blank'
                        rel='noreferrer'
                    >
                        <Button onClick={() => {}}>
                            CENINTEL Institutional Methodology Manual
                        </Button>
                    </a>
                    <a
                        href={
                            'https://prod-cenesg-s3-bucket.s3.eu-west-2.amazonaws.com/media/CENintel+API+Documentation+v1.0.1.pdf'
                        }
                        target='_blank'
                        rel='noreferrer'
                    >
                        <Button onClick={() => {}}>API Documentation</Button>
                    </a>
                </Container>
                <Container
                    maxWidth={'sm'}
                    sx={{ display: 'flex', flexDirection: 'column', p: 4 }}
                    component={Paper}
                >
                    <QueryBuilderForm
                        companiesData={companiesData}
                        industriesData={industriesData}
                        onGenerateExport={handleGenerateReport}
                        userType={user?.type ?? UserType.CLIENT}
                        isRegenerateReport={isRegenerateReport}
                        regenerateReportData={regenerateReportData}
                    />
                </Container>
                <ReportListTable regenerateReport={regenerateReport} />
            </Box>
        </WithSpinner>
    );
};
