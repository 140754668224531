import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useLocation, useNavigate } from 'react-router';
import { AdminRoutePaths, RoutePaths } from 'constants/enums/routes-constants';
import { generateRoutePath } from 'utils';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FC, useState } from 'react';
import { Collapse } from '@mui/material';

const drawerWidth = 240;

const sectionSettings = [
    {
        name: 'Countries',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_COUNTRIES,
        ]),
    },
    {
        name: 'Industries',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_INDUSTRIES,
        ]),
    },
    {
        name: 'Peer Groups',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_PEERGROUPS,
        ]),
    },
    {
        name: 'Users',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_USERS,
        ]),
    },
    {
        name: 'Categories',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_CATEGORY,
        ]),
    },
];

const sectionQuestionTemplates = [
    {
        name: 'Main Questionnaire',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_MAIN_QUESTIONS,
        ]),
    },
    {
        name: 'Company Performance',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_COMPANY_PERFORMANCE,
        ]),
    },
    {
        name: 'SDG',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_SDG_QUESTIONS,
        ]),
    },
    {
        name: 'Materiality',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_MATERIALITY_QUESTIONS,
        ]),
    },
];

const sectionImports = [
    {
        name: 'Import Users',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_IMPORT,
            AdminRoutePaths.ADMIN_IMPORT_USERS,
        ]),
    },
    {
        name: 'Import Assessments',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_IMPORT,
            AdminRoutePaths.ADMIN_IMPORT_ASSESSMENTS,
        ]),
    },
    {
        name: 'Import Performance Data',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_IMPORT,
            AdminRoutePaths.ADMIN_IMPORT_PERFORMANCE_DATA,
        ]),
    },
    {
        name: 'Import Report Data',
        route: generateRoutePath([
            RoutePaths.ADMIN,
            AdminRoutePaths.ADMIN_IMPORT,
            AdminRoutePaths.ADMIN_IMPORT_REPORT_DATA,
        ]),
    },
];

export const AdminNavigation = () => {
    // const [openQuestions, setOpenQuestions] = useState(false);
    // const navigate = useNavigate();
    // const location = useLocation();
    return (
        <Drawer
            variant='permanent'
            sx={{
                width: drawerWidth,
                [`& .MuiDrawer-paper`]: {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                    position: 'fixed',
                    margin: '120px 25px',
                },
            }}
        >
            <Box sx={{ overflow: 'auto' }}>
                <AdminNavigationCollapsable
                    data={sectionSettings}
                    sectionName='Settings'
                />
                <Divider />
                <AdminNavigationCollapsable
                    data={sectionQuestionTemplates}
                    sectionName='Question Templates'
                />
                <Divider />
                <AdminNavigationCollapsable
                    data={sectionImports}
                    sectionName='Imports'
                />
            </Box>
        </Drawer>
    );
};

type Section = {
    name: string;
    route: string;
};

type AdminNavigationCollapsableProps = {
    data: Section[];
    sectionName: string;
};

const AdminNavigationCollapsable: FC<AdminNavigationCollapsableProps> = ({
    data,
    sectionName,
}) => {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <>
            <ListItemButton onClick={() => setOpen(!open)}>
                <ListItemText primary={sectionName} />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout='auto' unmountOnExit>
                <List component='div' disablePadding>
                    {data.map((el, index) => (
                        <ListItemButton
                            key={`${sectionName}-${index}`}
                            sx={{ pl: 4 }}
                            onClick={() => navigate(el.route)}
                            selected={el.route === location.pathname}
                        >
                            <ListItemText primary={el.name} />
                        </ListItemButton>
                    ))}
                </List>
            </Collapse>
        </>
    );
};
