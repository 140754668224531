import { Tab, Tabs } from '@mui/material';
import { AssessmentTabs } from 'components/assessment/assessment-analyst/assessment-tabs-enum';
import { FC, SyntheticEvent } from 'react';
import { getTabProps } from 'utils/assessment-tabs-utils';

type Props = {
    value: AssessmentTabs;
    onChange: (e: SyntheticEvent, newValue: AssessmentTabs) => void;
};

export const AssessmentDetailsTabsHeaders: FC<Props> = ({
    value,
    onChange,
}) => {
    return (
        <Tabs value={value} onChange={onChange} variant='fullWidth'>
            <Tab {...getTabProps(AssessmentTabs.QUESTIONNAIRE)} />
            <Tab {...getTabProps(AssessmentTabs.MATERIALITY)} />
            <Tab
                {...getTabProps(AssessmentTabs.SUSTAINABLE_DEVELOPMENT_GOALS)}
            />
            <Tab {...getTabProps(AssessmentTabs.CONTROVERSIES_SEARCH)} />
            <Tab {...getTabProps(AssessmentTabs.COMPANY_PERFORMANCE_DATA)} />
        </Tabs>
    );
};
