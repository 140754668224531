import { authApi } from 'lib';
import {
    AssessmentCommentResponse,
    CreateAssessmentComment,
} from './assessmentCommentsTypes';

export const getAssessmentCommentsList = async (questionId: string) => {
    const response = await authApi.get<AssessmentCommentResponse>(
        `api/v1/assessment/comments/${questionId}`
    );
    return response.data;
};

export const createAssessmentComment = async (
    questionId: string,
    request: CreateAssessmentComment
) => {
    const response = await authApi.post<string>(
        `api/v1/assessment/comments/${questionId}`,
        request
    );
    return response.data;
};
