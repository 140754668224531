import { TableCell, TableHead, TableRow } from '@mui/material';

export const CustomTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Question Number</TableCell>
                <TableCell align='right'>Question</TableCell>
                <TableCell align='right'>Max Score</TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    );
};
