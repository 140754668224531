import { useQuery } from 'react-query';
import { TUserErrorRatesFilters, UserErrorRate } from './userTypes';
import { getUserErrorRates } from './userService';

type Props<M> = {
    onError?: (error: Error) => void;
    onSuccess?: (data: UserErrorRate[]) => void;
    mapper?: (data?: UserErrorRate[]) => M | UserErrorRate[] | undefined;
    enabled?: boolean;
    values: TUserErrorRatesFilters;
};

const defaultMapper = (data?: UserErrorRate[]) => data;

export const useGetUserErrorRates = <M = UserErrorRate[]>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    values,
}: Props<M>) => {
    const query = useQuery<UserErrorRate[], Error>(
        ['userErrorRates'],
        () => getUserErrorRates(values),
        {
            onSuccess,
            onError,
            enabled: !(
                values.companyIsin ||
                values.companyName ||
                values.analystName ||
                values.analystLastName
            ),
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
        refetch: query.refetch,
    };
};
