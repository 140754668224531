import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import { CustomTableHeader } from './table-header';
import { CustomTableBody } from './table-body';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useCreateApiKey, useGetUsers } from 'api/hooks';
import { DialogComponent } from 'components/dialog-component';
import { UserForm } from './form';
import { useCreateUser } from 'api/hooks/user/useCreateUser';
import { useUpdateUser } from 'api/hooks/user/useUpdateUser';
import { CustomPagination } from 'components/shared/custom-pagination';
import { GenerateApiKeyDialog } from 'components/generate-api-key-dialog';
import { WithSpinner } from 'components/with-spinner';
import { CreateUser, UpdateUser, User } from 'api/hooks/user/userTypes';

type Props = {};

export const AdminUserListTable: FC<Props> = () => {
    const [userForUpdate, setUserForUpdate] = useState<User | null>(null);
    const [formOpen, setFormOpen] = useState(false);
    const [apiKeyDialogoOpen, setApiKeyDialogoOpen] = useState(false);
    const [apiKey, setApiKey] = useState<null | string>(null);
    const [isCreate, setIsCreate] = useState(false);

    const { data, isLoading, query: getUserQuery } = useGetUsers({});

    const { mutate: createUser } = useCreateUser({
        onSuccess: (data) => {
            getUserQuery.refetch();
            setFormOpen(false);
        },
    });

    const { mutate: createApiKey } = useCreateApiKey({
        onSuccess: (data) => {
            setApiKeyDialogoOpen(true);
            setApiKey(data.key);
        },
    });

    const { mutate: updateUser } = useUpdateUser({
        onSuccess: (data) => {
            getUserQuery.refetch();
            setFormOpen(false);
        },
    });

    const handleOpenCreateFormDialog = () => {
        setUserForUpdate({} as User);
        setFormOpen(true);
        setIsCreate(true);
    };

    const handleOpenEditFormDialog = (id: string) => {
        const foundUser = data.find((user) => user._id === id);

        if (!foundUser) return;

        setUserForUpdate(foundUser);
        setFormOpen(true);
        setIsCreate(false);
    };

    const handleGenerateApiKey = (userId: string) => {
        createApiKey(userId);
    };
    const handleApiKeyDialogClose = () => {
        setApiKeyDialogoOpen(false);
    };

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleFormSave = (data: CreateUser | UpdateUser) => {
        if (isCreate) {
            const dataToCreate = data as CreateUser;
            createUser(dataToCreate);
        } else {
            const dataToUpdate = data as UpdateUser;
            updateUser({ id: userForUpdate?._id ?? '', request: dataToUpdate });
        }
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const pagedData =
        (data &&
            data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((element) => element)) ??
        [];

    return (
        <WithSpinner isLoading={isLoading}>
            <Box sx={{ width: '100%', ml: 1 }}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <IconButton onClick={handleOpenCreateFormDialog}>
                        <AddCircleIcon fontSize='large' color='primary' />
                    </IconButton>
                    <TableContainer>
                        <Table stickyHeader>
                            <CustomTableHeader />
                            <CustomTableBody
                                data={pagedData}
                                openEditFormDialog={handleOpenEditFormDialog}
                                generateApiKey={handleGenerateApiKey}
                            />
                        </Table>
                    </TableContainer>
                    <CustomPagination
                        dataLength={data?.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </Paper>
                <DialogComponent open={formOpen} onClose={handleFormClose}>
                    <UserForm
                        user={userForUpdate}
                        onClose={handleFormClose}
                        onSave={handleFormSave}
                        isCreate={isCreate}
                    />
                </DialogComponent>
                <GenerateApiKeyDialog
                    apiKey={apiKey ?? ''}
                    apiKeyDialogoOpen={apiKeyDialogoOpen}
                    handleApiKeyDialogClose={handleApiKeyDialogClose}
                />
            </Box>
        </WithSpinner>
    );
};
