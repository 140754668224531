import { Button, Container } from '@mui/material';
import { FC } from 'react';

type Props = {
    onCreateNewAssessment: () => void;
};

export const AssessmentListTableActions: FC<Props> = ({
    onCreateNewAssessment,
}) => {
    return (
        <Container
            sx={{ justifyContent: 'space-between', display: 'flex', mb: 1 }}
        >
            <Button variant='outlined' onClick={onCreateNewAssessment}>
                Create new Assessment
            </Button>
        </Container>
    );
};
