import { FC } from 'react';
import { useParams } from 'react-router';
import { AssessmentStatus } from 'api/hooks/assessment/assessmentTypes';
import { useCheckAssessmentStatus } from 'api/hooks/common/useCheckAssessmentStatus';
import { ControversyAssessmentListTable } from './controversy-list-table';

export const ControversyAssessment: FC = () => {
    const { assessmentId } = useParams();

    const assessmentStatus = useCheckAssessmentStatus({
        assessmentId: assessmentId ?? '',
        currentStatuses: [
            AssessmentStatus.ANALYSIS_IN_PROGRESS,
            AssessmentStatus.ASSURANCE_IN_PROGRESS,
        ],
    });

    return (
        <ControversyAssessmentListTable
            isReadonly={assessmentStatus.isReadOnly}
        />
    );
};
