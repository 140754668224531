import { authApi } from 'lib';
import {
    CreatePeerGroup,
    PeerGroup,
    PeerGroupListResponse,
    UpdatePeerGroup,
} from './peerGroupTypes';

export const getPeerGroupList = async () => {
    const response = await authApi.get<PeerGroupListResponse>(
        'api/v1/peer_groups'
    );
    return response.data;
};

export const createPeerGroup = async (request: CreatePeerGroup) => {
    const response = await authApi.post<PeerGroup>(
        'api/v1/peer_groups',
        request
    );
    return response.data;
};

export const updatePeerGroup = async (id: string, request: UpdatePeerGroup) => {
    const response = await authApi.put<PeerGroup>(
        `api/v1/peer_groups/${id}`,
        request
    );
    return response.data;
};
