import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import { FC } from 'react';

type Props = {
    onClick: () => void;
};

export const FloatingSaveButton: FC<Props> = ({ onClick }) => {
    return (
        <Fab variant='extended' onClick={onClick}>
            <SaveIcon sx={{ mr: 1 }} />
            Save
        </Fab>
    );
};
