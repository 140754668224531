import { useQuery } from 'react-query';
import { CountryListResponse } from './countryTypes';
import { getCountryList } from './countryService';

type Props<M> = {
    onError?: (error: Error) => void;
    onSuccess?: (data: CountryListResponse) => void;
    mapper?: (
        data?: CountryListResponse
    ) => M | CountryListResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: CountryListResponse) => data;

export const useGetCountryList = <M = CountryListResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
}: Props<M>) => {
    const query = useQuery<CountryListResponse, Error>(
        ['countries'],
        getCountryList,
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
