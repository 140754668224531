import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { DataState } from 'store/enums';
import {
    ControversyAssessment,
    ControversyAssessmentListResponse,
} from 'api/hooks/controversy-assessment/controversyAssessmentTypes';
import { v4 as uuidv4 } from 'uuid';
import { QaStatus } from 'api/hooks/commonTypes';

export interface ControversiesAssessmentState {
    table: {
        data: ControversyAssessment;
        state: DataState;
        selected: boolean;
    }[];
    page: number;
    rowsPerPage: number;
}

const initialState: ControversiesAssessmentState = {
    table: [],
    page: 0,
    rowsPerPage: 100,
};

export const controversiesAssessmentSlice = createSlice({
    name: 'controversiesAssessment',
    initialState,
    reducers: {
        updatePage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        updateRowsPerPage: (state, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload;
        },
        setInitialControversies: (
            state,
            action: PayloadAction<ControversyAssessmentListResponse>
        ) => {
            const newControversies = action.payload.map((controversy) => ({
                data: controversy,
                state: DataState.INITIAL,
                selected: false,
            }));
            state.table = newControversies;
        },
        updateControversy: (
            state,
            action: PayloadAction<{
                data: ControversyAssessment;
                state?: DataState;
            }>
        ) => {
            const modifiedControversies = state.table?.map((controversy) => {
                if (controversy.data._id === action.payload.data._id) {
                    return {
                        ...controversy,
                        data: action.payload.data,
                        state:
                            action.payload.state === undefined
                                ? controversy.state === DataState.NEW
                                    ? DataState.NEW
                                    : DataState.UPDATED
                                : action.payload.state,
                    };
                }
                return controversy;
            });
            state.table = modifiedControversies;
        },
        addNewControversy: (state) => {
            // const newExists = state.table.find(
            //     (controversy) => controversy.state === DataState.NEW
            // );
            // if (newExists) return;

            const newControversy = {
                data: {
                    _id: uuidv4(),
                    article_date: undefined,
                    incident: '',
                    incident_description: '',
                    link: '',
                    score_reduction: 0,
                } as ControversyAssessment,
                state: DataState.NEW,
                selected: false,
            };

            state.table = [newControversy, ...state.table];
        },
        softDeleteControversy: (state, action: PayloadAction<string>) => {
            const modifiedControversies = state.table?.map((controversy) => {
                if (controversy.data._id === action.payload) {
                    return {
                        ...controversy,
                        state: DataState.DELETED,
                    };
                }
                return controversy;
            });

            state.table = modifiedControversies;
        },
        deleteControversy: (
            state,
            action: PayloadAction<ControversyAssessment>
        ) => {
            const modifiedControversies = state.table?.filter(
                (controversy) => controversy.data._id !== action.payload._id
            );

            state.table = modifiedControversies;
        },
        updateQaStatus: (
            state,
            action: PayloadAction<{
                _id: string;
                qaStatus: QaStatus;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        data: {
                            ...el.data,
                            qa_status: action.payload.qaStatus,
                        },
                        state: DataState.UPDATED,
                        selected: false,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
        toggleSelected: (
            state,
            action: PayloadAction<{
                _id: string;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        selected: !el.selected,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
    },
});

export const {
    setInitialControversies,
    updateControversy,
    addNewControversy,
    softDeleteControversy,
    deleteControversy,
    updatePage,
    updateRowsPerPage,
    updateQaStatus,
    toggleSelected,
} = controversiesAssessmentSlice.actions;

export const selectControversiesAssessmentTable = (state: RootState) =>
    state.controversiesAssessment.table;

export const selectControversiesAssessment = (state: RootState) =>
    state.controversiesAssessment;

export const selectControversiesAssessmentTableData = (state: RootState) =>
    state.controversiesAssessment.table.map((el) => el.data);

export const selectControversiesAssessmentTableDataActive = (
    state: RootState
) =>
    state.controversiesAssessment.table
        .filter((el) => el.state !== DataState.DELETED)
        .map((el) => ({ data: el.data, isSelected: el.selected }));

export const selectControversiesAssessmentPageData = (state: RootState) => ({
    page: state.controversiesAssessment.page,
    rowsPerPage: state.controversiesAssessment.rowsPerPage,
});

export default controversiesAssessmentSlice.reducer;
