import { FC, memo } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    FormControlClasses,
    SelectProps,
    Box,
    Chip,
} from '@mui/material';
import { stringToMultipleSelectOptions } from 'utils';

export type OptionValue = {
    value: string | number | undefined;
    label?: string | number;
};

type Props = SelectProps & {
    formName: string;
    options?: OptionValue[];
    errorText: string | string[] | undefined;
    formControlClasses?: Partial<FormControlClasses>;
    helperText?: string;
    handleDelete: (value: string) => void;
};

export const CustomMultipleSelectField: FC<Props> = memo(
    ({
        name,
        formName,
        label,
        options,
        value,
        onChange,
        formControlClasses,
        disabled = false,
        errorText,
        helperText,
        handleDelete,
        required = false,
    }) => {
        const isHasError = Boolean(errorText);
        const isHelperShown = Boolean(helperText) && !isHasError;
        //asda,asd,asf,asfa,sda,sfa,sfa
        const valueArray = stringToMultipleSelectOptions(value as string);

        return (
            <FormControl
                fullWidth
                key={name}
                required={required}
                variant='outlined'
                {...(formControlClasses ? { classes: formControlClasses } : {})}
            >
                {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
                <Select
                    multiple
                    disabled={disabled}
                    error={isHasError}
                    inputProps={{
                        id: name,
                        name: name,
                        'data-testid': `${formName}-${name}`,
                    }}
                    label={label}
                    value={valueArray || []}
                    renderValue={(selected) => (
                        <Box
                            sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                        >
                            {selected.map((selectedValue) => (
                                <Chip
                                    key={selectedValue}
                                    label={
                                        options?.find(
                                            (option) =>
                                                option.value === selectedValue
                                        )?.label
                                    }
                                    size='small'
                                    disabled={disabled}
                                    onDelete={() => {
                                        handleDelete(selectedValue);
                                    }}
                                    deleteIcon={
                                        <CancelIcon
                                            onMouseDown={(event) =>
                                                event.stopPropagation()
                                            }
                                        />
                                    }
                                />
                            ))}
                        </Box>
                    )}
                    onChange={onChange}
                >
                    {options &&
                        options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label || option.value}
                            </MenuItem>
                        ))}
                </Select>
                {isHasError && (
                    <FormHelperText error>{label} is required</FormHelperText>
                )}
                {isHelperShown && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
);
