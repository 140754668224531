import {
    Button,
    Checkbox,
    FormControlLabel,
    SelectChangeEvent,
    Stack,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import {
    CustomSelectField,
    generateSelectFieldProps,
} from 'components/shared/form/custom-select-field';
import {
    EnumToMultipleDropdownOptions,
    multipleSelectOptionsToString,
    stringToMultipleSelectOptions,
} from 'utils';
import { UserRoles } from 'constants/enums/user-roles';
import {
    CustomMultipleSelectField,
    generateMultipleSelectFieldProps,
} from 'components/shared/form/custom-multiple-select-field';
import { useGetPeerGroupList } from 'api/hooks/peer-group/useGetPeerGroupList';
import { ScreenLoader } from 'components/shared/screen-loader';
import {
    CreateUser,
    UpdateUser,
    User,
    UserType,
} from 'api/hooks/user/userTypes';

type Props = {
    user: User | null;
    isCreate: boolean;
    onClose: () => void;
    onSave: (data: CreateUser | UpdateUser) => void;
};

export const UserForm: FC<Props> = ({ user, onClose, onSave, isCreate }) => {
    const formName = 'UserForm';

    const { data: peerGroupData, isLoading: peerGroupLoading } =
        useGetPeerGroupList({});

    const isLoading = peerGroupLoading;

    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        userType: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        userRoles: Yup.string().nullable().when('userType', {
            is: 'EMPLOYEE',
            then: Yup.string().required(),
        }),
        email: Yup.string().required('Required'),
        // companies: Yup.string().nullable().when('userType', {
        //     is: 'CLIENT',
        //     then: Yup.string().nullable(),
        // }),
        personalId: Yup.string(),
        peerGroup: Yup.string().nullable().when('userType', {
            is: 'CLIENT',
            then: Yup.string(),
        }),
    });

    const formik = useFormik({
        initialValues: {
            name: user?.name ?? '',
            userType: user?.type ?? '',
            lastName: user?.last_name ?? '',
            userRoles: multipleSelectOptionsToString(user?.roles),
            email: user?.email ?? '',
            peerGroup: user?.peer_group?.isin ?? '',
            personalId: user?.personal_id ?? '',
            isActive: user?.is_active ?? false,
            isSuperUser: user?.is_superuser ?? false,
            // companies: multipleSelectOptionsToString(
            //     user?.companies?.map((el) => el.isin ?? '') ?? []
            // ),
        },
        validationSchema,
        onSubmit: (values) => {
            const peerGroup = peerGroupData.find(
                (peerGroup) => peerGroup.isin === values.peerGroup
            );

            if (isCreate) {
                const dataToCreate: CreateUser = {
                    email: values.email,
                    last_name: values.lastName,
                    name: values.name,
                    personal_id: values.personalId,
                    phone: '',
                    type: values.userType,
                    is_active: values.isActive,
                    is_superuser: values.isSuperUser,
                    roles: values.userRoles
                        ? values.userRoles.split(';')
                        : null,
                };
                if (values.userType === UserType.CLIENT) {
                    // dataToCreate.companies = companies;
                    dataToCreate.peer_group = {
                        isin: peerGroup?.isin,
                        _id: peerGroup?._id ?? '',
                        name: peerGroup?.name,
                    };
                }
                onSave(dataToCreate);
            } else {
                const dataToUpdate: UpdateUser = {
                    last_name: values.lastName,
                    name: values.name,
                    personal_id: values.personalId,
                    phone: '',
                    is_active: values.isActive,
                    is_superuser: values.isSuperUser,
                    roles: values.userRoles
                        ? values.userRoles.split(';')
                        : null,
                };
                if (values.userType === UserType.CLIENT) {
                    // dataToUpdate.companies = companies;
                    dataToUpdate.peer_group = {
                        isin: peerGroup?.isin,
                        _id: peerGroup?._id ?? '',
                        name: peerGroup?.name,
                    };
                }
                onSave(dataToUpdate);
            }
        },
    });

    const handleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
    };

    const handleMultipleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        const newValue = Array.isArray(value)
            ? value.length
                ? multipleSelectOptionsToString(value)
                : null
            : value;

        formik.setFieldValue(name, newValue);
    };

    type FormikTypes = typeof formik.initialValues;

    const handleMultipleDelete = (value: string, name: keyof FormikTypes) => {
        const newArray = stringToMultipleSelectOptions(
            formik.values[name] as string
        )?.filter((current) => {
            return current !== value;
        });
        const newValues = multipleSelectOptionsToString(newArray);

        formik.setFieldValue(name, newValues === '' ? null : newValues);
    };

    const userTypeOptions = EnumToMultipleDropdownOptions(UserType);
    const userRoleOptions = EnumToMultipleDropdownOptions(UserRoles);

    const peerGroupOptions = peerGroupData?.map((peerGroup) => ({
        value: peerGroup.isin,
        label: peerGroup.name,
    }));

    if (isLoading) {
        return <ScreenLoader />;
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'name',
                            'Name',
                            formName,
                            formik
                        )}
                        required
                    />
                    <CustomSelectField
                        options={userTypeOptions}
                        onChange={handleSelect}
                        {...generateSelectFieldProps<FormikTypes>(
                            'userType',
                            'User Type',
                            formName,
                            formik
                        )}
                        required
                        disabled={!isCreate}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'lastName',
                            'Last Name',
                            formName,
                            formik
                        )}
                        required
                    />
                    {formik.values.userType === 'EMPLOYEE' && (
                        <CustomMultipleSelectField
                            options={userRoleOptions}
                            onChange={handleMultipleSelect}
                            {...generateMultipleSelectFieldProps<FormikTypes>(
                                'userRoles',
                                'User Roles',
                                formName,
                                formik
                            )}
                            required
                            handleDelete={(value) =>
                                handleMultipleDelete(value, 'userRoles')
                            }
                        />
                    )}
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'email',
                            'Email',
                            formName,
                            formik
                        )}
                        required
                        disabled={!isCreate}
                    />
                    {/* {formik.values.userType === 'CLIENT' && (
                        <CustomMultipleSelectField
                            options={companyOptions}
                            onChange={handleMultipleSelect}
                            {...generateMultipleSelectFieldProps<FormikTypes>(
                                'companies',
                                'Companies',
                                formName,
                                formik
                            )}
                            handleDelete={(value) =>
                                handleMultipleDelete(value, 'companies')
                            }
                        />
                    )} */}
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'personalId',
                            'Personal ID',
                            formName,
                            formik
                        )}
                        required={false}
                    />
                    {formik.values.userType === 'CLIENT' && (
                        <CustomSelectField
                            options={peerGroupOptions}
                            onChange={handleSelect}
                            {...generateSelectFieldProps<FormikTypes>(
                                'peerGroup',
                                'Peer Group',
                                formName,
                                formik
                            )}
                        />
                    )}
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.isActive}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'isActive',
                                        !formik.values.isActive
                                    );
                                }}
                            />
                        }
                        label='Is Active'
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.isSuperUser}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'isSuperUser',
                                        !formik.values.isSuperUser
                                    );
                                }}
                            />
                        }
                        label='Is Super User'
                    />
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={'flex-end'}
                >
                    <Button
                        color='primary'
                        data-testid={`${formName}-cancel`}
                        variant='contained'
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        data-testid={`${formName}-submit`}
                        role='submit'
                        type='submit'
                        variant='contained'
                    >
                        Save
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};
