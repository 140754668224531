import { Button, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useCallback } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import { CustomAutoCompleteField } from 'components/shared/form/custom-autocomplete-field';
import {
    autocompleteFormikValueToOptions,
    optionsToAutoCompleteFormikValue,
} from 'utils/multiple-select-utils';
import {
    CreatePeerGroup,
    PeerGroup,
    UpdatePeerGroup,
} from 'api/hooks/peer-group/peerGroupTypes';
import { Company } from 'api/hooks/company/companyTypes';

type Props = {
    record: PeerGroup | null;
    isCreate: boolean;
    companiesData: Company[];
    onClose: () => void;
    onSave: (data: CreatePeerGroup | UpdatePeerGroup) => void;
};

export const PeerGroupForm: FC<Props> = ({
    record,
    onClose,
    onSave,
    isCreate,
    companiesData,
}) => {
    const formName = 'PeerGroupForm';

    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        isin: Yup.string().required('Required'),
        companies: Yup.string().required('Required'),
    });

    const formulateInitialCompanies = useCallback(() => {
        const initialCompanies = record?.companies?.map((companyIsin) => {
            const company = companiesData.find(
                (company) => company.isin === companyIsin
            );
            return {
                value: companyIsin,
                label: company?.name ?? '',
            };
        });

        return initialCompanies;
    }, [companiesData, record?.companies]);

    const formik = useFormik({
        initialValues: {
            name: record?.name ?? '',
            isin: record?.isin ?? '',
            companies: optionsToAutoCompleteFormikValue(
                formulateInitialCompanies() ?? []
            ),
        },
        validationSchema,
        onSubmit: (values) => {
            const request = {
                isin: values.isin,
                name: values.name,
                companies: autocompleteFormikValueToOptions(
                    values.companies
                ).map((value) => value.value),
            };
            if (isCreate) {
                const dataToCreate: CreatePeerGroup = request;
                onSave(dataToCreate);
            } else {
                const dataToUpdate: UpdatePeerGroup = request;
                onSave(dataToUpdate);
            }
        },
    });

    type FormikTypes = typeof formik.initialValues;

    const handleAutoCompleteSelect = (name: string, newValue: string) => {
        formik.setFieldValue(name, newValue);
    };

    const companies = companiesData.map((company) => ({
        value: company.isin,
        label: company.name,
    }));

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'name',
                            'Name',
                            formName,
                            formik
                        )}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'isin',
                            'ISIN',
                            formName,
                            formik
                        )}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomAutoCompleteField
                        options={companies}
                        onChange={(value) => {
                            handleAutoCompleteSelect('companies', value);
                        }}
                        value={formik.values.companies}
                        label={'Companies'}
                    />
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={'flex-end'}
                >
                    <Button
                        color='primary'
                        data-testid={`${formName}-cancel`}
                        variant='contained'
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        data-testid={`${formName}-submit`}
                        role='submit'
                        type='submit'
                        variant='contained'
                    >
                        Save
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};
