export const sdgQuestions = {
    Q1: {
        question: 'Does the company highlight the goal?',
        dropdownOptions: ['Yes', 'No'],
    },
    Q2: {
        question: 'Does the company align the goal to its company operations?',
        dropdownOptions: ['Fully', 'Partially', 'No'],
    },
    Q3: {
        question:
            'Does the company align the goal to the value chain (excluding company operations)?',
        dropdownOptions: ['Fully', 'Partially', 'No'],
    },
    Q4: {
        question: 'Does the company set a target for the goal?',
        dropdownOptions: ['UN SDG Target', 'Company Target', 'No'],
    },
    Q5: {
        question: 'Does the company disclose an indicator for the goal?',
        dropdownOptions: ['UN SDG Target', 'Company Target', 'No'],
    },
};
