import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FC } from 'react';
import { SDGQuestionGuide } from 'api/hooks/sgd-question/sdgQuestionsTypes';
import { ConfirmationDialog } from 'components/confirmation-dialog';
import { GuideTableHeader } from './guide-table-header';

type Props = {
    onDelete: (index: number) => void;
    data?: SDGQuestionGuide[];
};

export const GuideListTable: FC<Props> = ({ onDelete, data }) => {
    const handleDelete = (index: string) => {
        onDelete(parseInt(index));
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
                <GuideTableHeader />
                <TableBody>
                    {data
                        ?.sort((a, b) => a.order - b.order)
                        .map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell>{row.order}</TableCell>
                                <TableCell align='right'>{row.title}</TableCell>
                                <TableCell align='right'>{row.text}</TableCell>
                                <TableCell align='right'>
                                    <ConfirmationDialog
                                        id={index.toString()}
                                        onDelete={handleDelete}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
