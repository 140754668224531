import { TableBody } from '@mui/material';

import { FC } from 'react';
import { CustomTableRow } from './table-row';
import { Report } from 'api/hooks/report/reportTypes';

type Props = {
    data: Report[];
    regenerateReport: (report: Report) => void;
};

export const CustomTableBody: FC<Props> = ({ data, regenerateReport }) => {
    return (
        <>
            <TableBody>
                {data.map((row) => {
                    return (
                        <CustomTableRow
                            data={row}
                            key={row._id}
                            regenerateReport={regenerateReport}
                        />
                    );
                })}
            </TableBody>
        </>
    );
};
