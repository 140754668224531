import {
    Button,
    Checkbox,
    Chip,
    FormControlLabel,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import { Box } from '@mui/system';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
    CreateIndustry,
    Industry,
    UpdateIndustry,
} from 'api/hooks/industry/industryTypes';

type Props = {
    record: Industry | null;
    isCreate: boolean;
    onClose: () => void;
    onSave: (data: CreateIndustry | UpdateIndustry) => void;
};

export const IndustryForm: FC<Props> = ({
    record,
    onClose,
    onSave,
    isCreate,
}) => {
    const formName = 'IndustryForm';

    const validationSchema = Yup.object({
        name: Yup.string().required('Required'),
        code: Yup.string().required('Required'),
        subIndustries: Yup.string().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            name: record?.name ?? '',
            code: record?.code ?? '',
            newSubIndustry: '',
            subIndustries:
                (record?.sub_industries &&
                    record.sub_industries
                        .map((subIndustry) => subIndustry.code)
                        .join(';')) ??
                '',
            participatesInReports: record?.participates_in_reports ?? false,
        },
        validationSchema,
        onSubmit: (values) => {
            if (isCreate) {
                const dataToCreate: CreateIndustry = {
                    code: values.code,
                    name: values.name,
                    sub_industries: values.subIndustries
                        .split(';')
                        .map((subIndustry) => ({
                            code: subIndustry.toUpperCase(),
                            description: subIndustry,
                            name: subIndustry,
                            deleted: false,
                            deprecated: false,
                        })),
                    participates_in_reports: values.participatesInReports,
                };
                onSave(dataToCreate);
            } else {
                const dataToUpdate: UpdateIndustry = {
                    code: values.code,
                    name: values.name,
                    sub_industries: values.subIndustries
                        .split(';')
                        .map((subIndustry) => ({
                            code: subIndustry.toUpperCase(),
                            description: subIndustry,
                            name: subIndustry,
                            deleted: false,
                            deprecated: false,
                        })),
                    participates_in_reports: values.participatesInReports,
                };
                onSave(dataToUpdate);
            }
        },
    });

    const handleSubIndustryDelete = (subIndustryToSearch: string) => {
        const newSubIndustries = formik.values.subIndustries
            .split(';')
            .filter((subIndustry) => subIndustry !== subIndustryToSearch)
            .join(';');
        formik.setFieldValue('subIndustries', newSubIndustries);
    };

    const handleNewSubIndustryAdd = () => {
        const oldSubIndustries = formik.values.subIndustries
            ? formik.values.subIndustries.split(';')
            : [];
        const newSubIndustries = [
            ...oldSubIndustries,
            formik.values.newSubIndustry,
        ].join(';');
        formik.setFieldValue('subIndustries', newSubIndustries);
        formik.setFieldValue('newSubIndustry', '');
    };

    type FormikTypes = typeof formik.initialValues;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'name',
                            'Name',
                            formName,
                            formik
                        )}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'code',
                            'Code',
                            formName,
                            formik
                        )}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.participatesInReports}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'participatesInReports',
                                        !formik.values.participatesInReports
                                    );
                                }}
                            />
                        }
                        label='Participates In Reports'
                    />
                </Stack>

                <Typography
                    variant='h6'
                    component='div'
                    textAlign={'center'}
                    marginBottom={4}
                >
                    Sub Industries
                </Typography>
                <Stack direction={'row'} spacing={2}>
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'newSubIndustry',
                            'New Sub Industry',
                            formName,
                            formik
                        )}
                    />
                    <IconButton onClick={handleNewSubIndustryAdd}>
                        <AddCircleIcon fontSize='medium' color='primary' />
                    </IconButton>
                </Stack>
                <Stack>
                    {formik.values.subIndustries &&
                        formik.values.subIndustries
                            .split(';')
                            .map((subIndustry, index) => {
                                return (
                                    <Box
                                        display={'flex'}
                                        minWidth={'100%'}
                                        key={index}
                                    >
                                        <Chip
                                            label={subIndustry}
                                            sx={{
                                                m: 0.5,
                                                width: '100%',
                                            }}
                                        />
                                        <IconButton
                                            onClick={() =>
                                                handleSubIndustryDelete(
                                                    subIndustry
                                                )
                                            }
                                        >
                                            <DeleteIcon
                                                fontSize='small'
                                                color='primary'
                                            />
                                        </IconButton>
                                    </Box>
                                );
                            })}
                </Stack>

                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={'flex-end'}
                >
                    <Button
                        color='primary'
                        data-testid={`${formName}-cancel`}
                        variant='contained'
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        color='primary'
                        data-testid={`${formName}-submit`}
                        role='submit'
                        type='submit'
                        variant='contained'
                    >
                        Save
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};
