import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import {
    MainQuestionAssessmentAnswer,
    MainQuestionAssessmentFilters,
} from 'api/hooks/main-question-assessment/mainQuestionAssessmentTypes';
import { useGetMainQuestionAssessmentList } from 'api/hooks/main-question-assessment/useGetMainQuestionAssessmentList';
import { FC, useState } from 'react';
import { useParams } from 'react-router';
import {
    selectMainQuestionAssessmentFilters,
    setInitialMainQuestions,
    updateFilters,
} from 'store/slices/mainQuestionAssessmentSlice';
import { useAppDispatch, useAppSelector } from 'store/store';
import { EnumToDropdownOptions, multipleSelectOptionsToString } from 'utils';
import { useMainQuestionSave } from './use-main-question-save';
import { QaStatus } from 'api/hooks/commonTypes';
import { CustomMultipleSelectField } from 'components/shared/form/custom-multiple-select-field';

type Props = {};

export const MainQuestionAssessmentFilterSection: FC<Props> = () => {
    const { assessmentId } = useParams();
    const [isFormVisible, setIsFormVisible] = useState(false);
    const dispatch = useAppDispatch();
    const { save } = useMainQuestionSave();

    const filters = useAppSelector(selectMainQuestionAssessmentFilters);

    const { query } = useGetMainQuestionAssessmentList({
        assessmentId: assessmentId ?? '',
        enabled: false,
        onSuccess: (data) => {
            dispatch(setInitialMainQuestions(data));
        },
        filters: filters ?? {},
    });

    const toggleFormVisibility = () => {
        setIsFormVisible(!isFormVisible);
    };

    const handleInputChange = (
        propertyName: keyof MainQuestionAssessmentFilters,
        value: string | string[]
    ) => {
        const updatedFilters = { ...filters, [propertyName]: value };
        dispatch(updateFilters(updatedFilters));
    };

    const handleSearch = async () => {
        const isSuccess = await save();
        if (isSuccess) query.refetch();
    };

    const handleClear = () => {
        dispatch(updateFilters({}));
    };

    const handleMultipleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        const newValue = Array.isArray(value)
            ? value.length
                ? multipleSelectOptionsToString(value)
                : null
            : value;

        // formik.setFieldValue(name, newValue);
        const updatedFilters = { ...filters, [name]: newValue?.split(';') };
        dispatch(updateFilters(updatedFilters));
    };

    const handleMultipleDelete = (
        value: string,
        name: keyof MainQuestionAssessmentFilters
    ) => {
        const newArray = filters?.qa_status?.filter((current) => {
            return current !== value;
        });
        const updatedFilters = { ...filters, [name]: newArray };
        dispatch(updateFilters(updatedFilters));
    };

    const mainQuestionAssessmentAnswerOptions = EnumToDropdownOptions(
        MainQuestionAssessmentAnswer
    );

    const qaStatusAnswerOptions = EnumToDropdownOptions(QaStatus);

    return (
        <Box mt={2}>
            <Button
                variant='contained'
                color='primary'
                onClick={toggleFormVisibility}
            >
                {isFormVisible ? 'Hide Filters' : 'Show Filters'}
            </Button>
            {isFormVisible && (
                <Grid container spacing={2} mt={2}>
                    {/* <Grid item xs={3}>
                        <TextField
                            label='Assessment ID'
                            fullWidth
                            value={filters.assessment_id || ''}
                            onChange={(e) =>
                                handleInputChange(
                                    'assessment_id',
                                    e.target.value
                                )
                            }
                        />
                    </Grid> */}
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id='status'>Answer</InputLabel>
                            <Select
                                id='status'
                                label='Status'
                                value={filters?.answer || ''}
                                onChange={(e) =>
                                    handleInputChange('answer', e.target.value)
                                }
                            >
                                <MenuItem value=''>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='null'>
                                    <em>Blank</em>
                                </MenuItem>
                                {mainQuestionAssessmentAnswerOptions &&
                                    mainQuestionAssessmentAnswerOptions.map(
                                        (option) => (
                                            <MenuItem
                                                key={option.value}
                                                value={option.value}
                                            >
                                                {option.label || option.value}
                                            </MenuItem>
                                        )
                                    )}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            {/* <InputLabel id='status'>Qa Status</InputLabel> */}
                            <CustomMultipleSelectField
                                options={qaStatusAnswerOptions}
                                onChange={handleMultipleSelect}
                                // {...generateMultipleSelectFieldProps<FormikTypes>(
                                //     'dataFormat',
                                //     'Data Format',
                                //     formName,
                                //     formik
                                // )}
                                value={filters?.qa_status?.join(';')}
                                name='qa_status'
                                formName='MainQuestsionsForm'
                                label='QA Status'
                                errorText={''}
                                handleDelete={(value) =>
                                    handleMultipleDelete(value, 'qa_status')
                                }
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl fullWidth>
                            <InputLabel id='status'>Comments</InputLabel>
                            <Select
                                id='comments'
                                label='Comments'
                                value={filters?.has_comments ?? ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        'has_comments',
                                        e.target.value
                                    )
                                }
                            >
                                <MenuItem value=''>
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value='true'>
                                    <em>Yes</em>
                                </MenuItem>
                                <MenuItem value='false'>
                                    <em>No</em>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={3}>
                        <TextField
                            label='Question'
                            fullWidth
                            value={filters?.question || ''}
                            onChange={(e) =>
                                handleInputChange('question', e.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Unique ID (Separate with ;)'
                            fullWidth
                            value={(filters?.unique_id || []).join(';')}
                            onChange={(e) =>
                                handleInputChange(
                                    'unique_id',
                                    e.target.value.split(';')
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Identifier (Separate with ;)'
                            fullWidth
                            value={(filters?.identifier || []).join(';')}
                            onChange={(e) =>
                                handleInputChange(
                                    'identifier',
                                    e.target.value.split(';')
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Category (Separate with ;)'
                            fullWidth
                            value={(filters?.category || []).join(';')}
                            onChange={(e) =>
                                handleInputChange(
                                    'category',
                                    e.target.value.split(';')
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Sub Category First (Separate with ;)'
                            fullWidth
                            value={(filters?.sub_category_first || []).join(
                                ';'
                            )}
                            onChange={(e) =>
                                handleInputChange(
                                    'sub_category_first',
                                    e.target.value.split(';')
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Sub Category Second (Separate with ;)'
                            fullWidth
                            value={(filters?.sub_category_second || []).join(
                                ';'
                            )}
                            onChange={(e) =>
                                handleInputChange(
                                    'sub_category_second',
                                    e.target.value.split(';')
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label='Scoring Guidance'
                            fullWidth
                            value={filters?.scoring_guidance || ''}
                            onChange={(e) =>
                                handleInputChange(
                                    'scoring_guidance',
                                    e.target.value
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Box display={'flex'}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                handleInputChange(
                                                    'scoring_judgment',
                                                    'null'
                                                );
                                            } else {
                                                handleInputChange(
                                                    'scoring_judgment',
                                                    ''
                                                );
                                            }
                                        }}
                                    />
                                }
                                label='Check Null Scoring Judgement'
                            />
                            {/* <TextField
                                label='Scoring Judgment'
                                fullWidth
                                value={filters?.scoring_judgment || ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        'scoring_judgment',
                                        e.target.value
                                    )
                                }
                            /> */}
                        </Box>
                    </Grid>
                    <Grid item xs={3}>
                        <Box display={'flex'}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        onChange={(event) => {
                                            if (event.target.checked) {
                                                handleInputChange(
                                                    'reference_for_answer',
                                                    'null'
                                                );
                                            } else {
                                                handleInputChange(
                                                    'reference_for_answer',
                                                    ''
                                                );
                                            }
                                        }}
                                    />
                                }
                                label='Check Null Reference For Answer'
                            />
                            {/* <TextField
                                label='Reference For Answer'
                                fullWidth
                                value={filters?.reference_for_answer || ''}
                                onChange={(e) =>
                                    handleInputChange(
                                        'reference_for_answer',
                                        e.target.value
                                    )
                                }
                            /> */}
                        </Box>
                    </Grid>
                    <Grid item xs={9}></Grid>
                    <Grid item xs={1.5}>
                        <Button
                            variant='contained'
                            color='primary'
                            fullWidth
                            onClick={handleSearch}
                        >
                            Filter
                        </Button>
                    </Grid>
                    <Grid item xs={1.5}>
                        <Button
                            variant='outlined'
                            color='secondary'
                            fullWidth
                            onClick={handleClear}
                        >
                            Clear
                        </Button>
                    </Grid>
                </Grid>
            )}
        </Box>
    );
};
