import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { DataState } from 'store/enums';
import {
    MaterialityAssessment,
    MaterialityAssessmentListResponse,
} from 'api/hooks/materiality-assessment/materialityAssessmentTypes';
import { QaStatus } from 'api/hooks/commonTypes';

export interface MaterialityAssessmentState {
    table: {
        data: MaterialityAssessment;
        state: DataState;
        selected: boolean;
    }[];
    page: number;
    rowsPerPage: number;
}

const initialState: MaterialityAssessmentState = {
    table: [],
    page: 0,
    rowsPerPage: 100,
};

export const materialityAssessmentSlice = createSlice({
    name: 'materialityAssessment',
    initialState,
    reducers: {
        updatePage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        updateRowsPerPage: (state, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload;
        },
        setInitialMateriality: (
            state,
            action: PayloadAction<MaterialityAssessmentListResponse>
        ) => {
            const newTable = action.payload.map((materiality) => ({
                selected: false,
                data: materiality,
                state: DataState.INITIAL,
            }));
            state.table = newTable;
        },
        updateMateriality: (
            state,
            action: PayloadAction<{
                data: MaterialityAssessment;
                state?: DataState;
            }>
        ) => {
            const modifiedTable = state.table?.map((materiality) => {
                if (materiality.data._id === action.payload.data._id) {
                    return {
                        ...materiality,
                        data: action.payload.data,
                        state: action.payload.state ?? DataState.UPDATED,
                    };
                }
                return materiality;
            });
            state.table = modifiedTable;
        },
        updateQaStatus: (
            state,
            action: PayloadAction<{
                _id: string;
                qaStatus: QaStatus;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        data: {
                            ...el.data,
                            qa_status: action.payload.qaStatus,
                        },
                        state: DataState.UPDATED,
                        selected: false,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
        toggleSelected: (
            state,
            action: PayloadAction<{
                _id: string;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        selected: !el.selected,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
    },
});

export const {
    setInitialMateriality,
    updateMateriality,
    updatePage,
    updateRowsPerPage,
    toggleSelected,
    updateQaStatus,
} = materialityAssessmentSlice.actions;

export const selectMaterialityAssessment = (state: RootState) =>
    state.materialityAssessment;

export const selectMaterialityAssessmentTableData = (state: RootState) =>
    state.materialityAssessment.table.map((el) => ({
        data: el.data,
        isSelected: el.selected,
    }));

export const selectMaterialityAssessmentTable = (state: RootState) =>
    state.materialityAssessment.table;

export const selectMaterialityAssessmentPageData = (state: RootState) => ({
    page: state.materialityAssessment.page,
    rowsPerPage: state.materialityAssessment.rowsPerPage,
});

export default materialityAssessmentSlice.reducer;
