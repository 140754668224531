import { authApi } from 'lib';
import {
    CompaniesForSelectsResponse,
    CompanyListResponse,
    CompanyResponse,
    CreateUpdateCompany,
} from './companyTypes';

export const getCompanyList = async () => {
    const response = await authApi.get<CompanyListResponse>(
        '/api/v1/companies'
    );
    return response.data;
};

export const getCompanyById = async (id: string) => {
    const response = await authApi.get<CompanyResponse>(
        `/api/v1/companies/${id}`
    );
    return response.data;
};

export const getCompaniesForSelects = async () => {
    const response = await authApi.get<CompaniesForSelectsResponse>(
        `/api/v1/companies/for_selects`
    );
    return response.data;
};

export const createCompany = async (request: CreateUpdateCompany) => {
    const response = await authApi.post<CompanyResponse>(
        `/api/v1/companies`,
        request
    );
    return response.data;
};

export const updateCompany = async (
    id: string,
    request: CreateUpdateCompany
) => {
    const response = await authApi.put<CompanyResponse>(
        `/api/v1/companies/${id}`,
        request
    );
    return response.data;
};
