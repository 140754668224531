import { Backdrop, CircularProgress } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { useAppSelector } from 'store/store';
import { selectMainQuestionAssessment } from './slices/mainQuestionAssessmentSlice';
import { useParams } from 'react-router';
import { selectMaterialityAssessment } from './slices/materialityAssessmentSlice';
import { selectSDGAssessment } from './slices/sdgAssessmentSlice';
import { selectControversiesAssessment } from './slices/controversiesAssessmentSlice';
import { FloatingSaveButton } from 'components/floating-save-button/floating-save-button';
import { selectCompanyPerformanceAssessment } from './slices/companyPerformanceAssessmentSlice';
import { useCheckAssessmentStatus } from 'api/hooks/common/useCheckAssessmentStatus';
import { AssessmentStatus } from 'api/hooks/assessment/assessmentTypes';
import { debounce } from 'lodash';
import { useSaveState } from './saveState';

export interface AutoSaveRef {
    save: () => Promise<void>;
}

const DEBOUNCE_SAVE_DELAY_MS = 20 * 60 * 6000;
// const DEBOUNCE_SAVE_DELAY_MS = 2000;

export const AutoSave = forwardRef<AutoSaveRef, {}>((props, ref) => {
    const { assessmentId } = useParams();
    const { save } = useSaveState();
    const assessmentStatus = useCheckAssessmentStatus({
        assessmentId: assessmentId ?? '',
        currentStatuses: [
            AssessmentStatus.ANALYSIS_IN_PROGRESS,
            AssessmentStatus.ASSURANCE_IN_PROGRESS,
        ],
    });
    const [isSaving] = useState(false);
    const mainQuestionAssessment = useAppSelector(selectMainQuestionAssessment);
    const materialityAssessment = useAppSelector(selectMaterialityAssessment);
    const sdgAssessment = useAppSelector(selectSDGAssessment);
    const controversiesAssessment = useAppSelector(
        selectControversiesAssessment
    );
    const companyPerformanceAssessment = useAppSelector(
        selectCompanyPerformanceAssessment
    );

    useEffect(() => {
        const deb = debounce(() => {
            save();
        }, DEBOUNCE_SAVE_DELAY_MS);
        deb();

        return () => {
            deb.cancel();
        };
    }, [save]);

    useEffect(() => {
        if (ref) {
            (ref as React.MutableRefObject<AutoSaveRef>).current = {
                save: async () => {
                    await save();
                },
            };
        }
    }, [
        ref,
        mainQuestionAssessment,
        materialityAssessment,
        sdgAssessment,
        controversiesAssessment,
        companyPerformanceAssessment,
        save,
    ]);

    if (assessmentStatus.isReadOnly) {
        return null;
    }

    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isSaving}
            >
                <CircularProgress color='inherit' />
            </Backdrop>
            <FloatingSaveButton onClick={() => save()} />
        </>
    );
});
