import { FC, memo, FocusEvent } from 'react';
import { FieldInputProps } from 'formik';
import { InputBaseProps, TextField, TextFieldProps } from '@mui/material';

type Props = TextFieldProps & {
    formName?: string;
    lengthCounter?: number;
    type?: string;
    isTouched?: boolean | undefined;
    errorText?: string | string[] | undefined;
    inputProps?: InputBaseProps['inputProps'];
    fieldProps?: FieldInputProps<string | number>;
    disabled?: boolean;
};

export const CustomTextField: FC<Props> = memo(
    ({
        name,
        formName,
        label,
        onChange,
        lengthCounter,
        type,
        value,
        isTouched,
        errorText,
        inputProps,
        fieldProps,
        disabled,
        required = false,
        onBlur,
        ...otherProps
    }) => {
        const helperText = isTouched && errorText;
        const labelProps =
            inputProps?.type === 'number' ? { shrink: true } : {};

        const onBlurComposition = (e: FocusEvent<HTMLInputElement>) => {
            fieldProps?.onBlur(e);
            if (onBlur) onBlur(e);
        };

        return (
            <TextField
                {...otherProps}
                fullWidth
                InputLabelProps={labelProps}
                disabled={disabled}
                error={isTouched && Boolean(errorText)}
                helperText={
                    lengthCounter ? (
                        <>
                            <span>{`${
                                String(value).length
                            } / ${lengthCounter}`}</span>
                        </>
                    ) : (
                        helperText
                    )
                }
                id={name}
                {...fieldProps}
                inputProps={{
                    'data-testid': `${formName}-${name}`,
                    ...inputProps,
                }}
                label={label}
                value={value}
                variant='outlined'
                type={type}
                required={required}
                onBlur={onBlurComposition}
                onChange={onChange}
            />
        );
    }
);
