import { FC } from 'react';
import { useGetMe } from 'api/hooks';
import { ScreenLoader } from 'components/shared/screen-loader';
import { AssessmentList } from 'components/assessment/assessment-list';
import { QueryBuilder } from 'features/query-builder';
import { UserType } from 'api/hooks/user/userTypes';

export const Dashboard: FC = () => {
    const { data: user, isLoading } = useGetMe({});
    // const user = useSelector(selectAuthUser);

    if (isLoading) {
        return <ScreenLoader />;
    }

    const dashboardComponent =
        user?.type === UserType.EMPLOYEE ? (
            <AssessmentList />
        ) : user?.type === UserType.CLIENT ? (
            <QueryBuilder />
        ) : null;

    return dashboardComponent ? dashboardComponent : null;
};
