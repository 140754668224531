import {
    Box,
    Button,
    Checkbox,
    IconButton,
    TableBody,
    TableCell,
    TableRow,
    TextField,
} from '@mui/material';

import { ChangeEvent, FC } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { ControversyAssessment } from 'api/hooks/controversy-assessment/controversyAssessmentTypes';
import ChatIcon from '@mui/icons-material/Chat';
import { QaStatusComponent } from '../qa-status';
import { QaStatus } from 'api/hooks/commonTypes';

type Props = {
    data: {
        data: ControversyAssessment;
        isSelected: boolean;
    }[];
    onChange: (updatedData: ControversyAssessment) => void;
    onDelete: (id: string) => void;
    handleOpenChat: (id: string) => void;
    handleStatusChange: (
        qaStatus: QaStatus,
        row: ControversyAssessment
    ) => void;
    isReadonly: boolean;
    onCheck: (id: string) => void;
};

const Details: FC<{
    data: ControversyAssessment;
}> = ({ data }) => {
    return (
        <>
            <Box>
                <Box display={'flex'}>
                    <Box fontWeight={'bold'}>Score:</Box>
                    {data?.score_reduction}
                </Box>
                <Box display={'flex'}>
                    <Box fontWeight={'bold'}>Adjusted Score:</Box>
                    {data?.adjusted_score}
                </Box>
            </Box>
        </>
    );
};

export const CustomTableBody: FC<Props> = ({
    data,
    onChange,
    onDelete,
    handleOpenChat,
    handleStatusChange,
    isReadonly,
    onCheck,
}) => {
    return (
        <>
            <TableBody>
                {data.map(({ data: row, isSelected }, rowIndex) => {
                    const handleChange = (
                        event: ChangeEvent<HTMLInputElement>
                    ) => {
                        const { name, value } = event.target;

                        let modifiedValue = value;
                        if (name === 'score') {
                            const score =
                                parseInt(value) > 100
                                    ? '100'
                                    : parseInt(value) < 0
                                    ? '0'
                                    : value;
                            modifiedValue = score;
                        }
                        const updatedRow = { ...row, [name]: modifiedValue };
                        onChange(updatedRow);
                    };

                    const handleArticleDateChange = (newValue: Date | null) => {
                        if (!newValue) return;

                        const modifiedArticleDate =
                            moment(newValue).format('YYYY-MM-DD');

                        const updatedRow = {
                            ...row,
                            article_date: modifiedArticleDate,
                        };
                        onChange(updatedRow);
                    };

                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component='th' scope='row'>
                                <Checkbox
                                    disabled={!row.for_qa}
                                    checked={isSelected}
                                    value={isSelected}
                                    onChange={() => onCheck(row._id)}
                                />
                            </TableCell>
                            <TableCell component='th' scope='row'>
                                {rowIndex}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                                {Details({ data: row })}
                            </TableCell>
                            <TableCell>
                                <TextField
                                    name='incident'
                                    value={row.incident}
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    disabled={isReadonly}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={row.incident_description}
                                    name='incident_description'
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    disabled={isReadonly}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={row.link}
                                    name='link'
                                    onChange={handleChange}
                                    multiline
                                    rows={4}
                                    fullWidth
                                    disabled={isReadonly}
                                />
                            </TableCell>
                            <TableCell>
                                <DesktopDatePicker
                                    inputFormat='DD/MM/YYYY'
                                    value={
                                        row.article_date
                                            ? new Date(row.article_date)
                                            : null
                                    }
                                    onChange={(newValue) => {
                                        handleArticleDateChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant='outlined'
                                            // sx={{ width: 200 }}
                                        />
                                    )}
                                    disabled={isReadonly}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    value={row.score}
                                    name='score'
                                    onChange={handleChange}
                                    // sx={{ width: 100 }}
                                    // fullWidth
                                    disabled={isReadonly}
                                    InputProps={{
                                        inputProps: { min: 0, max: 100 },
                                    }}
                                />
                            </TableCell>
                            <TableCell>{row?.qa_status}</TableCell>
                            <TableCell align='right'>
                                <Button
                                    onClick={() => onDelete(row._id)}
                                    disabled={isReadonly}
                                >
                                    Delete
                                </Button>
                            </TableCell>
                            <TableCell>
                                <IconButton
                                    onClick={() => handleOpenChat(row._id)}
                                >
                                    <ChatIcon
                                        fontSize='large'
                                        color={
                                            row.has_comments
                                                ? 'success'
                                                : 'primary'
                                        }
                                    />
                                </IconButton>
                            </TableCell>
                            <TableCell>
                                {!isReadonly && (
                                    <QaStatusComponent
                                        for_qa={row.for_qa}
                                        qa_status={row.qa_status}
                                        handleClick={(qaStatus) =>
                                            handleStatusChange(qaStatus, row)
                                        }
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </>
    );
};
