import {
    Box,
    Collapse,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { FC, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SyncIcon from '@mui/icons-material/Sync';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { Import, Status } from 'api/hooks/import/importType';

type Props = {
    data: Import;
};

export const CustomTableRow: FC<Props> = ({ data }) => {
    const [open, setOpen] = useState(false);

    const convertDateTimeToString = (datetime: string | Date = '') => {
        return (
            datetime &&
            `${new Date(datetime).toLocaleDateString()} ${new Date(
                datetime
            ).toLocaleTimeString()}`
        );
    };

    const getStatusIconByStatus = (status: Status | undefined) => {
        switch (status) {
            case Status.FAILED:
                return <ErrorIcon color='error' />;
            case Status.COMPLETED:
                return <CheckCircleIcon color='success' />;
            case Status.IN_PROGRESS:
                return <SyncIcon color='primary' />;
            case Status.IMPORT_PARTIALLY_COMPLETED:
                return <HourglassBottomIcon color='primary' />;
            default:
                return;
        }
    };

    return (
        <>
            <TableRow
                key={data._id}
                sx={{
                    '&:last-child td, &:last-child th': {
                        border: 0,
                    },
                }}
            >
                <TableCell>
                    {data.results && (
                        <IconButton
                            aria-label='expand row'
                            size='small'
                            onClick={() => setOpen(!open)}
                        >
                            {open ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    )}
                </TableCell>
                <TableCell>{`${data.user?.name} ${data.user?.last_name}`}</TableCell>
                <TableCell align='right'>{data.type}</TableCell>
                <TableCell align='right'>
                    {getStatusIconByStatus(data.status)}
                </TableCell>
                <TableCell align='right'>
                    {convertDateTimeToString(data.start_datetime)}
                </TableCell>
                <TableCell align='right'>
                    {convertDateTimeToString(data.end_datetime)}
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={6}
                >
                    {data.results && (
                        <Collapse in={open} timeout='auto' unmountOnExit>
                            <Box sx={{ margin: 1 }}>
                                <Typography
                                    variant='h6'
                                    gutterBottom
                                    component='div'
                                >
                                    Results
                                </Typography>
                                <Table size='small' aria-label='purchases'>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Identifier</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Assessment</TableCell>
                                            <TableCell>Main Question</TableCell>
                                            <TableCell>SDG</TableCell>
                                            <TableCell>
                                                Company Performance
                                            </TableCell>
                                            <TableCell>Materiality</TableCell>
                                            <TableCell>Company</TableCell>
                                            <TableCell>Controverse</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {data.results?.map((result, index) => (
                                            <TableRow
                                                key={`${
                                                    data._id
                                                }-${result.identifier.join(
                                                    '-'
                                                )}`}
                                            >
                                                <TableCell
                                                    component='th'
                                                    scope='row'
                                                >
                                                    {result.identifier.join(
                                                        ' / '
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {result.status}
                                                </TableCell>
                                                <TableCell>
                                                    {JSON.stringify(
                                                        result.information
                                                            .assessment
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {JSON.stringify(
                                                        result.information
                                                            .main_question
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {JSON.stringify(
                                                        result.information.sdg
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {JSON.stringify(
                                                        result.information
                                                            .company_performance
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {JSON.stringify(
                                                        result.information
                                                            .materiality
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {JSON.stringify(
                                                        result.information
                                                            .company
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    {JSON.stringify(
                                                        result.information
                                                            .controversies
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    )}
                </TableCell>
            </TableRow>
        </>
    );
};
