import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    SelectChangeEvent,
    Stack,
    TextField,
} from '@mui/material';
import { FC, useEffect } from 'react';
import { useParams } from 'react-router';
import { useGetAnalystAndAssurer } from 'api/hooks/assessment/useGetAnalystAndAssurer';
import { useUpdateAnalystAndAssurer } from 'api/hooks/assessment/useUpdateAnalystAndAssurer';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { dateToAPIDate } from 'utils/common';
import {
    CustomSelectField,
    generateSelectFieldProps,
} from 'components/shared/form/custom-select-field';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useGetUsersForSelects } from 'api/hooks/user/useGetUsersForSelects';
import { RoleType } from 'api/hooks/user/userTypes';

type AnalystAndAssurerDialogComponentProps = {
    assessmentId: string;
    open: boolean;
    setOpen: (isOpen: boolean) => void;
};

const AnalystAndAssurerDialogComponent: FC<
    AnalystAndAssurerDialogComponentProps
> = ({ open, setOpen, assessmentId }) => {
    // const { data: users, isLoading: usersLoading } = useGetUsers({});
    const { data: analysts, isLoading: analystsLoading } =
        useGetUsersForSelects({
            filters: { roles: [RoleType.ANALYST] },
        });
    const { data: assurers, isLoading: assurersLoading } =
        useGetUsersForSelects({
            filters: { roles: [RoleType.ASSURER] },
        });
    const {
        data: analystAndAssurerData,
        isLoading: analystAndAssurerDataLoading,
    } = useGetAnalystAndAssurer({
        assessmentId: assessmentId ?? '',
    });

    const { mutateAsync: updateAnalystAndAssurer } = useUpdateAnalystAndAssurer(
        {}
    );

    const formName = 'AnalystAndAssurerEditForm';
    const validationSchema = Yup.object({
        analyst: Yup.string().required('Required'),
        assurer: Yup.string().required('Required'),
        assessment_deadline: Yup.string().required('Required'),
        assurance_deadline: Yup.string().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            analyst: analystAndAssurerData?.analyst,
            assurer: analystAndAssurerData?.assurer,
            assessment_deadline:
                analystAndAssurerData?.assessment_deadline?.toString(),
            assurance_deadline:
                analystAndAssurerData?.assurance_deadline?.toString(),
        },
        validationSchema,
        onSubmit: async (data) => {
            await updateAnalystAndAssurer({
                id: assessmentId,
                request: {
                    analyst: data.analyst ?? '',
                    assurer: data.assurer ?? '',
                    assessment_deadline: dateToAPIDate(
                        new Date(data.assessment_deadline ?? '')
                    ),
                    assurance_deadline: dateToAPIDate(
                        new Date(data.assurance_deadline ?? '')
                    ),
                },
            });
            setOpen(false);
        },
    });
    type FormikTypes = typeof formik.initialValues;

    useEffect(() => {
        formik.setValues({
            analyst: analystAndAssurerData?.analyst,
            assurer: analystAndAssurerData?.assurer,
            assessment_deadline:
                analystAndAssurerData?.assessment_deadline?.toString(),
            assurance_deadline:
                analystAndAssurerData?.assurance_deadline?.toString(),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        analystAndAssurerData?.analyst,
        analystAndAssurerData?.assessment_deadline,
        analystAndAssurerData?.assurance_deadline,
        analystAndAssurerData?.assurer,
    ]);

    const handleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
    };

    const analystOptions = analysts?.map((analyst) => ({
        value: analyst._id,
        label: analyst.name,
    }));

    const assurerOptions = assurers?.map((assurer) => ({
        value: assurer._id,
        label: assurer.name,
    }));

    const isLoading =
        analystAndAssurerDataLoading || analystsLoading || assurersLoading;

    if (isLoading) {
        return null;
    }

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            fullWidth
            maxWidth='sm'
        >
            <DialogTitle>Analyst/Assurer Edit</DialogTitle>
            <DialogContent>
                <Stack spacing={4} p={2}>
                    <Stack direction={'row'} spacing={2}>
                        <CustomSelectField
                            options={analystOptions}
                            onChange={handleSelect}
                            {...generateSelectFieldProps<FormikTypes>(
                                'analyst',
                                'Analyst',
                                formName,
                                formik
                            )}
                        />
                        <CustomSelectField
                            options={assurerOptions}
                            onChange={handleSelect}
                            {...generateSelectFieldProps<FormikTypes>(
                                'assurer',
                                'QA',
                                formName,
                                formik
                            )}
                        />
                    </Stack>

                    <Stack direction={'row'} spacing={2}>
                        <DesktopDatePicker
                            label='Analyst Deadline'
                            inputFormat='DD/MM/YYYY'
                            value={formik.values.assessment_deadline ?? null}
                            onChange={(newValue) => {
                                formik.setFieldValue(
                                    'assessment_deadline',
                                    newValue
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant='outlined'
                                />
                            )}
                            disablePast
                        />
                        <DesktopDatePicker
                            label='QA Dead Line'
                            inputFormat='DD/MM/YYYY'
                            value={formik.values.assurance_deadline ?? null}
                            onChange={(newValue) => {
                                formik.setFieldValue(
                                    'assurance_deadline',
                                    newValue
                                );
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant='outlined'
                                />
                            )}
                            disablePast
                            minDate={formik.values.assessment_deadline}
                        />
                    </Stack>

                    <Stack
                        direction={'row'}
                        spacing={2}
                        justifyContent={'flex-end'}
                    >
                        <Button
                            variant='outlined'
                            onClick={() => setOpen(false)}
                        >
                            Close
                        </Button>

                        <Button
                            variant='contained'
                            onClick={() => formik.submitForm()}
                        >
                            Save
                        </Button>
                    </Stack>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

type AnalystAndAssurerEditProps = {
    open: boolean;
    setOpen: (isOpen: boolean) => void;
};

export const AnalystAndAssurerEdit: FC<AnalystAndAssurerEditProps> = ({
    open,
    setOpen,
}) => {
    const { assessmentId } = useParams();

    return (
        <>
            <AnalystAndAssurerDialogComponent
                open={open}
                setOpen={setOpen}
                assessmentId={assessmentId ?? ''}
            />
        </>
    );
};
