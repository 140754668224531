import { Button, Stack, TextField } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import {
    CustomSelectField,
    generateSelectFieldProps,
} from 'components/shared/form/custom-select-field';
import { EnumToDropdownOptions } from 'utils/enumHelper';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { UsersForSelectsResponse } from 'api/hooks/user/userTypes';
import { Company } from 'api/hooks/company/companyTypes';
import {
    AssuranceType,
    CreateUpdateGeneralInformation,
    GeneralInformation,
} from 'api/hooks/assessment/assessmentTypes';
import * as Yup from 'yup';
import { dateToAPIDate } from 'utils/common';
import {
    CustomAutoCompleteComboBoxField,
    OptionValue,
} from 'components/shared/form/custom-autocomplete-combobox-field';

type Props = {
    generalInformation?: GeneralInformation;
    assurers: UsersForSelectsResponse;
    analysts: UsersForSelectsResponse;
    companies: Company[];
    isReadOnly?: boolean;
    onCancel?: () => void;
    onSave?: (
        request: CreateUpdateGeneralInformation,
        needToContinueFlow: boolean
    ) => void;
};

export const AssessmentGeneralInformationForm: FC<Props> = ({
    generalInformation,
    assurers,
    analysts,
    companies,
    isReadOnly = false,
    onCancel,
    onSave,
}) => {
    const [isSave, setIsSave] = useState(false);

    const formName = 'GeneralInformationForm';
    const validationSchema = Yup.object({
        analyst: Yup.string().required('Required'),
        assurer: Yup.string().required('Required'),
        fiscal_year: Yup.number().required('Required'),
        assessment_deadline: Yup.string().required('Required'),
        company: Yup.string().required('Required'),
        assurance_deadline: Yup.string().required('Required'),
        assurance_type: Yup.string().required('Required'),
    });
    const formik = useFormik({
        initialValues: {
            analyst: generalInformation?.analyst,
            assurer: generalInformation?.assurer,
            fiscal_year: generalInformation?.fiscal_year?.toString(),
            assessment_deadline:
                generalInformation?.assessment_deadline?.toString(),
            company: generalInformation?.company,
            assurance_deadline:
                generalInformation?.assurance_deadline?.toString(),
            assurance_type: generalInformation?.assurance_type,
        },
        validationSchema,
        onSubmit: (data) => {
            onSave &&
                onSave(
                    {
                        analyst: data.analyst ?? '',
                        assessment_deadline: dateToAPIDate(
                            new Date(data.assessment_deadline ?? '')
                        ),
                        assurance_deadline: dateToAPIDate(
                            new Date(data.assurance_deadline ?? '')
                        ),
                        assurance_type: data.assurance_type ?? '',
                        assurer: data.assurer ?? '',
                        company: data.company ?? '',
                        fiscal_year: parseInt(data.fiscal_year ?? ''),
                    },
                    !isSave
                );
        },
    });
    type FormikTypes = typeof formik.initialValues;

    const handleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
    };

    const handleAutoCompleteComboBoxSelect = (
        name: string,
        value: OptionValue
    ) => {
        formik.setFieldValue(name, value.value);
    };

    const handleSave = () => {
        setIsSave(true);
        formik.submitForm();
    };

    const handleSaveAndContinue = () => {
        setIsSave(false);
        formik.submitForm();
    };

    const analystOptions = analysts.map((analyst) => ({
        value: analyst._id,
        label: `${analyst.name} ${analyst.last_name}`,
    }));

    const assurerOptions = assurers.map((assurer) => ({
        value: assurer._id,
        label: `${assurer.name} ${assurer.last_name}`,
    }));
    const companyOptions = companies.map((company) => ({
        value: company.isin,
        label: company.name,
    }));

    const qaTypeOptions = EnumToDropdownOptions(AssuranceType);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={4}>
                <Stack direction={'row'} spacing={2}>
                    <CustomSelectField
                        options={analystOptions}
                        onChange={handleSelect}
                        {...generateSelectFieldProps<FormikTypes>(
                            'analyst',
                            'Analyst',
                            formName,
                            formik
                        )}
                        disabled={isReadOnly}
                    />
                    <CustomAutoCompleteComboBoxField
                        options={companyOptions}
                        onChange={(value) =>
                            handleAutoCompleteComboBoxSelect('company', value)
                        }
                        value={formik.values.company ?? ''}
                        label='Company'
                    />
                    {/* <CustomSelectField
                        options={companyOptions}
                        onChange={handleSelect}
                        {...generateSelectFieldProps<FormikTypes>(
                            'company',
                            'Company',
                            formName,
                            formik
                        )}
                        disabled={isReadOnly}
                    /> */}
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomSelectField
                        options={assurerOptions}
                        onChange={handleSelect}
                        {...generateSelectFieldProps<FormikTypes>(
                            'assurer',
                            'QA',
                            formName,
                            formik
                        )}
                        disabled={isReadOnly}
                    />
                    <CustomTextField
                        {...generateTextFieldProps<FormikTypes>(
                            'fiscal_year',
                            'Fiscal Year',
                            formName,
                            formik
                        )}
                        disabled={isReadOnly}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <DesktopDatePicker
                        label='Analyst Deadline'
                        inputFormat='DD/MM/YYYY'
                        value={formik.values.assessment_deadline ?? null}
                        onChange={(newValue) => {
                            formik.setFieldValue(
                                'assessment_deadline',
                                newValue
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant='outlined'
                            />
                        )}
                        disabled={isReadOnly}
                        disablePast
                    />
                    <CustomSelectField
                        options={qaTypeOptions}
                        onChange={handleSelect}
                        {...generateSelectFieldProps<FormikTypes>(
                            'assurance_type',
                            'QA Type',
                            formName,
                            formik
                        )}
                        disabled={isReadOnly}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <DesktopDatePicker
                        label='QA Dead Line'
                        inputFormat='DD/MM/YYYY'
                        value={formik.values.assurance_deadline ?? null}
                        onChange={(newValue) => {
                            formik.setFieldValue(
                                'assurance_deadline',
                                newValue
                            );
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                fullWidth
                                variant='outlined'
                            />
                        )}
                        disabled={isReadOnly}
                        disablePast
                        minDate={formik.values.assessment_deadline}
                    />
                    <div style={{ width: '100%' }} />
                </Stack>
                {!isReadOnly && (
                    <Stack
                        direction={'row'}
                        spacing={2}
                        justifyContent={'flex-end'}
                    >
                        <Button variant='outlined' onClick={onCancel}>
                            Close
                        </Button>

                        <Button variant='contained' onClick={handleSave}>
                            Save
                        </Button>
                        <Button
                            variant='contained'
                            onClick={handleSaveAndContinue}
                        >
                            Save and Continue
                        </Button>
                    </Stack>
                )}
            </Stack>
        </form>
    );
};
