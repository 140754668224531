import { FormikProps } from 'formik';

export const generateSelectFieldProps = <T>(
    name: keyof T,
    label: string,
    formName: string,
    formik: Partial<FormikProps<T>> &
        Pick<FormikProps<T>, 'values' | 'touched' | 'errors'>,
    helperText?: string
) => ({
    formName: formName,
    label: label,
    name: name,
    value: formik.values && formik.values[name],
    isTouched: formik.touched[name],
    errorText: formik.errors[name],
    helperText: helperText,
});
