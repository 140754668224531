import { authApi } from 'lib';
import { ImportListResponse, ImportResponse } from './importType';

export const getImportList = async () => {
    const response = await authApi.get<ImportListResponse>('/api/v1/imports');
    return response.data;
};

export const getImportById = async (id: string) => {
    const response = await authApi.get<ImportResponse>(`/api/v1/imports/${id}`);
    return response.data;
};

export const createImportAssessment = async (formData: FormData) => {
    const response = await authApi.post<ImportResponse>(
        '/api/v1/imports/assessments',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return response.data;
};

export const createImportCompanyPerformanceReportData = async (
    formData: FormData
) => {
    const response = await authApi.post<ImportResponse>(
        '/api/v1/imports/company_performance_report_data',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return response.data;
};

export const createImportCompanyReportData = async (formData: FormData) => {
    const response = await authApi.post<ImportResponse>(
        '/api/v1/imports/pdfs',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return response.data;
};

export const createImportUser = async (formData: FormData) => {
    const response = await authApi.post<ImportResponse>(
        '/api/v1/imports/users',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
    );
    return response.data;
};
