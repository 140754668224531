import { useCallback } from 'react';
import { useMainQuestionSave } from 'components/assessment/assessment-analyst/main-question-assessment/use-main-question-save';
import { useCompanyPerformanceSave } from 'components/assessment/assessment-analyst/company-performance-assessment/use-company-performance-save';
import { useControversyAssessmentnSave } from 'components/assessment/assessment-analyst/controversy-assessment/use-controversy-assessment-save';
import { useMaterialityAssessmentSave } from 'components/assessment/assessment-analyst/materiality-assessment/use-materiality-assessment-save';
import { useSDGAssessmentSave } from 'components/assessment/assessment-analyst/sdg-assessment/use-sdg-assessment-save';

export const useSaveState = () => {
    const { save: saveMainQuestions } = useMainQuestionSave();
    const { save: saveCompanyPerformance } = useCompanyPerformanceSave();
    const { save: saveControversies } = useControversyAssessmentnSave();
    const { save: saveMaterialities } = useMaterialityAssessmentSave();
    const { save: saveSDGs } = useSDGAssessmentSave();

    const save = useCallback(async () => {
        await saveMainQuestions();
        await saveCompanyPerformance();
        await saveControversies();
        await saveMaterialities();
        await saveSDGs();
    }, [
        saveMainQuestions,
        saveCompanyPerformance,
        saveControversies,
        saveMaterialities,
        saveSDGs,
    ]);

    return { save };
};
