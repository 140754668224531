import { ChangeEventHandler } from 'react';
import { FormikProps } from 'formik';

export const generateTextFieldProps = <T>(
    name: keyof T,
    label: string,
    formName: string,
    formik: Partial<FormikProps<T>> &
        Pick<FormikProps<T>, 'values' | 'touched' | 'errors'>,
    onChange?: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
) => ({
    errorText: formik.errors[name],
    formName: formName,
    isTouched: formik.touched[name],
    label: label,
    name: name,
    value: formik.values && formik.values[name],
    onChange: onChange || formik.handleChange,
    fieldProps: formik.getFieldProps ? formik.getFieldProps(name) : undefined,
});
