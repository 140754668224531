import { Box, Button, TextField } from '@mui/material';
import { FC } from 'react';

type Props = {
    onCreateNewCompanyClick: () => void;
};

export const CompanyListTableActions: FC<Props> = ({
    onCreateNewCompanyClick,
}) => {
    return (
        <Box justifyContent={'space-between'} display={'flex'}>
            <Button variant='outlined' onClick={onCreateNewCompanyClick}>
                Create new company
            </Button>
            <TextField label='Search' variant='outlined' />
        </Box>
    );
};
