import { useQuery } from 'react-query';
import {
    MainQuestionAssessmentFilters,
    MainQuestionAssessmentListResponse,
} from './mainQuestionAssessmentTypes';
import { getMainQuestionAssessmentList } from './mainQuestionAssessmentService';

type Props<M> = {
    filters: MainQuestionAssessmentFilters;
    assessmentId: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: MainQuestionAssessmentListResponse) => void;
    mapper?: (
        data?: MainQuestionAssessmentListResponse
    ) => M | MainQuestionAssessmentListResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: MainQuestionAssessmentListResponse) => data;

export const useGetMainQuestionAssessmentList = <
    M = MainQuestionAssessmentListResponse
>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    assessmentId,
    filters,
}: Props<M>) => {
    const query = useQuery<MainQuestionAssessmentListResponse, Error>(
        ['mainQuestionAssessments', assessmentId],
        () => getMainQuestionAssessmentList(assessmentId, filters),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
