import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton, { StepButtonProps } from '@mui/material/StepButton';
import { useGetAssessmentStatus } from 'api/hooks/assessment/useGetAssessmentStatus';
import { useNavigate, useParams } from 'react-router';
import {
    AssessmentDirection,
    AssessmentStatus,
    AssessmentStatusResponse,
} from 'api/hooks/assessment/assessmentTypes';
import { AssessmentGeneralInformation } from 'components/assessment/assessment-general-information';
import { AssessmentCompanyInformation } from '../assessment-company-information';
import { WithSpinner } from 'components/with-spinner';
import { AssessmentAnalyst } from '../assessment-analyst/assessment-analyst';
import { useCheckAssessmentStatus } from 'api/hooks/common/useCheckAssessmentStatus';
import { RoutePaths } from 'routes';
import { Button, Stack } from '@mui/material';
import { Fragment, useEffect, useRef, useState } from 'react';
import { AutoSaveRef } from 'store/autosave';
import { AssessmentStepperConfirmation } from '../assessment-stepper-confirmation';
import { useSaveState } from 'store/saveState';

const steps = [
    {
        index: 0,
        label: 'General Information',
    },
    {
        index: 1,
        label: 'Company Information',
    },
    {
        index: 2,
        label: 'Analyst',
    },
    {
        index: 3,
        label: 'QA',
    },
    // {
    //     index: 4,
    //     label: 'Final review',
    // },
    {
        index: 4,
        label: 'Completed',
    },
];

interface CustomStepButtonProps extends StepButtonProps {
    isCompleted: boolean;
    isCurrentStep: boolean;
}

const CustomStepButton = ({
    isCompleted,
    isCurrentStep,
    ...props
}: CustomStepButtonProps) => <StepButton {...props} />;

export const AssessmentStepper = () => {
    const { assessmentId } = useParams();
    const { save } = useSaveState();
    const autoSaveRef = useRef<AutoSaveRef | null>(null);
    const navigate = useNavigate();
    const [direction, setDirection] = useState<null | AssessmentDirection>(
        null
    );
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [currentStep, setCurrentStep] = useState(0);
    const [completedSteps, setCompletedSteps] = useState<number[]>([]);

    const { data: status, isLoading: statusLoading } = useGetAssessmentStatus({
        assessmentId: assessmentId ?? '',
        enabled: assessmentId !== '0' && !!assessmentId,
    });

    useEffect(() => {
        if (status) {
            setupStepper(status);
        }
    }, [status]);

    const assessmentStatus = useCheckAssessmentStatus({
        assessmentId: assessmentId ?? '',
        currentStatuses: [
            AssessmentStatus.ANALYSIS_IN_PROGRESS,
            AssessmentStatus.ASSURANCE_IN_PROGRESS,
        ],
    });

    const setupStepper = (data: AssessmentStatusResponse) => {
        const statusMapping = {
            [AssessmentStatus.DRAFT]: steps[0].index,
            [AssessmentStatus.READY_FOR_ANALYSIS]: steps[1].index,
            [AssessmentStatus.ANALYSIS_IN_PROGRESS]: steps[2].index,
            [AssessmentStatus.READY_FOR_ASSURANCE]: steps[2].index,
            [AssessmentStatus.ASSURANCE_IN_PROGRESS]: steps[3].index,
            [AssessmentStatus.READY_FOR_AMENDMENT]: steps[3].index,
            [AssessmentStatus.COMPLETED]: steps[4].index,
        };

        const status = data.status;

        setCompletedSteps([...Array(statusMapping[status]).keys()]);
        setActiveStep(statusMapping[status]);
        setCurrentStep(statusMapping[status]);
    };

    const handleStep = (step: number) => async () => {
        await save();
        setActiveStep(step);
    };

    const handleContinue = async () => {
        setDirection(AssessmentDirection.PROCESS);
        setOpenConfirmation(true);
    };

    const handleGoBack = async () => {
        setDirection(AssessmentDirection.ROLLBACK);
        setOpenConfirmation(true);
    };

    const handleAmend = async () => {
        setDirection(AssessmentDirection.AMEND);
        setOpenConfirmation(true);
    };

    const handleClose = () => {
        navigate(RoutePaths.ASSESSMENT_LIST);
    };

    const isLoading = statusLoading || assessmentStatus.isLoading;

    return (
        <WithSpinner isLoading={isLoading}>
            <Box sx={{ width: '100%' }}>
                <Stepper
                    nonLinear
                    alternativeLabel
                    activeStep={activeStep}
                    sx={{ mb: 4 }}
                >
                    {steps.map(({ index, label }) => {
                        const isCompleted = completedSteps.includes(index);
                        const isCurrentStep = index === currentStep;

                        return (
                            <Step key={index} completed={isCompleted}>
                                <CustomStepButton
                                    isCompleted={isCompleted}
                                    isCurrentStep={isCurrentStep}
                                    disabled={!isCompleted && !isCurrentStep}
                                    onClick={handleStep(index)}
                                >
                                    {label}
                                </CustomStepButton>
                            </Step>
                        );
                    })}
                </Stepper>
                <div>
                    <Fragment>
                        {activeStep === steps[0].index && (
                            <AssessmentGeneralInformation />
                        )}
                        {activeStep === steps[1].index && (
                            <AssessmentCompanyInformation />
                        )}
                        {activeStep === steps[2].index && (
                            <AssessmentAnalyst autoSaveRef={autoSaveRef} />
                        )}
                        {activeStep === steps[3].index && (
                            <AssessmentAnalyst autoSaveRef={autoSaveRef} />
                        )}
                        {activeStep === steps[4].index && (
                            <AssessmentAnalyst autoSaveRef={autoSaveRef} />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-end',
                                pt: 2,
                            }}
                        >
                            {true && (
                                <>
                                    {assessmentStatus.currentStatus ===
                                        AssessmentStatus.ANALYSIS_IN_PROGRESS && (
                                        <Stack
                                            direction={'row'}
                                            spacing={2}
                                            justifyContent={'flex-end'}
                                            marginTop={2}
                                        >
                                            <Button
                                                variant='outlined'
                                                onClick={handleClose}
                                            >
                                                Close
                                            </Button>
                                            <Button
                                                variant='contained'
                                                onClick={handleContinue}
                                            >
                                                Ready for Assurance
                                            </Button>
                                        </Stack>
                                    )}
                                    {assessmentStatus.currentStatus ===
                                        AssessmentStatus.READY_FOR_ASSURANCE && (
                                        <Stack
                                            direction={'row'}
                                            spacing={2}
                                            justifyContent={'flex-end'}
                                            marginTop={2}
                                        >
                                            <Button
                                                variant='outlined'
                                                onClick={handleGoBack}
                                            >
                                                Back To Analysis
                                            </Button>
                                            <Button
                                                variant='contained'
                                                onClick={handleContinue}
                                            >
                                                Start Assurance
                                            </Button>
                                        </Stack>
                                    )}
                                    {assessmentStatus.currentStatus ===
                                        AssessmentStatus.ASSURANCE_IN_PROGRESS && (
                                        <Stack
                                            direction={'row'}
                                            spacing={2}
                                            justifyContent={'flex-end'}
                                            marginTop={2}
                                        >
                                            <Button
                                                variant='outlined'
                                                onClick={handleAmend}
                                            >
                                                Ready for Amendment
                                            </Button>
                                            <Button
                                                variant='contained'
                                                onClick={handleContinue}
                                            >
                                                Complete
                                            </Button>
                                        </Stack>
                                    )}
                                    {assessmentStatus.currentStatus ===
                                        AssessmentStatus.READY_FOR_AMENDMENT && (
                                        <Stack
                                            direction={'row'}
                                            spacing={2}
                                            justifyContent={'flex-end'}
                                            marginTop={2}
                                        >
                                            <Button
                                                variant='outlined'
                                                onClick={handleGoBack}
                                            >
                                                Cancel Amendment
                                            </Button>
                                            <Button
                                                variant='contained'
                                                onClick={handleContinue}
                                            >
                                                Start Amendment
                                            </Button>
                                        </Stack>
                                    )}
                                    {assessmentStatus.currentStatus ===
                                        AssessmentStatus.COMPLETED && (
                                        <Stack
                                            direction={'row'}
                                            spacing={2}
                                            justifyContent={'flex-end'}
                                            marginTop={2}
                                        >
                                            <Button
                                                variant='outlined'
                                                onClick={handleGoBack}
                                            >
                                                Revert Assessment
                                            </Button>
                                        </Stack>
                                    )}
                                </>
                            )}
                        </Box>
                    </Fragment>
                </div>
                <AssessmentStepperConfirmation
                    autoSaveRef={autoSaveRef}
                    direction={direction}
                    open={openConfirmation}
                    setOpen={setOpenConfirmation}
                />
            </Box>
        </WithSpinner>
    );
};
