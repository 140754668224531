import { Container } from '@mui/system';
import { LoginContainer } from './styles';
import { useNavigate } from 'react-router-dom';
import { useLogin } from 'api/hooks';
import { RoutePaths } from 'routes';
import { ForgotPasswordButton } from 'components/forgot-password-button';
import { LoginForm } from 'components/login-form';
import { FC } from 'react';
// import backgroundImage from 'assets/login-background.png';
import backgroundImage from 'assets/login-background.svg';
import { Box, Paper } from '@mui/material';
import { Header } from 'components/header';
import { Footer } from 'components/footer';
import { WithSpinner } from 'components/with-spinner';

export const Login: FC = () => {
    const navigate = useNavigate();
    // const location = useLocation();
    // const dispatch = useAppDispatch();

    // const from = ((location.state as any)?.from.pathname as string) || '/';

    const { mutateAsync: logIn } = useLogin({
        // onSuccess: (data) => {
        //     localStorage.setItem(AUTH_TOKEN, data.access_token);
        //     localStorage.setItem(REFRESH_TOKEN, data.refresh_token);
        //     getMe.refetch();
        //     navigate(RoutePaths.DASHBOARD);
        // },
    });

    const handleLogin = async (username: string, password: string) => {
        const loginInfo = await logIn({ username, password });
        // console.log({ loginInfo: loginInfo.detail });
        localStorage.setItem('tempToken', loginInfo.access_token);
        // localStorage.setItem(REFRESH_TOKEN, loginInfo.refresh_token);
        // getMe.refetch();
        navigate(RoutePaths.MFA);
    };

    const handleForgotPasswordClick = () => {
        navigate(RoutePaths.RECOVER_PASSWORD);
    };

    const isLoading = false;

    return (
        <WithSpinner isLoading={isLoading}>
            <Box>
                <Header onlyView={true} />
                <LoginContainer
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                >
                    {/* <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '50%',
                }}
            >
                <Logo style={{ width: 200, height: 100 }} />
                <Typography sx={{ fontSize: '2rem', color: 'white' }}>
                    Powering Sustainable Business
                </Typography>
            </div> */}

                    <Container maxWidth='sm' component={Paper} sx={{ pt: 4 }}>
                        <LoginForm onSubmit={handleLogin} />
                        <ForgotPasswordButton
                            onClick={handleForgotPasswordClick}
                        />
                    </Container>
                </LoginContainer>
                <Footer />
            </Box>
        </WithSpinner>
    );
};
