import React, {
    ChangeEvent,
    FC,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {
    Box,
    Button,
    Checkbox,
    debounce,
    IconButton,
    MenuItem,
    Paper,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { CustomPagination } from 'components/shared/custom-pagination';
import {
    MainQuestionAssessment,
    MainQuestionAssessmentAnswer,
} from 'api/hooks/main-question-assessment/mainQuestionAssessmentTypes';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
    selectMainQuestionAssessmentFilters,
    selectMainQuestionAssessmentPageData,
    selectMainQuestionAssessmentTable,
    setInitialMainQuestions,
    toggleSelected,
    updateMainQuestion,
    updateMainQuestionAnswerToND,
    updatePage,
    updateQaStatus,
    updateRowsPerPage,
} from 'store/slices/mainQuestionAssessmentSlice';
import { EnumToDropdownOptions } from 'utils';
import PopChat from 'components/popchat/popchat';
import { AssessmentCommentTarget } from 'api/hooks/assessment-comments/assessmentCommentsTypes';
import ChatIcon from '@mui/icons-material/Chat';
import { QaStatusComponent } from '../qa-status';
import { QaStatus } from 'api/hooks/commonTypes';
import { useGetMainQuestionAssessmentList } from 'api/hooks/main-question-assessment/useGetMainQuestionAssessmentList';
import { useParams } from 'react-router';
import { WithSpinner } from 'components/with-spinner';
import { DataState } from 'store/enums';
import { useMainQuestionSave } from './use-main-question-save';

type Props = {
    isReadOnly: boolean;
};

const QuestionDetails: FC<{ data: MainQuestionAssessment }> = ({ data }) => {
    return (
        <Box display={'flex'} alignItems={'flex-start'}>
            <Box>
                <Box display={'flex'}>
                    <Box fontWeight={'bold'}>Question:</Box>
                    {data?.question}
                </Box>
                <Box display={'flex'}>
                    <Box fontWeight={'bold'}>Category:</Box>
                    {data?.category}
                </Box>
                <Box display={'flex'}>
                    <Box fontWeight={'bold'}>Identifier:</Box>
                    {data?.identifier}
                </Box>
                <Box display={'flex'}>
                    <Box fontWeight={'bold'}>1st Sub-Category:</Box>
                    {data?.sub_category_first}
                </Box>
                <Box display={'flex'}>
                    <Box fontWeight={'bold'}>2nd Sub-category:</Box>
                    {data?.sub_category_second}
                </Box>
            </Box>
            {data?.scoring_guidance && (
                <Tooltip title={data?.scoring_guidance}>
                    <IconButton>
                        <HelpIcon />
                    </IconButton>
                </Tooltip>
            )}
        </Box>
    );
};

export const MainQuestionAssessmentListTable: React.FC<Props> = React.memo(
    ({ isReadOnly }) => {
        const dispatch = useAppDispatch();

        const [scrollPosition, setScrollPosition] = useState(0);
        const tableRef = useRef<HTMLTableElement>(null);

        const { assessmentId } = useParams();
        const mainQuestionAssessmentTable = useAppSelector(
            selectMainQuestionAssessmentTable
        );
        const filters = useAppSelector(selectMainQuestionAssessmentFilters);
        const { page, rowsPerPage } = useAppSelector(
            selectMainQuestionAssessmentPageData
        );
        const { save } = useMainQuestionSave();

        const {
            isLoading,
            query: { isRefetching },
        } = useGetMainQuestionAssessmentList({
            assessmentId: assessmentId ?? '',
            enabled: !!assessmentId && assessmentId !== '0',
            onSuccess: (data) => {
                dispatch(setInitialMainQuestions(data));
            },
            filters: filters ?? {},
        });

        const [chatQuestionId, setChatQuestionId] = useState<string | null>(
            null
        );
        const [openChat, setOpenChat] = useState(false);

        const handleSetRowsPerPage = async (rowsPerPage: number) => {
            await save();
            dispatch(updateRowsPerPage(rowsPerPage));
        };

        const handleSetPage = async (page: number) => {
            await save();
            dispatch(updatePage(page));
        };

        const handleInputChange = useCallback(
            (
                e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
                row: MainQuestionAssessment
            ) => {
                const { value, name } = e.target;

                // if (name === 'score') {
                //     // Parse the input value to a float if it's not null or empty
                //     value = unmodifiedValue
                //         ? parseFloat(unmodifiedValue)
                //         : null;
                // } else {
                //     value = unmodifiedValue;
                // }

                const updatedRow = {
                    ...row,
                    [name]: value,
                } as MainQuestionAssessment;

                dispatch(updateMainQuestion({ data: updatedRow }));
            },
            [dispatch]
        );

        const handleAnswerChange = useCallback(
            (e: SelectChangeEvent, row: MainQuestionAssessment) => {
                const { value } = e.target;
                let score: number | undefined = undefined;
                let judgement = row.scoring_judgment;
                let reference_for_answer = row.reference_for_answer;

                if (row.answer === 'ND' && value !== 'ND') {
                    reference_for_answer = '';
                }

                switch (value) {
                    case MainQuestionAssessmentAnswer.Yes:
                        score = 100;
                        judgement = '';
                        // reference_for_answer = '';
                        break;
                    case MainQuestionAssessmentAnswer.No:
                        score = 0;
                        judgement = '';
                        // reference_for_answer = '';
                        break;
                    case MainQuestionAssessmentAnswer.ND:
                        score = 0;
                        judgement = '';
                        reference_for_answer = 'ND';
                        break;
                    case MainQuestionAssessmentAnswer['n/a']:
                        score = 0;
                        break;
                    default:
                        score = undefined;
                        judgement = '';
                        // reference_for_answer = '';
                        break;
                }

                const updatedRow = {
                    ...row,
                    answer: value,
                    score: score,
                    scoring_judgment: judgement,
                    reference_for_answer: reference_for_answer,
                } as MainQuestionAssessment;

                dispatch(updateMainQuestion({ data: updatedRow }));
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [dispatch]
        );

        const handleOpenChat = useCallback(
            (id: string) => {
                setChatQuestionId(id);
                setOpenChat(true);
            },
            [setChatQuestionId, setOpenChat]
        );

        const handleCloseChat = useCallback(() => {
            setChatQuestionId(null);
            setOpenChat(false);
        }, [setChatQuestionId, setOpenChat]);

        const handleNoStatusClick = useCallback(
            (row: MainQuestionAssessment) => {
                dispatch(updateMainQuestionAnswerToND({ _id: row._id }));
            },
            [dispatch]
        );

        const handleStatusChange = useCallback(
            (qaStatus: QaStatus, row: MainQuestionAssessment) => {
                const updatedRow = {
                    ...row,
                    qa_status: qaStatus,
                } as MainQuestionAssessment;

                dispatch(updateMainQuestion({ data: updatedRow }));
            },
            [dispatch]
        );

        // const answerValidationOptions = EnumToDropdownOptions(
        //     MainQuestionAssessmentAnswer
        // );

        const handleScroll = () => {
            if (tableRef.current) {
                setScrollPosition(tableRef.current.scrollTop);
            }
        };

        // Scroll to the saved position after rendering
        useEffect(() => {
            if (tableRef.current) {
                tableRef.current.scrollTop = scrollPosition;
            }
        }, [scrollPosition, mainQuestionAssessmentTable]);

        const handleCheck = (id: string) => {
            dispatch(toggleSelected({ _id: id }));
        };

        const handleBulkStatusChange = (qaStatus: QaStatus) => {
            const selectedData = mainQuestionAssessmentTable.filter(
                (el) => el.selected
            );
            for (const data of selectedData) {
                dispatch(
                    updateQaStatus({
                        _id: data.data._id,
                        qaStatus,
                    })
                );
            }
        };

        const isBulkUpdateVisible = mainQuestionAssessmentTable.some(
            (el) => el.selected
        );

        return (
            <WithSpinner isLoading={isLoading || isRefetching}>
                <Box>
                    {isBulkUpdateVisible && (
                        <Box sx={{ mt: 2 }}>
                            <QaStatusComponent
                                label='Bulk Qa Status Update'
                                forceShow
                                handleClick={handleBulkStatusChange}
                            />
                        </Box>
                    )}

                    <Box sx={{ mt: 5, overflowX: 'auto' }}>
                        <div>
                            {/* <Paper sx={{ overflow: 'hidden' }}> */}
                            <TableContainer
                                component={Paper}
                                ref={tableRef}
                                onScroll={debounce(handleScroll, 700)}
                                style={{ height: '60vh' }}
                            >
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    background: 'white',
                                                    zIndex: 1,
                                                }}
                                            />

                                            <TableCell
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    background: 'white',
                                                    zIndex: 1,
                                                }}
                                            >
                                                N
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    background: 'white',
                                                    zIndex: 1,
                                                }}
                                            >
                                                Details
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    background: 'white',
                                                    zIndex: 1,
                                                }}
                                            >
                                                Is answered and publicly
                                                disclosed
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    background: 'white',
                                                    zIndex: 1,
                                                }}
                                            >
                                                Source Document/website link and
                                                page
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    background: 'white',
                                                    zIndex: 1,
                                                }}
                                            >
                                                Scoring Judgement
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    background: 'white',
                                                    zIndex: 1,
                                                }}
                                            >
                                                Score (/100) / Formula
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    background: 'white',
                                                    zIndex: 1,
                                                }}
                                            >
                                                Qa Status
                                            </TableCell>
                                            <TableCell
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    background: 'white',
                                                    zIndex: 1,
                                                }}
                                            />
                                            <TableCell
                                                style={{
                                                    position: 'sticky',
                                                    top: 0,
                                                    background: 'white',
                                                    zIndex: 1,
                                                }}
                                            />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {mainQuestionAssessmentTable
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map(
                                                ({
                                                    data: row,
                                                    state,
                                                    selected,
                                                }) => {
                                                    return (
                                                        <Row
                                                            key={row._id}
                                                            row={row}
                                                            state={state}
                                                            isReadOnly={
                                                                isReadOnly
                                                            }
                                                            handleAnswerChange={
                                                                handleAnswerChange
                                                            }
                                                            handleInputChange={
                                                                handleInputChange
                                                            }
                                                            handleOpenChat={
                                                                handleOpenChat
                                                            }
                                                            handleNoStatusClick={
                                                                handleNoStatusClick
                                                            }
                                                            handleStatusChange={
                                                                handleStatusChange
                                                            }
                                                            selected={selected}
                                                            onCheck={
                                                                handleCheck
                                                            }
                                                        />
                                                    );
                                                }
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <CustomPagination
                                dataLength={mainQuestionAssessmentTable?.length}
                                page={page}
                                rowsPerPage={rowsPerPage}
                                setPage={handleSetPage}
                                setRowsPerPage={handleSetRowsPerPage}
                            />
                            {/* </Paper> */}
                        </div>

                        {openChat && (
                            <PopChat
                                questionId={chatQuestionId ?? ''}
                                closeChat={handleCloseChat}
                                target={
                                    AssessmentCommentTarget.MAIN_QUESTIONNAIRE
                                }
                                isReadOnly={isReadOnly}
                            />
                        )}
                    </Box>
                </Box>
            </WithSpinner>
        );
    }
);

type RowProps = {
    row: MainQuestionAssessment;
    state: DataState;
    isReadOnly: boolean;
    handleAnswerChange: (
        e: SelectChangeEvent<MainQuestionAssessmentAnswer>,
        row: MainQuestionAssessment
    ) => void;
    handleInputChange: (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        row: MainQuestionAssessment
    ) => void;
    handleOpenChat: (id: string) => void;
    handleNoStatusClick: (row: MainQuestionAssessment) => void;
    handleStatusChange: (
        qaStatus: QaStatus,
        row: MainQuestionAssessment
    ) => void;
    onCheck: (id: string) => void;
    selected: boolean;
};

export const Row: React.FC<RowProps> = React.memo(
    ({
        row,
        state,
        handleAnswerChange,
        handleInputChange,
        handleOpenChat,
        handleNoStatusClick,
        handleStatusChange,
        isReadOnly,
        selected,
        onCheck,
    }) => {
        const answerValidationOptions = EnumToDropdownOptions(
            MainQuestionAssessmentAnswer
        );

        return (
            <TableRow
                key={row._id}
                sx={{
                    backgroundColor:
                        state === DataState.ERROR ? 'rgba(255, 0, 0, 0.1)' : '',
                    '&:last-child td, &:last-child th': {
                        border: 0,
                    },
                }}
            >
                <TableCell component='th' scope='row'>
                    <Checkbox
                        disabled={!row.for_qa}
                        checked={selected}
                        value={selected}
                        onChange={() => onCheck(row._id)}
                    />
                </TableCell>
                <TableCell component='th' scope='row'>
                    {row?.question_number}
                </TableCell>
                <TableCell
                    style={{
                        position: 'relative',
                    }}
                >
                    {QuestionDetails({
                        data: row,
                    })}
                </TableCell>
                {/* <TableCell >
                                            {row?.category}
                                        </TableCell> */}
                <TableCell>
                    <Select
                        name='answer'
                        value={row?.answer || ''}
                        onChange={(e) => {
                            handleAnswerChange(e, row);
                        }}
                        sx={{ width: 100 }}
                        disabled={isReadOnly}
                    >
                        {answerValidationOptions &&
                            answerValidationOptions.map((option) => (
                                <MenuItem
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label || option.value}
                                </MenuItem>
                            ))}
                    </Select>
                </TableCell>
                <TableCell>
                    {/* {row?.reference_for_answer */}
                    <TextField
                        fullWidth
                        sx={{ width: 300 }}
                        multiline
                        rows={4}
                        value={row?.reference_for_answer ?? ''}
                        name='reference_for_answer'
                        onChange={(e) => handleInputChange(e, row)}
                        disabled={
                            row?.answer === MainQuestionAssessmentAnswer.ND ||
                            isReadOnly
                        }
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        fullWidth
                        sx={{ width: 200 }}
                        value={row?.scoring_judgment ?? undefined}
                        name='scoring_judgment'
                        onChange={(e) => handleInputChange(e, row)}
                        disabled={
                            (row?.answer !==
                                MainQuestionAssessmentAnswer.Partial &&
                                row?.answer !==
                                    MainQuestionAssessmentAnswer['n/a']) ||
                            isReadOnly
                        }
                    />
                </TableCell>
                <TableCell>
                    {row?.answer === MainQuestionAssessmentAnswer.Partial ? (
                        <>
                            <TextField
                                fullWidth
                                sx={{ width: 100 }}
                                value={row?.formula ?? undefined}
                                name='formula'
                                onChange={(e) => handleInputChange(e, row)}
                                disabled={isReadOnly}
                            />

                            <Typography>Score: {row?.score}</Typography>
                        </>
                    ) : (
                        <TextField
                            fullWidth
                            sx={{ width: 100 }}
                            value={row?.score ?? undefined}
                            name='score'
                            onChange={(e) => handleInputChange(e, row)}
                            disabled={true}
                        />
                    )}
                </TableCell>
                <TableCell>{row?.qa_status}</TableCell>
                <TableCell>
                    <IconButton onClick={() => handleOpenChat(row._id)}>
                        <ChatIcon
                            fontSize='large'
                            color={row.has_comments ? 'success' : 'primary'}
                        />
                    </IconButton>
                    <Button
                        sx={{ p: 0 }}
                        onClick={() => handleNoStatusClick(row)}
                    >
                        Set "ND"
                    </Button>
                </TableCell>
                <TableCell>
                    {!isReadOnly && (
                        <QaStatusComponent
                            for_qa={row.for_qa}
                            qa_status={row.qa_status}
                            handleClick={(qaStatus) =>
                                handleStatusChange(qaStatus, row)
                            }
                        />
                    )}
                </TableCell>
                {/* <TableCell >
                                            {row?.weighted_score}
                                        </TableCell> */}
            </TableRow>
        );
    }
);
