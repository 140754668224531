import { useQuery } from 'react-query';
import { IndustryListResponse } from './industryTypes';
import { getIndustryList } from './industryService';

type Props<M> = {
    onError?: (error: Error) => void;
    onSuccess?: (data: IndustryListResponse) => void;
    mapper?: (
        data?: IndustryListResponse
    ) => M | IndustryListResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: IndustryListResponse) => data;

export const useGetIndustries = <M = IndustryListResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
}: Props<M>) => {
    const query = useQuery<IndustryListResponse, Error>(
        ['industries'],
        getIndustryList,
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
