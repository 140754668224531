import { Button, Container, TextField } from '@mui/material';
import { DialogComponent } from 'components/dialog-component';
import { FC } from 'react';
import copy from 'clipboard-copy';

type Props = {
    apiKeyDialogoOpen: boolean;
    handleApiKeyDialogClose: () => void;
    apiKey: string;
};

export const GenerateApiKeyDialog: FC<Props> = ({
    apiKeyDialogoOpen,
    handleApiKeyDialogClose,
    apiKey,
}) => {
    return (
        <DialogComponent
            open={apiKeyDialogoOpen}
            onClose={handleApiKeyDialogClose}
            contentText='This is your personal API key, linked to your CENintel account, which gives your access to CENintel via API. Please do not share this with anyone else. Once you close this window you will not be able retrieve your key again without regenerating a new key'
            title='API Key'
        >
            <Container sx={{ mt: 2, display: 'flex', flexDirection: 'column' }}>
                <TextField
                    multiline={true}
                    value={apiKey}
                    disabled
                    fullWidth
                    rows={4}
                />
                <Container
                    sx={{
                        mt: 2,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button onClick={() => copy(apiKey ?? '')}>Copy</Button>
                    <Button onClick={handleApiKeyDialogClose}>Close</Button>
                </Container>
            </Container>
        </DialogComponent>
    );
};
