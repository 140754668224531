import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {
    Box,
    Checkbox,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Tooltip,
} from '@mui/material';
import { FC, useState } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { CustomPagination } from 'components/shared/custom-pagination';
import { useAppDispatch, useAppSelector } from 'store/store';
import {
    selectMaterialityAssessmentPageData,
    selectMaterialityAssessmentTableData,
    setInitialMateriality,
    toggleSelected,
    updateMateriality,
    updatePage,
    updateQaStatus,
    updateRowsPerPage,
} from 'store/slices/materialityAssessmentSlice';
import {
    MaterialityAssessment,
    MaterialityAssessmentAnswerValidation,
    MaterialityAssessmentYesOrND,
    MaterialityAssessmentYesOrNDOrNA,
} from 'api/hooks/materiality-assessment/materialityAssessmentTypes';
import { EnumToDropdownOptions } from 'utils';
import PopChat from 'components/popchat/popchat';
import { AssessmentCommentTarget } from 'api/hooks/assessment-comments/assessmentCommentsTypes';
import ChatIcon from '@mui/icons-material/Chat';
import { QaStatusComponent } from '../qa-status';
import { QaStatus } from 'api/hooks/commonTypes';
import { useGetMaterialityAssessmentList } from 'api/hooks/materiality-assessment/useGetMaterialityAssessmentList';
import { useParams } from 'react-router';
import { WithSpinner } from 'components/with-spinner';
import { useMaterialityAssessmentSave } from './use-materiality-assessment-save';

type Props = {
    isReadOnly: boolean;
};

// const Details: FC<{ data: MaterialityAssessment }> = ({ data }) => {
//     return (
//         <>
//             <Box>
//                 <Box display={'flex'}>
//                     <Box fontWeight={'bold'}>Score:</Box>
//                     {data?.score}
//                 </Box>
//                 <Box display={'flex'}>
//                     <Box fontWeight={'bold'}>Weighted Score:</Box>
//                     {data?.weighted_score}
//                 </Box>
//                 <Box display={'flex'}>
//                     <Box fontWeight={'bold'}>Weighted Max Score:</Box>
//                     {data?.weighted_max_score_possible}
//                 </Box>
//             </Box>
//         </>
//     );
// };

export const MaterialityAssessmentListTable: FC<Props> = ({ isReadOnly }) => {
    const dispatch = useAppDispatch();
    const { assessmentId } = useParams();
    const [chatQuestionId, setChatQuestionId] = useState<string | null>(null);
    const [openChat, setOpenChat] = useState(false);
    const handleOpenChat = (id: string) => {
        setChatQuestionId(id);
        setOpenChat(true);
    };

    const handleCloseChat = () => {
        setChatQuestionId(null);
        setOpenChat(false);
    };

    const materialityAssessmentTableData = useAppSelector(
        selectMaterialityAssessmentTableData
    );

    const {
        isLoading,
        query: { isRefetching },
    } = useGetMaterialityAssessmentList({
        assessmentId: assessmentId ?? '',
        enabled: !!assessmentId && assessmentId !== '0',
        onSuccess: (data) => {
            dispatch(setInitialMateriality(data));
        },
    });

    const { save } = useMaterialityAssessmentSave();

    const { page, rowsPerPage } = useAppSelector(
        selectMaterialityAssessmentPageData
    );

    const handleSetRowsPerPage = async (rowsPerPage: number) => {
        await save();
        dispatch(updateRowsPerPage(rowsPerPage));
    };

    const handleSetPage = async (page: number) => {
        await save();
        dispatch(updatePage(page));
    };

    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        row: MaterialityAssessment
    ) => {
        const { value, name } = e.target;
        const updatedRow = { ...row, [name]: value } as MaterialityAssessment;

        dispatch(updateMateriality({ data: updatedRow }));
    };

    const handleSelect = (
        event: unknown | SelectChangeEvent,
        row: MaterialityAssessment
    ) => {
        const { name, value } = (event as SelectChangeEvent).target;
        const updatedRow = { ...row, [name]: value } as MaterialityAssessment;
        if (
            (row.question_number === 2 || row.question_number === 3) &&
            name === 'answer'
        ) {
            const question7th = materialityAssessmentTableData.find(
                (el) => el.data.question_number === 7
            );
            if (value === 'Yes') {
                if (question7th) {
                    dispatch(
                        updateMateriality({
                            data: { ...question7th.data, answer: 'Yes' },
                        })
                    );
                }
            } else if (value === 'ND') {
                const otherQuestion = materialityAssessmentTableData.find(
                    (el) =>
                        el.data.question_number ===
                        (row.question_number === 2 ? 3 : 2)
                );
                if (otherQuestion?.data.answer === 'ND') {
                    if (question7th) {
                        dispatch(
                            updateMateriality({
                                data: { ...question7th.data, answer: '' },
                            })
                        );
                    }
                }
            }
        } else if (
            (row.question_number === 4 ||
                row.question_number === 5 ||
                row.question_number === 6) &&
            name === 'answer'
        ) {
            const question8th = materialityAssessmentTableData.find(
                (el) => el.data.question_number === 8
            );
            if (value === 'Yes') {
                if (question8th) {
                    dispatch(
                        updateMateriality({
                            data: { ...question8th.data, answer: 'Yes' },
                        })
                    );
                }
            } else if (value === 'ND') {
                const toCheckNumbers = [4, 5, 6].filter(
                    (num) => num !== row.question_number
                );
                const otherQuestionsHasYes =
                    materialityAssessmentTableData.some(
                        (el) =>
                            toCheckNumbers.some(
                                (num) => num === el.data.question_number
                            ) && el.data.answer === 'Yes'
                    );
                if (!otherQuestionsHasYes) {
                    if (question8th) {
                        dispatch(
                            updateMateriality({
                                data: { ...question8th.data, answer: '' },
                            })
                        );
                    }
                }
            }
        }

        if (name === 'answer' && value === 'ND' && row.question_number === 1) {
            materialityAssessmentTableData.forEach((element) => {
                dispatch(
                    updateMateriality({
                        data: {
                            ...element.data,
                            answer: 'ND',
                            reference_for_answer: 'ND',
                        },
                    })
                );
            });
        }

        if (name === 'answer' && value === 'Yes' && row.question_number === 1) {
            materialityAssessmentTableData.forEach((element) => {
                dispatch(
                    updateMateriality({
                        data: {
                            ...element.data,
                            answer: undefined,
                            reference_for_answer: '',
                        },
                    })
                );
            });
        }

        if (name === 'answer' && value === 'ND') {
            updatedRow.reference_for_answer = 'ND';
        }

        dispatch(updateMateriality({ data: updatedRow }));
    };

    const handleStatusChange = (
        qaStatus: QaStatus,
        row: MaterialityAssessment
    ) => {
        const updatedRow = {
            ...row,
            qa_status: qaStatus,
        } as MaterialityAssessment;

        dispatch(updateMateriality({ data: updatedRow }));
    };

    const handleCheck = (id: string) => {
        dispatch(toggleSelected({ _id: id }));
    };

    const handleBulkStatusChange = (qaStatus: QaStatus) => {
        const selectedData = materialityAssessmentTableData.filter(
            (el) => el.isSelected
        );
        for (const data of selectedData) {
            dispatch(
                updateQaStatus({
                    _id: data.data._id,
                    qaStatus,
                })
            );
        }
    };

    const YesOrNDOptions = EnumToDropdownOptions(MaterialityAssessmentYesOrND);

    const YesOrNDOrNAOptions = EnumToDropdownOptions(
        MaterialityAssessmentYesOrNDOrNA
    );

    const isBulkUpdateVisible = materialityAssessmentTableData.some(
        (el) => el.isSelected
    );

    const firstQuestionIsND = materialityAssessmentTableData.some(
        (el) => el.data.question_number === 1 && el.data.answer === 'ND'
    );

    return (
        <WithSpinner isLoading={isLoading || isRefetching}>
            <Box>
                {isBulkUpdateVisible && (
                    <Box sx={{ mt: 2 }}>
                        <QaStatusComponent
                            label='Bulk Qa Status Update'
                            forceShow
                            handleClick={handleBulkStatusChange}
                        />
                    </Box>
                )}

                <Box sx={{ mt: 5 }}>
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>Question ID</TableCell>
                                        {/* <TableCell width={200}>Details</TableCell> */}
                                        <TableCell>Question</TableCell>
                                        <TableCell>
                                            Is Answered And Publicly Disclosed
                                        </TableCell>
                                        <TableCell>Source</TableCell>
                                        <TableCell>Qa Status</TableCell>
                                        <TableCell />
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {materialityAssessmentTableData
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map(({ data: row, isSelected }) => {
                                            const answerValidationTypeOptions =
                                                row.answer_validation ===
                                                MaterialityAssessmentAnswerValidation[
                                                    'Yes or ND'
                                                ]
                                                    ? YesOrNDOptions
                                                    : row.answer_validation ===
                                                      MaterialityAssessmentAnswerValidation[
                                                          'Yes or ND or n/a'
                                                      ]
                                                    ? YesOrNDOrNAOptions
                                                    : null;

                                            return (
                                                <TableRow
                                                    key={row._id}
                                                    sx={{
                                                        '&:last-child td, &:last-child th':
                                                            {
                                                                border: 0,
                                                            },
                                                    }}
                                                >
                                                    <TableCell
                                                        component='th'
                                                        scope='row'
                                                    >
                                                        <Checkbox
                                                            disabled={
                                                                !row.for_qa
                                                            }
                                                            checked={isSelected}
                                                            value={isSelected}
                                                            onChange={() =>
                                                                handleCheck(
                                                                    row._id
                                                                )
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        component='th'
                                                        scope='row'
                                                    >
                                                        {row?.question_number}
                                                    </TableCell>
                                                    {/* <TableCell
                                                component='th'
                                                scope='row'
                                            >
                                                {Details({ data: row })}
                                            </TableCell> */}
                                                    <TableCell
                                                        style={{
                                                            position:
                                                                'relative',
                                                        }}
                                                    >
                                                        {row?.question}
                                                        {row?.scoring_guidance && (
                                                            <Tooltip
                                                                title={
                                                                    row?.scoring_guidance
                                                                }
                                                                style={{
                                                                    position:
                                                                        'absolute',
                                                                    left: 0,
                                                                    top: 0,
                                                                }}
                                                            >
                                                                <IconButton>
                                                                    <HelpIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Select
                                                            sx={{ width: 200 }}
                                                            name='answer'
                                                            value={
                                                                row?.answer ??
                                                                ''
                                                            }
                                                            onChange={(e) =>
                                                                handleSelect(
                                                                    e,
                                                                    row
                                                                )
                                                            }
                                                            disabled={
                                                                isReadOnly ||
                                                                (![1].includes(
                                                                    row.question_number!
                                                                ) &&
                                                                    firstQuestionIsND)
                                                            }
                                                        >
                                                            {answerValidationTypeOptions &&
                                                                answerValidationTypeOptions.map(
                                                                    (
                                                                        option
                                                                    ) => (
                                                                        <MenuItem
                                                                            key={
                                                                                option.value
                                                                            }
                                                                            value={
                                                                                option.value
                                                                            }
                                                                        >
                                                                            {option.label ||
                                                                                option.value}
                                                                        </MenuItem>
                                                                    )
                                                                )}
                                                        </Select>
                                                    </TableCell>
                                                    <TableCell>
                                                        {/* {row?.reference_for_answer} */}
                                                        <TextField
                                                            sx={{ width: 400 }}
                                                            multiline
                                                            rows={2}
                                                            value={
                                                                row?.reference_for_answer ??
                                                                ''
                                                            }
                                                            name='reference_for_answer'
                                                            onChange={(e) =>
                                                                handleInputChange(
                                                                    e,
                                                                    row
                                                                )
                                                            }
                                                            disabled={
                                                                isReadOnly ||
                                                                row.answer ===
                                                                    'ND'
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {row?.qa_status}
                                                    </TableCell>
                                                    <TableCell>
                                                        <IconButton
                                                            onClick={() =>
                                                                handleOpenChat(
                                                                    row._id
                                                                )
                                                            }
                                                        >
                                                            <ChatIcon
                                                                fontSize='large'
                                                                color={
                                                                    row.has_comments
                                                                        ? 'success'
                                                                        : 'primary'
                                                                }
                                                            />
                                                        </IconButton>
                                                    </TableCell>
                                                    <TableCell>
                                                        {!isReadOnly && (
                                                            <QaStatusComponent
                                                                for_qa={
                                                                    row.for_qa
                                                                }
                                                                qa_status={
                                                                    row.qa_status
                                                                }
                                                                handleClick={(
                                                                    qaStatus
                                                                ) =>
                                                                    handleStatusChange(
                                                                        qaStatus,
                                                                        row
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomPagination
                            dataLength={materialityAssessmentTableData?.length}
                            page={page}
                            rowsPerPage={rowsPerPage}
                            setPage={handleSetPage}
                            setRowsPerPage={handleSetRowsPerPage}
                        />
                    </Paper>
                    {openChat && (
                        <PopChat
                            questionId={chatQuestionId ?? ''}
                            closeChat={handleCloseChat}
                            target={AssessmentCommentTarget.MATERIALITY}
                            isReadOnly={isReadOnly}
                        />
                    )}
                </Box>
            </Box>
        </WithSpinner>
    );
};
