import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { RoleType, UserType } from 'api/hooks/user/userTypes';

export type User = {
    type: UserType;
    roles?: RoleType[];
    initial_password_changed: boolean;
};

interface UserState {
    authUser: User | null;
    initialPasswordChanged: boolean;
}

const initialState: UserState = {
    authUser: null,
    initialPasswordChanged: false,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setAuthUser: (state, action: PayloadAction<User>) => {
            state.authUser = action.payload;
        },
        logoutUser: (state) => {
            state = initialState;
        },
    },
});

export const { setAuthUser, logoutUser } = userSlice.actions;

export const selectAuthUser = (state: RootState) => state.user.authUser;

export default userSlice.reducer;
