import { Button, IconButton, TableCell, TableRow } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FC } from 'react';
import { ConfirmationDialog } from 'components/confirmation-dialog';
import { MainQuestionVariation } from 'api/hooks/main-question/mainQuestionsTypes';

type Props = {
    row: MainQuestionVariation;
    setOpen: (isOpen: boolean) => void;
    open: boolean;
    onDelete: () => void;
    onEdit: () => void;
};

export const VariationsTableBodyRow: FC<Props> = ({
    row,
    setOpen,
    open,
    onEdit,
    onDelete,
}) => {
    return (
        <TableRow
            key={row?.question_number}
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            }}
        >
            <TableCell>
                <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
            </TableCell>
            <TableCell component='th' scope='row' align='right'>
                {row?.industry}
            </TableCell>
            <TableCell component='th' scope='row' align='right'>
                {row?.sub_industry}
            </TableCell>
            <TableCell component='th' scope='row' align='right'>
                {row?.question_number}
            </TableCell>
            <TableCell component='th' scope='row' align='right'>
                {row?.scoring_guidance}
            </TableCell>
            <TableCell component='th' scope='row' align='right'>
                {`${row?.guides?.length ?? 0} Items`}
            </TableCell>
            <TableCell component='th' scope='row' align='right'>
                {row?.max_score_possible}
            </TableCell>
            <TableCell component='th' scope='row' align='right'>
                <Button
                    color='primary'
                    role='submit'
                    variant='outlined'
                    sx={{ m: 1 }}
                    onClick={onEdit}
                >
                    Edit
                </Button>
            </TableCell>
            <TableCell component='th' scope='row' align='right'>
                <ConfirmationDialog id={''} onDelete={onDelete} />
            </TableCell>
        </TableRow>
    );
};
