import { useQuery } from 'react-query';
import { MainQuestionListResponse } from './mainQuestionsTypes';
import { getMainQuestionList } from './mainQuestionsService';

type Props<M> = {
    onError?: (error: Error) => void;
    onSuccess?: (data: MainQuestionListResponse) => void;
    mapper?: (
        data?: MainQuestionListResponse
    ) => M | MainQuestionListResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: MainQuestionListResponse) => data;

export const useGetMainQuestionList = <M = MainQuestionListResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
}: Props<M>) => {
    const query = useQuery<MainQuestionListResponse, Error>(
        ['mainQuestionList'],
        getMainQuestionList,
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
