import { authApi } from 'lib';
import {
    CreateMaterialityAssessment,
    MaterialityAssessmentListResponse,
} from './materialityAssessmentTypes';

export const getMaterialityAssessmentList = async (id: string) => {
    const response = await authApi.get<MaterialityAssessmentListResponse>(
        '/api/v1/materiality_assessments',
        { params: { assessment_id: id } }
    );
    return response.data;
};

export const updateMaterialityAssessment = async (
    id: string,
    request: CreateMaterialityAssessment
) => {
    const response = await authApi.put<CreateMaterialityAssessment>(
        `/api/v1/materiality_assessments/${id}`,
        request
    );
    return response.data;
};
