import { useQuery } from 'react-query';
import { GeneralInformation } from './assessmentTypes';
import { getGeneralInformation } from './assessmentService';

type Props<M> = {
    assessmentId: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: GeneralInformation) => void;
    mapper?: (data?: GeneralInformation) => M | GeneralInformation | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: GeneralInformation) => data;

export const useGetGeneralInformation = <M = GeneralInformation>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    assessmentId,
}: Props<M>) => {
    const query = useQuery<GeneralInformation, Error>(
        ['generalInformation', assessmentId],
        () => getGeneralInformation(assessmentId),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
