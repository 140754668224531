import { Box, Button, Container, Paper, Stack, TextField } from '@mui/material';
import { WithSpinner } from 'components/with-spinner';
import { LoginContainer } from 'features/login/styles';
import backgroundImage from 'assets/login-background.svg';
import { Footer } from 'components/footer';
import { Header } from 'components/header';
import { useRef } from 'react';
import { useVerifyOtp } from 'api/hooks/auth/useVerifyOtp';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useGetMe } from 'api/hooks';
import { RoutePaths } from 'routes';
import { AUTH_TOKEN, REFRESH_TOKEN } from 'constants/session-storage-constants';

function MFA() {
    const navigate = useNavigate();

    const { query: getMe } = useGetMe({
        enabled: false,
    });

    const { mutate } = useVerifyOtp({
        onSuccess: ({ access_token, refresh_token }) => {
            localStorage.removeItem('tempToken');
            localStorage.setItem(AUTH_TOKEN, access_token);
            localStorage.setItem(REFRESH_TOKEN, refresh_token);
            getMe.refetch();
            navigate(RoutePaths.DASHBOARD);
        },
        onError: (error) => {
            toast.error(error.message);
        },
    });

    const textFieldRef = useRef<HTMLInputElement | null>(null);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (textFieldRef?.current?.value) {
            mutate(textFieldRef.current.value);
        }
    };

    return (
        <WithSpinner isLoading={false}>
            <Box>
                <Header onlyView={true} />
                <LoginContainer
                    style={{
                        backgroundImage: `url(${backgroundImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                    }}
                >
                    <Container maxWidth='sm' component={Paper} sx={{ pt: 4 }}>
                        <form onSubmit={handleSubmit}>
                            <Stack spacing={2} p={2}>
                                <TextField
                                    label='One-time code'
                                    inputRef={textFieldRef}
                                />
                                <Button variant='contained' type='submit'>
                                    Submit
                                </Button>
                            </Stack>
                        </form>
                    </Container>
                </LoginContainer>
                <Footer />
            </Box>
        </WithSpinner>
    );
}

export default MFA;
