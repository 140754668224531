import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { FC, useState } from 'react';
import { Box, Button, Collapse, Container, Typography } from '@mui/material';
import { VariationGuideForm } from './variation-guide-form';
import {
    CreateUpdateMainQuestionVariation,
    MainQuestionVariationGuide,
} from 'api/hooks/main-question/mainQuestionsTypes';

type Props = {
    open: boolean;
    row: CreateUpdateMainQuestionVariation;
    onAddGuide: (variationGuide: MainQuestionVariationGuide) => boolean;
    onDeleteGuide: (orderNumber: number) => void;
};

export const VariationGuideListTableRow: FC<Props> = ({
    open,
    row,
    onAddGuide,
    onDeleteGuide,
}) => {
    const [showGuideForm, setShowGuideForm] = useState(false);

    const handleAddGuide = (variationGuide: MainQuestionVariationGuide) => {
        const added = onAddGuide(variationGuide);

        if (added) setShowGuideForm(false);
    };
    return (
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={15}>
                <Collapse in={open} timeout='auto' unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                        <Container
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Typography variant='h6' component='div'>
                                Guides
                            </Typography>
                            {!showGuideForm && (
                                <Button
                                    color='primary'
                                    role='submit'
                                    type='submit'
                                    variant='outlined'
                                    size='small'
                                    sx={{ ml: 2 }}
                                    onClick={() => setShowGuideForm(true)}
                                >
                                    Add
                                </Button>
                            )}
                        </Container>
                        {showGuideForm && (
                            <VariationGuideForm
                                onAdd={handleAddGuide}
                                onCancel={() => setShowGuideForm(false)}
                            />
                        )}

                        <Table size='small' aria-label='purchases'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Order Number</TableCell>
                                    <TableCell align='right'>Title</TableCell>
                                    <TableCell align='right'>Text</TableCell>
                                    <TableCell align='right' />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.guides?.sort(
                                    (a, b) => a.order - b.order
                                ) &&
                                    row.guides.map((result, index) => (
                                        <TableRow
                                            key={`variation-guide-list-table-row-${index}`}
                                        >
                                            <TableCell
                                                component='th'
                                                scope='row'
                                            >
                                                {result.order}
                                            </TableCell>
                                            <TableCell
                                                component='th'
                                                scope='row'
                                                align='right'
                                            >
                                                {result.title}
                                            </TableCell>
                                            <TableCell
                                                component='th'
                                                scope='row'
                                                align='right'
                                            >
                                                {result.text}
                                            </TableCell>
                                            <TableCell
                                                component='th'
                                                scope='row'
                                                align='right'
                                            >
                                                <Button
                                                    variant='outlined'
                                                    onClick={() =>
                                                        onDeleteGuide(
                                                            result.order
                                                        )
                                                    }
                                                >
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Box>
                </Collapse>
            </TableCell>
        </TableRow>
    );
};
