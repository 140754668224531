import { Button, Stack, TextField } from '@mui/material';
import { TUserErrorRatesFilters } from 'api/hooks/user/userTypes';
import { FormikState } from 'formik';
import { useState } from 'react';

type UserErrorRatesFiltersProps = {
    values: TUserErrorRatesFilters;
    handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
    resetForm: (
        nextState?: Partial<FormikState<TUserErrorRatesFilters>> | undefined
    ) => void;
};

function UserErrorRatesFilters({
    handleChange,
    handleSubmit,
    values,
    resetForm,
}: UserErrorRatesFiltersProps) {
    const [showFilters, setShowFilters] = useState(false);

    return (
        <div>
            <Button
                variant='contained'
                onClick={() => {
                    setShowFilters((prev) => !prev);
                }}
            >
                {showFilters ? 'Hide Filters' : 'Show Filters'}
            </Button>
            {showFilters ? (
                <form onSubmit={handleSubmit}>
                    <Stack direction='row' spacing={2} py={2}>
                        <TextField
                            sx={{
                                width: '250px',
                            }}
                            name='companyIsin'
                            label='Company ISIN (Separate with ;)'
                            onChange={handleChange}
                            value={values.companyIsin}
                        />
                        <TextField
                            sx={{
                                width: '250px',
                            }}
                            name='companyName'
                            label='Company Name (Separate with ;)'
                            onChange={handleChange}
                            value={values.companyName}
                        />
                        <TextField
                            sx={{
                                width: '250px',
                            }}
                            name='analystName'
                            label='Analyst Name (Separate with ;)'
                            onChange={handleChange}
                            value={values.analystName}
                        />
                        <TextField
                            sx={{
                                width: '250px',
                            }}
                            name='analystLastName'
                            label='Analyst Last Name (Separate with ;)'
                            onChange={handleChange}
                            value={values.analystLastName}
                        />
                    </Stack>
                    <Button
                        type='submit'
                        variant='contained'
                        sx={{ width: '100px' }}
                    >
                        Filter
                    </Button>
                    <Button
                        variant='outlined'
                        sx={{ width: '100px', ml: 2 }}
                        onClick={() => {
                            resetForm();
                        }}
                    >
                        Clear
                    </Button>
                </form>
            ) : null}
        </div>
    );
}

export default UserErrorRatesFilters;
