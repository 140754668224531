import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { DataState } from 'store/enums';
import {
    SDGAssessment,
    SDGAssessmentListResponse,
} from 'api/hooks/sdg-assessment/sdgAssessmentTypes';
import { QaStatus } from 'api/hooks/commonTypes';

export interface SdgAssessmentState {
    table: {
        data: SDGAssessment;
        state: DataState;
        selected: boolean;
    }[];
    page: number;
    rowsPerPage: number;
}

const initialState: SdgAssessmentState = {
    table: [],
    page: 0,
    rowsPerPage: 100,
};

export const sdgAssessmentSlice = createSlice({
    name: 'sdgAssessment',
    initialState,
    reducers: {
        updatePage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        updateRowsPerPage: (state, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload;
        },
        setInitialSDG: (
            state,
            action: PayloadAction<SDGAssessmentListResponse>
        ) => {
            const newTable = action.payload.map((sdg) => ({
                selected: false,
                data: sdg,
                state: DataState.INITIAL,
            }));
            state.table = newTable;
        },
        updateSDG: (
            state,
            action: PayloadAction<{
                data: SDGAssessment;
                state?: DataState;
            }>
        ) => {
            const modifiedTable = state.table.map((sdg) => {
                if (sdg.data._id === action.payload.data._id) {
                    return {
                        ...sdg,
                        data: action.payload.data,
                        state: action.payload.state ?? DataState.UPDATED,
                    };
                }
                return sdg;
            });

            state.table = modifiedTable;
        },
        updateSDGAnswerToNO: (
            state,
            action: PayloadAction<{ _id: string }>
        ) => {
            const modifiedTable = state.table.map((sdg) => {
                if (sdg.data._id === action.payload._id) {
                    return {
                        ...sdg,
                        data: {
                            ...sdg.data,
                            questions: sdg.data.questions?.map((question) => {
                                return {
                                    ...question,
                                    answer: 'No',
                                };
                            }),
                        },
                        state: DataState.UPDATED,
                    };
                }
                return sdg;
            });

            state.table = modifiedTable;
        },
        updateSDGAnswer: (
            state,
            action: PayloadAction<{
                data: {
                    _id: string;
                    question: string;
                    answer: string;
                };
                state?: DataState;
            }>
        ) => {
            const modifiedTable = state.table.map((sdg) => {
                if (sdg.data._id === action.payload.data._id) {
                    return {
                        ...sdg,
                        data: {
                            ...sdg.data,
                            questions: sdg.data.questions?.map((question) => {
                                if (
                                    question.question ===
                                    action.payload.data.question
                                ) {
                                    return {
                                        ...question,
                                        answer: action.payload.data.answer,
                                        reference_for_answer:
                                            action.payload.data.answer === 'No'
                                                ? ''
                                                : question.reference_for_answer,
                                    };
                                }

                                return question;
                            }),
                        },
                        state: action.payload.state ?? DataState.UPDATED,
                    };
                }
                return sdg;
            });

            state.table = modifiedTable;
        },
        updateSDGReferenceForAnswer: (
            state,
            action: PayloadAction<{
                data: {
                    _id: string;
                    question: string;
                    reference_for_answer: string;
                };
                state?: DataState;
            }>
        ) => {
            const modifiedTable = state.table.map((sdg) => {
                if (sdg.data._id === action.payload.data._id) {
                    return {
                        ...sdg,
                        data: {
                            ...sdg.data,
                            questions: sdg.data.questions?.map((question) => {
                                if (
                                    question.question ===
                                    action.payload.data.question
                                ) {
                                    return {
                                        ...question,
                                        reference_for_answer:
                                            action.payload.data
                                                .reference_for_answer,
                                    };
                                }

                                return question;
                            }),
                        },
                        state: action.payload.state ?? DataState.UPDATED,
                    };
                }
                return sdg;
            });

            state.table = modifiedTable;
        },
        updateSDGQaStatus: (
            state,
            action: PayloadAction<{
                data: {
                    _id: string;
                    qaStatus: QaStatus;
                };
                state?: DataState;
            }>
        ) => {
            const modifiedTable = state.table.map((sdg) => {
                if (sdg.data._id === action.payload.data._id) {
                    return {
                        ...sdg,
                        data: {
                            ...sdg.data,
                            qa_status: action.payload.data.qaStatus,
                        },
                        state: action.payload.state ?? DataState.UPDATED,
                    };
                }
                return sdg;
            });

            state.table = modifiedTable;
        },
        updateQaStatus: (
            state,
            action: PayloadAction<{
                _id: string;
                qaStatus: QaStatus;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        data: {
                            ...el.data,
                            qa_status: action.payload.qaStatus,
                        },
                        state: DataState.UPDATED,
                        selected: false,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
        toggleSelected: (
            state,
            action: PayloadAction<{
                _id: string;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        selected: !el.selected,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
    },
});

export const {
    setInitialSDG,
    updateSDGAnswer,
    updateSDGReferenceForAnswer,
    updateSDG,
    updateSDGQaStatus,
    updateSDGAnswerToNO,
    updatePage,
    updateRowsPerPage,
    updateQaStatus,
    toggleSelected,
} = sdgAssessmentSlice.actions;

export const selectSDGAssessment = (state: RootState) => state.sdgAssessment;

export const selectSDGAssessmentTableData = (state: RootState) =>
    state.sdgAssessment.table.map((el) => ({
        data: el.data,
        isSelected: el.selected,
    }));

export const selectSDGAssessmentTable = (state: RootState) =>
    state.sdgAssessment.table;

export const selectSDGAssessmentPageData = (state: RootState) => ({
    page: state.sdgAssessment.page,
    rowsPerPage: state.sdgAssessment.rowsPerPage,
});

export default sdgAssessmentSlice.reducer;
