import { FC, memo, SyntheticEvent } from 'react';
import { Autocomplete, TextField } from '@mui/material';

export type OptionValue = {
    value: string;
    label: string;
};

type Props = {
    options: OptionValue[];
    value: string;
    onChange: (value: OptionValue) => void;
    label: string;
    disabled?: boolean;
};

export const CustomAutoCompleteComboBoxField: FC<Props> = memo(
    ({ options, onChange, value, label, disabled = false }) => {
        const modifiedValue = value
            ? options.find((el) => el.value === value)
            : null;

        const handleChange = (
            event: SyntheticEvent,
            selectedOptions: OptionValue | null
        ) => {
            if (selectedOptions !== null) {
                onChange(selectedOptions);
            }
        };

        return (
            <Autocomplete
                fullWidth
                disabled={disabled}
                options={options}
                renderInput={(params) => {
                    return <TextField {...params} label={label} />;
                }}
                onChange={handleChange}
                value={modifiedValue}
                disablePortal
            />
        );
    }
);
