import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface AutoSaveState {
    autoSaveTimer: NodeJS.Timeout | null;
    enableAutoSave: boolean;
}

const initialState: AutoSaveState = {
    autoSaveTimer: null,
    enableAutoSave: false,
};

export const autoSaveSlice = createSlice({
    name: 'autoSave',
    initialState,
    reducers: {
        saveTimer: (
            state,
            action: PayloadAction<{ timeoutId: NodeJS.Timeout }>
        ) => {
            state.autoSaveTimer = action.payload.timeoutId;
        },
        enableAutoSave: (state) => {
            state.enableAutoSave = true;
        },
        resetAutoSave: (state) => {
            state.autoSaveTimer = null;
            state.enableAutoSave = false;
        },
    },
});

export const { enableAutoSave, resetAutoSave, saveTimer } =
    autoSaveSlice.actions;

export const selectAutoSave = (state: RootState) => state.autoSave;

export default autoSaveSlice.reducer;
