import { authApi } from 'lib';
import {
    ControversyAssessmentListResponse,
    CreateControversyAssessment,
} from './controversyAssessmentTypes';

export const getControversyAssessmentList = async (id: string) => {
    const response = await authApi.get<ControversyAssessmentListResponse>(
        '/api/v1/controversies_assessments',
        { params: { assessment_id: id } }
    );
    return response.data;
};

export const updateControversyAssessment = async (
    id: string,
    request: CreateControversyAssessment
) => {
    const requestData = replaceEmptyStringsWithNull(request);
    const response = await authApi.put(
        `/api/v1/controversies_assessments/${id}`,
        requestData
    );
    return response.data;
};

export const createControversyAssessment = async (
    request: CreateControversyAssessment
) => {
    const requestData = replaceEmptyStringsWithNull(request);
    const response = await authApi.post(
        `/api/v1/controversies_assessments`,
        requestData
    );
    return response.data;
};

export const deleteControversyAssessment = async (id: string) => {
    return await authApi.delete(`/api/v1/controversies_assessments/${id}`);
};

// Utility function to replace empty strings with null
const replaceEmptyStringsWithNull = (
    obj: CreateControversyAssessment
): CreateControversyAssessment => {
    const replacedObj: CreateControversyAssessment = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            //@ts-ignore
            if (typeof obj[key] === 'string' && obj[key].trim() === '') {
                //@ts-ignore
                replacedObj[key] = null;
            } else {
                //@ts-ignore
                replacedObj[key] = obj[key];
            }
        }
    }
    return replacedObj;
};
