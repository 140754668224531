import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Fab,
    MenuItem,
    Select,
} from '@mui/material';
import { FC, useState } from 'react';
import { useUpdateInternalExternal } from 'api/hooks/assessment/useUpdateInternalExternal';

type Props = {
    assessmentIds: string[];
};

export const InternalExternalEdit: FC<Props> = ({ assessmentIds }) => {
    const [internal, setInternal] = useState<null | boolean>(null);
    const [open, setOpen] = useState(false);

    const { mutateAsync } = useUpdateInternalExternal({});

    const handleSave = () => {
        if (internal !== null) {
            mutateAsync({
                assessment_ids: assessmentIds,
                internal,
            });
            setOpen(false);
        }
    };

    const handleInputChange = (value: boolean) => {
        setInternal(value);
    };

    return (
        <>
            <>
                <Box>
                    <Fab variant='extended' onClick={() => setOpen(true)}>
                        Change Internal/External
                    </Fab>
                </Box>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    fullWidth
                    maxWidth='sm'
                >
                    <DialogTitle>Internal/External Edit</DialogTitle>
                    <DialogContent>
                        <Box display='flex'>
                            <Select
                                fullWidth
                                id='internalExternal'
                                // label='Qa Status'
                                name='Internal/External'
                                value={
                                    internal === true
                                        ? 'true'
                                        : internal === false
                                        ? 'false'
                                        : null
                                }
                                onChange={(e) => {
                                    if (e.target.value) {
                                        handleInputChange(
                                            e.target.value === 'true'
                                                ? true
                                                : false
                                        );
                                    }
                                }}
                            >
                                <MenuItem value={'true'}>Internal</MenuItem>
                                <MenuItem value={'false'}>External</MenuItem>
                                {/* {qaTypeOptions &&
                                    qaTypeOptions.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label || option.value}
                                        </MenuItem>
                                    ))} */}
                            </Select>
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={handleSave}
                            >
                                Save
                            </Button>
                        </Box>
                    </DialogContent>
                </Dialog>
            </>
        </>
    );
};
