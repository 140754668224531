import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
import { DataState } from 'store/enums';
import {
    MainQuestionAssessment,
    MainQuestionAssessmentFilters,
    MainQuestionAssessmentListResponse,
} from 'api/hooks/main-question-assessment/mainQuestionAssessmentTypes';
import { QaStatus } from 'api/hooks/commonTypes';

export interface MainQuestionAssessmentState {
    table: {
        data: MainQuestionAssessment;
        state: DataState;
        selected: boolean;
    }[];
    page: number;
    rowsPerPage: number;
    filters?: MainQuestionAssessmentFilters;
}

const initialState: MainQuestionAssessmentState = {
    table: [],
    page: 0,
    rowsPerPage: 100,
    filters: {},
};

export const mainQuestionAssessmentSlice = createSlice({
    name: 'mainQuestionAssessment',
    initialState,
    reducers: {
        updatePage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },
        updateRowsPerPage: (state, action: PayloadAction<number>) => {
            state.rowsPerPage = action.payload;
        },
        updateFilters: (
            state,
            action: PayloadAction<MainQuestionAssessmentFilters>
        ) => {
            state.filters = action.payload;
        },
        setInitialMainQuestions: (
            state,
            action: PayloadAction<MainQuestionAssessmentListResponse>
        ) => {
            const newTable = action.payload.map((mainQuestion) => ({
                data: mainQuestion,
                state: DataState.INITIAL,
                selected: false,
            }));
            state.table = newTable;
        },
        updateMainQuestion: (
            state,
            action: PayloadAction<{
                data: MainQuestionAssessment;
                state?: DataState;
            }>
        ) => {
            // const modifiedTable = state.table?.map((mainQuestion) => {
            //     if (mainQuestion.data._id === action.payload.data._id) {
            //         return {
            //             data: action.payload.data,
            //             state: action.payload.state ?? DataState.UPDATED,
            //         };
            //     }
            //     return mainQuestion;
            // });

            const indexOfQuestion = state.table.findIndex(
                (el) => el.data._id === action.payload.data._id
            );
            state.table[indexOfQuestion] = {
                ...state.table[indexOfQuestion],
                data: action.payload.data,
                state: action.payload.state ?? DataState.UPDATED,
            };
        },
        updateMainQuestionAnswerToND: (
            state,
            action: PayloadAction<{ _id: string }>
        ) => {
            const modifiedTable = state.table.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        data: {
                            ...el.data,
                            answer: 'ND',
                            score: '0',
                            reference_for_answer: 'ND',
                        } as MainQuestionAssessment,
                        state: DataState.UPDATED,
                    };
                }
                return el;
            });

            state.table = modifiedTable;
        },
        updateQaStatus: (
            state,
            action: PayloadAction<{
                _id: string;
                qaStatus: QaStatus;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        data: {
                            ...el.data,
                            qa_status: action.payload.qaStatus,
                        },
                        state: DataState.UPDATED,
                        selected: false,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
        toggleSelected: (
            state,
            action: PayloadAction<{
                _id: string;
            }>
        ) => {
            const modifiedFormData = state.table?.map((el) => {
                if (el.data._id === action.payload._id) {
                    return {
                        ...el,
                        selected: !el.selected,
                    };
                }
                return el;
            });
            state.table = modifiedFormData;
        },
    },
});

export const {
    setInitialMainQuestions,
    updateMainQuestion,
    updateFilters,
    updatePage,
    updateRowsPerPage,
    updateMainQuestionAnswerToND,
    updateQaStatus,
    toggleSelected,
} = mainQuestionAssessmentSlice.actions;

const selectSelf = (state: RootState) => state.mainQuestionAssessment;

export const selectMainQuestionAssessment = createSelector(
    selectSelf,
    (state: MainQuestionAssessmentState) => state
);

export const selectMainQuestionAssessmentTableData = createSelector(
    selectSelf,
    (state: MainQuestionAssessmentState) => state.table.map((el) => el.data)
);

export const selectMainQuestionAssessmentTable = createSelector(
    selectSelf,
    (state: MainQuestionAssessmentState) => state.table
);

export const selectMainQuestionAssessmentPageData = createSelector(
    selectSelf,
    (state: MainQuestionAssessmentState) => ({
        page: state.page,
        rowsPerPage: state.rowsPerPage,
    })
);

export const selectMainQuestionAssessmentFilters = createSelector(
    selectSelf,
    (state: MainQuestionAssessmentState) => state.filters
);

export default mainQuestionAssessmentSlice.reducer;
