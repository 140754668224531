import { useQuery } from 'react-query';
import { AnalystAndAssurerResponse } from './assessmentTypes';
import { getAnalystAndAssurer } from './assessmentService';

type Props<M> = {
    assessmentId: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: AnalystAndAssurerResponse) => void;
    mapper?: (
        data?: AnalystAndAssurerResponse
    ) => M | AnalystAndAssurerResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: AnalystAndAssurerResponse) => data;

export const useGetAnalystAndAssurer = <M = AnalystAndAssurerResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    assessmentId,
}: Props<M>) => {
    const query = useQuery<AnalystAndAssurerResponse, Error>(
        ['analystAndAssurer', assessmentId],
        () => getAnalystAndAssurer(assessmentId),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
