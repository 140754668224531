import { useQuery } from 'react-query';
import { AssessmentStatusResponse } from './assessmentTypes';
import { getAssessmentStatus } from './assessmentService';

type Props<M> = {
    assessmentId: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: AssessmentStatusResponse) => void;
    mapper?: (
        data?: AssessmentStatusResponse
    ) => M | AssessmentStatusResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: AssessmentStatusResponse) => data;

export const useGetAssessmentStatus = <M = AssessmentStatusResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    assessmentId,
}: Props<M>) => {
    const query = useQuery<AssessmentStatusResponse, Error>(
        ['assessmentStatus', assessmentId],
        () => getAssessmentStatus(assessmentId),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
