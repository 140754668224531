import { CssBaseline } from '@mui/material';
import { AppRoutes } from './routes';
import { ToastContainer } from 'react-toastify';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

import 'react-toastify/dist/ReactToastify.css';

const App = () => {
    console.log('v2.0.0');

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <ToastContainer />
            <CssBaseline />
            <AppRoutes />
        </LocalizationProvider>
    );
};

export default App;
