import { User } from './user/userTypes';

export type Error = {
    loc: Array<string | number>;
    msg: string;
    type: string;
};

export type ErrorResponse = {
    detail?: string | Error[];
};

export type AssurerComment = {
    date_time?: string;
    user?: Partial<User>;
    comment?: string;
};

export type ScoreByAnswer = {
    answer?: string;
    score?: number;
};

export enum QaStatus {
    PENDING = 'PENDING',
    CORRECT = 'CORRECT',
    INCORRECT = 'INCORRECT',
    AMENDED = 'AMENDED',
    WARNING_RESOLVED = 'WARNING_RESOLVED',
    ERROR_RESOLVED = 'ERROR_RESOLVED',
}
