import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { AUTH_TOKEN } from 'constants/session-storage-constants';
import { useGetMe } from 'api/hooks';
import { ScreenLoader } from '../screen-loader';
import { RoleType, UserType } from 'api/hooks/user/userTypes';

interface Props {
    allowedTypes?: UserType[];
    allowedRoles?: RoleType[];
    isForSuperUser?: boolean;
}

export const RequireUser: FC<Props> = ({
    allowedRoles = [],
    allowedTypes = [],
    isForSuperUser = false,
}) => {
    const location = useLocation();
    // const navigate = useNavigate();
    const token = localStorage.getItem(AUTH_TOKEN);
    // const user = useSelector(selectAuthUser);

    const { data: user, isLoading } = useGetMe({
        enabled: !!token,
    });

    // useEffect(() => {
    //     getMe.refetch();
    // }, [location.pathname]);

    const isUserAuthenticated = user && !!token;

    const isUserRoleAllowed = user?.roles
        ? allowedRoles.length > 0
            ? user.roles.some((value) => allowedRoles.includes(value))
            : true
        : true;

    const isUserTypeAllowed = user?.type
        ? allowedTypes.length > 0
            ? allowedTypes.includes(user.type)
            : true
        : true;

    const isOnlyForSuperUser = user?.is_superuser;

    const isUserAllowed =
        isOnlyForSuperUser ||
        (user?.type === UserType.CLIENT && isUserTypeAllowed) ||
        (user?.type === UserType.EMPLOYEE &&
            isUserTypeAllowed &&
            isUserRoleAllowed);

    if (isLoading) {
        return <ScreenLoader />;
    }

    if (
        user &&
        !user?.initial_password_changed &&
        location.pathname !== '/change-password'
    ) {
        return <Navigate to='change-password' replace />;
        // navigate(RoutePaths.CHANGE_PASSWORD);
    }

    // if (!isUserAuthenticated) {
    //     navigate(RoutePaths.LOGIN);
    // }

    // if (!isUserRoleAllowed || !isUserTypeAllowed) {
    //     console.log('NOT ALLOWED');
    //     navigate(RoutePaths.NOT_ALLOWED);
    // }

    // return <Outlet />;

    return isUserAuthenticated ? (
        isUserAllowed ? (
            <Outlet />
        ) : (
            <Navigate to='not-allowed' replace />
        )
    ) : (
        <Navigate to='login' replace />
    );
};
