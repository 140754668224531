import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, IconButton } from '@mui/material';
import { FC, useState } from 'react';
import { CustomTableHeader } from './table-header';
import { CustomTableBody } from './table-body';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
    useCreatePeerGroup,
    useGetCompanyList,
    useUpdatePeerGroup,
} from 'api/hooks';
import { DialogComponent } from 'components/dialog-component';
import { PeerGroupForm } from './form';
import { useGetPeerGroupList } from 'api/hooks/peer-group/useGetPeerGroupList';
import { CustomPagination } from 'components/shared/custom-pagination';
import { WithSpinner } from 'components/with-spinner';
import {
    CreatePeerGroup,
    PeerGroup,
    UpdatePeerGroup,
} from 'api/hooks/peer-group/peerGroupTypes';

type Props = {};

export const AdminPeerGroupsListTable: FC<Props> = () => {
    const [recordToCreateUpdate, setRecordToCreateUpdate] =
        useState<PeerGroup | null>(null);
    const [formOpen, setFormOpen] = useState(false);
    const [isCreate, setIsCreate] = useState(false);

    const {
        data,
        isLoading: peerGroupsLoading,
        query: getList,
    } = useGetPeerGroupList({});
    const { data: companiesData, isLoading: companiesLoading } =
        useGetCompanyList({});

    const { mutate: create } = useCreatePeerGroup({
        onSuccess: (data) => {
            getList.refetch();
            setFormOpen(false);
        },
    });

    const { mutate: update } = useUpdatePeerGroup({
        onSuccess: (data) => {
            getList.refetch();
            setFormOpen(false);
        },
    });

    const handleOpenCreateFormDialog = () => {
        setRecordToCreateUpdate({} as PeerGroup);
        setFormOpen(true);
        setIsCreate(true);
    };

    const handleOpenEditFormDialog = (id: string) => {
        const foundRecord = data.find((record) => record._id === id);

        if (!foundRecord) return;

        setRecordToCreateUpdate(foundRecord);
        setFormOpen(true);
        setIsCreate(false);
    };

    const handleFormClose = () => {
        setFormOpen(false);
    };

    const handleFormSave = (data: CreatePeerGroup | UpdatePeerGroup) => {
        if (isCreate) {
            const dataToCreate = data as CreatePeerGroup;
            create(dataToCreate);
        } else {
            const dataToUpdate = data as UpdatePeerGroup;
            update({
                id: recordToCreateUpdate?._id ?? '',
                request: dataToUpdate,
            });
        }
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const pagedData =
        (data &&
            data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((element) => element)) ??
        [];

    const isLoading = peerGroupsLoading || companiesLoading;

    return (
        <WithSpinner isLoading={isLoading}>
            <Box sx={{ width: '100%', ml: 1 }}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <IconButton onClick={handleOpenCreateFormDialog}>
                        <AddCircleIcon fontSize='large' color='primary' />
                    </IconButton>
                    <TableContainer>
                        <Table stickyHeader>
                            <CustomTableHeader />
                            <CustomTableBody
                                data={pagedData}
                                openEditFormDialog={handleOpenEditFormDialog}
                            />
                        </Table>
                    </TableContainer>
                    <CustomPagination
                        dataLength={data?.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </Paper>
                <DialogComponent open={formOpen} onClose={handleFormClose}>
                    <PeerGroupForm
                        record={recordToCreateUpdate}
                        companiesData={companiesData}
                        onClose={handleFormClose}
                        onSave={handleFormSave}
                        isCreate={isCreate}
                    />
                </DialogComponent>
            </Box>
        </WithSpinner>
    );
};
