export enum RoutePaths {
    DASHBOARD = '/',
    LOGIN = '/login',
    MFA = '/mfa',
    RECOVER_PASSWORD = '/recover-password',
    RESET_PASSWORD = '/reset-password',
    CHANGE_PASSWORD = '/change-password',
    NOT_ALLOWED = '/not-allowed',
    COMPANY_LIST = '/company-list',
    USER_ERROR_LIST = '/user-error-list',
    ASSESSMENT_LIST = '/assessment-list',
    QUERY_BUILDER = '/query-builder',
    COMPANY_INFORMATION = '/company-information',
    ASSESSMENT_INFORMATION = '/assessment-information',
    GENERAL_INFORMATION = '/general-information',
    ADMIN = '/admin',
}

export enum AdminRoutePaths {
    ADMIN_COUNTRIES = '/countries',
    ADMIN_INDUSTRIES = '/industries',
    ADMIN_PEERGROUPS = '/peergroups',
    ADMIN_USERS = '/users',
    ADMIN_MAIN_QUESTIONS = '/main-questions',
    ADMIN_COMPANY_PERFORMANCE = '/company-performance',
    ADMIN_CATEGORY = '/category',
    ADMIN_SDG_QUESTIONS = '/sdg-questions',
    ADMIN_MATERIALITY_QUESTIONS = '/materiality-questions',
    ADMIN_IMPORT = '/import',
    ADMIN_IMPORT_USERS = '/users',
    ADMIN_IMPORT_PERFORMANCE_DATA = '/performance-data',
    ADMIN_IMPORT_ASSESSMENTS = '/assessments',
    ADMIN_IMPORT_REPORT_DATA = '/report-data',
}

export enum RouteParams {
    ASSESSMENT_ID = '/:assessmentId',
    COMPANY_ID = '/:companyId',
    MAIN_QUESTION_ID = '/:mainQuestionId',
    COMPANY_PERFORMANCE_ID = '/:companyPerformanceId',
    CATEGORY_ID = '/:categoryId',
    SDG_QUESTION_ID = '/:sdgQuestionId',
    MATERIALITY_QUESTION_ID = '/:materialityQuestionId',
}
