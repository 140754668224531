import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, Button } from '@mui/material';
import { FC, useState } from 'react';
import { CustomTableHeader } from './table-header';
import { CustomTableBody } from './table-body';
import {
    useCreateUserImport,
    useCreateAssessmentImport,
    useGetImportList,
    useCreateCompanyPerformanceReportDataImport,
    useCreateCompanyReportDataImport,
} from 'api/hooks';
import { CustomPagination } from 'components/shared/custom-pagination';
import { WithSpinner } from 'components/with-spinner';
import { ImportType } from 'api/hooks/import/importType';

type Props = {
    importType: ImportType;
};

export const AdminImportListTable: FC<Props> = ({ importType }) => {
    const [isFileChosen, setIsFileChosen] = useState(false);
    const [fileName, setFileName] = useState('');
    const [file, setFile] = useState<null | File>(null);

    const {
        data,
        isLoading,
        query: getList,
    } = useGetImportList({
        mapper: (data) => {
            const filteredData = data?.filter(
                (dataElement) => dataElement.type === importType
            );

            return filteredData ?? [];
        },
        refetchInterval: 5000,
    });

    const { mutate: createAssessmentImport } = useCreateAssessmentImport({
        onSuccess: (data) => {
            getList.refetch();
        },
    });

    const { mutate: createUserImport } = useCreateUserImport({
        onSuccess: (data) => {
            getList.refetch();
        },
    });

    const { mutate: createCompanyPerformanceReportData } =
        useCreateCompanyPerformanceReportDataImport({
            onSuccess: (data) => {
                getList.refetch();
            },
        });

    const { mutate: createCompanyReportData } =
        useCreateCompanyReportDataImport({
            onSuccess: () => {
                getList.refetch();
            },
        });

    const handleImport = () => {
        if (!file) return;
        const formData = new FormData();
        formData.append('file', file);
        if (importType === ImportType.ASSESSMENT) {
            createAssessmentImport(formData);
        }
        if (importType === ImportType.USER) {
            createUserImport(formData);
        }
        if (importType === ImportType.PERFORMANCE_DATA) {
            createCompanyPerformanceReportData(formData);
        }
        if (importType === ImportType.REPORT_DATA) {
            createCompanyReportData(formData);
        }
        setFileName('');
        setFile(null);
        setIsFileChosen(false);
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const pagedData =
        (data &&
            data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((element) => element)) ??
        [];

    const fileAcceptTypes =
        importType === ImportType.ASSESSMENT ? '.zip,.rar,.7z,.gz' : '.csv';

    return (
        <WithSpinner isLoading={isLoading}>
            <Box sx={{ width: '100%', ml: 1 }}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <Button variant='outlined' component='label' sx={{ m: 2 }}>
                        {fileName || 'Upload'}
                        <input
                            hidden
                            accept={fileAcceptTypes}
                            type='file'
                            onChange={(event) => {
                                if (event.target.files) {
                                    setFileName(event.target.files[0].name);
                                    setIsFileChosen(true);
                                    setFile(event.target.files[0]);
                                }
                            }}
                        />
                    </Button>
                    <Button
                        onClick={handleImport}
                        disabled={!isFileChosen}
                        variant='contained'
                    >
                        Import
                    </Button>
                    <TableContainer>
                        <Table stickyHeader>
                            <CustomTableHeader />
                            <CustomTableBody data={pagedData} />
                        </Table>
                    </TableContainer>
                    <CustomPagination
                        dataLength={data?.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </Paper>
            </Box>
        </WithSpinner>
    );
};
