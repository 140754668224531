import { FC, memo } from 'react';

import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    FormControlClasses,
    SelectProps,
} from '@mui/material';

export type OptionValue = {
    value: string | number | undefined;
    label?: string | number;
};

type Props = SelectProps & {
    formName?: string;
    options?: OptionValue[];
    errorText?: string | string[] | undefined;
    formControlClasses?: Partial<FormControlClasses>;
    helperText?: string;
};

export const CustomSelectField: FC<Props> = memo(
    ({
        name,
        formName,
        label,
        defaultValue,
        options,
        value,
        onChange,
        formControlClasses,
        disabled = false,
        errorText,
        helperText,
        required = false,
    }) => {
        const isHasError = Boolean(errorText);
        const isHelperShown = Boolean(helperText) && !isHasError;

        return (
            <FormControl
                fullWidth
                key={name}
                required={required}
                variant='outlined'
                {...(formControlClasses ? { classes: formControlClasses } : {})}
            >
                {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
                <Select
                    defaultValue={defaultValue}
                    disabled={disabled}
                    error={isHasError}
                    inputProps={{
                        id: name,
                        name: name,
                        'data-testid': `${formName}-${name}`,
                    }}
                    label={label}
                    value={value || ''}
                    onChange={onChange}
                >
                    {options &&
                        options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label || option.value}
                            </MenuItem>
                        ))}
                </Select>
                {isHasError && (
                    <FormHelperText error>{label} is required</FormHelperText>
                )}
                {isHelperShown && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
);
