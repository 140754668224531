import { Button, TableBody, TableCell, TableRow } from '@mui/material';
import { MainQuestion } from 'api/hooks/main-question/mainQuestionsTypes';

import { FC } from 'react';

type Props = {
    data: MainQuestion[];
    onEdit: (id: string) => void;
};

export const CustomTableBody: FC<Props> = ({ data, onEdit }) => {
    return (
        <>
            <TableBody>
                {data.map((row) => {
                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell>{row.identifier}</TableCell>
                            <TableCell align='right'>{row.unique_id}</TableCell>
                            <TableCell align='right'>{row.category}</TableCell>
                            <TableCell align='right'>
                                {row.sub_category_first}
                            </TableCell>
                            <TableCell align='right'>
                                {row.sub_category_second}
                            </TableCell>
                            <TableCell align='right'>{row.question}</TableCell>
                            <TableCell align='right'>
                                <Button onClick={() => onEdit(row._id)}>
                                    Edit
                                </Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </>
    );
};
