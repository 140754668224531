import { Container } from '@mui/material';
import {
    AssessmentDirection,
    AssessmentStatus,
    CreateUpdateGeneralInformation,
} from 'api/hooks/assessment/assessmentTypes';
import { useFlow } from 'api/hooks/assessment/useFlow';
import { WithSpinner } from 'components/with-spinner';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router';
import { RoutePaths } from 'routes';
import { AssessmentGeneralInformationForm } from './assessment-general-information-form';
import { useCreateGeneralInformation } from 'api/hooks/assessment/useCreateGeneralInformation';
import { useUpdateGeneralInformation } from 'api/hooks/assessment/useUpdateGeneralInformation';
import { useGetGeneralInformation } from 'api/hooks/assessment/useGetGeneralInformation';
import { useCheckAssessmentStatus } from 'api/hooks/common/useCheckAssessmentStatus';
import { generateRoutePath } from 'utils';
import { useGetCompaniesForSelects } from 'api/hooks/company/useGetCompaniesForSelects';
import { useGetUsersForSelects } from 'api/hooks/user/useGetUsersForSelects';
import { RoleType } from 'api/hooks/user/userTypes';

export const AssessmentGeneralInformation: FC = () => {
    const navigate = useNavigate();

    const { assessmentId } = useParams();

    const assessmentStatus = useCheckAssessmentStatus({
        assessmentId: assessmentId ?? '',
        currentStatuses: [AssessmentStatus.DRAFT],
    });

    const { data: generalInformation, isLoading: generalInformationLoading } =
        useGetGeneralInformation({
            assessmentId: assessmentId ?? '',
            enabled: !!assessmentId && assessmentId !== '0',
        });
    const { data: analysts, isLoading: analystsLoading } =
        useGetUsersForSelects({
            filters: { roles: [RoleType.ANALYST] },
        });
    const { data: assurers, isLoading: assurersLoading } =
        useGetUsersForSelects({
            filters: { roles: [RoleType.ASSURER] },
        });
    const { data: companies, isLoading: companiesLoading } =
        useGetCompaniesForSelects({});
    const { mutateAsync: create } = useCreateGeneralInformation({});
    const { mutateAsync: update } = useUpdateGeneralInformation({});
    const { mutateAsync: flow } = useFlow({});

    const handleCancel = () => {
        navigate(RoutePaths.ASSESSMENT_LIST);
    };

    const handleSave = async (
        data: CreateUpdateGeneralInformation,
        needToContinueFlow: boolean
    ) => {
        let id: string = '';
        if (assessmentId !== '0' && !!assessmentId) {
            await update({
                id: assessmentId,
                request: data,
            });
            id = assessmentId;
        } else {
            const assessment = await create(data);
            id = assessment._id;
        }

        if (needToContinueFlow) {
            await flow({
                id: id,
                request: { direction: AssessmentDirection.PROCESS },
            });
        }

        navigate(generateRoutePath([RoutePaths.ASSESSMENT_LIST, `/${id}`]));
    };

    const isLoading =
        generalInformationLoading ||
        analystsLoading ||
        assurersLoading ||
        companiesLoading;

    return (
        <WithSpinner isLoading={isLoading}>
            <Container>
                <AssessmentGeneralInformationForm
                    analysts={analysts}
                    assurers={assurers}
                    generalInformation={generalInformation}
                    companies={companies}
                    onCancel={handleCancel}
                    onSave={handleSave}
                    isReadOnly={assessmentStatus.isReadOnly}
                />
            </Container>
        </WithSpinner>
    );
};
