import { TableCell, TableHead, TableRow } from '@mui/material';

export const CustomTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell>Section</TableCell>
                <TableCell align='right'>Topic</TableCell>
                <TableCell align='right'>Link To AT</TableCell>
                <TableCell align='right'>Unique ID</TableCell>
                <TableCell align='right'>Question ID</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
            </TableRow>
        </TableHead>
    );
};
