import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { FC, memo, useState } from 'react';
import { CustomPagination } from 'components/shared/custom-pagination';
import { UserErrorRate } from 'api/hooks/user/userTypes';

type Props = {
    data: UserErrorRate[];
};

const UserErrorRateListTable: FC<Props> = ({ data }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    return (
        <Box sx={{ mt: 5 }}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align='right'>Last Name</TableCell>
                                <TableCell align='right'>
                                    Company Name
                                </TableCell>
                                <TableCell align='right'>QA Type</TableCell>
                                <TableCell align='right'>
                                    Fiscal Year End
                                </TableCell>
                                <TableCell align='right'>ISIN</TableCell>
                                <TableCell align='right'>
                                    Calculation Date
                                </TableCell>
                                <TableCell align='right'>
                                    Main Questionnaire Error Rate
                                </TableCell>
                                <TableCell align='right'>
                                    Company Performance Error Rate
                                </TableCell>
                                <TableCell align='right'>
                                    Normilized Error Rate
                                </TableCell>
                                <TableCell align='right'>
                                    Number Of Questions
                                </TableCell>
                                <TableCell align='right'>
                                    Number Of Yes
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data
                                ?.slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row, index) => (
                                    <TableRow
                                        key={index}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component='th' scope='row'>
                                            {row.name}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.last_name}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.company_name || ''}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.qa_type || ''}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.fiscal_year_end.toString()}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.isin}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.calculation_date.toString()}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.main_questionnaire_error_rate}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.company_performance_error_rate}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.normalized_error_rate}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.number_of_questions}
                                        </TableCell>
                                        <TableCell align='right'>
                                            {row.number_of_yes}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <CustomPagination
                    dataLength={data?.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
        </Box>
    );
};

export default memo(UserErrorRateListTable);
