import { useQuery } from 'react-query';
import { SDGQuestionResponse } from './sdgQuestionsTypes';
import { getSDGQuestionById } from './sdgQuestionService';

type Props<M> = {
    id: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: SDGQuestionResponse) => void;
    mapper?: (
        data?: SDGQuestionResponse
    ) => M | SDGQuestionResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: SDGQuestionResponse) => data;

export const useGetSDGQuestion = <M = SDGQuestionResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    id,
}: Props<M>) => {
    const query = useQuery<SDGQuestionResponse, Error>(
        ['sdgQuestion', id],
        () => getSDGQuestionById(id),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
