import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box } from '@mui/material';
import { FC, useState } from 'react';
import { CustomTableHeader } from './table-header';
import { CustomTableBody } from './table-body';
import { useGetReports } from 'api/hooks';
import { CustomPagination } from 'components/shared/custom-pagination';
import { WithSpinner } from 'components/with-spinner';
import { Report } from 'api/hooks/report/reportTypes';

type Props = {
    regenerateReport: (report: Report) => void;
};

export const ReportListTable: FC<Props> = ({ regenerateReport }) => {
    const { data, isLoading } = useGetReports({
        refetchInterval: 5000,
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const pagedData =
        (data &&
            data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((element) => element)) ??
        [];

    return (
        <WithSpinner isLoading={isLoading}>
            <Box sx={{ mt: 5 }}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TableContainer>
                        <Table stickyHeader>
                            <CustomTableHeader />
                            <CustomTableBody
                                data={pagedData}
                                regenerateReport={regenerateReport}
                            />
                        </Table>
                    </TableContainer>
                    <CustomPagination
                        dataLength={data?.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </Paper>
            </Box>
        </WithSpinner>
    );
};
