import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Button, Checkbox } from '@mui/material';
import { FC, useState } from 'react';
import ArrowRightTwoToneIcon from '@mui/icons-material/ArrowRightTwoTone';
import { CustomPagination } from 'components/shared/custom-pagination';
import { Assessment } from 'api/hooks/assessment/assessmentTypes';
import { useGetMe } from 'api/hooks';
import { RoleType } from 'api/hooks/user/userTypes';

type Props = {
    data: Assessment[];
    onOpenClick: (id: string) => void;
    onSort: (columnName: string) => void;
    currentSortColumn: string | null;
    currentSortOrder: string;
    onCheckAssessment: (assessmentId: string, checked: boolean) => void;
};

export const AssessmentListTable: FC<Props> = ({
    data,
    onOpenClick,
    onSort,
    currentSortColumn,
    currentSortOrder,
    onCheckAssessment,
}) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const { data: user, isLoading } = useGetMe({});

    const isAdmin = user?.roles?.includes(RoleType.ADMIN) ?? false;

    const handleSort = (columnName: string) => {
        onSort(columnName);
    };

    const columns = [
        { name: '', label: '' },
        { name: 'company.name', label: 'Company Name' },
        { name: 'fiscal_year', label: 'Fiscal Year' },
        { name: 'analyst.name', label: 'Analyst' },
        { name: 'assessment_deadline', label: 'Analyst Deadline' },
        { name: 'assurer.name', label: 'QA' },
        { name: 'assurance_deadline', label: 'QA Deadline' },
        { name: 'status', label: 'Status' },
        { name: 'company.is_client', label: 'Client' },
        { name: 'company.peer_group', label: 'Peer Group' },
        { name: 'company.peer_group', label: 'Internal/External' },
        { name: '', label: '' },
    ];

    if (isLoading) {
        return null;
    }

    return (
        <Box sx={{ mt: 5 }}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => {
                                    if (
                                        !isAdmin &&
                                        (column.label === 'Client' ||
                                            column.label === 'Peer Group')
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <TableCell
                                            key={column.name}
                                            // align={index === 0 ? 'left' : 'right'}
                                            onClick={() =>
                                                handleSort(column.name)
                                            }
                                        >
                                            {column.label}
                                            {currentSortColumn ===
                                                column.name && (
                                                <span>
                                                    {currentSortOrder === 'asc'
                                                        ? '▲'
                                                        : '▼'}
                                                </span>
                                            )}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((row) => (
                                    <TableRow
                                        key={row._id}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    border: 0,
                                                },
                                        }}
                                    >
                                        <TableCell component='th' scope='row'>
                                            <Checkbox
                                                onChange={(event) =>
                                                    onCheckAssessment(
                                                        row._id,
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row?.company?.name}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row?.fiscal_year}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row?.analyst?.name}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row?.assessment_deadline?.toString()}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row?.assurer?.name}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row?.assurance_deadline?.toString()}
                                        </TableCell>
                                        <TableCell align='left'>
                                            {row?.status}
                                        </TableCell>
                                        {isAdmin && (
                                            <TableCell align='left'>
                                                {row?.company?.is_client
                                                    ? 'Yes'
                                                    : 'No'}
                                            </TableCell>
                                        )}
                                        {isAdmin && (
                                            <TableCell align='left'>
                                                {row?.company?.peer_group?.name}
                                            </TableCell>
                                        )}
                                        <TableCell align='left'>
                                            {row?.internal
                                                ? 'Internal'
                                                : 'External'}
                                        </TableCell>
                                        <TableCell align='left'>
                                            <Button
                                                onClick={() =>
                                                    onOpenClick(row?._id)
                                                }
                                            >
                                                <ArrowRightTwoToneIcon fontSize='large' />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <CustomPagination
                    dataLength={data?.length}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    setRowsPerPage={setRowsPerPage}
                />
            </Paper>
        </Box>
    );
};
