import { authApi } from 'lib';
import {
    CreateUpdateSDGQuestion,
    SDGQuestionListResponse,
    SDGQuestionResponse,
} from './sdgQuestionsTypes';

export const getSDGQuestionList = async () => {
    const response = await authApi.get<SDGQuestionListResponse>(
        '/api/v1/questions/sdg_questions'
    );
    return response.data;
};

export const getSDGQuestionById = async (id: string) => {
    const response = await authApi.get<SDGQuestionResponse>(
        `/api/v1/questions/sdg_questions/${id}`
    );
    return response.data;
};

export const createSDGQuestion = async (request: CreateUpdateSDGQuestion) => {
    const response = await authApi.post<SDGQuestionResponse>(
        `/api/v1/questions/sdg_questions`,
        request
    );
    return response.data;
};

export const updateSDGQuestion = async (
    id: string,
    request: CreateUpdateSDGQuestion
) => {
    const response = await authApi.put<SDGQuestionResponse>(
        `/api/v1/questions/sdg_questions/${id}`,
        request
    );
    return response.data;
};

export const deleteSDGQuestion = async (id: string) => {
    return await authApi.delete(`/api/v1/questions/sdg_questions/${id}`);
};
