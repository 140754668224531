import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import { Box, Button } from '@mui/material';
import { FC, useState } from 'react';
import { CustomTableHeader } from './table-header';
import { CustomTableBody } from './table-body';
import { CustomPagination } from 'components/shared/custom-pagination';
import { WithSpinner } from 'components/with-spinner';
import { useNavigate } from 'react-router';
import { RoutePaths } from 'routes';
import { generateRoutePath } from 'utils';
import { AdminRoutePaths } from 'constants/enums/routes-constants';
import { useGetSDGQuestionList } from 'api/hooks/sgd-question/useGetSDGQuestionList';
import { useDeleteSDGQuestion } from 'api/hooks/sgd-question/useDeleteSDGQuestion';

type Props = {};

export const AdminSDGQuestionListTable: FC<Props> = () => {
    const navigate = useNavigate();

    const {
        data,
        isLoading,
        query: getSDGQuestionList,
    } = useGetSDGQuestionList({});

    const { mutate: deleteSDGQuestion } = useDeleteSDGQuestion({
        onSuccess: () => {
            getSDGQuestionList.refetch();
        },
    });

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const pagedData =
        (data &&
            data
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((element) => element)) ??
        [];

    const handleEdit = (id: string | null) => {
        const urlId = id ? id : '0';
        navigate(
            generateRoutePath([
                RoutePaths.ADMIN,
                AdminRoutePaths.ADMIN_SDG_QUESTIONS,
                `/${urlId}`,
            ])
        );
    };

    const handleDelete = (id: string) => {
        deleteSDGQuestion(id);
    };

    return (
        <WithSpinner isLoading={isLoading}>
            <Box sx={{ width: '100%', ml: 1 }}>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <Button
                        color='primary'
                        role='submit'
                        type='submit'
                        variant='outlined'
                        onClick={() => handleEdit(null)}
                        sx={{ m: 1 }}
                    >
                        Create New
                    </Button>
                    <TableContainer>
                        <Table stickyHeader>
                            <CustomTableHeader />
                            <CustomTableBody
                                data={pagedData}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                            />
                        </Table>
                    </TableContainer>
                    <CustomPagination
                        dataLength={data?.length}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        setPage={setPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </Paper>
            </Box>
        </WithSpinner>
    );
};
