import { useQuery } from 'react-query';
import {
    CompanyPerformanceAssessmentFilters,
    CompanyPerformanceAssessmentListResponse,
} from './companyPerformanceAssessmentTypes';
import { getCompanyPerformanceAssessmentList } from './companyPerformanceAssessmentService';

type Props<M> = {
    filters: CompanyPerformanceAssessmentFilters;
    assessmentId: string;
    onError?: (error: Error) => void;
    onSuccess?: (data: CompanyPerformanceAssessmentListResponse) => void;
    mapper?: (
        data?: CompanyPerformanceAssessmentListResponse
    ) => M | CompanyPerformanceAssessmentListResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: CompanyPerformanceAssessmentListResponse) => data;

export const useGetCompanyPerformanceAssessmentList = <
    M = CompanyPerformanceAssessmentListResponse
>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    assessmentId,
    filters,
}: Props<M>) => {
    const query = useQuery<CompanyPerformanceAssessmentListResponse, Error>(
        ['companyPerformanceAssessment', assessmentId],
        () => getCompanyPerformanceAssessmentList(assessmentId, filters),
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
