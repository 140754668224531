import { Button, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';

type Props = {
    onSubmit: (email: string) => void;
};

export const RecoverPasswordForm: FC<Props> = ({ onSubmit }) => {
    const formName = 'RecoverPasswordForm';

    const validationSchema = Yup.object({
        email: Yup.string().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            email: '',
        },
        validationSchema,
        onSubmit: (values) => {
            onSubmit(values.email);
        },
    });

    type FormikTypes = typeof formik.initialValues;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <CustomTextField
                    {...generateTextFieldProps<FormikTypes>(
                        'email',
                        'Email',
                        formName,
                        formik
                    )}
                />
                <Button
                    color='primary'
                    data-testid={`${formName}-submit`}
                    role='submit'
                    type='submit'
                    variant='contained'
                >
                    Reset
                </Button>
            </Stack>
        </form>
    );
};
