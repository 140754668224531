import { authApi } from 'lib';
import {
    AssessmentFilters,
    AssessmentListResponse,
    AssessmentResponse,
    AssessmentStatusResponse,
    AssuranceType,
    CompanyInformation,
    CompanyInformationBasic,
    CreateAssessmentRequest,
    CreateAssessmentResponse,
    CreateUpdateCompanyInformation,
    CreateUpdateGeneralInformation,
    FlowRequest,
    GeneralInformation,
    InternalExternalRequest,
    Statistics,
    UpdateAnalystAndAssurer,
} from './assessmentTypes';

export const getAssesmentList = async (filters: AssessmentFilters) => {
    const params = new URLSearchParams();

    if (filters.company_is_client) {
        params.append('company__is_client', String(filters.company_is_client));
    }

    if (filters.company_isin) {
        filters.company_isin.forEach((data) => {
            params.append('company__isin', data);
        });
    }

    if (filters.company_name) {
        filters.company_name.forEach((data) => {
            params.append('company__name', data);
        });
    }

    if (filters.analyst_id) {
        filters.analyst_id.forEach((data) => {
            params.append('analyst___id', data);
        });
    }

    if (filters.assurer_id) {
        filters.assurer_id.forEach((data) => {
            params.append('assurer___id', data);
        });
    }

    if (filters.order_by) {
        params.append('order_by', filters.order_by);
    }

    if (filters.fiscal_year) {
        filters.fiscal_year.forEach((data) => {
            params.append('fiscal_year', data.toString());
        });
    }

    if (filters.assessment_deadline_from) {
        params.append(
            'assessment_deadline_from',
            filters.assessment_deadline_from.toString()
        );
    }

    if (filters.assessment_deadline_to) {
        params.append(
            'assessment_deadline_to',
            filters.assessment_deadline_to.toString()
        );
    }

    if (filters.status) {
        filters.status.forEach((data) => {
            params.append('status', data);
        });
    }

    if (filters.assurance_deadline_from) {
        params.append(
            'assurance_deadline_from',
            filters.assurance_deadline_from.toString()
        );
    }

    if (filters.assurance_deadline_to) {
        params.append(
            'assurance_deadline_to',
            filters.assurance_deadline_to.toString()
        );
    }

    const response = await authApi.get<AssessmentListResponse>(
        `/api/v1/assessments?${params.toString()}`
    );

    return response.data;
};

export const getAssessmentById = async (id: string) => {
    const response = await authApi.get<AssessmentResponse>(
        `/api/v1/assessments/${id}`
    );
    return response.data;
};

export const createAssessment = async (request: CreateAssessmentRequest) => {
    const response = await authApi.post<CreateAssessmentResponse>(
        `/api/v1/assessments`,
        request
    );
    return response.data;
};

export const flow = async (id: string, request: FlowRequest) => {
    await authApi.post(`/api/v1/assessments/${id}/flow`, request);
};

export const getAssessmentStatus = async (id: string) => {
    const response = await authApi.get<AssessmentStatusResponse>(
        `/api/v1/assessments/${id}/status`
    );
    return response.data;
};

export const getGeneralInformation = async (id: string) => {
    const response = await authApi.get<GeneralInformation>(
        `/api/v1/assessments/${id}/general_information`
    );
    return response.data;
};

export const createGeneralInformation = async (
    request: CreateUpdateGeneralInformation
) => {
    const response = await authApi.post<GeneralInformation>(
        `/api/v1/assessments/general_information`,
        request
    );
    return response.data;
};

export const updateGeneralInformation = async (
    id: string,
    request: CreateUpdateGeneralInformation
) => {
    const response = await authApi.put<GeneralInformation>(
        `/api/v1/assessments/${id}/general_information`,
        request
    );
    return response.data;
};

export const getCompanyInformation = async (id: string) => {
    const response = await authApi.get<CompanyInformation>(
        `/api/v1/assessments/${id}/company_information`
    );
    return response.data;
};

export const updateCompanyInformation = async (
    id: string,
    request: CreateUpdateCompanyInformation
) => {
    const response = await authApi.put<CompanyInformation>(
        `/api/v1/assessments/${id}/company_information`,
        request
    );
    return response.data;
};

export const updateCompanyInformationBasic = async (
    id: string,
    request: CompanyInformationBasic
) => {
    const response = await authApi.put<CompanyInformation>(
        `/api/v1/assessments/${id}/company_information_basic`,
        request
    );
    return response.data;
};

export const getStatistics = async (id: string) => {
    const response = await authApi.get<Statistics>(
        `/api/v1/assessments/${id}/statistics`
    );
    return response.data;
};

export const changeQaType = async (id: string, qaType: AssuranceType) => {
    const response = await authApi.post(
        `/api/v1/assessments/${id}/change_qa_type?change_to=${qaType}`
    );
    return response.data;
};

export const changeInternalExternal = async (
    request: InternalExternalRequest
) => {
    const response = await authApi.post(
        `/api/v1/assessments/internal_external`,
        request
    );
    return response.data;
};

export const getAnalystAndAssurer = async (id: string) => {
    const response = await authApi.get(
        `/api/v1/assessments/${id}/analyst_and_assurer`
    );
    return response.data;
};

export const updateAnalystAndAssurer = async (
    id: string,
    request: UpdateAnalystAndAssurer
) => {
    const response = await authApi.put(
        `/api/v1/assessments/${id}/analyst_and_assurer`,
        request
    );
    return response.data;
};

export const deleteAssessment = async (id: string) => {
    const response = await authApi.delete(`/api/v1/assessments/${id}`);
    return response.data;
};
