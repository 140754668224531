import { Button, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useMemo } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import { toast } from 'react-toastify';

type Props = {
    onSubmit: (
        oldPassword: string,
        password: string,
        repeatPassword: string
    ) => void;
};

export const ChangePasswordForm: FC<Props> = ({ onSubmit }) => {
    const formName = 'ChangePasswordForm';

    const validationSchema = useMemo(
        () =>
            Yup.object({
                oldPassword: Yup.string().required('Required'),
                password: Yup.string().required('Required').min(12),
                repeatPassword: Yup.string().required('Required').min(12),

            }),
        []
    );

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            password: '',
            repeatPassword: '',
        },
        validationSchema,
        onSubmit: (values) => {
            if (values.password !== values.repeatPassword) {
                toast.error('Password does not match');
            } else {
                onSubmit(
                    values.oldPassword,
                    values.password,
                    values.repeatPassword
                );
            }
        },
    });

    type FormikTypes = typeof formik.initialValues;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2}>
                <CustomTextField
                    {...generateTextFieldProps<FormikTypes>(
                        'oldPassword',
                        'Old Password',
                        formName,
                        formik
                    )}
                    type='password'
                />
                <CustomTextField
                    {...generateTextFieldProps<FormikTypes>(
                        'password',
                        'New Password',
                        formName,
                        formik
                    )}
                    type='password'
                />
                <CustomTextField
                    {...generateTextFieldProps<FormikTypes>(
                        'repeatPassword',
                        'Repeat Password',
                        formName,
                        formik
                    )}
                    type='password'
                />
                <Button
                    color='primary'
                    data-testid={`${formName}-submit`}
                    role='submit'
                    type='submit'
                    variant='contained'
                >
                    Save
                </Button>
            </Stack>
        </form>
    );
};
