import { Container } from '@mui/material';
import { AssessmentDetailsOverallInfo } from 'components/assessment/assessment-details-overall-info';
import { PageTitle } from 'components/shared/page-title';
import { FC } from 'react';
import { AssessmentStepper } from 'components/assessment/assessment-stepper/assessment-stepper';

export const AssessmentDetails: FC = () => {
    return (
        <Container>
            <PageTitle title='Company Assessment' />
            <AssessmentDetailsOverallInfo />
            <AssessmentStepper />
        </Container>
    );
};
