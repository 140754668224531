import { useQuery } from 'react-query';
import { ImportListResponse } from './importType';
import { getImportList } from './importService';

type Props<M> = {
    onError?: (error: Error) => void;
    onSuccess?: (data: ImportListResponse) => void;
    mapper?: (data?: ImportListResponse) => M | ImportListResponse | undefined;
    enabled?: boolean;
    refetchInterval?: number | false;
};

const defaultMapper = (data?: ImportListResponse) => data;

export const useGetImportList = <M = ImportListResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
    refetchInterval = false,
}: Props<M>) => {
    const query = useQuery<ImportListResponse, Error>(
        ['imports'],
        getImportList,
        {
            onSuccess,
            onError,
            enabled: enabled,
            refetchInterval: refetchInterval,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
