import { TableBody } from '@mui/material';

import { FC } from 'react';
import { CustomTableRow } from './table-row';
import { Import } from 'api/hooks/import/importType';

type Props = {
    data: Import[];
};

export const CustomTableBody: FC<Props> = ({ data }) => {
    return (
        <>
            <TableBody>
                {data.map((row) => {
                    return <CustomTableRow data={row} key={row._id} />;
                })}
            </TableBody>
        </>
    );
};
