import { useCreateControversyAssessment } from 'api/hooks/controversy-assessment/useCreateControversyAssessment';
import { useDeleteControversyAssessment } from 'api/hooks/controversy-assessment/useDeleteControversyAssessment';
import { useGetControversyAssessmentList } from 'api/hooks/controversy-assessment/useGetControversyAssessmentList';
import { useUpdateControversyAssessment } from 'api/hooks/controversy-assessment/useUpdateControversyAssessment';
import { useState } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { DataState } from 'store/enums';
import {
    selectControversiesAssessment,
    setInitialControversies,
} from 'store/slices/controversiesAssessmentSlice';
import { useAppDispatch, useAppSelector } from 'store/store';

export const useControversyAssessmentnSave = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { assessmentId } = useParams();
    const dispatch = useAppDispatch();

    const data = useAppSelector(selectControversiesAssessment);

    const { mutateAsync: update } = useUpdateControversyAssessment({});
    const { mutateAsync: create } = useCreateControversyAssessment({});
    const { mutateAsync: remove } = useDeleteControversyAssessment({});

    const { query: queryControversyAssessment } =
        useGetControversyAssessmentList({
            assessmentId: assessmentId ?? '',
            enabled: false,
        });

    const save = async (): Promise<boolean> => {
        const updateData = data.table.filter(
            (element) => element.state === DataState.UPDATED
        );
        const createData = data.table.filter(
            (element) => element.state === DataState.NEW
        );

        const deleteData = data.table.filter(
            (element) => element.state === DataState.DELETED
        );

        if (updateData.length || createData.length || deleteData.length) {
            setIsLoading(true);
            let success = true;
            for (const element of updateData) {
                try {
                    await update({
                        id: element.data._id,
                        request: { assessment: assessmentId, ...element.data },
                    });
                } catch (error) {
                    success = false;
                }
            }

            for (const element of createData) {
                try {
                    const { _id, ...rest } = element.data;
                    await create({
                        request: { assessment: assessmentId, ...rest },
                    });
                } catch (error) {
                    success = false;
                }
            }

            for (const element of deleteData) {
                try {
                    await remove(element.data._id);
                } catch (error) {
                    success = false;
                }
            }

            if (success) {
                toast.success('Controversies Saved!');
                const data = await queryControversyAssessment.refetch();
                dispatch(setInitialControversies(data.data ?? []));
            } else {
                toast.error('Controversies  Save Failed!');
            }

            return success;
        }

        return true;
    };

    return { save, isLoading };
};
