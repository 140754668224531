import { Button, TableBody, TableCell, TableRow } from '@mui/material';

import { FC } from 'react';
import { CompanyPerformanceQuestion } from 'api/hooks/company-performance-question/companyPerformanceQuestionsTypes';
import { ConfirmationDialog } from 'components/confirmation-dialog';

type Props = {
    data: CompanyPerformanceQuestion[];
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
};

export const CustomTableBody: FC<Props> = ({ data, onEdit, onDelete }) => {
    return (
        <>
            <TableBody>
                {data.map((row) => {
                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell>{row.section}</TableCell>
                            <TableCell align='right'>{row.topic}</TableCell>
                            <TableCell align='right'>
                                {row.link_to_at}
                            </TableCell>
                            <TableCell align='right'>{row.unique_id}</TableCell>
                            <TableCell align='right'>
                                {row.question_id}
                            </TableCell>
                            <TableCell align='right'>
                                <Button onClick={() => onEdit(row._id)}>
                                    Edit
                                </Button>
                            </TableCell>
                            <TableCell align='right'>
                                <ConfirmationDialog
                                    id={row._id}
                                    onDelete={onDelete}
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </>
    );
};
