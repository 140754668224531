import { FC } from 'react';
import { useParams } from 'react-router';
import { AssessmentStatus } from 'api/hooks/assessment/assessmentTypes';
import { useCheckAssessmentStatus } from 'api/hooks/common/useCheckAssessmentStatus';
import { CompanyPerformanceAssessmentListTable } from './company-performance-assessment-list-table';
import { CompanyPerformanceAssessmentFilterSection } from './company-performance-assessment-filter-section';

export const CompanyPerformanceAssessment: FC = () => {
    const { assessmentId } = useParams();

    const assessmentStatus = useCheckAssessmentStatus({
        assessmentId: assessmentId ?? '',
        currentStatuses: [
            AssessmentStatus.ANALYSIS_IN_PROGRESS,
            AssessmentStatus.ASSURANCE_IN_PROGRESS,
        ],
    });

    return (
        <>
            <CompanyPerformanceAssessmentFilterSection />
            <CompanyPerformanceAssessmentListTable
                isReadOnly={assessmentStatus.isReadOnly}
            />
        </>
    );
};
