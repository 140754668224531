import { authApi } from 'lib';
import { ApiKeyResponse } from './apiKeysTypes';

export const createApiKey = async (userId: string) => {
    const response = await authApi.post<ApiKeyResponse>(
        `api/v1/users/${userId}/api_keys`
    );
    return response.data;
};

export const createMeApiKey = async () => {
    const response = await authApi.post<ApiKeyResponse>(
        `api/v1/users/me/api_keys`
    );
    return response.data;
};
