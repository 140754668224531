import { useQuery } from 'react-query';
import { CategoryListResponse } from './categoryTypes';
import { getCategoryList } from './categoryService';

type Props<M> = {
    onError?: (error: Error) => void;
    onSuccess?: (data: CategoryListResponse) => void;
    mapper?: (
        data?: CategoryListResponse
    ) => M | CategoryListResponse | undefined;
    enabled?: boolean;
};

const defaultMapper = (data?: CategoryListResponse) => data;

export const useGetCategoryList = <M = CategoryListResponse>({
    onError = () => {},
    onSuccess = () => {},
    mapper = defaultMapper,
    enabled = true,
}: Props<M>) => {
    const query = useQuery<CategoryListResponse, Error>(
        ['categories'],
        getCategoryList,
        {
            onSuccess,
            onError,
            enabled: enabled,
        }
    );

    return {
        data: mapper(query.data) as M,
        isLoading: query.isLoading,
        error: query.error,
        query,
    };
};
