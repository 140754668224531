import { authApi } from 'lib';
import { CreateReport, ReportListResponse } from './reportTypes';

export const getReportList = async () => {
    const response = await authApi.get<ReportListResponse>(
        'api/v1/reports/assessments'
    );
    return response.data;
};

export const createReport = async (request: CreateReport) => {
    const response = await authApi.post<void>(
        'api/v1/reports/assessments',
        request
    );
    return response.data;
};
