import { useMutation } from 'react-query';
import { LoginResponse } from '../user/userTypes';
import { verifyOtp } from '../user/userService';
import { modifyError } from 'utils/error-modifer-utils';
import { toast } from 'react-toastify';

type Props = {
    onError?: (error: Error) => void;
    onSuccess?: (data: LoginResponse) => void;
};

export const useVerifyOtp = ({
    onSuccess = () => {},
    onError = () => {},
}: Props) => {
    const onErrorModified = (error: any) => {
        const errors = modifyError(error?.response?.data);
        if (errors) {
            for (const err of errors) {
                toast.error(err);
            }
        } else {
            toast.error('Internal error, please contact administrator');
        }

        if (onError) {
            onError(error);
        }
    };
    const mutation = useMutation(verifyOtp, {
        onSuccess,
        onError: onErrorModified,
    });

    return mutation;
};
