import { Button, TableBody, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { FC } from 'react';
import { Country } from 'api/hooks/country/countryTypes';

type Props = {
    data: Country[];
    openEditFormDialog: (id: string) => void;
};

export const CustomTableBody: FC<Props> = ({ data, openEditFormDialog }) => {
    return (
        <>
            <TableBody>
                {data.map((row) => {
                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell>{row.name}</TableCell>
                            <TableCell align='right'>{row.code}</TableCell>
                            <TableCell align='right'>
                                <Button
                                    onClick={() => openEditFormDialog(row._id)}
                                >
                                    <EditIcon fontSize='large' />
                                </Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </>
    );
};
