import { Button, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';

type Props = {
    onSubmit: (userName: string, password: string) => void;
};

export const LoginForm: FC<Props> = ({ onSubmit }) => {
    const formName = 'LoginForm';
    const validationSchema = Yup.object({
        password: Yup.string().required('Required'),
        userName: Yup.string().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            userName: '',
            password: '',
        },
        validationSchema,
        onSubmit: (data) => {
            onSubmit(data.userName, data.password);
        },
    });

    type FormikTypes = typeof formik.initialValues;

    return (
        <form onSubmit={formik.handleSubmit}>
            <Stack spacing={2} px={2}>
                <CustomTextField
                    {...generateTextFieldProps<FormikTypes>(
                        'userName',
                        'Username',
                        formName,
                        formik
                    )}
                />
                <CustomTextField
                    {...generateTextFieldProps<FormikTypes>(
                        'password',
                        'Password',
                        formName,
                        formik
                    )}
                    type='password'
                />
                <Button
                    type='submit'
                    color='primary'
                    data-testid={`${formName}-submit`}
                    role='submit'
                    variant='contained'
                    // onClick={() =>
                    //     onSubmit(formik.values.userName, formik.values.password)
                    // }
                >
                    Log in
                </Button>
            </Stack>
        </form>
    );
};
