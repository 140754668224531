import { Button, Chip, TableBody, TableCell, TableRow } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

import { FC } from 'react';
import { Industry } from 'api/hooks/industry/industryTypes';

type Props = {
    data: Industry[];
    openEditFormDialog: (id: string) => void;
};

export const CustomTableBody: FC<Props> = ({ data, openEditFormDialog }) => {
    return (
        <>
            <TableBody>
                {data.map((row) => {
                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell>{row.name}</TableCell>
                            <TableCell align='right'>{row.code}</TableCell>
                            <TableCell align='right'>
                                {row.sub_industries.map(
                                    (subIndustry, index) => {
                                        return (
                                            <Chip
                                                key={index}
                                                label={subIndustry.name}
                                                sx={{ m: 0.5 }}
                                            />
                                        );
                                    }
                                )}
                            </TableCell>
                            <TableCell align='right'>
                                {row.participates_in_reports ? 'Yes' : 'No'}
                            </TableCell>
                            <TableCell align='right'>
                                <Button
                                    onClick={() => openEditFormDialog(row._id)}
                                >
                                    <EditIcon fontSize='large' />
                                </Button>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </>
    );
};
