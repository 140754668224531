import { Button, TableBody, TableCell, TableRow } from '@mui/material';
import { Category } from 'api/hooks/category/categoryTypes';

import { FC } from 'react';

type Props = {
    data: Category[];
    onEdit: (id: string) => void;
    onDelete: (id: string) => void;
};

export const CustomTableBody: FC<Props> = ({ data, onEdit, onDelete }) => {
    return (
        <>
            <TableBody>
                {data.map((row) => {
                    return (
                        <TableRow
                            key={row._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell>{row.category_name}</TableCell>
                            <TableCell align='right'>
                                {row.category_code}
                            </TableCell>{' '}
                            <TableCell align='right'>
                                {row.first_sub_category_name}
                            </TableCell>
                            <TableCell align='right'>{row.version}</TableCell>
                            <TableCell align='right'>
                                {row.use_for_benchmarking ? 'Yes' : 'No'}
                            </TableCell>
                            <TableCell align='right'>
                                <Button onClick={() => onEdit(row._id)}>
                                    Edit
                                </Button>
                            </TableCell>
                            {/* <TableCell align='right'>
                                <ConfirmationDialog
                                    id={row._id}
                                    onDelete={onDelete}
                                />
                            </TableCell> */}
                        </TableRow>
                    );
                })}
            </TableBody>
        </>
    );
};
