export enum UpdateState {
    'IN_PROGRESS' = 'In Progress...',
    'NOT_SAVED' = 'Not Saved',
    'SAVED' = 'Saved',
}

export enum DataState {
    'INITIAL',
    'NEW',
    'UPDATED',
    'DELETED',
    'ERROR',
}
