import { TableCell, TableHead, TableRow } from '@mui/material';

export const CustomTableHeader = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell />
                <TableCell>User</TableCell>
                <TableCell align='right'>Type</TableCell>
                <TableCell align='right'>Status</TableCell>
                <TableCell align='right'>Start Date</TableCell>
                <TableCell align='right'>End Date</TableCell>
            </TableRow>
        </TableHead>
    );
};
