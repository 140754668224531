import {
    Button,
    Checkbox,
    FormControlLabel,
    Stack,
    TextField,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { useFormik } from 'formik';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
    CustomMultipleSelectField,
    generateMultipleSelectFieldProps,
} from 'components/shared/form/custom-multiple-select-field';
import {
    EnumToDropdownOptions,
    multipleSelectOptionsToString,
    stringToMultipleSelectOptions,
} from 'utils';
import {
    CustomSelectField,
    generateSelectFieldProps,
} from 'components/shared/form/custom-select-field';
import { CustomAutoCompleteField } from 'components/shared/form/custom-autocomplete-field';
import { autocompleteFormikValueToOptions } from 'utils/multiple-select-utils';
import { MultipleSelectType } from 'types/common-types';
import { toast } from 'react-toastify';
import {
    CreateReport,
    Report,
    ReportQueryAccessLevel,
    ReportQueryDataFormat,
    ReportQueryType,
} from 'api/hooks/report/reportTypes';
import { UserType } from 'api/hooks/user/userTypes';
import { Company } from 'api/hooks/company/companyTypes';
import { Industry } from 'api/hooks/industry/industryTypes';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { dateToAPIDate } from 'utils/common';

type Props = {
    companiesData: Company[];
    industriesData: Industry[];
    userType: UserType;
    onGenerateExport: (report: CreateReport) => void;
    isRegenerateReport?: boolean;
    regenerateReportData?: Report | null;
};

enum SearchType {
    INIT,
    COMPANY_ONLY,
    ALL,
}

export const QueryBuilderForm: FC<Props> = ({
    companiesData,
    industriesData,
    userType,
    onGenerateExport,
    isRegenerateReport = false,
    regenerateReportData,
}) => {
    const formName = 'QueryBuilderForm';

    const formik = useFormik({
        initialValues: useMemo(
            () => ({
                queryType: '',
                company: '',
                industry: '',
                subIndustry: '',
                fiscalYear: '',
                dataFormat: 'XLSX',
                dataReport: false,
                pdfReport: false,
                publication_date: '',
            }),
            []
        ),
        onSubmit: () => {},
    });
    type FormikTypes = typeof formik.initialValues;

    const [industryOptions, setIndustryOptions] = useState<
        MultipleSelectType[]
    >([]);

    const [subIndustriesOptions, setSubIndustriesOptions] = useState<
        MultipleSelectType[]
    >([]);
    const [companyOptions, setCompanyOptions] = useState<MultipleSelectType[]>(
        []
    );
    const [fiscalYearOptions, setFiscalYearOptions] = useState<
        MultipleSelectType[]
    >([]);

    const [searchType, setSearchType] = useState<SearchType>(SearchType.INIT);

    const handleGenerateExport = () => {
        if (!formik.values.company && !formik.values.industry) {
            toast.error('Company or Industry must be filled');
            return;
        }
        const companies = autocompleteFormikValueToOptions(
            formik.values.company
        ).map((val) => val.value);

        const industries = autocompleteFormikValueToOptions(
            formik.values.industry
        ).map((val) => val.value);

        const subIndustries = autocompleteFormikValueToOptions(
            formik.values.subIndustry
        ).map((val) => val.value);

        const fiscalYears = stringToMultipleSelectOptions(
            formik.values.fiscalYear
        ).map((val) => parseInt(val));

        const formats = stringToMultipleSelectOptions(
            formik.values.dataFormat
        ).map((val) => val as ReportQueryDataFormat);

        const type =
            userType === UserType.CLIENT
                ? ReportQueryType.EXTERNAL_CLIENT_DATA
                : (formik.values.queryType as ReportQueryType);

        let report: CreateReport = {
            type: type,
            companies: companies,
            industries: industries,
            sub_industries: subIndustries,
            fiscal_years: fiscalYears,
            formats: formats,
            data_report: formik.values.dataReport,
            pdf_report: formik.values.pdfReport,
            access_level: ReportQueryAccessLevel.PRIVATE,
        };
        if (formik.values.publication_date) {
            const publicationDate = dateToAPIDate(
                new Date(formik.values.publication_date)
            );
            report.publication_date = publicationDate;
        }

        onGenerateExport(report);
    };
    const handleAutoCompleteSelect = (name: string, newValue: string) => {
        formik.setFieldValue(name, newValue);
    };
    const handleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
    };
    const handleMultipleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        const newValue = Array.isArray(value)
            ? value.length
                ? multipleSelectOptionsToString(value)
                : null
            : value;

        formik.setFieldValue(name, newValue);
    };
    const handleMultipleDelete = (value: string, name: keyof FormikTypes) => {
        const newArray = stringToMultipleSelectOptions(
            formik.values[name] as string
        )?.filter((current) => {
            return current !== value;
        });
        const newValues = multipleSelectOptionsToString(newArray);

        formik.setFieldValue(name, newValues === '' ? null : newValues);
    };
    const handleClean = () => {
        formik.resetForm();
        setSearchType(SearchType.INIT);
    };

    const filterCompanies = useCallback(() => {
        const selectedIndustryValues = stringToMultipleSelectOptions(
            formik.values.industry
        ).map((val) => (JSON.parse(val) as MultipleSelectType).value);
        const selectedSubIndustryValues = stringToMultipleSelectOptions(
            formik.values.subIndustry
        ).map((val) => (JSON.parse(val) as MultipleSelectType).value);

        const filteredCompanies = companiesData.filter((company) => {
            const industryCode = company.industry?.code ?? '';
            const subIndustryCodes =
                company.sub_industries?.map(
                    (subIndustry) => subIndustry.code ?? ''
                ) ?? [];

            const industryMatch = selectedIndustryValues.includes(industryCode);
            const subIndustryMatch =
                selectedSubIndustryValues.length > 0
                    ? selectedSubIndustryValues.some((subIndustry) =>
                          subIndustryCodes.includes(subIndustry)
                      )
                    : true;

            return industryMatch && subIndustryMatch;
        });

        const companies = filteredCompanies.map((company) => ({
            value: company.isin,
            label: company.name,
        }));

        setCompanyOptions(companies);
    }, [companiesData, formik.values.industry, formik.values.subIndustry]);

    //set initial data
    useEffect(() => {
        const companies = companiesData.map((company) => ({
            value: company.isin,
            label: company.name,
        }));
        setCompanyOptions(companies);

        const fiscalYears = [...Array(new Date().getFullYear() - 2016).keys()]
            .reverse()
            .map((i) => {
                const modifiedYear = 2017 + i;
                return {
                    value: modifiedYear.toString(),
                    label: modifiedYear.toString(),
                };
            });
        setFiscalYearOptions(fiscalYears);

        const industries = industriesData.map((industry) => ({
            value: industry.code,
            label: industry.name,
        }));
        setIndustryOptions(industries);
    }, [companiesData, industriesData]);

    //set sub industries
    useEffect(() => {
        const selectedIndustryValues = stringToMultipleSelectOptions(
            formik.values.industry
        ).map((val) => (JSON.parse(val) as MultipleSelectType).value);

        const selectedIndustries = industriesData.filter((industry) =>
            selectedIndustryValues.includes(industry.code)
        );
        const subIndustries = selectedIndustries.reduce(
            (prevValue, currValue) => {
                const nextArray = currValue.sub_industries.map(
                    (subIndustry) => ({
                        value: subIndustry.code,
                        label: subIndustry.name,
                    })
                );
                return [...prevValue, ...nextArray];
            },
            [] as MultipleSelectType[]
        );
        formik.setFieldValue('subIndustry', '');
        formik.setFieldValue('company', '');
        setSubIndustriesOptions(subIndustries);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik.values.industry, industriesData, formik.setFieldValue]);

    useEffect(() => {
        const isEmptyForm =
            formik.values.company === '' &&
            formik.values.industry === '' &&
            formik.values.subIndustry === '';
        if (isEmptyForm) {
            setSearchType(SearchType.INIT);
        }

        if (!isEmptyForm && formik.values.company !== '') {
            setSearchType(SearchType.COMPANY_ONLY);
        }

        if (!isEmptyForm && formik.values.industry !== '') {
            setSearchType(SearchType.ALL);
        }
    }, [
        formik.values.company,
        formik.values.industry,
        formik.values.subIndustry,
    ]);

    useEffect(() => {
        if (searchType === SearchType.ALL) {
            filterCompanies();
        }
        if (searchType === SearchType.INIT) {
            const companies = companiesData.map((company) => ({
                value: company.isin,
                label: company.name,
            }));
            setCompanyOptions(companies);
        }
    }, [searchType, companiesData, filterCompanies]);

    useEffect(() => {
        if (isRegenerateReport) {
            const companies = multipleSelectOptionsToString(
                regenerateReportData?.query.companies.map((company) =>
                    JSON.stringify({ value: company.isin, label: company.name })
                ) ?? []
            );
            const industries = multipleSelectOptionsToString(
                regenerateReportData?.query.industries.map((industry) =>
                    JSON.stringify({
                        value: industry.code,
                        label: industry.name,
                    })
                ) ?? []
            );
            const dataFormats = multipleSelectOptionsToString(
                regenerateReportData?.query.formats
            );
            const fiscalYears = multipleSelectOptionsToString(
                regenerateReportData?.query.fiscal_years.map((year) =>
                    year.toString()
                )
            );
            formik.setValues({
                company: companies,
                queryType: regenerateReportData?.query.type ?? '',
                industry: industries ?? '',
                subIndustry: '',
                fiscalYear: fiscalYears,
                dataFormat: dataFormats,
                dataReport: regenerateReportData?.query.data_report ?? false,
                pdfReport: regenerateReportData?.query.pdf_report ?? false,
                publication_date:
                    regenerateReportData?.query.publication_date.toString() ??
                    '',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRegenerateReport, regenerateReportData]);

    const dataFormatsOptions = EnumToDropdownOptions(ReportQueryDataFormat);
    const reportQueryTypesOptions = EnumToDropdownOptions(ReportQueryType);

    return (
        <form>
            <Stack spacing={4}>
                <Stack direction={'row'} spacing={2}>
                    {userType !== UserType.CLIENT && (
                        <CustomSelectField
                            options={reportQueryTypesOptions}
                            onChange={handleSelect}
                            {...generateSelectFieldProps<FormikTypes>(
                                'queryType',
                                'Query Type',
                                formName,
                                formik
                            )}
                        />
                    )}
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomAutoCompleteField
                        options={companyOptions}
                        onChange={(value) => {
                            handleAutoCompleteSelect('company', value);
                        }}
                        value={formik.values.company}
                        label={'Company'}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomAutoCompleteField
                        options={industryOptions}
                        onChange={(value) => {
                            handleAutoCompleteSelect('industry', value);
                        }}
                        value={formik.values.industry}
                        label={'ESG Industry'}
                        disabled={searchType === SearchType.COMPANY_ONLY}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomAutoCompleteField
                        options={subIndustriesOptions}
                        onChange={(value) => {
                            handleAutoCompleteSelect('subIndustry', value);
                        }}
                        value={formik.values.subIndustry}
                        label={'ESG Sub-Industry'}
                        disabled={searchType === SearchType.COMPANY_ONLY}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <DesktopDatePicker
                        label='Published Since'
                        inputFormat='DD/MM/YYYY'
                        value={formik.values.publication_date ?? null}
                        onChange={(newValue) => {
                            formik.setFieldValue('publication_date', newValue);
                        }}
                        renderInput={(params) => {
                            return (
                                <TextField
                                    {...params}
                                    fullWidth
                                    variant='outlined'
                                    error={false}
                                />
                            );
                        }}
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <CustomMultipleSelectField
                        options={fiscalYearOptions}
                        onChange={handleMultipleSelect}
                        {...generateMultipleSelectFieldProps<FormikTypes>(
                            'fiscalYear',
                            'Fiscal Year',
                            formName,
                            formik
                        )}
                        handleDelete={(value) =>
                            handleMultipleDelete(value, 'fiscalYear')
                        }
                    />
                    <CustomMultipleSelectField
                        options={dataFormatsOptions}
                        onChange={handleMultipleSelect}
                        {...generateMultipleSelectFieldProps<FormikTypes>(
                            'dataFormat',
                            'Data Format',
                            formName,
                            formik
                        )}
                        handleDelete={(value) =>
                            handleMultipleDelete(value, 'dataFormat')
                        }
                    />
                </Stack>
                <Stack direction={'row'} spacing={2}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.dataReport}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'dataReport',
                                        !formik.values.dataReport
                                    );
                                }}
                            />
                        }
                        label='Data'
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.pdfReport}
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'pdfReport',
                                        !formik.values.pdfReport
                                    );
                                }}
                            />
                        }
                        label='Report'
                    />
                </Stack>
                <Stack
                    direction={'row'}
                    spacing={2}
                    justifyContent={'flex-end'}
                >
                    <Button variant='contained' onClick={handleClean}>
                        Clear
                    </Button>
                    <Button
                        color='primary'
                        data-testid={`${formName}-submit`}
                        disabled={
                            !(
                                formik.values.pdfReport ||
                                formik.values.dataReport
                            )
                        }
                        onClick={handleGenerateExport}
                        variant='contained'
                    >
                        Generate Export
                    </Button>
                </Stack>
            </Stack>
        </form>
    );
};
