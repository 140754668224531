import { authApi } from 'lib';
import {
    CreateMainQuestionAssessment,
    MainQuestionAssessmentFilters,
    MainQuestionAssessmentListResponse,
} from './mainQuestionAssessmentTypes';

export const getMainQuestionAssessmentList = async (
    id: string,
    filters: MainQuestionAssessmentFilters
) => {
    const params = new URLSearchParams();

    if (filters.answer) {
        params.append('answer', String(filters.answer));
    }

    if (filters.qa_status) {
        // params.append('qa_status', String(filters.qa_status));
        filters.qa_status.forEach((data) => {
            params.append('qa_status', data);
        });
    }

    if (filters.has_comments) {
        params.append('has_comments', String(filters.has_comments));
    }

    if (filters.question) {
        params.append('question', String(filters.question));
    }

    if (filters.unique_id) {
        filters.unique_id.forEach((data) => {
            params.append('unique_id', data);
        });
    }

    if (filters.identifier) {
        filters.identifier.forEach((data) => {
            params.append('identifier', data);
        });
    }

    if (filters.category) {
        filters.category.forEach((data) => {
            params.append('category', data);
        });
    }

    if (filters.category) {
        filters.category.forEach((data) => {
            params.append('category', data);
        });
    }

    if (filters.scoring_judgment) {
        params.append('scoring_judgment', String(filters.scoring_judgment));
    }

    if (filters.reference_for_answer) {
        params.append(
            'reference_for_answer',
            String(filters.reference_for_answer)
        );
    }

    if (filters.sub_category_first) {
        filters.sub_category_first.forEach((data) => {
            params.append('sub_category_first', data);
        });
    }

    if (filters.sub_category_first) {
        filters.sub_category_first.forEach((data) => {
            params.append('sub_category_first', data);
        });
    }

    if (filters.sub_category_second) {
        filters.sub_category_second.forEach((data) => {
            params.append('sub_category_second', data);
        });
    }

    if (filters.scoring_guidance) {
        params.append('scoring_guidance', String(filters.scoring_guidance));
    }

    if (filters.order_by) {
        params.append('order_by', String(filters.order_by));
    }

    const response = await authApi.get<MainQuestionAssessmentListResponse>(
        `/api/v1/main_question_assessments?${params.toString()}`,
        { params: { assessment_id: id } }
    );
    return response.data;
};

export const updateMainQuestionAssessment = async (
    id: string,
    request: CreateMainQuestionAssessment
) => {
    const requestData = replaceEmptyStringsWithNull(request);

    const response = await authApi.put<CreateMainQuestionAssessment>(
        `/api/v1/main_question_assessments/${id}`,
        requestData
    );
    return response.data;
};

// Utility function to replace empty strings with null
const replaceEmptyStringsWithNull = (
    obj: CreateMainQuestionAssessment
): CreateMainQuestionAssessment => {
    const replacedObj: CreateMainQuestionAssessment = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            //@ts-ignore
            if (typeof obj[key] === 'string' && obj[key].trim() === '') {
                //@ts-ignore
                replacedObj[key] = null;
            } else {
                //@ts-ignore
                replacedObj[key] = obj[key];
            }
        }
    }
    return replacedObj;
};
