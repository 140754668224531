export const booleanToText = (bool: boolean) => {
    return bool ? 'Yes' : 'No';
};

export const dateToAPIDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
    const day = String(date.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
};

export const formatNumber = (number: number) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(number);
};

export const formatNumberWithThousandSeparations = (number: number) => {
    return new Intl.NumberFormat('en-US').format(number);
};
