import { Button, Container, SelectChangeEvent, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { FC, useMemo } from 'react';
import * as Yup from 'yup';
import {
    CustomTextField,
    generateTextFieldProps,
} from 'components/shared/form/custom-text-field';
import {
    CustomSelectField,
    generateSelectFieldProps,
} from 'components/shared/form/custom-select-field';
import { useGetIndustries } from 'api/hooks';
import { WithSpinner } from 'components/with-spinner';
import { CreateUpdateMainQuestionVariation } from 'api/hooks/main-question/mainQuestionsTypes';

type Props = {
    onEdit: (
        variationIndex: number,
        variation: CreateUpdateMainQuestionVariation
    ) => void;
    onAdd: (variation: CreateUpdateMainQuestionVariation) => void;
    onCancel: () => void;
    variation?: {
        data: CreateUpdateMainQuestionVariation;
        index: number;
    };
};

export const VariationForm: FC<Props> = ({
    onCancel,
    onAdd,
    onEdit,
    variation,
}) => {
    const { data: industries, isLoading: industriesLoading } = useGetIndustries(
        {}
    );
    const isEdit = !!variation;

    const formName = VariationForm.name;
    const validationSchema = Yup.object({
        industry: Yup.string().required('Required'),
        sub_industry: Yup.string().required('Required'),
        question_order_number: Yup.string().required('Required'),
        scoring_guidance: Yup.string().required('Required'),
        max_score_possible: Yup.number().required('Required'),
    });

    const formik = useFormik({
        initialValues: {
            industry: variation?.data.industry ?? '',
            sub_industry: variation?.data.sub_industry ?? '',
            question_number: variation?.data.question_number.toString() ?? '',
            scoring_guidance: variation?.data.scoring_guidance ?? '',
            max_score_possible:
                variation?.data.max_score_possible?.toString() ?? '0',
        },
        validationSchema,
        onSubmit: (data) => {},
    });
    type FormikTypes = typeof formik.initialValues;

    const handleAddSave = () => {
        if (isEdit) {
            onEdit(variation.index, {
                industry: formik.values.industry,
                sub_industry: formik.values.sub_industry,
                scoring_guidance: formik.values.scoring_guidance,
                max_score_possible: parseFloat(
                    formik.values.max_score_possible
                ),
                question_number: parseInt(formik.values.question_number),
            });
        } else {
            onAdd({
                industry: formik.values.industry,
                sub_industry: formik.values.sub_industry,
                scoring_guidance: formik.values.scoring_guidance,
                max_score_possible: parseFloat(
                    formik.values.max_score_possible
                ),
                question_number: parseInt(formik.values.question_number),
            });
        }
    };

    const handleSelect = (event: unknown | SelectChangeEvent) => {
        const { name, value } = (event as SelectChangeEvent).target;
        formik.setFieldValue(name, value);
    };

    const industriesOptions = useMemo(() => {
        if (industries) {
            return industries.map((industry) => ({
                value: industry.code,
                label: industry.name,
            }));
        }

        return [];
    }, [industries]);

    const subIndustriesOptions = useMemo(() => {
        if (industries) {
            return industries
                .find((ind) => ind.code === formik.values.industry)
                ?.sub_industries.map((subIndustry) => ({
                    value: subIndustry.code,
                    label: subIndustry.name,
                }));
        }

        return [];
    }, [industries, formik.values.industry]);

    const isLoading = industriesLoading;

    return (
        <WithSpinner isLoading={isLoading}>
            <Container>
                <Stack spacing={2}>
                    <Stack direction={'row'} spacing={2}>
                        <CustomSelectField
                            options={industriesOptions}
                            onChange={handleSelect}
                            {...generateSelectFieldProps<FormikTypes>(
                                'industry',
                                'Industry',
                                formName,
                                formik
                            )}
                        />
                        <CustomSelectField
                            options={subIndustriesOptions}
                            onChange={handleSelect}
                            {...generateSelectFieldProps<FormikTypes>(
                                'sub_industry',
                                'Sub-Industry',
                                formName,
                                formik
                            )}
                        />
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                        <CustomTextField
                            {...generateTextFieldProps<FormikTypes>(
                                'question_number',
                                'Question Order Number',
                                formName,
                                formik
                            )}
                        />
                        <CustomTextField
                            {...generateTextFieldProps<FormikTypes>(
                                'scoring_guidance',
                                'Scoring Guidance',
                                formName,
                                formik
                            )}
                        />
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                        <CustomTextField
                            {...generateTextFieldProps<FormikTypes>(
                                'max_score_possible',
                                'Max Score',
                                formName,
                                formik
                            )}
                        />
                        <div style={{ width: '100%' }} />
                    </Stack>
                    <Stack direction={'row'} spacing={2}>
                        <Button
                            variant='outlined'
                            role='submit'
                            type='submit'
                            onClick={handleAddSave}
                        >
                            {isEdit ? 'Save' : 'Add'}
                        </Button>
                        <Button variant='outlined' onClick={onCancel}>
                            Cancel
                        </Button>
                    </Stack>
                </Stack>
            </Container>
        </WithSpinner>
    );
};
